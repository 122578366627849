import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import './tag-create.styles.scss';
import { ITagUser } from '../../../types/interfaces/tags.interface';
import * as apiTags from '../../../api/api.tags';
import { RootState } from '../../../types/root-state';
import { useSelector } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import EditInput from '../../general/edit-input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { refetchApplicants } from '../../../redux/Dashboard/dashboard.actions';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	setTagsArray: Dispatch<SetStateAction<ITagUser[]>>;
	tagsArray: ITagUser[];
	setShowSearchTags: Dispatch<SetStateAction<boolean>>;
	handleMarkUrgent: (bool: boolean) => void;
};

const TagCreate: FC<Props> = ({
	setShowModal,
	setTagsArray,
	tagsArray,
	setShowSearchTags,
	handleMarkUrgent,
}) => {
	const dispatch = useDispatch();

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const [state, setState] = useState({
		displayName: '',
		description: '',
		automatic: false,
	});

	const handleGetInfo = (val: string, input: string): void => {
		setState({
			...state,
			[input]: val,
		});
	};

	const postTag = () => {
		return apiTags
			.postTag(applicant.workerUuid, state)
			.then((res) => {
				if (res.data && res.data.data) {
					if (state.displayName.toLowerCase() === 'urgent') {
						handleMarkUrgent(true);
					}
					dispatch(refetchApplicants());
					setTagsArray([...tagsArray, res.data.data]);
					setShowSearchTags(false);
					setShowModal(false);
					dispatch(
						setAlert({
							type: 'success',
							message:
								'Successfully created tag and added to user',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message:
							err.message ===
							'Tag is already attached onto this user'
								? err.message
								: 'Unable to create tag',
						isVisible: true,
					}),
				);
			});
	};
	return (
		<div>
			<div className='create-tag-inputs'>
				<div className='create-tag-input'>
					<EditInput
						inputName='displayName'
						placeholder='Tag Name'
						value={state.displayName}
						title=''
						emitValue={handleGetInfo}
					/>
				</div>

				<div className='create-tag-input'>
					<EditInput
						inputName='description'
						placeholder='Description'
						value={state.description}
						title=''
						emitValue={handleGetInfo}
					/>
				</div>
			</div>
			<div className='modal-button'>
				<OrkaButton
					buttonContent='Save'
					disabled={!state.description || !state.displayName}
					emitClicked={postTag}
				/>
			</div>
		</div>
	);
};

export default TagCreate;
