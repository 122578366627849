import React, { useState, useRef, FC, useEffect } from 'react';
import './risk-item.styles.scss';
import riskError from '../../../assets/icons/risk-error.svg';
import edit from '../../../assets/icons/edit.svg';
import trash from '../../../assets/icons/trash.svg';
import * as apiStage from '../../../api/api.stage';
import * as utils from '../../../utils/utilsFunctions';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';
import { useSelector, useDispatch } from 'react-redux';
import { setStageRisks } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import Modal from '../../modals/modal/modal.component';
import RiskModal from '../risk-modal/risk-modal.component';
import riskIcon from '../../../assets/icons/risk-error.svg';
import { RootState } from '../../../types/root-state';
import {
	IRiskItem,
	IStageDataItem,
} from '../../../types/interfaces/stage.interface';
import RiskModalAssigned from '../risk-modal-assigned/risk-modal-assigned.component';

type Props = {
	stage: IStageDataItem;
	risk: IRiskItem;
};

const RiskItemComponent: FC<Props> = ({ stage, risk }) => {
	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const user = useSelector((state: RootState) => state.auth.user);
	const [riskNotes, setRiskNotes] = useState<string>('');
	const [originalRisk, setOriginalRisk] = useState<string>('');
	const dispatch = useDispatch();
	const [assignedTo, setAssignedTo] = useState('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [riskStatus, setRiskStatus] = useState(
		risk.status
			? utils.capitalizeFirstLetter(risk.status)
			: 'Needs Approval',
	);

	useEffect(() => {
		risk.status
			? setRiskStatus(utils.capitalizeFirstLetter(risk.status))
			: setRiskStatus('Needs Approval');
	}, [risk.status]);

	useEffect(() => {
		const assignedStaff = allStaff.filter((staffMember) => {
			return staffMember.email === risk.assignedTo;
		});

		if (assignedStaff.length > 0) {
			setAssignedTo(
				`${utils.capitalizeFirstLetter(
					assignedStaff[0].name,
				)} ${utils.capitalizeFirstLetter(assignedStaff[0].surname)}`,
			);
		} else {
			setAssignedTo('');
		}
	}, [risk.assignedTo]);

	useEffect(() => {
		const text = '(assignedComments >)';

		if (risk) {
			const [raisedRisk, advisorComments] = risk.riskNotes.split(text);
			setOriginalRisk(raisedRisk);
			setRiskNotes(advisorComments ? advisorComments : '');
		} else {
			setOriginalRisk('');
			setRiskNotes('');
		}
	}, [JSON.stringify(stageData)]);

	const handleDeleteRisk = () => {
		const payload = {
			stageUuid: stage.uuid,
			uuid: risk.uuid,
			deletedAt: new Date(),
			riskType: risk.risk.description,
		};

		apiStage
			.updateRisk(applicant.workerUuid, payload)
			.then((res) => {
				if (res.status !== 404) {
					dispatch(setStageRisks(res.data));
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully added risk',
							isVisible: true,
						}),
					);
				} else {
					dispatch(
						setAlert({
							type: 'Error',
							message: 'Unable to add risk',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'Error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	const handleGetValFromAlert = (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			handleDeleteRisk();
		}
	};

	const handleEditRIsk = () => {
		setShowModal(true);
	};

	return (
		<div className='risk-item-container' ref={containerRef}>
			<div className='risk-item-icon'>
				<img src={riskError} alt='error' />
			</div>
			<div className='risk-item-info'>
				<div className='risk-item-info-top'>
					<h4>{risk.risk.title}</h4>
					{risk.risk.title === 'Risk' && <p>{riskStatus}</p>}
				</div>
				<p className='orka-profile-label'>
					{assignedTo.length > 0 ? `Assigned to: ${assignedTo}` : ''}
				</p>
				<p>
					Issue raised: <em>{originalRisk}</em>
				</p>
				{riskNotes && (
					<p>
						Assigned user notes: <em>{riskNotes}</em>
					</p>
				)}
			</div>
			<div className='risk-item-actions'>
				<img src={edit} alt='edit' onClick={handleEditRIsk} />
				<img
					src={trash}
					alt='delete'
					onClick={() => setShowAlertConfirm(true)}
				/>
			</div>
			{showAlertConfirm && (
				<AlertConfirm
					message='Are you sure you want to delete this file?'
					getVal={handleGetValFromAlert}
					confirm='Delete'
					reject='Cancel'
				/>
			)}
			{showModal && (
				<>
					{risk.assignedTo === user.email ? (
						<Modal
							title='Accept Risk'
							setShowModal={setShowModal}
							showModal={showModal}
							modalContentHeight='80vh'
							modalContentWidth='480px'
						>
							<RiskModalAssigned
								setShowModal={setShowModal}
								stage={stage}
								risk={risk}
							/>
						</Modal>
					) : (
						<Modal
							title='Add Risk'
							setShowModal={setShowModal}
							showModal={showModal}
							modalContentHeight='60vh'
							titleIcon={riskIcon}
						>
							<RiskModal
								setShowModal={setShowModal}
								stage={stage}
								risk={risk}
							/>
						</Modal>
					)}
				</>
			)}
		</div>
	);
};

export default RiskItemComponent;
