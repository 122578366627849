import React, { useState, FC } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../modals/modal/modal.component';
import ProfileDeclarationModal from '../profile-declaration-modal/profile-declaration-modal.component';
import { RootState } from '../../../types/root-state';

const ProfileDeclaration: FC = () => {
	const declaration = useSelector(
		(state: RootState) => state.profile.overallSubmission,
	);
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleShowDocument = () => {
		setShowModal(true);
	};

	return (
		<div>
			<p className='orka-profile-label margin-bottom-8'>
				Worker Declaration
			</p>
			{declaration && declaration.data && declaration.data.signedDate ? (
				<div>
					<button
						className='orka-link orka-profile-value'
						onClick={handleShowDocument}
					>
						View Document
					</button>
				</div>
			) : (
				<div>
					<p className='orka-profile-value'>No Document</p>
				</div>
			)}

			{showModal && (
				<Modal
					title='Worker Declaration'
					setShowModal={setShowModal}
					showModal={showModal}
				>
					<ProfileDeclarationModal setShowModal={setShowModal} />
				</Modal>
			)}
		</div>
	);
};

export default ProfileDeclaration;
