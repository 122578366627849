import React, {
	useEffect,
	useState,
	useRef,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import './modal.styles.scss';
import cross from '../../../assets/icons/cross.svg';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
const modalRoot = document.getElementById('modal-root') as HTMLElement;

type Props = {
	children: React.ReactNode;
	title?: string;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	showModal: boolean;
	modalContentHeight?: string;
	modalContentWidth?: string;
	titleIcon?: string | null;
};

const Modal: FC<Props> = ({
	title,
	children,
	setShowModal,
	showModal,
	modalContentHeight = '80vh',
	modalContentWidth = '560px',
	titleIcon = null,
}) => {
	const modalContent = useRef<HTMLDivElement>(null);
	const [fadeIn, setFade] = useState<boolean>(false);

	const handleEmitClose = () => {
		setShowModal(false);
	};

	useEffect(() => {
		if (showModal) {
			window.scrollTo(0, 0);
			window.addEventListener('scroll', handleDisableScroll);
		}
		return () => {
			window.removeEventListener('scroll', handleDisableScroll);
		};
	}, [showModal]);

	useEffect(() => {
		setFade(true);
		setTimeout(() => {
			if (modalContent.current) {
				modalContent.current.style.height = modalContentHeight;
				modalContent.current.style.maxWidth = modalContentWidth;
			}
		}, 10);
	}, [modalContentHeight]);

	const handleDisableScroll = () => {
		window.scrollTo(0, 0);
	};

	return ReactDOM.createPortal(
		<div className='modal-container'>
			<CSSTransition in={fadeIn} timeout={1000} classNames='my-node'>
				<div>
					{fadeIn && (
						<div className='modal-content' ref={modalContent}>
							<div className='modal-header'>
								<div className='modal-header-title'>
									{titleIcon && (
										<img src={titleIcon} alt='title icon' />
									)}
									{title && <h1>{title}</h1>}
								</div>

								<img
									onClick={handleEmitClose}
									alt='close'
									src={cross}
								/>
							</div>
							<div className='modal-center'>{children}</div>
						</div>
					)}
				</div>
			</CSSTransition>
		</div>,
		modalRoot,
	);
};

export default Modal;
