import '../external-dashboard-table/external-dashboard-table.styles.scss';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { Zoom } from '@mui/material';
import { generateUniqueKey } from '../../../utils/utilsFunctions';
import {
	getStatusBackgroundColour,
	getStatusClassName,
	checkInvitedDate,
	checkApplicantDates,
	DashboardToolTip,
	EmailRecipientToolTip,
	convertTimestampToDate,
	formatDateToSlash,
	mapStatusTab,
} from '../../../utils/externalUtilsFunction';
import { clearProfileData } from '../../../redux/Profile/profile.actions';
import { setAlert, setIsLoading } from '../../../redux/General/general.actions';

import ExternalDashboardGetReportData from './external-dashboard-get-report-data.component';

import uploadBlue from '../../../assets/icons/external-portal/upload/upload.png';
import editPencilIcon from '../../../assets/icons/external-portal/settings/edit.png';
import deleteIcon from '../../../assets/icons/external-portal/settings/delete.png';
import { IApplicant } from '../../../types/interface';

type Props = {
	columns: any;
	data: any;
	type: string;
	role: string;
	onClickEditEmail?: () => void;
	onSelectedData?: (data: any, action: string) => void | null | undefined;
};

const ExternalDashboardTable: FC<Props> = ({
	columns,
	data,
	type,
	role,
	onSelectedData,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const staff = useSelector((state: RootState) => state.auth.staff);
	const user = useSelector((state: RootState) => state.auth.user);

	const [showReport, setShowReport] = useState(false);
	const [uuid, setUuid] = useState('');
	const [workerUuid, setWorkerUuid] = useState('');

	const handleSelectEditDeleteIcon = (data: any, action: string) => {
		if (onSelectedData) {
			onSelectedData(data, action);
		}
	};

	useEffect(() => {
		dispatch(setIsLoading(false));
	}, []);

	const getInvitedByUser = (staffId: number[] | number) => {
		const idArray = Array.isArray(staffId) ? staffId : [staffId];
		const staffMember = staff.find(
			(s) =>
				s.id !== undefined &&
				idArray.includes(s.id) &&
				s.company?.id === user.company.id,
		);
		return staffMember
			? `${staffMember.name} ${staffMember.surname}`
			: 'Unknown';
	};

	const handleGenerateReport = (
		uuid: string,
		workerUuid: string,
		clearanceStatus: string,
	): void => {
		if (
			clearanceStatus === 'fully_compliant' ||
			clearanceStatus === 'partially_compliant'
		) {
			dispatch(clearProfileData());
			setUuid(uuid);
			setWorkerUuid(workerUuid);
			setShowReport(true);
		} else {
			dispatch(
				setAlert({
					type: 'error',
					message: 'No report available for this user',
					isVisible: true,
				}),
			);
		}
	};

	const handleReportRendered = () => {
		navigate('/check/report');
	};

	const handleUserProfile = (uuid: string, workerUuid: string) => {
		return navigate(`user/${uuid}`, {
			state: {
				workerUuid: workerUuid,
				uuid: uuid,
			},
		});
	};

	//Will Be Deleted
	const emailRecipientObject = {
		id: 1,
		limited: 'Limited Clearance',
		full: 'Full Clearance',
	};

	const getApplicantStatus = (row: IApplicant) => {
		return (
			<div
				className='status-container'
				style={{
					backgroundColor: `${getStatusBackgroundColour(
						row.submissionStatusTab,
					)}`,
				}}
			>
				<span className={getStatusClassName(row.submissionStatusTab)}>
					{mapStatusTab(row.submissionStatusTab)}
				</span>
			</div>
		);
	};

	return (
		<div className='external-dashboard-table-container'>
			<table>
				<thead>
					<tr className='dashboard-table-header'>
						{columns.map((head: any) => (
							<th key={head.key}>{head.header}</th>
						))}
						{type === 'Settings' && role === 'manager' && (
							<>
								<th style={{ textAlign: 'right' }}>
									Edit / Delete
								</th>
							</>
						)}
					</tr>
				</thead>
				<tbody>
					{data &&
						data.map((row: any, i: number) => {
							return (
								<tr
									key={row.uuid}
									onClick={() => {
										type === 'Dashboard' &&
											handleUserProfile(
												row.uuid,
												row.workerUuid,
											);
									}}
									style={{
										cursor:
											type === 'Dashboard'
												? 'pointer'
												: 'default',
									}}
								>
									{columns.map((col: any, j: number) => {
										const cellValue = row[col.key];
										if (
											col.key === 'candidate' &&
											(row.overallSubmissionStatus ===
												'not yet submitted' ||
												row.overallSubmissionStatus ===
												'Not yet created')
										) {
											const newApplicantCheck =
												checkInvitedDate(row.createdAt);

											if (newApplicantCheck) {
												return (
													<td
														key={generateUniqueKey()}
													>
														<p className='orange-row-bullet'>
															&nbsp;
															{row.name}{' '}
															<b>{row.surname}</b>
														</p>
													</td>
												);
											} else {
												return (
													<td
														key={generateUniqueKey()}
													>
														{row.name}{' '}
														<b>{row.surname}</b>
													</td>
												);
											}
										} else if (col.key === 'candidate') {
											return (
												<td key={j}>
													{row.name}{' '}
													<b>{row.surname}</b>
												</td>
											);
										} else if (col.key === 'status') {
											return (
												<td key={j}>
													{getApplicantStatus(row)}
												</td>
											);
										} else if (col.key === 'invited') {
											return (
												<DashboardToolTip
													key={generateUniqueKey()}
													arrow
													TransitionComponent={Zoom}
													placement='left-start'
													title={
														<>
															{checkApplicantDates(
																convertTimestampToDate(
																	row.createdAt,
																),
																false,
															)}{' '}
															days ago
															<br />
															<i>
																by{' '}
																{getInvitedByUser(
																	row.invitedBy,
																)}
															</i>
														</>
													}
												>
													<td key={j}>
														{formatDateToSlash(
															row.createdAt,
														)}
													</td>
												</DashboardToolTip>
											);
										} else if (
											col.key === 'submissionDate' &&
											row.submissionDate
										) {
											return (
												<td key={j}>
													{formatDateToSlash(
														row.submissionDate,
													)}
												</td>
											);
										} else if (
											col.key === 'clearedDate' &&
											row.clearance?.clearanceStatus ===
											'partially_compliant' &&
											row.clearance
												?.provisionallyClearedDate
										) {
											return (
												<DashboardToolTip
													key={generateUniqueKey()}
													arrow
													TransitionComponent={Zoom}
													placement='left-start'
													title={
														<>
															{checkApplicantDates(
																row.clearance
																	?.provisionallyClearedDate,
																true,
															)}{' '}
															days left
															<br />
															<i>
																Extended by{' '}
																{
																	row
																		.clearance
																		?.provisionallyClearedBy
																}
															</i>
														</>
													}
												>
													<td key={j}>
														{formatDateToSlash(
															row.clearance
																?.provisionallyClearedDate,
														)}
													</td>
												</DashboardToolTip>
											);
										} else if (
											col.key === 'clearedDate' &&
											row.clearance?.clearanceStatus ===
											'fully_compliant' &&
											row.clearance?.fullyClearedDate
										) {
											return (
												<td key={j}>
													{formatDateToSlash(
														row.clearance
															?.fullyClearedDate,
													)}
												</td>
											);
										} else if (col.key === 'report') {
											if (
												row.clearance
													?.clearanceStatus ===
												'partially_compliant' ||
												row.clearance
													?.clearanceStatus ===
												'fully_compliant'
											) {
												return (
													<td
														key={j}
														onClick={(e) => {
															e.stopPropagation();
															handleGenerateReport(
																row.uuid,
																row.workerUuid,
																row.clearance
																	?.clearanceStatus ||
																'not_compliant',
															);
														}}
													>
														<img
															className='upload-icon'
															src={uploadBlue}
														/>
														<a>View</a>
													</td>
												);
											} else {
												return <td key={j}></td>;
											}
											//Settings Table Conditions
										} else if (
											col.key === 'emailRecipient' &&
											row.emailRecipient === 'Some'
										) {
											return (
												<EmailRecipientToolTip
													key={generateUniqueKey()}
													arrow
													TransitionComponent={Zoom}
													placement='left-start'
													title={
														<>
															<div>
																{
																	emailRecipientObject.limited
																}
																<br />
																{
																	emailRecipientObject.full
																}
															</div>
														</>
													}
												>
													<td key={j}>{cellValue}</td>
												</EmailRecipientToolTip>
											);
										} else if (col.key === 'staff') {
											return (
												<td key={j}>
													{row.firstName}{' '}
													<b>{row.lastName}</b>
												</td>
											);
										} else {
											return <td key={j}>{cellValue}</td>;
										}
									})}
									{type === 'Settings' &&
										role === 'manager' && (
											<td
												style={{
													textAlign: 'right',
												}}
											>
												<img
													className='edit-icon'
													src={editPencilIcon}
													alt='edit'
													style={{
														opacity:
															data[i]
																.permissions ===
																'Manager'
																? 0.5
																: 1,
													}}
													onClick={() => {
														data[i].permissions ===
															'Admin' &&
															handleSelectEditDeleteIcon(
																data[i],
																'edit-email',
															);
													}}
												/>
												<img
													className='delete-icon'
													src={deleteIcon}
													alt='delete'
													onClick={() => {
														handleSelectEditDeleteIcon(
															data[i],
															'delete-staff',
														);
													}}
												/>
											</td>
										)}
								</tr>
							);
						})}
				</tbody>
			</table>
			{!data.length && (
				<div>
					<br></br>
					<p className='no-data'>No Data Available</p>
				</div>
			)}
			{showReport ? (
				<ExternalDashboardGetReportData
					uuid={uuid}
					workerUuid={workerUuid}
					onRendered={handleReportRendered}
				/>
			) : null}
		</div>
	);
};

export default ExternalDashboardTable;
