import React, { FC, useState } from 'react';
import './profile-cancel-pause.styles.scss';
import greyCross from '../../../assets/icons/contact-employer-cross.svg';
import editPencil from '../../../assets/icons/edit.svg';
import NotesModal from '../../profile/notes-modal/notes-modal.component';
import * as apiApplicant from '../../../api/api.applicant';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';

const CancelPause: FC = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const overallSubmission = useSelector(
		(state: RootState) => state.profile.overallSubmission,
	);

	const handleNoteUpdate = (payload: any) => {
		return apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then(() => {
				setOpenModal(false);
				dispatch(updateApplicantMultiple(payload));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated cancellation status',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating cancellation status',
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	const handlePausedNoteUpdate = (payload: any) => {
		setOpenModal(false);
		if (applicant.paused) {
			payload.pausedNotes = null;
			payload.paused = false;
		} else {
			payload.paused = true;
		}
		return updateApplicant(payload).catch((err) => {
			dispatch(
				setAlert({
					type: 'error',
					message: err.message,
					isVisible: true,
				}),
			);
			throw err;
		});
	};

	const updateApplicant = (payload: any) => {
		return apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then(() => {
				dispatch(updateApplicantMultiple(payload));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated paused status',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	return (
		<div>
			{overallSubmission?.data?.status === 'cancelled' && (
				<div className='application-cancelled'>
					<div className='application-cancelled-left'>
						<img src={greyCross} alt='cross' />
						<p>This application has been cancelled</p>
					</div>

					<div className='application-cancelled-right'>
						<img
							src={editPencil}
							alt='edit'
							onClick={() => setOpenModal(true)}
						/>
					</div>
					{openModal && (
						<NotesModal
							handleClicked={handleNoteUpdate}
							buttonContent='Update Notes'
							setShowModal={setOpenModal}
							showModal={openModal}
							noteValue={applicant.cancelledNotes}
							type='cancelledNotes'
							modalTitle='Update Notes'
							placeholder='Please note why the application has been cancelled'
							modalInfo={
								<p>Update notes about this cancellation</p>
							}
						/>
					)}
				</div>
			)}
			{applicant.paused && (
				<div className='application-cancelled'>
					<div className='application-cancelled-left'>
						<img src={greyCross} alt='cross' />
						<p>This application has been paused</p>
					</div>

					<div className='application-cancelled-right'>
						<img
							src={editPencil}
							alt='edit'
							onClick={() => setOpenModal(true)}
						/>
					</div>
					{openModal && (
						<NotesModal
							handleClicked={handlePausedNoteUpdate}
							buttonContent='Unpause Applicant'
							setShowModal={setOpenModal}
							showModal={openModal}
							noteValue={applicant.pausedNotes}
							type='pausedNotes'
							modalTitle='Unpause Application'
							placeholder='Please note why the applicant has been paused'
							modalInfo={
								<p>
									Screener will need to be manually
									reassigned.
								</p>
							}
							disableTextBox={true}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default CancelPause;
