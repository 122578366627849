import './dashboard-sub-menu.scss';
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import DashboardSortAssign from '../dashboard-sort-assign/dashboard-sort-assign.component';
import DashboardSortby from '../dashboard-sortby/dashboard-sortby.component';
import DashboardStandardFilter from '../dashboard-standard-filter/dashboard-standard-filter.component';
import {
	clearFilters,
	setFlowQuery,
	setGlobalSortDir,
	setStatusQuery,
	setSubmissionQuery,
} from '../../../redux/Dashboard/dashboard.actions';
import DashboardCompanyFilter from '../dashboard-company-filter/dashboard-company-filter.component';

const DashboardSubMenu: FC = () => {
	const dispatch = useDispatch();
	const role = useSelector((state: RootState) => state.auth.role);
	const globalSubmissionQuery = useSelector(
		(state: RootState) => state.dashboard.submissionQuery,
	);
	const globalStatusQuery = useSelector(
		(state: RootState) => state.dashboard.statusQuery,
	);
	const globalFlowQuery = useSelector(
		(state: RootState) => state.dashboard.flowQuery,
	);

	const handleClear = () => {
		dispatch(setGlobalSortDir('DESC'));
		dispatch(clearFilters());
	};

	return (
		<div className='dashboard-sub-menu'>
			{role !== 'ambassador' && (
				<div className='dashboard-sub-menu-row'>
					<DashboardStandardFilter
						filterQuery='submissionFilter'
						setReduxVal={setSubmissionQuery}
						label='Submission Status'
						position='bottom-center'
						globalQuery={globalSubmissionQuery}
					/>
					<DashboardStandardFilter
						filterQuery='flowFilter'
						setReduxVal={setFlowQuery}
						label='Flow'
						position='bottom-center'
						globalQuery={globalFlowQuery}
					/>
					<DashboardStandardFilter
						filterQuery='statusFilter'
						setReduxVal={setStatusQuery}
						label='Clearance'
						position='bottom-center'
						globalQuery={globalStatusQuery}
					/>
					<DashboardCompanyFilter />
					<DashboardSortby />
					<DashboardSortAssign />
					<div className='clear-btn'>
						<button onClick={handleClear}>Reset</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default DashboardSubMenu;
