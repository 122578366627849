import { CLEAR_NAVIGATION_STATE, SET_FROM_ACTIVITY } from "./navigation.types";


export const setFromActivity = (activityItem) => {
    return {
        type: SET_FROM_ACTIVITY,
		activityItem: activityItem
    };
};

export const clearNavigationState = () => {
	return {
		type: CLEAR_NAVIGATION_STATE
	}
}