import React, { useEffect, FC, Dispatch, SetStateAction } from 'react';
import './dashboard.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setDynamicTableData } from '../../redux/Dashboard/dashboard.actions';
import { clearProfileData } from '../../redux/Profile/profile.actions';
import * as utils from '../../utils/utilsFunctions';
import DashboardContainer from '../../components/dashboard/dashboard-container/dashboard-container.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import { clearAmbassadorData } from '../../redux/Profile/profile.actions';
import { RootState } from '../../types/root-state';

type Props = {
	showFilters: boolean;
	setShowFilters: Dispatch<SetStateAction<boolean>>;
};

const Dashboard: FC<Props> = ({ showFilters, setShowFilters }) => {
	const bucket = useSelector(
		(state: RootState) => state.dashboard.selectedBucket,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const dispatch = useDispatch();

	useEffect(() => {
		if (role !== 'ambassador') {
			dispatch(clearProfileData());
		} else {
			dispatch(clearAmbassadorData());
		}
	}, [dispatch, role]);

	useEffect(() => {
		const flag = role === 'ambassador' ? 'ambassadors' : bucket;
		const dynamicData = utils.getDynamicTableData(flag);
		dispatch(setDynamicTableData(dynamicData));
	}, [bucket, dispatch, role]);

	return (
		<div className='dashboard-page'>
			<ErrorBoundary applicantWorkerUuid='None'>
				<DashboardContainer
					showFilters={showFilters}
					setShowFilters={setShowFilters}
				/>
			</ErrorBoundary>
		</div>
	);
};

export default Dashboard;
