import React, { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import ProfileTag from '../profile-tag/profile-tag.component';
import './tags.styles.scss';
import { RootState } from '../../../types/root-state';
import * as apiTags from '../../../api/api.tags';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import { ITagUser } from '../../../types/interfaces/tags.interface';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import cross from '../../../assets/icons/cross.svg';
import crossWhite from '../../../assets/icons/cross-white.svg';
import TagSearch from '../tag-search/tag-search.component';
import Modal from '../../modals/modal/modal.component';
import TagCreate from '../tag-create/tag-create.component';
import plusCircle from '../../../assets/icons/plus-circle.svg';
import * as apiApplicant from '../../../api/api.applicant';
import { updateApplicant } from '../../../redux/Profile/profile.actions';
import { refetchApplicants } from '../../../redux/Dashboard/dashboard.actions';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

const Tags: FC = () => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const [suspended, setSuspended] = useState<boolean>(
		applicant.suspendedAt ? true : false,
	);
	const [tagsArray, setTagsArray] = useState<ITagUser[]>(
		applicant.tags ? applicant.tags : [],
	);
	const [showSearchTags, setShowSearchTags] = useState<boolean>(false);
	const [openTagModal, setOpenTagModal] = useState<boolean>(false);

	useEffect(() => {
		applicant.suspendedAt ? setSuspended(true) : setSuspended(false);
	}, [applicant.suspendedAt]);

	const handleMarkUrgent = (bool: boolean) => {
		return apiApplicant
			.updateApplicant(applicant.workerUuid, { urgent: bool })
			.then(() => {
				dispatch(refetchApplicants());
				dispatch(updateApplicant('urgent', bool));
			})
			.catch((err: any) => {
				throw err;
			});
	};

	const handleDeleteTag = (tag: ITagUser) => {
		return apiTags
			.deleteTag(applicant.workerUuid, tag.uuid)
			.then((res) => {
				if (res.data && res.data.data) {
					if (tag.tag.displayName.toLowerCase() === 'urgent') {
						handleMarkUrgent(false);
					}
					const updatedTagArray = tagsArray.filter(
						(tag: ITagUser) => {
							return tag.uuid !== res.data.data.uuid;
						},
					);
					setTagsArray(updatedTagArray);
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully removed tag',
							isVisible: true,
						}),
					);
				}
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to remove tag',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div
			className={
				role === 'ambassador'
					? 'profile-tags-ambassador'
					: 'profile-tags'
			}
		>
			<div className='tags-header margin-bottom-8'>
				<p className='orka-profile-label'>Tags</p>
				<img
					src={plusCircle}
					onClick={() => setShowSearchTags(!showSearchTags)}
					alt='create'
				/>
			</div>
			{showSearchTags && (
				<div className='tag-search-outer'>
					<TagSearch
						setOpenTagModal={setOpenTagModal}
						setTagsArray={setTagsArray}
						tagsArray={tagsArray}
						setShowSearchTags={setShowSearchTags}
						handleMarkUrgent={handleMarkUrgent}
					/>
				</div>
			)}

			<div className='tags-container'>
				{tagsArray &&
					tagsArray.length > 0 &&
					tagsArray.map((tag: ITagUser) => {
						return (
							<div
								key={tag.uuid}
								className={
									tag.tag.displayName.toLowerCase() ===
									'urgent'
										? 'tag-container urgent'
										: 'tag-container'
								}
							>
								<TooltipLg title={tag.tag.description}>
									<p>{tag.tag.displayName}</p>
								</TooltipLg>
								{!tag.automatic && (
									<img
										src={
											tag.tag.displayName.toLowerCase() ===
											'urgent'
												? crossWhite
												: cross
										}
										alt='delete'
										onClick={() => handleDeleteTag(tag)}
									/>
								)}
							</div>
						);
					})}
				{suspended && (
					<ProfileTag
						text='Suspended'
						type='suspended'
						cypressTag='suspend-tag'
						applicant={applicant}
					/>
				)}
			</div>
			{openTagModal && (
				<Modal
					title='Create a Tag'
					setShowModal={setOpenTagModal}
					showModal={openTagModal}
					modalContentHeight='40vh'
					modalContentWidth='480px'
				>
					<TagCreate
						setShowModal={setOpenTagModal}
						setTagsArray={setTagsArray}
						tagsArray={tagsArray}
						setShowSearchTags={setShowSearchTags}
						handleMarkUrgent={handleMarkUrgent}
					/>
				</Modal>
			)}
		</div>
	);
};

export default Tags;
