import React, { FC } from 'react';
import SectionHeader from '../section-header/section-header.component';
import { RootState } from '../../../types/root-state';
import KeyVal from '../key-val/key-val.component';
import store from '../../../redux/store';
import * as utils from '../../../utils/utilsFunctions';
import RiskContainer from '../risk-container/risk-container.component';
import { Image, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	section: {
		display: 'flex',
	},
	main: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		minWidth: '200px',
		width: '100%',
		marginRight: '24px',
		borderBottom: '1px',
		borderStyle: 'solid',
		borderColor: '#FFFFFF',
		marginBottom: '16px',
		paddingBottom: '16px',
		position: 'relative',
	},
	image: {
		width: '200px',
		height: 'auto',
		marginBottom: '16px',
	},
	imageContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		maxWidth: '300px',
		paddingBottom: '16px',
	},
	gap: {
		backgroundColor: '#EC8079',
		width: '126px',
		padding: '6px 16px',
		borderRadius: '6px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	},
	pdf: {
		marginBottom: '8px',
		paddingBottom: '8px',
		borderBottom: '1px',
		borderStyle: 'solid',
		borderColor: '#FFFFFF',
	},
	textBold: {
		fontSize: '10px',
		fontWeight: 700,
		marginRight: '24px',
		minWidth: '80px',
	},
	textNormal: {
		fontSize: '10px',
		fontWeight: 600,
		maxWidth: '400px',
	},
});

const Address: FC = () => {
	const state: RootState = store.getState();
	const { addressHistory, files } = state.profile;
	const addressFiles = files.filter((file) =>
		file.tags.includes('proofOfAddress'),
	);
	return (
		<div>
			<SectionHeader title='Address History' />
			{addressHistory.length > 0 &&
				addressHistory.map((address, index) => {
					if (address.type === 'gap') {
						return (
							<div style={styles.main} key={address.startAt}>
								<div style={styles.gap}>
									<Text
										style={{
											color: 'white',
											fontSize: '10px',
										}}
									>{`Gap ${address.gapDays} days`}</Text>
								</div>
							</div>
						);
					}

					if (address.type !== 'gap') {
						const startDate = utils.formatDateForUser(
							address.startAt,
						);
						const endDate = address.endAt
							? utils.formatDateForUser(address.endAt)
							: 'Present';
						return (
							<div style={styles.main} key={address.startAt}>
								<div>
									<KeyVal
										title='Dates'
										value={`${startDate} - ${endDate}`}
									/>
									<KeyVal
										title='Address'
										value={`${address.address.streetAddress}, ${address.address.city}, ${address.address.postcode}`}
									/>
								</div>
								{index === 0 && (
									<div style={styles.imageContainer}>
										{addressFiles.length > 0 &&
											addressFiles.map((file) => {
												if (
													file.status != 'rejected' &&
													!file.deletedAt
												) {
													return (
														<div key={file.uuid}>
															{file.contentType !==
																'application/pdf' &&
																!file.deletedAt && (
																	<Image
																		src={
																			file.readUrl
																		}
																		cache={
																			false
																		}
																		style={
																			styles.image
																		}
																	/>
																)}
															{file.contentType ===
																'application/pdf' && (
																<div>
																	<Text
																		style={
																			styles.textBold
																		}
																	>
																		Current
																		Address
																		Proof
																	</Text>
																	<Text
																		style={
																			styles.textNormal
																		}
																	>
																		PDF
																		File:
																		(Deploi
																		Appendix)
																	</Text>
																</div>
															)}
														</div>
													);
												}
											})}
									</div>
								)}
							</div>
						);
					}
				})}
			<RiskContainer title='Address History' />
		</div>
	);
};

export default Address;
