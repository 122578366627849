import React, { FC } from 'react';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import './profile-id-check-document.styles.scss';
import NotFound from '../../general/not-found/not-found.component';
import * as utils from '../../../utils/utilsFunctions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { IIdCheckData } from '../../../types/interfaces/identity.interface';

type Props = {
	checkData: IIdCheckData;
};

const ProfileIdCheckDocument: FC<Props> = ({ checkData }) => {
	const role = useSelector((state: RootState) => state.auth.role);
	return (
		<div className='id-check-doc-info'>
			{checkData ? (
				<div>
					<div className='document-info margin-bottom-16'>
						<div className='document-info-item'>
							<ProfileDisplayItem
								label='Document Type'
								value={utils.removeUnderscore(
									utils.removeHyphen(
										checkData.documents.front.type,
									),
								)}
							/>
						</div>
						{checkData.reports && (
							<>
								<div className='document-info-item'>
									<ProfileDisplayItem
										label='Issuing Country'
										value={
											checkData.reports.properties
												.issuing_country
										}
									/>
								</div>
								<div className='document-info-item'>
									<ProfileDisplayItem
										label='Document Expiry Date'
										value={utils.formatDateForUser(
											checkData.reports.properties,
										)}
									/>
								</div>
							</>
						)}
					</div>

					<div className='id-image-section'>
						<p className='orka-profile-label'>Images</p>

						<div className='id-image-section-images'>
							<a
								href={checkData.documents.front.url}
								target='_blank'
								rel='noopener noreferrer'
							>
								View Front
							</a>

							{checkData.documents.back && (
								<div>
									<a
										href={checkData.documents.back.url}
										target='_blank'
										rel='noopener noreferrer'
									>
										View Back
									</a>
								</div>
							)}
						</div>
					</div>
					{checkData.data && role !== 'ambassador' && (
						<div className='margin-bottom-16 onfido'>
							<a
								href={checkData.data.results_uri}
								target='_blank'
								rel='noopener noreferrer'
							>
								Open Onfido
							</a>
						</div>
					)}
				</div>
			) : (
				<NotFound type='noData' />
			)}
		</div>
	);
};

export default ProfileIdCheckDocument;
