import React, { FC } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	sectionHeader: {
		backgroundColor: 'white',
		padding: '11px 16px',
		marginBottom: '24px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	textHeaderNormal: {
		fontSize: '12px',
		fontWeight: 600,
	},
});

type Props = {
	title: string;
};

const SectionHeader: FC<Props> = ({ title }) => {
	return (
		<View style={styles.sectionHeader}>
			<Text style={styles.textHeaderNormal}>{title}</Text>
		</View>
	);
};

export default SectionHeader;
