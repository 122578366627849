import React, { FC } from 'react';
import { Pagination } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from '../../../redux/Dashboard/dashboard.actions';
import './dashboard-pagination.styles.scss';
import { RootState } from '../../../types/root-state';

const DashboardPagination: FC = () => {
	const totalPages = useSelector(
		(state: RootState) => state.dashboard.totalPages,
	);
	const currentPage = useSelector((state: RootState) => state.dashboard.page);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const dispatch = useDispatch();

	const handleChange = (event: unknown, page: number): void => {
		dispatch(setPage(page));
	};

	return (
		<div className='pagination-component'>
			<Pagination
				count={totalPages}
				variant='outlined'
				shape='rounded'
				onChange={handleChange}
				page={currentPage}
				size={isMobile ? 'small' : 'medium'}
			/>
		</div>
	);
};

export default DashboardPagination;
