import React, { useState, FC, useEffect } from 'react';
import './sidebar-buckets.styles.scss';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import arrowUp from '../../../assets/icons/chevron-up-white.svg';
import arrowDown from '../../../assets/icons/chevron-down-white.svg';
import { BucketState, BucketData } from '../../../types/interface';
import { useLocation } from 'react-router-dom';
const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	state: BucketState;
	sidebarIsOpen: boolean;
	handleSelectBucket: (bucket: BucketData) => void;
};

const SidebarBucketContainer: FC<Props> = ({
	state,
	sidebarIsOpen,
	handleSelectBucket,
}) => {
	const { pathname } = useLocation();
	const [showBuckets, setShowBuckets] = useState<boolean>(false);

	useEffect(() => {
		if (pathname === '/check') setShowBuckets(true);
	}, [pathname]);

	return (
		<div className='bucket-container'>
			{sidebarIsOpen && (
				<div
					className='bucket-dropdown-open'
					onClick={() => setShowBuckets(!showBuckets)}
				>
					<img src={showBuckets ? arrowUp : arrowDown} alt='arrow' />
					<p>Buckets</p>
				</div>
			)}
			{!sidebarIsOpen && (
				<div className='bucket-dropdown-closed'>
					<TooltipLg title='Buckets'>
						<img
							src={showBuckets ? arrowUp : arrowDown}
							alt='arrow'
							onClick={() => setShowBuckets(!showBuckets)}
						/>
					</TooltipLg>
				</div>
			)}

			<div className='bucket-info-container'>
				{state.buckets.length > 0 &&
					showBuckets &&
					state.buckets.map((bucket) => {
						return sidebarIsOpen ? (
							<div
								key={bucket.text}
								className={`bucket-item ${bucket.selected ? 'active-bucket-tab' : ''}`}
								onClick={() => handleSelectBucket(bucket)}
							>
								<div className='bucket-item-info'>
									<img src={bucket.icon} alt='bucket' />
									<p
										className={`bucket-action-lg ${bucket.selected ? 'bucket-selected' : ''}`}
									>
										{bucket.text}
									</p>
								</div>

								<div className='bucket-amount'>
									<p>{bucket.amount}</p>
								</div>
							</div>
						) : (
							<div key={bucket.text}>
								<TooltipLg
									title={`${bucket.text} (${bucket.amount})`}
								>
									<div
										className='bucket-item-sm'
										onClick={() =>
											handleSelectBucket(bucket)
										}
									>
										<img
											src={
												bucket.selected
													? bucket.iconSelected
													: bucket.icon
											}
											alt='bucket'
										/>
									</div>
								</TooltipLg>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default SidebarBucketContainer;
