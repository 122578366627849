import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { IFileItem } from '../../../types/interfaces/document.interface';
import { RootState } from '../../../types/root-state';
import SectionHeader from '../section-header/section-header.component';
import store from '../../../redux/store';
import { consentData } from '../../../utils/data';
import * as utils from '../../../utils/utilsFunctions';

const styles = StyleSheet.create({
	section: {
		padding: 5,
		marginBottom: '16px',
	},
	sectionSmall: {
		padding: 5,
		marginBottom: '8px',
	},
	heading: {
		fontSize: '12px',
		fontWeight: 600,
		marginBottom: '14px',
	},
	text: {
		fontSize: '12px',
		marginBottom: '8px',
	},
	listItem: {
		fontSize: '12px',
		marginBottom: '4px',
		marginTop: '8px',
	},
	image: {
		width: '250px',
		height: '200px',
	},
});

type Props = {
	file: IFileItem;
};

const Consent: FC<Props> = ({ file }) => {
	const state: RootState = store.getState();
	const { overallSubmission, applicant } = state.profile;

	return (
		<div>
			<SectionHeader title='Consent' />
			{consentData.map((element: any, index: number) => {
				return (
					<View
						style={
							element.small ? styles.sectionSmall : styles.section
						}
						key={index}
					>
						<Text style={styles.heading}>{element.heading}</Text>
						{element.bodyText && (
							<Text style={styles.text}>{element.bodyText}</Text>
						)}
						{element.list &&
							element.list.map((listItem: string) => {
								return (
									<Text
										key={listItem}
										style={styles.listItem}
									>
										{listItem}
									</Text>
								);
							})}
					</View>
				);
			})}
			<View style={styles.sectionSmall}>
				<Text style={styles.text}>
					{utils.getFullName('', applicant.name, applicant.surname)}
				</Text>
				<Text style={styles.text}>
					Date Signed{' '}
					{utils.formatDateForUser(overallSubmission.data.signedDate)}
				</Text>
			</View>
			<Image src={file.readUrl} cache={false} style={styles.image} />
		</div>
	);
};

export default Consent;
