import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import * as utils from '../../../utils/utilsFunctions';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import './profile-licences.scss';
import { RootState } from '../../../types/root-state';

const ProfileLicences: FC = () => {
	const licences = useSelector((state: RootState) => state.profile.licences);

	return (
		<div className='licence-section'>
			{licences && licences.length > 0 ? (
				licences.map((licence, index) => {
					return (
						<div
							key={licence.number}
							className='margin-bottom-16 orka-check-file-bucket'
						>
							<p className='orka-profile-label'>
								Licence {index + 1}
							</p>
							<p className='orka-profile-value'>
								{licence.number}
							</p>
							<p className='orka-profile-value'>
								{licence.sector} - {licence.role}
							</p>
							<p className='orka-profile-value'>
								Expires -{' '}
								{utils.formatDateForUser(licence.expiryDate)}
							</p>
							{licence.frontReadUrl && licence.backReadUrl && (
								<div className='licence-images-container'>
									<p className='orka-profile-label'>
										Licence Photos
									</p>
									<div className='flex-row'>
										<p className='orka-profile-value'>
											<a
												href={licence.frontReadUrl}
												target='_blank'
												rel='noreferrer noopener'
											>
												Front of the licence
											</a>
										</p>
										<p className='orka-profile-value'>
											<a
												href={licence.backReadUrl}
												target='_blank'
												rel='noreferrer noopener'
											>
												Back of the licence
											</a>
										</p>
									</div>
								</div>
							)}
						</div>
					);
				})
			) : (
				<div className='padding-24'>
					<ProfileDisplayItem
						label='Licences'
						value='None Submitted'
					/>
				</div>
			)}
			<div className='orka-check-file-bucket'>
				<a
					href='https://services.sia.homeoffice.gov.uk/PublicRegister/SearchPublicRegisterNew?aspxerrorpath=/PublicRegister/SearchPublicRegisterByLicence'
					target='_blank'
					rel='noopener noreferrer'
					className='sia-licence-link'
				>
					Search SIA Website
				</a>
			</div>
		</div>
	);
};

export default ProfileLicences;
