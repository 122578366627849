import React, {
	FC,
	useState,
	useCallback,
	FormEvent,
	useEffect,
	Dispatch,
	SetStateAction,
} from 'react';
import Search from '../../general/search/search.component';
import './organisation-header.styles.scss';
import debounce from 'lodash.debounce';
import Modal from '../../modals/modal/modal.component';
import OrganisationCreate from '../organisation-create/organisation-create.component';
import backArrow from '../../../assets/icons/back-blue.svg';
import OrkaButton from '../../general/orka-button/orka-button.component';

type Props = {
	getSearchTerm: Dispatch<SetStateAction<string>>;
	setRefetch: Dispatch<SetStateAction<boolean>>;
	refetch: boolean;
	fromActivity: boolean;
	onClick?: () => void;
};

const ReferenceHeader: FC<Props> = ({
	getSearchTerm,
	setRefetch,
	refetch,
	fromActivity,
	onClick,
}) => {
	const [search, setSearch] = useState<string>('');
	const [openCreateOrganisation, setOpenCreateOrganisation] =
		useState<boolean>(false);

	const sendQuery = (query: string) => {
		getSearchTerm(query);
	};

	const delayedQuery = useCallback(
		debounce(() => {
			sendQuery(search);
		}, 500),
		[search],
	);

	const handleChange = (e: FormEvent<HTMLInputElement>): void => {
		setSearch(e.currentTarget.value);
	};

	useEffect(() => {
		delayedQuery();
		return delayedQuery.cancel;
	}, [search, delayedQuery]);

	return (
		<div className='organisations-header'>
			<div className='organisations-header-title'>
				{fromActivity && (
					<img
						src={backArrow}
						alt='back'
						role='button'
						onClick={onClick}
						className='organisations-header-back-btn'
					/>
				)}
				<h2>Organisation Database</h2>
			</div>

			<div className='organisations-header-actions'>
				{!fromActivity && (
					<OrkaButton
						emitClicked={() => setOpenCreateOrganisation(true)}
						disabled={false}
						buttonContent='Add New'
					/>
				)}
				<div className='organisation-search'>
					<Search handleChange={handleChange} search={search} />
				</div>
			</div>
			{openCreateOrganisation && (
				<Modal
					title='Create Organisation'
					setShowModal={setOpenCreateOrganisation}
					showModal={openCreateOrganisation}
					modalContentWidth='400px'
				>
					<OrganisationCreate
						setShowModal={setOpenCreateOrganisation}
						setRefetch={setRefetch}
						refetch={refetch}
					/>
				</Modal>
			)}
		</div>
	);
};

export default ReferenceHeader;
