import React, {
	FC,
	useEffect,
	useState,
	FormEvent,
	Dispatch,
	SetStateAction,
	useRef,
} from 'react';
import './tag-search.styles.scss';
import * as apiTags from '../../../api/api.tags';
import { ITag, ITagUser } from '../../../types/interfaces/tags.interface';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { setAlert } from '../../../redux/General/general.actions';
import searchIcon from '../../../assets/icons/search.svg';
import { refetchApplicants } from '../../../redux/Dashboard/dashboard.actions';

type Props = {
	setOpenTagModal: Dispatch<SetStateAction<boolean>>;
	setTagsArray: Dispatch<SetStateAction<ITagUser[]>>;
	tagsArray: ITagUser[];
	setShowSearchTags: Dispatch<SetStateAction<boolean>>;
	handleMarkUrgent: (bool: boolean) => void;
};

const TagSearch: FC<Props> = ({
	setOpenTagModal,
	setTagsArray,
	tagsArray,
	setShowSearchTags,
	handleMarkUrgent,
}) => {
	const dispatch = useDispatch();

	const [tags, setTags] = useState<ITag[]>([]);
	const [displayTags, setDisplayTags] = useState<ITag[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const searchTagRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (searchTagRef.current) {
			searchTagRef.current.focus();
		}
	}, []);

	useEffect(() => {
		const getAllTags = () => {
			return apiTags
				.getAllTags()
				.then((res) => {
					if (res.data && res.data.data) {
						// const formattedTags = res.data.data.map((tag: ITag) => {
						//     tag.name = utils.formatCamelCase(tag.name);
						//     return tag;
						// });
						setTags(res.data.data);
						setDisplayTags(res.data.data);
					}
				})
				.catch((err) => {
					throw err;
				});
		};
		getAllTags();
	}, []);

	const handleSearchTag = (e: FormEvent<HTMLInputElement>): void => {
		setSearchTerm(e.currentTarget.value);

		const filteredTagArray = tags.filter((tag: ITag) => {
			if (
				tag.displayName
					.toLowerCase()
					.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
					.includes(e.currentTarget.value.toLowerCase())
			) {
				return tag;
			}
		});

		setDisplayTags(filteredTagArray);
	};

	const assignTag = (tag: ITag) => {
		const body = {
			displayName: tag.displayName,
			description: tag.description,
			automatic: false,
		};
		return apiTags
			.postTag(applicant.workerUuid, body)
			.then((res) => {
				if (res.data && res.data.data) {
					const tag = res.data.data;

					if (tag.tag.displayName.toLowerCase() === 'urgent') {
						handleMarkUrgent(true);
					}
					setTagsArray([...tagsArray, tag]);
					dispatch(refetchApplicants());
					setOpenTagModal(false);
					setShowSearchTags(false);

					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully added tag to user',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message:
							err.message ===
							'Tag is already attached onto this user'
								? err.message
								: 'Unable to create tag',
						isVisible: true,
					}),
				);
			});
	};

	const handleModals = () => {
		setShowSearchTags(false);
		setOpenTagModal(true);
	};

	return (
		<div className='tag-search-container'>
			<div className='tag-search-component'>
				<img src={searchIcon} alt='search' />
				<input
					type='text'
					value={searchTerm}
					onChange={handleSearchTag}
					placeholder='Search'
					ref={searchTagRef}
				/>
			</div>

			{displayTags.length > 0 && (
				<div className='tags-search-container-list'>
					{displayTags.map((tag: ITag) => {
						return (
							<div
								key={tag.uuid}
								onClick={() => assignTag(tag)}
								className='tags-search-container-list-item'
							>
								<p>{tag.displayName}</p>
							</div>
						);
					})}
				</div>
			)}
			<div className='create-tag-button' onClick={handleModals}>
				<p>Create a tag</p>
			</div>
		</div>
	);
};

export default TagSearch;
