import './external-user-profile-tabs.styles.scss';

import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';
import { YotiDocumentDetails } from '../../../types/interface';
import ExternalUserProfileIdentity from '../external-user-profile-sections/external-user-profile-identity.component';
import ExternalUserProfileActivity from '../external-user-profile-sections/external-user-profile-activity.component';
import ExternalUserProfileQualifications from '../external-user-profile-sections/external-user-profile-qualifications.component';
import ExternalUserProfileAddress from '../external-user-profile-sections/external-user-profile-address.component';
import ExternalUserProfileChecks from '../external-user-profile-sections/external-user-profile-checks.component';
import ExternalUserProfileFiles from '../external-user-profile-sections/external-user-profile-files.component';
import { CheckType, RtwDocType } from '../../../types/enum';

type Props = {
	toggleModal: (value: boolean) => void;
	handleReviewModalType: (value: RtwDocType) => void;
	rtwFilesArray: IFileItem[];
	rtwManualDocArray: IFileItem[];
	addressFileArray: IFileItem[];
	additionalFileArray: IFileItem[];
	yotiFaceToFaceArray: YotiDocs[];
	yotiRtwArray: YotiDocs[];
	yotiDocumentDetails?: YotiDocumentDetails;
	faceToFaceArray?: IFileItem[];
	faceToFaceFileArray: IFileItem[];
	niFileArray: IFileItem[];
};

const ExternalUserProfileTabs: FC<Props>= ({ 
	toggleModal,
	handleReviewModalType,
	rtwFilesArray,
	rtwManualDocArray,
	addressFileArray,
	additionalFileArray,
	yotiFaceToFaceArray,
	yotiRtwArray,
	yotiDocumentDetails,
	faceToFaceFileArray,
	niFileArray,
}) => {
	const dispatch = useDispatch();

	const loggedInUser = useSelector((state: RootState) => state.auth.user);

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const checkType = useSelector(
		(state: RootState) => state.profile.applicant.checkType,
	);
	const tabs = [
		'Identity',
		'Activity',
		'Qualifications',
		'Address',
		'Checks',
		'Files',
	];
	type TabName = typeof tabs[number];
	const visibleTabs = checkType === CheckType.RTW ? ['Identity', 'Files'] : tabs;
	const [activeTab, setActiveTab] = useState('Identity');
	const sectionRefs = useRef<
		Record<TabName, React.RefObject<HTMLDivElement>>
	>(
		visibleTabs.reduce((acc, tab) => {
			acc[tab] = React.createRef<HTMLDivElement>();
			return acc;
		}, {} as Record<TabName, React.RefObject<HTMLDivElement>>)
	);

	const scrollToSection = (tab: TabName) => {
		setActiveTab(tab);
		sectionRefs.current[tab]?.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	};

	useEffect(() => {
		visibleTabs.forEach((tab) => {
			if (!sectionRefs.current[tab]) {
			sectionRefs.current[tab] = React.createRef<HTMLDivElement>();
			}
		});
	}, [visibleTabs]);

	const sectionsMap: Record<TabName, JSX.Element>  = {
		Identity: <ExternalUserProfileIdentity
			rtwFilesArray={rtwFilesArray}
			rtwManualDocArray={rtwManualDocArray}
			yotiFaceToFaceArray={yotiFaceToFaceArray}
			yotiRtwArray={yotiRtwArray}
			faceToFaceArray={faceToFaceFileArray}
			yotiDocumentDetails={yotiDocumentDetails}
			toggleModal={toggleModal}
			handleReviewModalType={handleReviewModalType}
			niFileArray={niFileArray}
		/>,
		Activity: <ExternalUserProfileActivity
			loggedInUser={loggedInUser}
		/>,
		Qualifications: <ExternalUserProfileQualifications
			loggedInUser={loggedInUser}
		/>,
		Address: <ExternalUserProfileAddress
			addressFileArray={addressFileArray}
		/>,
		Checks: <ExternalUserProfileChecks
			loggedInUser={loggedInUser}
		/>,
		Files: <ExternalUserProfileFiles
			additionalFileArray={additionalFileArray}
		/>,
	};
	
	return (
		<div className='external-user-profile-tab-container'>
			{/* DOUBLE CHECK */}
			<div className={`external-user-profile-tabs-header ${checkType === CheckType.RTW ? 'limited-tabs' : 'all-tabs'}`}>
				{visibleTabs.map((tab) => (
					<div
						key={tab}
						className={
							`external-user-profile-tabs-header-tab ${activeTab === tab ? 'active' : ''}`}
						onClick={() => scrollToSection(tab)}
					>
						{tab.toUpperCase()}
					</div>
				))}
			</div>
			<br />
			{visibleTabs.map((tab) => (
				<div key={tab} ref={sectionRefs.current[tab]}>
					{sectionsMap[tab]}
				</div>
			))}
		</div>
	);
};

export default ExternalUserProfileTabs;
