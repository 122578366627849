import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import './organisation-row.styles.scss';
import { IOrganization } from '../../../types/interfaces/reference.interface';
import OrganisationActions from '../organisation-actions/organisation-actions.component';
import { WorkHistoryItem } from '../../../types/interface';

type props = {
	organisation: IOrganization;
	fromActivity: boolean;
	activityItem: WorkHistoryItem | null;
	setRefetch: Dispatch<SetStateAction<boolean>>;
	refetch: boolean;
};

const ReferenceRow: FC<props> = ({
	organisation,
	fromActivity,
	activityItem,
	setRefetch,
	refetch,
}) => {
	const [organisationState, setOrganisationState] =
		useState<IOrganization>(organisation);
	return (
		<div className='organisations-row-container'>
			<div className='organisations-row-container-left'>
				<div className='col-md'>
					<p>{organisation.company}</p>
				</div>
				<div className='col-md'>
					<p className='days'>{organisation.region}</p>
				</div>
				<div className='col-md'>
					<p>{organisation.contactNumber}</p>
				</div>
				<div className='col-lg'>
					<p>{organisation.email}</p>
				</div>
			</div>
			<div className='organisations-row-container-right'>
				<OrganisationActions
					fromActivity={fromActivity}
					organisation={organisationState}
					setOrganisationState={setOrganisationState}
					activityItem={activityItem}
					refetch={refetch}
					setRefetch={setRefetch}
				/>
			</div>
		</div>
	);
};

export default ReferenceRow;
