import React, { FC } from 'react';
import { IRiskItem } from '../../../types/interfaces/stage.interface';
import KeyVal from '../key-val/key-val.component';

type Props = {
	risk: IRiskItem;
};

const Risk: FC<Props> = ({ risk }) => {
	const [originalRisk, assignedComments] = risk.riskNotes.split(
		'(assignedComments >)',
	);

	return (
		<div>
			<KeyVal
				title={risk.risk.title}
				value={`Issue Raised: ${originalRisk}\n${
					assignedComments
						? 'Assigned User Notes: ' + assignedComments
						: ''
				}`}
				danger={true}
			/>
		</div>
	);
};

export default Risk;
