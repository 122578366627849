import { useEffect } from 'react';
import * as apiApplicant from '../api/api.applicant';
import * as utils from './utilsFunctions';
import { IGap, WorkHistoryItem } from '../types/interface';
import { setWorksHistory } from '../redux/Profile/profile.actions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../types/root-state';

const useGetWorkHistory: any = (workerUuid: string) => {
	const dispatch = useDispatch();
	const workHistory = useSelector(
		(state: RootState) => state.profile.workHistory,
	);

	const alertInfo = useSelector(
		(state: RootState) => state.general.alertInfo,
	);
	const backFromActivityVal = useSelector(
		(state: RootState) => state.profile.backFromActivity,
	);
	const getWorkHistory = () => {
		apiApplicant
			.getApplicantDataByWorkerUuid(workerUuid, 'work-history')
			.then((res) => {
				let workHistory = utils.sortByDate(res);
				workHistory = workHistory.map(
					(item: WorkHistoryItem | IGap, index: number) => {
						if (index >= 1) {
							findOverlap(item, workHistory[index - 1]);
						}
						return item;
					},
				);
				dispatch(setWorksHistory(workHistory));
			})
			.catch(() => {
				dispatch(setWorksHistory([]));
			});
	};
	useEffect(() => {
		if (workerUuid && !workHistory) {
			getWorkHistory();
		}
	}, [workHistory, workerUuid, backFromActivityVal]);

	useEffect(() => {
		if (
			alertInfo.message === 'Successfully deleted document' ||
			alertInfo.message === 'Successfully uploaded document' ||
			alertInfo.message === 'Updated activity reference status' ||
			alertInfo.message === 'Updated activity item field' ||
			alertInfo.message === 'Updated organisation successfully' ||
			alertInfo.message ===
				'Successfully verified this reference address' ||
			alertInfo.message === 'Activity successfully updated'
		) {
			getWorkHistory();
		}
	}, [alertInfo]);

	const findOverlap = (
		currentItem: WorkHistoryItem | IGap,
		nextItem: WorkHistoryItem | IGap,
	) => {
		const currentItemEnd = currentItem.endAt
			? moment(currentItem.endAt)
			: moment();
		const nextItemStart = moment(nextItem.startAt);

		currentItem.overlap = currentItemEnd > nextItemStart;
	};
};

export default useGetWorkHistory;
