import React, { useState, useEffect, FC } from 'react';
import './profile-edit-user-modal.scss';
import OrkaButton from '../../general/orka-button/orka-button.component';
import EditInput from '../../general/edit-input/input.component';
import { useSelector, useDispatch } from 'react-redux';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';
import * as apiApplicant from '../../../api/api.applicant';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';

type Props = {
	emitClicked: (val: boolean) => void;
};

type State = {
	name: string;
	surname: string;
	email: string;
	nationalInsuranceNumber: string;
	contactNumber: string;
};

const ProfileEditUserModal: FC<Props> = ({ emitClicked }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [initState, setInitState] = useState<State>();
	useEffect(() => {
		setInitState({
			name: applicant.name ? applicant.name : '',
			surname: applicant.surname ? applicant.surname : '',
			email: applicant.email ? applicant.email : '',
			nationalInsuranceNumber: applicant.nationalInsuranceNumber
				? applicant.nationalInsuranceNumber
				: '',
			contactNumber: applicant?.contactNumber
				? applicant.contactNumber
				: '',
		});
	}, []);

	const [formVals, setFormVals] = useState<State>({
		name: applicant.name ? applicant.name : '',
		surname: applicant.surname ? applicant.surname : '',
		email: applicant.email ? applicant.email : '',
		nationalInsuranceNumber: applicant.nationalInsuranceNumber
			? applicant.nationalInsuranceNumber
			: '',
		contactNumber: applicant?.contactNumber ? applicant.contactNumber : '',
	});

	const buildPayload = (valArray: any) => {
		const payload: any = {};

		if (initState) {
			valArray.forEach((value: keyof State) => {
				if (formVals[value] !== initState[value]) {
					payload[value] = formVals[value];
				}
			});
			return payload;
		}
	};

	const handleEmitClicked = () => {
		const payload = buildPayload([
			'name',
			'surname',
			'email',
			'nationalInsuranceNumber',
			'contactNumber',
		]);

		apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				dispatch(updateApplicantMultiple(payload));
				emitClicked(false);
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated user information',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to update user information',
						isVisible: true,
					}),
				);
			});
	};

	const emitValue = (val: string, inputName: keyof State) => {
		setFormVals({
			...formVals,
			[inputName]: val,
		});
	};

	return (
		<div>
			<div className='edit-user-modal'>
				<EditInput
					title='First Name'
					value={formVals.name}
					emitValue={emitValue}
					placeholder='First Name'
					inputName='name'
				></EditInput>

				<EditInput
					title='Last Name'
					value={formVals.surname}
					emitValue={emitValue}
					placeholder='Last name'
					inputName='surname'
				></EditInput>

				<EditInput
					title='National insurance number'
					value={formVals.nationalInsuranceNumber}
					emitValue={emitValue}
					placeholder='National insurance number'
					inputName='nationalInsuranceNumber'
				></EditInput>

				<EditInput
					inputName='contactNumber'
					placeholder='Phone Number'
					value={formVals.contactNumber}
					title='Phone Number'
					emitValue={emitValue}
					type='tel'
				/>

				<EditInput
					title='Email'
					value={formVals.email}
					emitValue={emitValue}
					placeholder='Email'
					inputName='email'
				></EditInput>
			</div>

			<div className='modal-button'>
				<OrkaButton
					buttonContent='Update'
					disabled={false}
					emitClicked={handleEmitClicked}
				/>
			</div>
		</div>
	);
};

export default ProfileEditUserModal;
