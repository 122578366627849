import React, { useState, FC } from 'react';
import ProfileFileBucket from '../profile-file-bucket/profile-file-bucket.component';
import './profile-document-bucket.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFilesRedux } from '../../../redux/Profile/profile.actions';
import * as docsApi from '../../../api/api.docs';
import { setAlert } from '../../../redux/General/general.actions';
import FileList from '../file-list/file-list.component';
import { WorkHistoryItemFile } from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import { IFileItem } from '../../../types/interfaces/document.interface';

type Props = {
	tag: string;
	fileArray: IFileItem[] | WorkHistoryItemFile[];
	isWork?: boolean;
	workItemUuid?: string | null;
	handleGetFiles?: (fileData: any) => void;
	emitFileToDelete?: (uuid: string) => void;
	isModal?: boolean;
};

const ProfileDocumentBucket: FC<Props> = ({
	tag,
	fileArray,
	isWork = false,
	workItemUuid = null,
	handleGetFiles = null,
	emitFileToDelete,
	isModal,
}) => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const dispatch = useDispatch();
	const [openFooter, setOpenFooter] = useState<boolean>(false);
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const emitFiles = async (files: IFileItem[]) => {
		setOpenDialog(false);

		// eslint-disable-next-line prefer-const
		for (let file of files) {
			await uploadFiles(file);
		}
	};

	const uploadFiles = (file: IFileItem) => {
		return getSignedUrl(file)
			.then((file) => {
				return docsApi
					.uploadToGoogle(file)
					.then((file) => {
						const tags = JSON.stringify(['unselected', tag]);
						return docsApi
							.saveDocumentItem(
								file.config.data,
								tags,
								applicant,
								isWork,
								workItemUuid,
							)
							.then(({ data }) => {
								data.data.tags = tags;
								if (isWork) {
									if (handleGetFiles) {
										handleGetFiles(data.data);
									}
								} else {
									dispatch(uploadFilesRedux(data.data));
								}
								dispatch(
									setAlert({
										type: 'success',
										message:
											'Successfully uploaded document',
										isVisible: true,
									}),
								);
							})
							.catch(() => {
								dispatch(
									setAlert({
										type: 'error',
										message: 'Error saving document',
										isVisible: true,
									}),
								);
							});
					})
					.catch(() => {
						dispatch(
							setAlert({
								type: 'error',
								message: 'Error uploading to google',
								isVisible: true,
							}),
						);
					});
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error getting upload url',
						isVisible: true,
					}),
				);
			});
	};

	const getSignedUrl = (file: IFileItem) => {
		const bucketType = isWork ? 'workHistory' : 'general';
		return docsApi
			.getSignedUrl(file.type, applicant.workerUuid, bucketType)
			.then(({ data }) => {
				const { fileName, readUrl, writeUrl } = data.data.uploadUrl;
				file.fileName = fileName;
				file.readUrl = readUrl;
				file.writeUrl = writeUrl;
				return file;
			})
			.catch((err) => {
				throw err;
			});
	};

	return (
		<div className='profile-doc-container'>
			<FileList
				fileArray={fileArray}
				isWork={isWork}
				setOpenFooter={setOpenFooter}
				openFooter={openFooter}
				emitFileToDelete={emitFileToDelete}
			/>

			<ProfileFileBucket
				side='front'
				emitFiles={emitFiles}
				allowMultiple={true}
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				openFooter={openFooter}
				fileNumber={fileArray.length}
				isModal={isModal}
			/>
		</div>
	);
};

export default ProfileDocumentBucket;
