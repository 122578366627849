import React, { useEffect, useState, FC } from 'react';
import ProfileManualApprove from '../profile-manual-approve/profile-manual-approve.component';
import { useSelector, useDispatch } from 'react-redux';
import './profile-identity.styles.scss';
import * as apiId from '../../../api/api.identity';
import {
	setIdCheckData,
	setShareCodeData,
	updateFiles,
} from '../../../redux/Profile/profile.actions';
import ProfileIdCheckMain from '../profile-id-check-main/profile-id-check-main.component';
import ProfileIdCheckBreakdown from '../profile-id-check-breakdown/profile-id-check-breakdown.component';
import ProfileIdCheckDocument from '../profile-id-check-document/profile-id-check-document.component';
import ProfileSubSection from '../profile-sub-section/profile-sub-section.component';
import { RootState } from '../../../types/root-state';
import ShareCode from '../share-code/share-code.component';
import RtwImageDoc from '../rtw-image-doc/rtw-image-doc';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import { RtwManualDocStatus } from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';
import * as docsApi from '../../../api/api.docs';
import { setAlert } from '../../../redux/General/general.actions';

// Assets
import greenTick from '../../../assets/icons/green-tick.svg';
import YotiOverwrite from '../yoti-overwrite/yoti-overwrite';

type Props = {
	fileArray: IFileItem[];
	fileArrayYoti: YotiDocs[];
};

const ProfileIdentity: FC<Props> = ({ fileArray, fileArrayYoti }) => {
	const { applicant, idCheckData, shareCodeData } = useSelector(
		(state: RootState) => state.profile,
	);
	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);
	const dropdownDefault = utils.getDropdownData(
		'rtwManualDocActions',
		applicant,
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [dropdownData, setDropdownData] =
		useState<RtwManualDocStatus[]>(dropdownDefault);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [defaultData, setDefaultData] = useState<RtwManualDocStatus>(
		dropdownDefault[0],
	);

	const role = useSelector((state: RootState) => state.auth.role);
	const dispatch = useDispatch();

	useEffect(() => {
		if (fileArray.length > 0) {
			const data = dropdownData.filter((item) => {
				return item.dbValue === mostRecentImg().status;
			});
			setDefaultData(data[0]);
		}
	}, [JSON.stringify(fileArray)]);

	useEffect(() => {
		if (!idCheckData || !idCheckData.isLoaded) {
			apiId
				.getApplicantIdCheck(applicant.workerUuid)
				.then((res) => {
					if (res.status && res.status === 404) {
						dispatch(
							setIdCheckData({
								isLoaded: true,
							}),
						);
					} else {
						dispatch(
							setIdCheckData({
								...res.data.data,
								isLoaded: true,
							}),
						);
					}
				})
				.catch(() => {
					dispatch(setIdCheckData(null));
				});
		}
	}, [dispatch, applicant.workerUuid]);

	useEffect(() => {
		if (role !== 'ambassador' && !shareCodeData) {
			apiId
				.getShareCode(applicant.workerUuid)
				.then((res) => {
					if (res.status === 404) {
						dispatch(setShareCodeData(null));
					} else {
						dispatch(setShareCodeData(res.data.data));
					}
				})
				.catch((err) => {
					throw err;
				});
		}
	}, [role]);

	const mostRecentImg = () => {
		return fileArray[fileArray.length - 1];
	};

	const getDropdownVal = (val: RtwManualDocStatus) => {
		if (role === 'ambassador') {
			return;
		}
		const file = mostRecentImg();
		file.status = val.dbValue;
		const type = file.tags[1];
		file.tags = [val.dbValue, type];

		return docsApi
			.updateDocumentStatus(file, applicant, false)
			.then(() => {
				dispatch(updateFiles(file));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated document status',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating document status',
						isVisible: true,
					}),
				);
			});
	};

	let rtwYoti;
	if (fileArrayYoti && fileArrayYoti.length > 0) {
		rtwYoti = (
			<>
				{fileArrayYoti && fileArrayYoti.length > 0 && (
					<div className='rtw-manual-container'>
						<RtwImageDoc imgSrc={fileArrayYoti[0].readUrl} />
					</div>
				)}

				{yotiDetails && <YotiOverwrite />}
			</>
		);
	}

	let rtwViewManual;
	if (fileArray && fileArray.length > 0) {
		// Show Rtw manual upload document
		rtwViewManual = (
			<div className='rtw-manual-container'>
				<>
					<RtwImageDoc imgSrc={mostRecentImg().readUrl} />
					<div className='margin-top-16'>
						<Dropdown
							type='rtwManualDocActions'
							dropdownData={dropdownData}
							defaultData={defaultData}
							getDropdownVal={getDropdownVal}
							width='160'
							height={24}
							mobileResponsive={false}
							position='bottom-center'
						/>
					</div>
				</>
			</div>
		);
	}
	let rtwViewShareCode;
	if (shareCodeData) {
		// Show share code view
		rtwViewShareCode = (
			<ProfileSubSection title='Share Code'>
				<ShareCode />
			</ProfileSubSection>
		);
	}
	let showOnfido;
	if (!shareCodeData && idCheckData) {
		// Show old onfido data
		showOnfido = (
			<div className='margin-top-4'>
				{idCheckData.data && role !== 'ambassador' && (
					<>
						<ProfileManualApprove idCheckData={idCheckData} />
						<ProfileSubSection title='Main'>
							<ProfileIdCheckMain />
						</ProfileSubSection>
					</>
				)}

				{idCheckData.reports && role !== 'ambassador' && (
					<ProfileSubSection title='Report Breakdown'>
						<ProfileIdCheckBreakdown checkData={idCheckData} />
					</ProfileSubSection>
				)}

				{idCheckData.documents && idCheckData.documents.front && (
					<ProfileSubSection title='Document'>
						<ProfileIdCheckDocument checkData={idCheckData} />
					</ProfileSubSection>
				)}
			</div>
		);
	}

	return (
		<>
			{rtwYoti}
			{rtwViewManual}
			{rtwViewShareCode}
			{showOnfido}
		</>
	);
};

export default ProfileIdentity;
