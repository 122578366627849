import { axiosInstance } from './api.axiosInstance';
import { ITagArray } from '../types/interfaces/tags.interface';
import {
	ICreateTagResponse,
	ITagPayload,
} from '../types/interfaces/tags.interface';

export const postTag = (
	workerUuid: string,
	payload: ITagPayload,
): Promise<ICreateTagResponse> => {
	return axiosInstance()
		.post(`admin/applicants/${workerUuid}/tag`, payload)
		.catch((err) => {
			throw err;
		});
};

export const deleteTag = (
	workerUuid: string,
	userTagUuid: string,
): Promise<ICreateTagResponse> => {
	return axiosInstance()
		.delete(`admin/applicants/${workerUuid}/tag/${userTagUuid}`)
		.catch((err) => {
			throw err;
		});
};

export const getAllTags = (): Promise<ITagArray> => {
	return axiosInstance()
		.get('admin/tags')
		.catch((err) => {
			throw err;
		});
};
