import React, { FC, useEffect, useState } from 'react';
import './profile-flow.styles.scss';
import { IApplicant, IDropdownStandard } from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import * as apiApplicant from '../../../api/api.applicant';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

type Props = {
	applicant: IApplicant;
};

const ProfileFlow: FC<Props> = ({ applicant }) => {
	const dispatch = useDispatch();
	const [dropdownData, setDropdownData] = useState<IDropdownStandard[]>([]);
	const [defaultData, setDefaultData] = useState<IDropdownStandard>();
	const user = useSelector((state: RootState) => state.auth.user);

	useEffect(() => {
		setDropdownData(utils.getDropdownData('flowDropdown', applicant));
	}, [applicant.flow]);

	useEffect(() => {
		if (applicant && dropdownData.length > 0) {
			const data = dropdownData.filter((item) => {
				return item.dbValue === applicant.flow;
			});
			data.length > 0
				? setDefaultData(data[0])
				: setDefaultData(dropdownData[0]);
		}
	}, [dropdownData.length]);

	const getDropdownVal = (val: IDropdownStandard) => {
		return handleUpdateFlow(val.dbValue, val.value);
	};

	const handleUpdateFlow = (flow: string, value: string) => {
		const updatedNotes = (applicant.notes +=
			`\n` +
			`\n` +
			`Flow updated to ${value} by ${user.name + ' ' + user.surname}`);
		const payload = {
			flow,
			notes: updatedNotes,
		};
		apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				const updatedDefaultDropdown = dropdownData.filter((item) => {
					return item.dbValue === flow;
				});
				setDefaultData(updatedDefaultDropdown[0]);
				dispatch(updateApplicantMultiple(payload));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully changed flow',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to update flow',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div className='margin-bottom-16'>
			{defaultData && (
				<Dropdown
					type='flowDropdown'
					dropdownData={dropdownData}
					defaultData={defaultData}
					getDropdownVal={getDropdownVal}
					width='180'
					height={24}
					mobileResponsive={false}
					position='bottom-center'
				/>
			)}
		</div>
	);
};

export default ProfileFlow;
