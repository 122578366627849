import React, { useEffect, useState, useRef, FC } from 'react';
import './task-list.styles.scss';
import Layout from '../../components/general/layout-component/layout.component';
import TaskHeader from '../../components/task-center/task-header/task-header.component';
import TaskRow from '../../components/task-center/task-row/task-row.component';
import * as taskApi from '../../api/api.task';
import TaskPagination from '../../components/task-center/task-pagination/task-pagination.component';
import NotFound from '../../components/general/not-found/not-found.component';
import { useDispatch, useSelector } from 'react-redux';
import { clearProfileData } from '../../redux/Profile/profile.actions';
import {
	ITaskApplicant,
	ICheckTask,
} from '../../types/interfaces/task.interface';
import TaskSelector from '../../components/task-center/task-selector/task-selector.component';
import TaskCheckRow from '../../components/task-center/task-check-row/task-check-row.component';
import { RootState } from '../../types/root-state';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';

const TaskList: FC = () => {
	const dispatch = useDispatch();
	const taskUsersRef = useRef<HTMLDivElement>(null);
	const [refetchTasks, setRefetchTasks] = useState<boolean>(false);
	const taskPage = useSelector(
		(state: RootState) => state.dashboard.taskPage,
	);
	const [taskData, setTaskData] = useState([]);
	const [totalPages, setTotalPages] = useState(5);
	const [headerRef, getRef] = useState(Object);
	const [sortBy, setSortBy] = useState('completeBy');
	const [sortDirection, setSortDirection] = useState('ASC');
	const [taskType, setTaskType] = useState<string>('');

	useEffect(() => {
		dispatch(clearProfileData());
		taskApi
			.getTasks(taskPage, sortBy, sortDirection, taskType)
			.then((res) => {
				const total = Math.ceil(res.data.meta.total / 20);
				setTaskData(res.data.data);
				setTotalPages(total);
			})
			.catch((err) => {
				throw err;
			});
	}, [taskPage, dispatch, sortBy, sortDirection, taskType, refetchTasks]);

	const handleScroll = () => {
		if (taskUsersRef && taskUsersRef.current) {
			headerRef.current.scrollLeft = taskUsersRef.current.scrollLeft;
		}
	};

	return (
		<Layout>
			<div className='task-container'>
				<ErrorBoundary applicantWorkerUuid='None'>
					<TaskSelector
						setTaskType={setTaskType}
						setTaskData={setTaskData}
						setTotalPages={setTotalPages}
						taskType={taskType}
					/>
					<TaskHeader
						getRef={getRef}
						taskUsersRef={taskUsersRef}
						sortBy={sortBy}
						setSortBy={setSortBy}
						sortDirection={sortDirection}
						setSortDirection={setSortDirection}
						taskType={taskType}
					/>
					<div
						className='task-users'
						ref={taskUsersRef}
						onScroll={handleScroll}
					>
						{taskData.length > 0 ? (
							taskData.map(
								(applicant: ITaskApplicant | ICheckTask) => {
									return (
										<div key={applicant.uuid}>
											{taskType ? (
												<TaskCheckRow
													taskData={applicant}
													setRefetchTasks={
														setRefetchTasks
													}
													refetchTasks={refetchTasks}
													taskType={taskType}
												/>
											) : (
												<TaskRow
													applicant={applicant}
													setRefetchTasks={
														setRefetchTasks
													}
													refetchTasks={refetchTasks}
													taskType={taskType}
												/>
											)}
										</div>
									);
								},
							)
						) : (
							<NotFound type='No Data' />
						)}
					</div>

					{taskData.length > 0 && (
						<div className='task-pagination'>
							<TaskPagination
								totalPages={totalPages}
								type='task'
							/>
						</div>
					)}
				</ErrorBoundary>
			</div>
		</Layout>
	);
};

export default TaskList;
