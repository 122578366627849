import React, { useState, FC, FormEvent } from 'react';
import './ambassador-search.styles.scss';
import * as apiApplicant from '../../../api/api.applicant';
import { useDispatch } from 'react-redux';
import { setApplicants } from '../../../redux/Dashboard/dashboard.actions';
import searchIcon from '../../../assets/icons/search-white.svg';
import { setAlert } from '../../../redux/General/general.actions';
import { selectApplicant } from '../../../redux/Profile/profile.actions';

const AmbassadorSearch: FC = () => {
	const dispatch = useDispatch();
	const [searchTerm, setSearchTerm] = useState<string>('');

	const handleSearch = (): void => {
		if (searchTerm.length !== 8) {
			dispatch(
				setAlert({
					type: 'error',
					message: 'Unique ID must be 8 characters long',
					isVisible: true,
				}),
			);
			return;
		}
		apiApplicant
			.getAmbassadorApplicant(searchTerm)
			.then((res) => {
				const data = [res.data];
				if (res.status === 404 || res.data.length === 0) {
					dispatch(setApplicants([]));
					dispatch(
						setAlert({
							type: 'error',
							message: 'No applicants found',
							isVisible: true,
						}),
					);
				} else {
					dispatch(setApplicants(data));
					dispatch(selectApplicant(data[0]));
				}
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'No applicants found',
						isVisible: true,
					}),
				);
				dispatch(setApplicants([]));
			});
	};

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		setSearchTerm(e.currentTarget.value);
	};

	return (
		<div className='ambassador-search-component'>
			<input
				value={searchTerm}
				onChange={handleChange}
				placeholder='Enter unique code'
			/>
			<div className='search-btn' onClick={handleSearch}>
				<img src={searchIcon} alt='search' />
			</div>
		</div>
	);
};

export default AmbassadorSearch;
