import React, { FC } from 'react';
import RiskContainer from '../risk-container/risk-container.component';
import SectionHeader from '../section-header/section-header.component';
import { RootState } from '../../../types/root-state';
import store from '../../../redux/store';
import KeyVal from '../key-val/key-val.component';

type Props = {
	title: string;
};

const NotesOnly: FC<Props> = ({ title }) => {
	const state: RootState = store.getState();
	const { stageData } = state.profile;
	return (
		<div>
			<SectionHeader title={title} />
			<KeyVal
				title='Notes'
				value={
					stageData?.filter((stage) => stage?.title === title)[0]
						?.notes
				}
			/>
			<RiskContainer title={title} />
		</div>
	);
};

export default NotesOnly;
