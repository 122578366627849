import { IGetApplicantsResponse } from '../types/interfaces/responses.interface';
import { axiosInstance } from './api.axiosInstance';

export const getExternalApplicants = (
	searchTerm: string | number | boolean,
	sortBy: string,
	searchBy: string,
	page: number,
	sortDirection: string,
	submissionStatusTab: string,
): Promise<IGetApplicantsResponse> => {
	const sort = sortBy ? `&sortBy=${sortBy}` : '';
	const direction = sortDirection ? `&sort=${sortDirection}` : '';
	const find = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : '';
	const findBy = searchBy && searchTerm ? `&searchBy=${searchBy}` : '';
	const statusTab = `&submissionStatusTab=${encodeURIComponent(
		submissionStatusTab,
	)}`;

	return axiosInstance()
		.get(
			`admin/applicants?page=${page}${sort}${find}${findBy}${direction}${statusTab}`,
		)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getSubmissionStatuses = (): Promise<any> => {
	return axiosInstance()
		.get('admin/applicants/submission-statuses')
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
