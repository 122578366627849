import React, { FC, useEffect, useState } from 'react';
import './activity-original-modal.styles.scss';
import { WorkHistoryItem } from '../../../types/interface';
import ActivityOriginalItem from '../activity-original-item/activity-original-item.component';
import LoadingSpinner from '../../general/loading-spinner/loading-spinner.component';
import * as apiApplicant from '../../../api/api.applicant';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { setAlert } from '../../../redux/General/general.actions';
import * as utils from '../../../utils/utilsFunctions';

const ActivityOriginalModal: FC = () => {
	const dispatch = useDispatch();
	const [originalItems, setOriginalItems] = useState<WorkHistoryItem[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	useEffect(() => {
		setLoading(true);
		apiApplicant
			.getOriginalActivityHistory(applicant.workerUuid)
			.then((res) => {
				setLoading(false);
				setOriginalItems(utils.sortByDate(res.data.data));
			})
			.catch((err) => {
				setLoading(false);
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	}, [applicant.workerUuid, dispatch]);

	return (
		<div>
			{!isLoading ? (
				originalItems.map((item: WorkHistoryItem) => (
					<ActivityOriginalItem
						key={item.uuid}
						originalActivity={item}
					/>
				))
			) : (
				<div className='modal-loading-spinner'>
					<LoadingSpinner />
				</div>
			)}
		</div>
	);
};

export default ActivityOriginalModal;
