import React, { useEffect, FC, useState } from 'react';
import './alert.styles.scss';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../../redux/General/general.actions';
import blueTick from '../../../assets/icons/tick-blue-circle.svg';
import error from '../../../assets/icons/error.svg';
import info from '../../../assets/icons/urgent-blue.svg';
import * as utils from '../../../utils/utilsFunctions';
import { AlertData } from '../../../types/interface';

type Props = {
	alertData: AlertData;
};

const AlertComponent: FC<Props> = ({ alertData }) => {
	const dispatch = useDispatch();
	const [img, setImg] = useState('');

	useEffect(() => {
		setTimeout(() => {
			dispatch(clearAlert());
		}, 5000);
	}, [dispatch, alertData.isVisible]);

	useEffect(() => {
		switch (alertData.type) {
			case 'error':
				setImg(error);
				break;
			case 'success':
				setImg(blueTick);
				break;
			default:
				setImg(info);
		}
	}, [alertData.type]);

	return (
		<div className={`alert-component ${alertData.type}`}>
			<div className='alert-header'>
				<h2>{utils.capitalizeFirstLetter(alertData.type)}</h2>
				<img
					className={`${alertData.type}-img`}
					src={img}
					alt='alert'
				/>
			</div>

			<div className='alert-info'>
				<p>{alertData.message}</p>
			</div>
		</div>
	);
};

export default AlertComponent;
