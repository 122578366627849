import React, { FC, useEffect, useState } from 'react';
import './dashboard-sort-assign.styles.scss';
import { IDropdownStandard } from '../../../types/interface';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAssignVal,
	setPage,
} from '../../../redux/Dashboard/dashboard.actions';
import { RootState } from '../../../types/root-state';
import { IStaffMember } from '../../../types/interfaces/staff.interface';

const DashboardSortAssign: FC = () => {
	const dispatch = useDispatch();
	const [staff, setStaff] = useState<IDropdownStandard[]>([]);
	const [defaultStaff, setDefaultStaff] = useState<IDropdownStandard>();
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const globalAssigned = useSelector(
		(state: RootState) => state.dashboard.assignedQuery,
	);

	useEffect(() => {
		const dropdownData = allStaff.map(
			(staffMember: IStaffMember, index: number) => {
				return {
					id: index + 1,
					value: staffMember.name + ' ' + staffMember.surname,
					dbValue: staffMember.email ? staffMember.email : '',
					class: 'dropdown-grey',
				};
			},
		);
		const defaultItem = {
			id: 0,
			value: 'All',
			dbValue: 'all',
			class: 'dropdown-grey',
		};
		setStaff([defaultItem, ...dropdownData]);

		if (staff.length > 0 && allStaff.length > 0) {
			const defaultDataFromQuery = staff.filter((item) => {
				return item.dbValue === globalAssigned;
			});

			defaultDataFromQuery.length > 0
				? setDefaultStaff(defaultDataFromQuery[0])
				: setDefaultStaff(defaultItem);
			dispatch(setAssignVal(globalAssigned));
		}
	}, [allStaff.length, staff.length, globalAssigned]);

	const getDropdownValue = (val: IDropdownStandard) => {
		dispatch(setPage(1));
		dispatch(setAssignVal(val.dbValue));
		setDefaultStaff(val);
	};

	return (
		<div className='show-assigned-container'>
			{staff.length > 0 && defaultStaff && (
				<div className='show-assigned'>
					<div className='show-assigned-text'>
						<p>Assigned</p>
					</div>
					<Dropdown
						type=''
						dropdownData={staff}
						defaultData={defaultStaff}
						getDropdownVal={getDropdownValue}
						width='140'
						mobileResponsive={false}
						position='bottom-right'
						height={24}
					/>
				</div>
			)}
		</div>
	);
};

export default DashboardSortAssign;
