import {
	GET_APPLICANT,
	GET_OVERALL_SUBMISSION,
	GET_CHECKLIST,
	UPDATE_APPLICANT,
	SET_SUB_PAGE,
	CLEAR_DATA,
	SET_LICENCES,
	SET_NAME_HISTORY,
	SET_ADDRESS_HISTORY,
	SET_WORK_HISTORY,
	SET_CRIMINAL_BANKRUPTCY,
	GET_AMBASSADOR_CHECK_STATUS,
	UPLOAD_FILES,
	GET_FILES,
	UPDATE_FILES,
	SET_SELECTED_FILES,
	RESET_SELECTED_FILES,
	REMOVE_SELECTED_FILE,
	SET_ID_CHECK_DATA,
	SAVE_LICENCES,
	UPDATE_APPLICANT_MULTIPLE,
	UPDATE_CLEARANCE,
	SHOW_ACTIVITY,
	PASS_ID_RESULT,
	CLEAR_AMBASSADOR,
	SET_STAGE_DATA,
	UPDATE_ASSIGNED_STAFF,
	BACK_FROM_ACTIVITY,
	UPDATE_OVERALL_SUBMISSION,
	SET_F2F_STATUS,
	SET_SHARE_CODE_DATA,
	SET_YOTI_DETAILS,
	SET_INDIVIDUAL_STAGE,
	SET_STAGE_RISKS,
	SET_REPORT_DOC_INFO,
	SET_RTW_VERIFIED_DATA,
} from './profile.types';

export const selectApplicant = (applicant) => {
	return {
		type: GET_APPLICANT,
		applicant,
	};
};

export const setOverallSubmission = (overallSubmission) => {
	return {
		type: GET_OVERALL_SUBMISSION,
		overallSubmission,
	};
};

export const getChecklist = (checklistData) => {
	return {
		type: GET_CHECKLIST,
		checklistData,
	};
};

export const updateApplicant = (key, value) => {
	return {
		type: UPDATE_APPLICANT,
		[key]: value,
	};
};

export const updateApplicantMultiple = (obj) => {
	return {
		type: UPDATE_APPLICANT_MULTIPLE,
		obj,
	};
};

export const updateClearance = (obj) => {
	return {
		type: UPDATE_CLEARANCE,
		obj,
	};
};

export const setSubPage = (subPage) => {
	return {
		type: SET_SUB_PAGE,
		subPage,
	};
};

export const clearProfileData = () => {
	return {
		type: CLEAR_DATA,
	};
};

export const getLicenses = (licences) => {
	return {
		type: SET_LICENCES,
		licences,
	};
};

export const saveLicenses = (licences) => {
	return {
		type: SAVE_LICENCES,
		licences,
	};
};

export const setNameHistory = (nameHistory) => {
	return {
		type: SET_NAME_HISTORY,
		nameHistory,
	};
};

export const setAddressHistory = (addressHistory) => {
	return {
		type: SET_ADDRESS_HISTORY,
		addressHistory,
	};
};

export const setWorksHistory = (workHistory) => {
	return {
		type: SET_WORK_HISTORY,
		workHistory,
	};
};

export const setCriminalBankruptcy = (criminalBankruptcy) => {
	return {
		type: SET_CRIMINAL_BANKRUPTCY,
		criminalBankruptcy,
	};
};

export const getAmbassadorCheckStatus = (ambassadorCheckStatus) => {
	return {
		type: GET_AMBASSADOR_CHECK_STATUS,
		ambassadorCheckStatus,
	};
};

export const setF2FStatus = (status) => {
	return {
		type: SET_F2F_STATUS,
		status,
	};
};

export const setShareCodeData = (payload) => {
	return {
		type: SET_SHARE_CODE_DATA,
		payload,
	};
};

export const uploadFilesRedux = (file) => {
	return {
		type: UPLOAD_FILES,
		file,
	};
};

export const getFiles = (files) => {
	return {
		type: GET_FILES,
		files,
	};
};

export const updateFiles = (file) => {
	return {
		type: UPDATE_FILES,
		file,
	};
};

export const setSelectedFiles = (file) => {
	return {
		type: SET_SELECTED_FILES,
		file,
	};
};

export const resetSelectedFiles = () => {
	return {
		type: RESET_SELECTED_FILES,
	};
};

export const removeSelectedFile = (file) => {
	return {
		type: REMOVE_SELECTED_FILE,
		file,
	};
};

export const setIdCheckData = (data) => {
	return {
		type: SET_ID_CHECK_DATA,
		data,
	};
};

export const setShowActivity = (data) => {
	return {
		type: SHOW_ACTIVITY,
		data,
	};
};

export const passIdCheck = () => {
	return {
		type: PASS_ID_RESULT,
	};
};

export const clearAmbassadorData = () => {
	return {
		type: CLEAR_AMBASSADOR,
	};
};

export const setStageData = (stageData) => {
	return {
		type: SET_STAGE_DATA,
		stageData,
	};
};

export const setStageRisks = (risk) => {
	return {
		type: SET_STAGE_RISKS,
		risk,
	};
};

export const setIndividualStage = (stageData) => {
	return {
		type: SET_INDIVIDUAL_STAGE,
		stageData,
	};
};

export const updateAssignedStaff = (assignedStaff) => {
	return {
		type: UPDATE_ASSIGNED_STAFF,
		assignedStaff,
	};
};

export const backFromActivity = (val) => {
	return {
		type: BACK_FROM_ACTIVITY,
		val,
	};
};

export const updateOverallSubmission = (status) => {
	return {
		type: UPDATE_OVERALL_SUBMISSION,
		status,
	};
};
export const setYotiDetails = (details) => {
	return {
		type: SET_YOTI_DETAILS,
		details,
	};
};
export const setReportDocInfo = (details) => {
	return {
		type: SET_REPORT_DOC_INFO,
		details,
	};
};
export const setRtwVerifiedData = (rtwVerifiedDetails) => {
	return {
		type: SET_RTW_VERIFIED_DATA,
		rtwVerifiedDetails,
	}
};
