export const GET_APPLICANT = 'GET_APPLICANT';
export const GET_OVERALL_SUBMISSION = 'GET_OVERALL_SUBMISSION';
export const GET_CHECKLIST = 'GET_CHECKLIST';
export const UPDATE_APPLICANT = 'UPDATE_APPLICANT';
export const SET_SUB_PAGE = 'SET_SUB_PAGE';
export const CLEAR_DATA = 'CLEAR_DATA';
export const SET_LICENCES = 'SET_LICENCES';
export const SET_NAME_HISTORY = 'SET_NAME_HISTORY';
export const SET_ADDRESS_HISTORY = 'SET_ADDRESS_HISTORY';
export const SET_WORK_HISTORY = 'SET_WORK_HISTORY';
export const SET_CRIMINAL_BANKRUPTCY = 'SET_CRIMINAL_BANKRUPTCY';
export const GET_AMBASSADOR_CHECK_STATUS = 'GET_AMBASSADOR_CHECK_STATUS';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const GET_FILES = 'GET_FILES';
export const UPDATE_FILES = 'UPDATE_FILES';
export const SET_SELECTED_FILES = 'SET_SELECTED_FILES';
export const RESET_SELECTED_FILES = 'RESET_SELECTED_FILES';
export const REMOVE_SELECTED_FILE = 'REMOVE_SELECTED_FILE';
export const SET_ID_CHECK_DATA = 'SET_ID_CHECK_DATA';
export const SAVE_LICENCES = 'SAVE_LICENCES';
export const UPDATE_APPLICANT_MULTIPLE = 'UPDATE_APPLICANT_MULTIPLE';
export const UPDATE_CLEARANCE = 'UPDATE_CLEARANCE';
export const SHOW_ACTIVITY = 'SHOW_ACTIVITY';
export const PASS_ID_RESULT = 'PASS_ID_RESULT';
export const CLEAR_AMBASSADOR = 'CLEAR_AMBASSADOR';
export const SET_STAGE_DATA = 'SET_STAGE_DATA';
export const SET_INDIVIDUAL_STAGE = 'SET_INDIVIDUAL_STAGE';
export const SET_STAGE_RISKS = 'SET_STAGE_RISKS';
export const UPDATE_ASSIGNED_STAFF = 'UPDATE_ASSIGNED_STAFF';
export const BACK_FROM_ACTIVITY = 'BACK_FROM_ACTIVITY';
export const UPDATE_OVERALL_SUBMISSION = 'UPDATE_OVERALL_SUBMISSION';
export const SET_F2F_STATUS = 'SET_F2F_STATUS';
export const SET_SHARE_CODE_DATA = 'SET_SHARE_CODE_DATA';
export const SET_YOTI_DETAILS = 'SET_YOTI_DETAILS';
export const SET_REPORT_DOC_INFO = 'SET_REPORT_DOC_INFO';
export const SET_RTW_VERIFIED_DATA = 'SET_RTW_VERIFIED_DATA';
