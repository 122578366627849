import {combineReducers} from 'redux';
import authReducer from './Auth/auth.reducer';
import dashboardReducer from './Dashboard/dashboard.reducer';
import profileReducer from './Profile/profile.reducer';
import generalReducer from './General/general.reducer';
import navigationReducer from './Navigation/navigation.reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    profile: profileReducer,
    general: generalReducer,
	navigation: navigationReducer,
});

export default rootReducer;
