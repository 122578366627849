import React, { FC, useState, FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './notes-section.styles.scss';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { RootState } from '../../../types/root-state';
import * as apiApplicant from '../../../api/api.applicant';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';

const NotesSection: FC = () => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const [notes, setNotes] = useState<string>(
		applicant.notes ? applicant.notes : '',
	);

	useEffect(() => {
		if (applicant.notes) setNotes(applicant.notes);
	}, [applicant.notes]);

	const handleChange = (e: FormEvent<HTMLTextAreaElement>) => {
		setNotes(e.currentTarget.value);
	};
	const saveNotes = () => {
		return apiApplicant
			.updateApplicant(applicant.uuid, { notes })
			.then(() => {
				dispatch(updateApplicantMultiple({ notes }));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated notes',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating notes',
						isVisible: true,
					}),
				);
				throw err;
			});
	};
	return (
		<div className='notes-section-container'>
			<textarea
				value={notes}
				onChange={handleChange}
				placeholder={'notes'}
			/>
			<div className='notes-section-button-container'>
				<OrkaButton
					buttonContent={'Save'}
					disabled={false}
					emitClicked={saveNotes}
				/>
			</div>
		</div>
	);
};

export default NotesSection;
