import { useState, useEffect } from 'react';
import * as utils from '../../../utils/utilsFunctions';
import { IDropdownStandard } from '../../../types/interface';

const useGetDefault: any = (dropdownType: string, dbValue: string) => {
	const [dropdownData, setDropdownData] = useState<IDropdownStandard[]>([]);
	const [defaultDataFromHook, setDefaultData] = useState<IDropdownStandard>();
	useEffect(() => {
		setDropdownData(utils.getDropdownData(dropdownType, null));
	}, []);

	useEffect(() => {
		if (dropdownData.length > 0) {
			const defaultDataFromQuery = dropdownData.filter((item) => {
				return item.dbValue === dbValue;
			});
			defaultDataFromQuery.length > 0
				? setDefaultData(defaultDataFromQuery[0])
				: setDefaultData(dropdownData[0]);
		}
	}, [dropdownData.length]);

	return {
		dropdownData,
		defaultDataFromHook,
	};
};

export default useGetDefault;
