import React, {
	FC,
	SetStateAction,
	Dispatch,
	useState,
	FormEvent,
	useEffect,
} from 'react';
import './notes-modal.styles.scss';
import OrkaButton from '../../general/orka-button/orka-button.component';
import Modal from '../../modals/modal/modal.component';

type Props = {
	handleClicked: (payload: any) => void;
	buttonContent: string;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	showModal: boolean;
	noteValue: string;
	type: string;
	modalTitle: string;
	modalInfo?: JSX.Element;
	placeholder: string;
	disableTextBox?: boolean;
};

const NotesModal: FC<Props> = ({
	handleClicked,
	buttonContent,
	showModal,
	setShowModal,
	noteValue,
	type,
	modalTitle,
	modalInfo,
	placeholder,
	disableTextBox,
}) => {
	const [val, setVal] = useState<string>('');
	const handleChange = (event: FormEvent<HTMLTextAreaElement>) => {
		setVal(event.currentTarget.value);
	};

	useEffect(() => {
		setVal(noteValue ? noteValue : '');
	}, []);
	return (
		<Modal
			title={modalTitle}
			setShowModal={setShowModal}
			showModal={showModal}
			modalContentHeight='60vh'
		>
			<div className='notes-modal-container'>
				{modalInfo && <div>{modalInfo}</div>}
				<textarea
					value={val}
					onChange={handleChange}
					placeholder={placeholder}
					disabled={disableTextBox}
				/>

				<div className='notes-modal-btn'>
					<OrkaButton
						buttonContent={buttonContent}
						disabled={false}
						emitClicked={() => handleClicked({ [type]: val })}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default NotesModal;
