import React, {
	useState,
	FC,
	Dispatch,
	SetStateAction,
	FormEvent,
	useEffect,
} from 'react';
import './risk-modal-assigned.styles.scss';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as apiStage from '../../../api/api.stage';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import { setStageRisks } from '../../../redux/Profile/profile.actions';
import { RootState } from '../../../types/root-state';
import {
	IRiskItem,
	IStageDataItem,
	IRiskPayload,
} from '../../../types/interfaces/stage.interface';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import * as utils from '../../../utils/utilsFunctions';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	stage: IStageDataItem;
	risk?: IRiskItem;
};

const RiskModalAssigned: FC<Props> = ({ setShowModal, stage, risk = null }) => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.auth.user);
	const role = useSelector((state: RootState) => state.auth.role);
	const addressHistory = useSelector(
		(state: RootState) => state.profile.addressHistory,
	);
	const [isDisabled, setIsDisabled] = useState(true);

	const [riskNotes, setRiskNotes] = useState<string>('');
	const [originalRisk, setOriginalRisk] = useState<string>('');
	const [verifyName, setVerifyName] = useState<string>('');
	const addressWithoutGaps = addressHistory.filter(
		(address) => address.type !== 'gap',
	);

	useEffect(() => {
		const text = '(assignedComments >)';

		if (risk) {
			const newRiskNotes = risk.riskNotes.includes(text)
				? risk.riskNotes + text
				: risk.riskNotes;
			const [raisedRisk, advisorComments] = newRiskNotes.split(text);
			setOriginalRisk(raisedRisk);
			setRiskNotes(advisorComments ? advisorComments : '');
		} else {
			setOriginalRisk('');
			setRiskNotes('');
		}
	}, []);

	const handleNotesUpdate = (e: FormEvent<HTMLTextAreaElement>) => {
		setRiskNotes(e.currentTarget.value);
	};

	const handleDisableButtons = (e: FormEvent<HTMLInputElement>) => {
		const inputVal = e.currentTarget.value;
		setVerifyName(inputVal);
		const name = user.name + ' ' + user.surname;
		inputVal.toLowerCase() === name.toLowerCase()
			? setIsDisabled(false)
			: setIsDisabled(true);
	};

	const handleActionRisk = (val: string) => {
		const payload = {
			riskType: 'risk',
			riskNotes: originalRisk + '(assignedComments >)' + riskNotes,
			uuid: risk?.uuid,
			stageUuid: stage.uuid,
			assignedTo: risk?.assignedTo,
			createdBy: risk?.createdBy,
			status: val,
		};
		return updateRisk(payload);
	};

	const updateRisk = (payload: Partial<IRiskPayload>) => {
		return apiStage
			.updateRisk(applicant.workerUuid, payload)
			.then((res) => {
				if (res.status !== 404) {
					dispatch(setStageRisks(res.data));
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully updated risk',
							isVisible: true,
						}),
					);
				} else {
					dispatch(
						setAlert({
							type: 'Error',
							message: 'Unable to update risk',
							isVisible: true,
						}),
					);
				}
				setShowModal(false);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'Error',
						message: err.message,
						isVisible: true,
					}),
				);
				setShowModal(false);
			});
	};

	return (
		<div className='risk-modal-assigned'>
			<div className='risk-item-flexed'>
				<div className='risk-col'>
					<ProfileDisplayItem
						label='Applicants Name'
						value={`${utils.capitalizeFirstLetter(
							applicant.name,
						)} ${utils.capitalizeFirstLetter(applicant.surname)}`}
					/>
					{addressWithoutGaps.length > 0 ? (
						<ProfileDisplayItem
							label='Applicants Address'
							value={`${addressWithoutGaps[0]?.address?.streetAddress}, 
                                 ${addressWithoutGaps[0]?.address?.city}, 
                                ${addressWithoutGaps[0]?.address?.postcode}`}
						/>
					) : (
						<ProfileDisplayItem
							label='Applicants Address'
							value='No address recorded'
						/>
					)}
				</div>
			</div>

			<ProfileDisplayItem
				label='Date employment commenced'
				value={
					applicant.startDate
						? utils.formatDateForUser(applicant.startDate)
						: 'No Data'
				}
			/>

			<div>
				<p className='risk-reason'>
					Items requiring acceptance of risk:
				</p>

				<p className='risk-reason-original margin-bottom-16'>
					{originalRisk}
				</p>

				<p className='risk-reason'>
					The above named applicant’s screening file has been reviewed
					and I have/have not accepted this applicant as being
					appropriate having considered BS 7858:2019 because:
				</p>

				<div className='margin-top-16'>
					<textarea value={riskNotes} onChange={handleNotesUpdate} />
				</div>
			</div>

			<div className='risk-item-flexed'>
				<div className='risk-col'>
					<ProfileDisplayItem
						label='Your Name'
						value={`${utils.capitalizeFirstLetter(
							user.name,
						)} ${utils.capitalizeFirstLetter(user.surname)}`}
					/>
				</div>

				<div className='risk-col'>
					<ProfileDisplayItem
						label='Position'
						value={utils.capitalizeFirstLetter(role)}
					/>
				</div>
			</div>

			<div className='controller-input'>
				<p className='orka-profile-label'>
					Please type your full name to sign off this risk
				</p>
				<input
					name='controllerName'
					value={verifyName}
					onChange={handleDisableButtons}
				/>
			</div>

			<div className='action-risk-buttons'>
				<OrkaButton
					buttonContent='Fail'
					disabled={isDisabled}
					emitClicked={() => handleActionRisk('failed')}
				/>
				<OrkaButton
					buttonContent='Accept'
					disabled={isDisabled}
					emitClicked={() => handleActionRisk('accepted')}
				/>
			</div>
		</div>
	);
};

export default RiskModalAssigned;
