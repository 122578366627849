import React, { useRef, FC } from 'react';
import './profile-checklist.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import checkboxTick from '../../../assets/icons/checkbox-tick.svg';
import checkboxCross from '../../../assets/icons/checkbox-fail.svg';
import { getChecklist } from '../../../redux/Profile/profile.actions';
import * as apiApplicant from '../../../api/api.applicant';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import { CheckListItem } from '../../../types/interface';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

const ProfileChecklist: FC = () => {
	const dispatch = useDispatch();
	const checklistData = useSelector(
		(state: RootState) => state.profile.checklistData,
	);

	const ambassadorCheckStatus = useSelector(
		(state: RootState) => state.profile.ambassadorCheckStatus,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const checkboxRef = useRef<HTMLImageElement>(null);
	const handleCheckbox = (item: CheckListItem) => {
		if (role === 'screener') {
			dispatch(
				setAlert({
					type: 'error',
					message:
						'You do not have the permission to complete this task. Please contact us if you have any questions about your permissions',
					isVisible: true,
				}),
			);
			return;
		}
		if (item.id === 1 && ambassadorCheckStatus.status === 404) {
			dispatch(
				setAlert({
					type: 'error',
					message: 'Face to face status not yet created',
					isVisible: true,
				}),
			);
			return;
		}

		if (item.checklist_values === 1) {
			item.checklist_values = 0;
		} else if (item.checklist_values === 0) {
			item.checklist_values = 1;
		}

		updateChecklist(item)
			.then(() => {
				const updatedChecklist = checklistData.map((previousItem) => {
					if (item.id === previousItem.id) {
						previousItem.checklist_values = item.checklist_values;
					}
					return previousItem;
				});

				dispatch(getChecklist(updatedChecklist));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated checklist item',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to update checklist item',
						isVisible: true,
					}),
				);
			});
	};

	const handleCheckboxIcons = (item: CheckListItem) => {
		if (ambassadorCheckStatus === 'failed') {
			return checkboxCross;
		} else if (item.checklist_values === 0) {
			return checkboxWhite;
		} else if (item.checklist_values === 1) {
			return checkboxTick;
		}
	};

	const updateChecklist = (item: CheckListItem) => {
		const payload = {
			id: item.id,
			checklistValue: item.checklist_values,
		};

		return apiApplicant
			.updateApplicantDetails(applicant.workerUuid, payload, 'checklist')
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to update checklist item',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div className='checklist-component'>
			{checklistData &&
				checklistData.length > 0 &&
				checklistData.map((item, index) => {
					return (
						<div key={index} className='item'>
							{index !== 0 && <div className='bar'></div>}
							<div
								className={
									index === 0
										? 'first checklist-item'
										: 'checklist-item'
								}
							>
								<img
									src={handleCheckboxIcons(item)}
									alt='checklist'
									onClick={() => handleCheckbox(item)}
									ref={checkboxRef}
								/>
								<TooltipLg title={item.description} arrow>
									<p>{item.title}</p>
								</TooltipLg>
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default ProfileChecklist;
