import React, { FC, useState, FormEvent } from 'react';
import './number-input.styles.scss';

type Props = {
	label: string;
	getVal: (type: string, val: string) => void;
	type: string;
	initialVal: string;
};

const NumberInput: FC<Props> = ({ label, getVal, type, initialVal }) => {
	const [val, setVal] = useState<string>(initialVal);
	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		setVal(e.currentTarget.value);
		getVal(type, e.currentTarget.value);
	};

	return (
		<div className='number-input'>
			<p className='orka-profile-label'>{label}</p>
			<input type='number' value={Number(val)} onChange={handleChange} />
		</div>
	);
};

export default NumberInput;
