import {
	AUTH,
	SET_USER,
	CLEAR_AUTH,
	LOGIN,
	CLEAR_LOGGED_IN,
	SET_ROLE,
	SET_ALL_STAFF,
	SET_ALL_COMPANY,
} from './auth.types';

const INITIAL_STATE = {
	user: [],
	isLoggedIn: false,
	role: 'controller',
	staff: [],
	company: [],
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case AUTH:
        return {
          ...state,
        };
      case SET_USER:
        return {
          ...state,
          user: action.user,
        };
      case CLEAR_AUTH:
        return INITIAL_STATE;
      case LOGIN:
        return {
          ...state,
          isLoggedIn: true,
        };
      case SET_ROLE:
        return {
          ...state,
          role: action.role.toLowerCase(),
        };
      case SET_ALL_STAFF:
        return {
          ...state,
          staff: action.staff,
        };
      case SET_ALL_COMPANY:
        return {
          ...state,
          company: action.company,
        };
      case CLEAR_LOGGED_IN:
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default authReducer;

