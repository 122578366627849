import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import './profile-id-check-main.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import { RootState } from '../../../types/root-state';

const ProfileIdCheckMain: FC = () => {
	const idCheckData = useSelector(
		(state: RootState) => state.profile.idCheckData,
	);
	return (
		<div className='profile-id-check-main'>
			<div className='profile-id-item'>
				<ProfileDisplayItem
					label='Onfido Check Status'
					value={
						idCheckData.data.result
							? utils.capitalizeFirstLetter(
									idCheckData.data.result,
								)
							: 'N/A'
					}
				/>
			</div>

			<div className='profile-id-item'>
				<ProfileDisplayItem
					label='Check Date'
					value={utils.formatDateForUser(idCheckData.data.created_at)}
				/>
			</div>

			<div className='profile-id-item'>
				<ProfileDisplayItem
					label='Status'
					value={
						idCheckData.data.status
							? utils.capitalizeFirstLetter(
									idCheckData.data.status,
								)
							: 'N/A'
					}
				/>
			</div>
		</div>
	);
};

export default ProfileIdCheckMain;
