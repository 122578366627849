import { axiosInstance } from './api.axiosInstance';
import {
	IEmailPayload,
	IOrganization,
} from '../types/interfaces/reference.interface';

export const getOrganisations = (
	page: number,
	searchTerm: string,
): Promise<any> => {
	let searchParam = '';

	if (searchTerm) {
		searchParam = `&search=${encodeURIComponent(searchTerm)}`;
	}
	const url = `admin/companies/references?page=${page}${searchParam}`;
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const createOrganisation = (
	payload: Partial<IOrganization>,
): Promise<any> => {
	const url = `/admin/companies/references`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const updateOrganisation = (
	payload: Partial<IOrganization>,
): Promise<any> => {
	const url = `/admin/companies/references`;
	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const sendEmail = (payload: IEmailPayload): Promise<any> => {
	const url = `/admin/companies/email`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const removeOrganisation = (uuid: string): Promise<any> => {
	const url = `/admin/companies/references/${uuid}`;
	return axiosInstance()
		.delete(url)
		.catch((err) => {
			throw err;
		});
};
