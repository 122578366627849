import React, { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import './dashboard-header.styles.scss';
import { RootState } from '../../../types/root-state';
import filtersIcon from '../../../assets/icons/dashboard/filters.svg';
import filtersMobile from '../../../assets/icons/dashboard/filter-mobile.svg';
import AmbassadorSearch from '../../general/ambassador-search/ambassador-search.component';

type Props = {
	setShowFilters: Dispatch<SetStateAction<boolean>>;
	showFilters: boolean;
};

const DashboardHeader: FC<Props> = ({ setShowFilters, showFilters }) => {
	const selectedBucketData = useSelector(
		(state: RootState) => state.dashboard.selectedBucketData,
	);
	const role = useSelector((state: RootState) => state.auth.role);

	return (
		<div className='dashboard-header'>
			{selectedBucketData && role !== 'ambassador' && (
				<div className='dashboard-header-content'>
					<div className='dashboard-header-heading'>
						<h2>{selectedBucketData.text}</h2>
						<p>
							Found {selectedBucketData.amount}{' '}
							{selectedBucketData.amount === 1
								? 'worker'
								: 'workers'}
						</p>
					</div>

					<div className='dashboard-header-actions'>
						<div className='dashboard-header-item-container hide'>
							<div
								className='filters'
								onClick={() => setShowFilters(!showFilters)}
							>
								<img src={filtersIcon} alt='filters' />
								<p>Filters</p>
							</div>
						</div>

						<div className='dashboard-header-item-container filter-mobile'>
							<img
								src={filtersMobile}
								alt='filters'
								onClick={() => setShowFilters(!showFilters)}
							/>
						</div>
					</div>
				</div>
			)}

			{role === 'ambassador' && (
				<div className='dashboard-header-content'>
					<div className='dashboard-header-heading'>
						<h2>Ambassador Check</h2>
					</div>

					<div className='dashboard-header-item-container search-container'>
						<AmbassadorSearch />
					</div>
				</div>
			)}
		</div>
	);
};

export default DashboardHeader;
