import './profile-file-bucket.styles.scss';
import React, {
	useCallback,
	useEffect,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedFiles } from '../../../redux/Profile/profile.actions';
import { RootState } from '../../../types/root-state';
import { IFileItem } from '../../../types/interfaces/document.interface';

type Props = {
	emitFiles: (acceptedFiles: IFileItem[], side: string) => void;
	side: string;
	allowMultiple: boolean;
	openDialog: boolean;
	setOpenDialog: Dispatch<SetStateAction<boolean>>;
	openFooter: boolean;
	fileNumber: number;
	isModal?: boolean;
 };

const ProfileFileBucket: FC<Props> = ({
	emitFiles,
	side,
	allowMultiple,
	openDialog,
	setOpenDialog,
	openFooter,
	fileNumber,
	isModal = false,
}) => {
	const dispatch = useDispatch();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	useEffect(() => {
		dispatch(resetSelectedFiles());
	}, [dispatch]);

	const onDrop = useCallback((acceptedFiles: any) => {
		emitFiles(acceptedFiles, side);
	}, []);

	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDrop,
	});

	useEffect(() => {
		if (openDialog && !openFooter) {
			open();
			setOpenDialog(false);
		}
	}, [openDialog, open, setOpenDialog, openFooter]);

	return (
		<div className='profile-file-bucket-container'>
			{(
				fileNumber > 0 || isModal === false
			) && (
				<div className='file-upload-spacer'></div>
			)}
		 	<div className='profile-file-bucket-upload-area'>
					<div
						{...getRootProps()}
						className={
							isDragActive
								? 'file-bucket active'
								: 'file-bucket'
						}
					>
						<input {...getInputProps()} multiple={allowMultiple} />
						<div className='files-empty'>
							{isMobile ? (
								<p className='file-upload-text'>Click to upload files</p>
							) : (
								<p className='file-upload-text'>
									{/* eslint-disable-next-line react/no-unescaped-entities */}
									Drag 'n' drop, or click to&nbsp;
									<span className='underline'>upload files</span>
								</p>
							)}
						</div>
				</div>
		 	</div>
		</div>
	);
};

export default ProfileFileBucket;
