import React, { FC } from 'react';
import './certificate-list.styles.scss';
import circleTick from '../../../assets/icons/certificate/circle-tick.svg';
import incompleteIcon from '../../../assets/icons/certificate/incomplete.svg';
import * as utils from '../../../utils/utilsFunctions';
import riskError from '../../../assets/icons/risk-error.svg';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';

type Props = {
	stageData: IStageDataItem[];
};

const CertificateList: FC<Props> = ({ stageData }) => {
	return (
		<div className='certificate-list-item-container'>
			<div className='cert-section-title'>
				<p>Stage List</p>
			</div>
			{stageData.map((item) => (
				<div className='certificate-list-item' key={item.uuid}>
					<div className='certificate-list-item-top'>
						<div className='col-stage-fill'>
							<p>{item.title}</p>
						</div>
						<div className='col-stage-md status-text'>
							<p
								className={
									item.stageComplete
										? 'stage-complete'
										: 'grey-text'
								}
							>
								{item.stageComplete ? 'Complete' : 'Incomplete'}
							</p>
						</div>
						<div className='col-stage-sm'>
							<img
								src={
									item.stageComplete
										? circleTick
										: incompleteIcon
								}
								alt='stage-complete'
							/>
						</div>
						<div className='col-stage-md'>
							<p className='grey-text'>
								{item.stageCompletedAt
									? utils.formatDateForUser(
											item.stageCompletedAt,
										)
									: ''}
							</p>
						</div>
					</div>
					{item.risks.length > 0 && (
						<div className='risk-row-section'>
							{item.risks.map((risk) => {
								const [originalRisk, assignedComments] =
									risk.riskNotes.split(
										'(assignedComments >)',
									);
								return (
									!risk.deletedAt && (
										<div
											className='risk-row'
											key={risk.uuid}
										>
											<img
												src={riskError}
												alt='risk flag'
											/>
											<p className='risk-title'>
												{risk.risk.title}:
											</p>
											<p className='risk-notes'>
												<b>Issue Raised:</b>{' '}
												{originalRisk}{' '}
												{assignedComments ? (
													<span>
														<br />
														<b>
															Assigned User Notes:
														</b>{' '}
														{assignedComments}
													</span>
												) : (
													''
												)}
											</p>
										</div>
									)
								);
							})}
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default CertificateList;
