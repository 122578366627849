import React, { FC, useEffect, useState, FormEvent, useRef } from 'react';
import './file-owner-search.styles.scss';
import searchIcon from '../../../assets/icons/search.svg';
import { ICompanyItem } from '../../../types/interfaces/company.interface';

type Props = {
	onSelectedCompany: (company: ICompanyItem) => void;
	allCompanies: Array<ICompanyItem>;
};

const FileOwnerSearch: FC<Props> = ({ onSelectedCompany, allCompanies }) => {
	const [companies, setCompanies] = useState<ICompanyItem[]>([]);
	const [displayCompanies, setDisplayCompanies] = useState<ICompanyItem[]>(
		[],
	);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const searchCompanyRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (searchCompanyRef.current) {
			searchCompanyRef.current.focus();
		}
	}, []);

	useEffect(() => {
		setDisplayCompanies(allCompanies);
		setCompanies(allCompanies);
	}, []);

	const handleSearch = (e: FormEvent<HTMLInputElement>): void => {
		setSearchTerm(e.currentTarget.value);
		const filteredCompaniesArray = companies.filter(
			(company: ICompanyItem) => {
				if (
					company.companyName
						.toLowerCase()
						.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
						.includes(e.currentTarget.value.toLowerCase())
				) {
					return company;
				}
			},
		);

		setDisplayCompanies(filteredCompaniesArray);
	};

	return (
		<div className='file-owner-search-container'>
			<div className='file-owner-search-component'>
				<img src={searchIcon} alt='search' />
				<input
					type='text'
					value={searchTerm}
					onChange={handleSearch}
					placeholder='Search'
					ref={searchCompanyRef}
				/>
			</div>

			{displayCompanies.length > 0 && (
				<div className='file-owner-search-container-list'>
					{displayCompanies.map((company) => {
						return (
							<div
								key={company.uuid}
								onClick={() => onSelectedCompany(company)}
								className='file-owner-search-container-list-item'
							>
								<p>{company.companyName}</p>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default FileOwnerSearch;
