import React, { useState, FC, useEffect } from 'react';
import './external-profile-review-modal.scss';
import manualBookIcon from '../../../assets/icons/manual-book.svg';
import checkboxBlue from '../../../assets/icons/checkbox-blue.svg';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';
import * as apiRtwVerified from '../../../api/api.rtw-verified';
import * as idApi from '../../../api/api.identity';
import { RootState } from '../../../types/root-state';
import { setRtwVerifiedData, setShareCodeData } from '../../../redux/Profile/profile.actions';
import { getReviewModalDetails } from '../../../utils/externalUtilsFunction';
import { IShareCodePayload } from '../../../types/interfaces/identity.interface';
import { formatDateForDb } from '../../../utils/utilsFunctions';
import { IFileItem } from '../../../types/interfaces/document.interface';
import { RtwDocType } from '../../../types/enum';
import EditInput from '../../general/edit-input/input.component';

const RTW_EXPIRY_ERROR_MESSAGE = 'The date must be in the future and not exceed 10 years from today';
const NI_DOC_ERROR_MESSAGE = 'Proof of national insurance number is required';
const RTW_DOC_ERROR_MESSAGE = 'A right to work document is required';

type Props = {
	reviewModalType: RtwDocType;
	rtwFilesArray: IFileItem[];
	niFileArray: IFileItem[];
};

const ExternalProfileReviewModal: FC<Props> = ({
	reviewModalType,
	rtwFilesArray,
	niFileArray,
}) => {
	const dispatch = useDispatch();
	const reviewModalDetails = getReviewModalDetails(reviewModalType);
	const { applicant, shareCodeData, rtwVerifiedDetails } = useSelector((state: RootState) => state.profile);
	const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
	const [alertConfirmTitle, setAlertConfirmTitle] = useState<string>('');
	const [alertConfirmMessage, setAlertConfirmMessage] = useState<string>('');
	const [alertConfirmButtonText, setAlertConfirmButtonText] = useState<string>('');
	const [showAlertConfirm, setShowAlertConfirm] = useState(false);
	const [buttonType, setButtonType] = useState('');
	const role = useSelector((state: RootState) => state.auth.role);
	
	const [rtwExpiryDate, setRtwExpiryDate] = useState<string | Date | null >(
		applicant.rtwExpiryDate ? new Date(applicant.rtwExpiryDate) : null,
	);
	const [reviewModalValidationError, setReviewModalValidationError] = useState('');
	const [showValidationError, setShowValidationError] = useState(false);
	 
	function isValidDate(dateToCheck: string | Date | null): boolean {
		setReviewModalValidationError('');
		setShowValidationError(false);

		const now = new Date();
		const tenYearsFromNow = new Date();
		tenYearsFromNow.setFullYear(now.getFullYear() + 10);
	
		const dateToCheckAsDate = dateToCheck === null ? null : typeof dateToCheck === 'string' ? new Date(dateToCheck) : dateToCheck;
	
		if (!(dateToCheckAsDate instanceof Date) || isNaN(dateToCheckAsDate.getTime())) {
			return false;
		}
	
		const isWithinRange = dateToCheckAsDate > now && dateToCheckAsDate <= tenYearsFromNow;
		return isWithinRange;
	};

	const handleCheckboxSelection = () => {
		setIsCheckboxSelected((isCheckboxSelected) => !isCheckboxSelected);
	};

	const emitValue = (val: string | Date) => {
		const isExpiryDateValid = isValidDate(new Date(val));
		const date = formatDateForDb(val);
		if (isExpiryDateValid) {
			setRtwExpiryDate(date);
		} else {
			if (reviewModalType === RtwDocType.SHARE_CODE || reviewModalType === RtwDocType.ALL_OTHER_DOCS) {
				setRtwExpiryDate(date);
				setReviewModalValidationError(RTW_EXPIRY_ERROR_MESSAGE);
				setShowValidationError(true);
			}
		}
	};

	const handleReviewActionButtonClick = () => {
		if (
			reviewModalDetails?.buttonActionTitle &&
			reviewModalDetails?.buttonActionMessage &&
			reviewModalDetails?.buttonActionOpt
		) {
			setButtonType(reviewModalDetails.buttonType);
			setAlertConfirmTitle(reviewModalDetails.buttonActionTitle);
			setAlertConfirmMessage(reviewModalDetails.buttonActionMessage);
			setAlertConfirmButtonText(reviewModalDetails.buttonActionOpt);
			setShowAlertConfirm(true);
		}
	};

	const handleResetIdentitryCheck = () => {
		setButtonType('reset-identity');
		setAlertConfirmTitle('Are you sure?');
		setAlertConfirmMessage(
			'Do you want to reset this candidates identity check?',
		);
		setAlertConfirmButtonText('Yes');
		setShowAlertConfirm(true);
	};

	const handleGetValFromAlert = (val: boolean) => {
		setShowAlertConfirm(false);
		if (buttonType === '' || val === false) return;
		if (buttonType === 'reset-identity' || buttonType === 'reset-rtw') {
			idApi.rtwResetSection(applicant.workerUuid, buttonType)
			.then((res) => {
				if (res.status !== 200) {
					throw res;
				}
				dispatch(
					setAlert({
						type: 'success',
						message:
							buttonType === 'reset-rtw'
								? 'Candidate RTW successfully reset'
								: 'Candidate Identity Check successfully reset',
						isVisible: true,
					}),
				);
				setTimeout(() => {
					window.location.reload();
				}, 100);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message:
							buttonType === 'reset-rtw'
								? 'Candidate RTW reset failed'
								: 'Candidate Identity Check reset failed',
						isVisible: true,
					}),
				);
			});
		}
		if (buttonType === 'invalid-sharecode') {
			const expiryDate = new Date();
			expiryDate.setDate(expiryDate.getDate() + 30);
			const expiryDateString = expiryDate.toISOString().split('T')[0];
			const payload: IShareCodePayload = {
				status: 'rejected',
				expiryDate: expiryDateString,
			};
			idApi.actionShareCode(
				payload,
				applicant.workerUuid,
				shareCodeData.uuid
			).then((res) => {
				dispatch(setShareCodeData(res.data.data));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully marked share code as invalid',
						isVisible: true,
					}),
				)
			}).catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to mark share code as invalid',
						isVisible: true,
					}),
				)
			});
		}
	};

	const emitSaveConfirmation = () => {
		const options: apiRtwVerified.RtwVerifiedRequest = {
			applicantUuid: applicant.uuid,
		};

		const isValidExpiryDate = isValidDate(rtwExpiryDate);

		if ((reviewModalType === RtwDocType.SHARE_CODE || 
				reviewModalType === RtwDocType.ALL_OTHER_DOCS) && 
				!isValidExpiryDate) 
		{
			handleValidationError(RTW_EXPIRY_ERROR_MESSAGE);
			return;
		} else {
			options.data = {
				documentType: reviewModalType,
				documentExpiry: formatDateForDb(rtwExpiryDate),
			};
		}
	
		if (reviewModalType === RtwDocType.SHARE_CODE && rtwFilesArray.length === 0) {
			handleValidationError(RTW_DOC_ERROR_MESSAGE);
			return;
		}
	
		if (reviewModalType === RtwDocType.ALL_OTHER_DOCS && niFileArray.length === 0) {
			handleValidationError(NI_DOC_ERROR_MESSAGE);
			return;
		}

		apiRtwVerified.acceptCandidateRtw(options)
			.then((res: any) => {
				const rtwVerified = res.data.data;
				dispatch(
					setRtwVerifiedData({
						...rtwVerified
					})
				);
			})
			.catch((err: any) => {
				throw err;
			})

		return;
	};

	const handleValidationError = (message: string) => {
		setReviewModalValidationError(message);
		setShowValidationError(true);
	};

	const renderRtwReview = () => {
		if (!reviewModalDetails) {
			return null;
		}
		return (
			<>
				<div className='review-modal-confirmation-container'>
					{(reviewModalType === RtwDocType.SHARE_CODE || reviewModalType === RtwDocType.ALL_OTHER_DOCS)  && 
						<div className='review-modal-expiry'>
							<p
								className='semi orka-p-medium'
							>
									RTW Expiry Date:
							</p>
							<EditInput
								title='RTW Expiry Date'
								displayTitle={false}
								value={rtwExpiryDate ? new Date(rtwExpiryDate) : null}
								emitValue={emitValue}
								placeholder='Click Calendar'
								inputName='rtwExpiryDate'
							></EditInput>
						</div>
					}
					<div className='review-modal-confirmation'>
						<div className='review-modal-confirmation-checkbox'>
							<img
								onClick={() =>
									handleCheckboxSelection()
								}
								src={
									isCheckboxSelected
										? checkboxBlue
										: checkboxWhite
								}
								alt='checkbox'
							/>
						</div>
						<div className='review-modal-confirmation-message'>
							<p className='orka-p-small'>
								{reviewModalDetails.bodyText}
							</p>
						</div>
					</div>
					<div className='confirmation-button'>
						<button
								disabled={!isCheckboxSelected}
								className='confirmation-button-text orka-p-small'
								onClick={emitSaveConfirmation}
							>
								Confirm Right to Work
						</button>
					</div>
				</div>
				{showValidationError &&
						<p className='validation-error-text semi'>{reviewModalValidationError}</p>
				}
				<div
					className='review-modal-guidance orka-p-small'
					onClick={() =>
						window.open(`${process.env.REACT_APP_MODAL_GUIDE_LINK}`,
							'_blank')}
					>
					<img
						className='review-modal-guidance-img'
						src={manualBookIcon}
						alt='guide manual icon'
					/>
					<p className='orka-p-small review-modal-guidance-text'>
						<span className='semi'>Guide: </span>
						How to perform a right to work check
					</p>
				</div>
			</>
		)
	};

	const renderRtwReviewActions = () => {
		if (!reviewModalDetails) {
			return null;
		  }
		return (
			<>
					{reviewModalDetails.button1 !== '' && (
						<button
							className='review-action-button orka-p-small'
							onClick={handleReviewActionButtonClick}
						>
							{reviewModalDetails.button1}
						</button>
					)}
					{showAlertConfirm && (
						<AlertConfirm
							title={alertConfirmTitle}
							message={alertConfirmMessage}
							getVal={handleGetValFromAlert}
							confirm={alertConfirmButtonText}
							reject='Cancel'
							reviewModalType={reviewModalType}
						/>
					)}
					<button
						className='review-action-button orka-p-small'
						onClick={handleResetIdentitryCheck}
					>
						Reset Identity Check
					</button>
					<p className='orka-p-small review-action-text'>
						Resetting either check will mean the candidate needs
						to start that check process again. Your plan
						includes up to two resets per check.
					</p>
			</>
		)
	};

	const renderRtwPending = () => {
		return (
			<>
				<div className='review-modal-pending'>
					<div className='review-modal-pending-title demi-bold'>Verification Pending</div>
					<br></br>
					<p>Candidate has not yet been verified.</p>
				</div>
			</>
		)
	};

	const renderRtwVerified = () => {
		return (
			<>
				<div className='review-modal-verified'>
					<div className='review-modal-verified-title demi-bold'>Identity Verified</div>
					<br></br>
					<p>REVIEW HISTORY</p>
					<p className='demi-bold'>Evidence checked by:</p>
					<p>{rtwVerifiedDetails.evidenceCheckedBy ? rtwVerifiedDetails.evidenceCheckedBy : 'N/A'}</p>
					<p className='demi-bold'>Date checked:</p>
					<p>{rtwVerifiedDetails.dateChecked ? new Date(rtwVerifiedDetails.dateChecked).toLocaleDateString(): 'N/A'}</p>
					{rtwVerifiedDetails.documentExpiry &&
					<>
						<p className='demi-bold'>Document expiry:</p>
						<p>{rtwVerifiedDetails.documentExpiry ? new Date(rtwVerifiedDetails.documentExpiry).toLocaleDateString() : 'N/A'}</p>
						</>
					}
				</div>
			</>
		)
	};

	useEffect(() => {
		if (niFileArray.length > 0 || rtwFilesArray.length > 0) {
			setReviewModalValidationError('');
			setShowValidationError(false);
		}
	}, [niFileArray, rtwFilesArray]);
	
	return (
		<>
			{reviewModalDetails && 
				<div className='review-modal-container'>
					<div className='review-modal-title'>
						<p className='review-modal-header'>
							{reviewModalDetails.title}
						</p>
						<div className='review-modal-divider'></div>
					</div>

					<div className='review-modal'>
						<div className='review-modal-top'>
							{(rtwVerifiedDetails.status === false && role !== 'viewer') && renderRtwReview()}
							{(rtwVerifiedDetails.status === false && role === 'viewer') && renderRtwPending()}
							{rtwVerifiedDetails.status === true && renderRtwVerified()}
						</div>
						<div className='review-modal-bottom'>
							{(rtwVerifiedDetails.status === false && role !== 'viewer') && renderRtwReviewActions()}
						</div>				
					</div>
				</div>
			}
		</>
	);
};

export default ExternalProfileReviewModal;
