import React, { FC, useState, useRef, useEffect, Fragment } from 'react';
import './organisation-table.styles.scss';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import Layout from '../../components/general/layout-component/layout.component';
import ReferenceHeader from '../../components/organisation/organisation-header/organisation-header.component';
import ReferenceSubHeader from '../../components/organisation/organisation-sub-header/organisation-sub-header.component';
import ReferenceRow from '../../components/organisation/organisation-row/organisation-row.component';
import { IOrganization } from '../../types/interfaces/reference.interface';
import * as referenceApi from '../../api/api.reference';
import TaskPagination from '../../components/task-center/task-pagination/task-pagination.component';
import { setAlert } from '../../redux/General/general.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import { clearNavigationState } from '../../redux/Navigation/navigation.actions';

const ReferenceTable: FC = () => {
	const organisationPage = useSelector(
		(state: RootState) => state.dashboard.organisationPage,
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [organisations, setOrganisations] = useState<IOrganization[]>([]);
	const [headerRef, getRef] = useState(Object);
	const [totalPages, setTotalPages] = useState(5);
	const [refetch, setRefetch] = useState<boolean>(false);
	const [searchTerm, getSearchTerm] = useState('');
	const organisationsRef = useRef<HTMLDivElement>(null);
	const activityItem = useSelector(
		(state: RootState) => state.navigation.activityItem,
	);
	const fromActivity = useSelector(
		(state: RootState) => state.navigation.fromActivity,
	);
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearNavigationState());
		};
	}, []);

	useEffect(() => {
		referenceApi
			.getOrganisations(organisationPage, searchTerm)
			.then((res: any) => {
				if (res.data && res.data.data && res.data.data.items) {
					const organisations = res.data.data.items.filter(
						(organisation: IOrganization) =>
							!organisation.deletedAt,
					);
					setOrganisations(organisations);
					const total = Math.ceil(res.data.data.meta.total / 20);
					setTotalPages(total);
				} else {
					setOrganisations([]);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	}, [organisationPage, searchTerm, refetch]);

	const handleScroll = () => {
		if (organisationsRef && organisationsRef.current) {
			headerRef.current.scrollLeft = organisationsRef.current.scrollLeft;
		}
	};

	return (
		<Layout>
			<ErrorBoundary applicantWorkerUuid='None'>
				<div className='organisations-container'>
					<ReferenceHeader
						getSearchTerm={getSearchTerm}
						setRefetch={setRefetch}
						refetch={refetch}
						fromActivity={fromActivity}
					/>
					<ReferenceSubHeader
						getRef={getRef}
						organisationsRef={organisationsRef}
					/>
					<div
						className='organisations'
						ref={organisationsRef}
						onScroll={handleScroll}
					>
						{organisations.map((organisation: IOrganization) => {
							return (
								<Fragment key={organisation.uuid}>
									<ReferenceRow
										organisation={organisation}
										fromActivity={fromActivity}
										activityItem={activityItem}
										setRefetch={setRefetch}
										refetch={refetch}
									/>
								</Fragment>
							);
						})}
					</div>
					<div className='organisation-pagination'>
						<TaskPagination
							totalPages={totalPages}
							type='organisation'
						/>
					</div>
				</div>
			</ErrorBoundary>
		</Layout>
	);
};

export default ReferenceTable;
