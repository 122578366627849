import axios, { AxiosInstance } from 'axios';
import { getDefaultHeaders, getUrls } from './api.utils';
import { setIsLoading } from '../redux/General/general.actions';
import store from '../redux/store';

const { GATEWAY_URL } = getUrls();
const ignoreArray = ['admin/companies/references', '/check-fe-logging'];

export const axiosInstance = (): AxiosInstance => {
	const { dispatch } = store;

	const axiosInstance = axios.create({
		withCredentials: true,
		baseURL: GATEWAY_URL,
		headers: getDefaultHeaders(),
	});

	axiosInstance.interceptors.request.use(
		function (config) {
			const foundIgnore = ignoreArray.filter((el) => {
				if (
					el === config.url?.substring(0, el.length) ||
					el === config.url?.replace(/\?(.*)/, '') ||
					config.url?.includes('assign')
				) {
					return el;
				}
			})[0];

			if (foundIgnore) {
				return config;
			}
			dispatch(setIsLoading(true));
			return config;
		},
		function (error) {
			dispatch(setIsLoading(false));
			return Promise.reject(error);
		},
	);

	axiosInstance.interceptors.response.use(
		function (response) {
			dispatch(setIsLoading(false));
			return response;
		},
		function ({ response }) {
			dispatch(setIsLoading(false));

			if (response && response.data && response.data.message) {
				const errorArray = [
					'Logon details incorrect.',
					'User not found.',
					'Incorrect verification code.',
					'Maximum verification attempt reached. Please contact the Deploi Team.',
					'contactNumber must be a valid phone number',
				];

				const errMessage =
					typeof response.data.message === 'string'
						? response.data.message
						: response.data.message[0];

				if (
					response &&
					response.data &&
					errorArray.includes(errMessage)
				) {
					return Promise.reject({
						status: response.status,
						message: errMessage,
					});
				}
			}

			if (response.status === 404) {
				return Promise.resolve({
					data: [],
					status: 404,
					message: response.message,
				});
			} else if (response.status === 401 || response.status === 403) {
				window.location.href = '/login';
				return Promise.reject(response);
			}

			return Promise.reject(response);
		},
	);

	return axiosInstance;
};
