import './external-user-profile-sections.styles.scss';

import React, { FC } from 'react';
import { formatDateToText } from '../../../utils/externalUtilsFunction';
import { IFileItem } from '../../../types/interfaces/document.interface';

import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';

import filesIcon from '../../../assets/icons/external-portal/user-profile/files.svg';
import downloadFileIcon from '../../../assets/icons/external-portal/user-profile/download-file.svg';

interface Props {
	additionalFileArray: IFileItem[];
}

const ExternalUserProfileFiles: FC<Props> = ({ additionalFileArray }) => {
	const visibleFiles = additionalFileArray.filter(
		(file: IFileItem) => !file.deletedAt && file.status !== 'rejected',
	);

	const getSectionStatus = () => {
		if (!visibleFiles || visibleFiles.length === 0) {
			return 'verified';
		}

		const statuses = visibleFiles.map((file) => file?.status);

		if (statuses.every((status) => status === 'accepted')) {
			return 'verified';
		}

		if (statuses.some((status) => status === 'accepted')) {
			return 'partially_verified';
		}

		return '';
	};

	const getLatestDate = (): string => {
		if (visibleFiles.length === 0) {
			return '';
		}

		const latestUpdatedAt = visibleFiles.reduce(
			(latestDate: string, file: any) => {
				if (file.updatedAt && file.updatedAt > latestDate) {
					return file.updatedAt;
				}
				return latestDate;
			},
			'',
		);

		if (latestUpdatedAt !== '') {
			return formatDateToText(latestUpdatedAt);
		}

		const latestCreatedAt = visibleFiles.reduce(
			(latestDate: string, file: any) => {
				if (file.createdAt && file.createdAt > latestDate) {
					return file.createdAt;
				}
				return latestDate;
			},
			'',
		);

		return formatDateToText(latestCreatedAt);
	};

	const renderFilesSection = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon'
						src={filesIcon}
						alt='files-icon'
					></img>
					<p className='external-user-profile-sub-section-title external-user-profile-sub-section-title-margin'>
						Files
					</p>
				</div>
				{visibleFiles && visibleFiles.length > 0 ? (
					<>
						{visibleFiles.map((file: IFileItem, index: number) => (
							<div
								key={index}
								className='user-profile-section-file-container'
							>
								<div className='user-profile-section-file-content'>
									<a
										href={file.readUrl}
										target='_blank'
										rel='noreferrer'
										className='user-profile-section-file-link'
									>
										<img
											className='user-profile-section-file-icon'
											src={downloadFileIcon}
											alt='file-icon'
										/>
										<span className='user-profile-section-file-number'>
											File{' '}
											{visibleFiles.indexOf(file) + 1}
										</span>
									</a>
									<span
										className='user-profile-section-file-status'
										style={{
											color:
												file.status === 'accepted'
													? '#485BEB'
													: file.status ===
														  'unselected'
														? '#F8C96A'
														: '#EC8079',
										}}
									>
										{file.status === 'unselected'
											? 'Pending'
											: utils.capitalizeFirstLetter(
													file.status,
												)}
									</span>
								</div>
							</div>
						))}
					</>
				) : (
					<div className='external-user-profile-sub-section-subtitle-container'>
						<p className='external-user-profile-sub-section-subtitle'>
							No Files Available
						</p>
					</div>
				)}
			</section>
		);
	};

	return (
		<section className='view-container'>
			<div className='identity-container'>
				<p className='identity-header-title'>Additional Files</p>
				<div className='identity-header-right'>
					<ExternalUserProfileStatus
						sectionStatus={getSectionStatus()}
					/>
				</div>
			</div>
			<div className='user-profile-section-divider-line'></div>
			<div className='user-profile-section-main'>
				{renderFilesSection()}
			</div>
			<div className='last-verified'>
				{getLatestDate() && `Last Verified on ${getLatestDate()}`}
			</div>
		</section>
	);
};

export default ExternalUserProfileFiles;
