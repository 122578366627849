import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { IApplicant } from '../../../types/interface';
import './dashboard-row.styles.scss';
import DashboardRowContent from '../dashboard-row-content/dashboard-row-content.component';

type Props = {
	applicant: IApplicant;
	applicantNumber?: number;
	applicantLength?: number;
	setCancelClick: Dispatch<SetStateAction<boolean>>;
	cancelClick: boolean;
};

const DashboardRow: FC<Props> = ({
	applicant,
	applicantNumber,
	applicantLength,
	setCancelClick,
	cancelClick,
}) => {
	const [hideCancel, setHideCancel] = useState(false);

	return (
		<div
			className={
				hideCancel
					? 'dashboard-row-outer remove-position'
					: 'dashboard-row-outer'
			}
		>
			<div className='dashboard-row'>
				<DashboardRowContent
					applicant={applicant}
					applicantNumber={applicantNumber}
					applicantLength={applicantLength}
					setHideCancel={setHideCancel}
					setCancelClick={setCancelClick}
					cancelClick={cancelClick}
				/>
			</div>
			{(applicant?.overallSubmission?.status === 'cancelled' ||
				applicant.paused) &&
				!hideCancel && <div className='dashboard-row-dark' />}
		</div>
	);
};

export default DashboardRow;
