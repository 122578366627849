import React, { useEffect, useState, FC } from 'react';
import './certificate-activity.styles.scss';
import { useSelector } from 'react-redux';
import * as utils from '../../../utils/utilsFunctions';
import moment from 'moment';
import { WorkHistoryItem } from '../../../types/interface';

type RootState = {
	profile: {
		workHistory: WorkHistoryItem[];
	};
};

const CertificateActivity: FC = () => {
	const workHistory = useSelector(
		(state: RootState) => state.profile.workHistory,
	);
	const [localHistory, setLocalHistory] = useState(workHistory);

	useEffect(() => {
		const sortedWorkHistory = utils
			.sortByDate(workHistory)
			.map((item: WorkHistoryItem, index: number) => {
				if (index >= 1) {
					findOverlap(item, workHistory[index - 1]);
				}
				return item;
			});
		setLocalHistory(sortedWorkHistory);
	}, []);

	const findOverlap = (
		currentItem: WorkHistoryItem,
		nextItem: WorkHistoryItem,
	): void => {
		const currentItemEnd = currentItem.endAt
			? moment(currentItem.endAt)
			: moment();
		const nextItemStart = moment(nextItem.startAt);

		if (currentItemEnd > nextItemStart) {
			currentItem.overlap = true;
		} else {
			currentItem.overlap = false;
		}
	};

	const getWorkPattern = (item: WorkHistoryItem): string => {
		if (item.type === 'employed' || item.type === 'self-employed') {
			return item.isPartTime ? ' (PT)' : ' (FT)';
		}
		return '';
	};

	return (
		<>
			<div className='cert-section-title'>
				<p>Activity History</p>
			</div>

			<div className='cert-history-container'>
				<div className='cert-history-header'>
					<div className='col-lg'>
						<p className='grey-text'>Dates</p>
					</div>
					<div className='col'>
						<p className='grey-text'>Activity</p>
					</div>
					<div className='col'>
						<p className='grey-text'>Company</p>
					</div>
				</div>

				{localHistory.length > 0 &&
					localHistory.map((item: WorkHistoryItem) => (
						<div
							className={
								item.overlap
									? 'cert-history-row extra-padding'
									: 'cert-history-row'
							}
							key={item.uuid}
						>
							{item.overlap && (
								<div className='overlap'>overlap</div>
							)}

							<div className='col-lg'>
								<p>
									{utils.formatDateForUser(item.startAt)} -
									{item.endAt
										? utils.formatDateForUser(item.endAt)
										: 'Present'}
								</p>
							</div>

							<div className='col'>
								<p>
									{utils.capitalizeFirstLetter(
										utils.removeHyphen(item.type),
									)}
									{getWorkPattern(item)}
								</p>
							</div>

							<div className='col'>
								<p>{item.name ? item.name : 'N/A'}</p>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default CertificateActivity;
