import './profile-reset-rtw.scss';

import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { setAlert } from '../../../redux/General/general.actions';

import * as apiId from '../../../api/api.identity';
import OrkaButton from '../../general/orka-button/orka-button.component';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';

type Props = {
	resetType: 'RTW' | 'ID';
};

const ProfileResetRTW: FC<Props> = ({ resetType }) => {
	const dispatch = useDispatch();

	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const resetBtnAction = () => {
		setShowAlertConfirm(true);
	};

	const handleAlertReset = (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			return resetSection();
		}
	};

	const resetSection = () => {
		return apiId
			.rtwResetSection(applicant.workerUuid, resetType)
			.then((res) => {
				if (res.status !== 200) {
					throw res;
				}
				dispatch(
					setAlert({
						type: 'success',
						message:
							resetType === 'RTW'
								? 'Candidate RTW successfully reset'
								: 'Candidate Identity Check successfully reset',
						isVisible: true,
					}),
				);
				setTimeout(() => {
					window.location.reload();
				}, 100);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message:
							resetType === 'RTW'
								? 'Candidate RTW reset failed'
								: 'Candidate Identity Check reset failed',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div className='reset-rtw-section'>
			<div className='reset-rtw'>
				<OrkaButton
					buttonContent='Reset'
					disabled={false}
					emitClicked={resetBtnAction}
				/>
			</div>
			{showAlertConfirm && (
				<AlertConfirm
					message={`Are you sure you want to reset the ${
						resetType === 'RTW' ? 'RTW' : 'Identity Verification'
					} section for this user?`}
					getVal={handleAlertReset}
					confirm='Yes'
					reject='Cancel'
				/>
			)}
		</div>
	);
};

export default ProfileResetRTW;
