import React, {
	useEffect,
	useRef,
	useState,
	FC,
	Dispatch,
	SetStateAction,
	RefObject,
} from 'react';
import './task-header.styles.scss';
import chevronUp from '../../../assets/icons/chevron-up.svg';
import chevronDown from '../../../assets/icons/chevron-down.svg';
import { ITaskHeading } from '../../../types/interfaces/misc.interface';

type Props = {
	getRef: Dispatch<SetStateAction<RefObject<HTMLDivElement>>>;
	taskUsersRef: RefObject<HTMLDivElement>;
	sortBy: string;
	setSortBy: Dispatch<SetStateAction<string>>;
	sortDirection: string;
	setSortDirection: Dispatch<SetStateAction<string>>;
	taskType: string;
};

const TaskHeader: FC<Props> = ({
	getRef,
	taskUsersRef,
	sortBy,
	setSortBy,
	setSortDirection,
	taskType = '',
}) => {
	const taskHeaderRef = useRef<HTMLDivElement>(null);
	const [headings, setHeadings] = useState<ITaskHeading[]>([
		{
			name: 'Shifts',
			class: 'col-sm center accepted-shifts',
			sortDirection: 'DESC',
			sortBy: 'shifts',
			visible: false,
		},
		{
			name: 'Days',
			class: 'col-sm center',
			sortDirection: 'ASC',
			sortBy: 'completeBy',
			visible: true,
		},
		{
			name: 'Task',
			class: 'col-md',
			sortDirection: 'DESC',
			sortBy: 'task',
			visible: false,
		},
		{
			name: 'Email',
			class: 'col-lg',
			sortDirection: 'DESC',
			sortBy: 'email',
			visible: false,
		},
		{
			name: 'Status',
			class: 'col-sm center mobile-hide',
			sortDirection: 'DESC',
			sortBy: 'status',
			visible: false,
		},
	]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [checkTaskHeadings, setCheckTaskHeadings] = useState<ITaskHeading[]>([
		{
			name: 'Task',
			class: 'col-md',
			sortDirection: 'DESC',
			sortBy: '',
			visible: false,
		},
		{
			name: 'Email',
			class: 'col-lg',
			sortDirection: 'DESC',
			sortBy: 'email',
			visible: false,
		},
	]);

	useEffect(() => {
		getRef(taskHeaderRef);
	}, [getRef]);

	const handleScroll = () => {
		if (taskHeaderRef.current && taskUsersRef.current) {
			taskUsersRef.current.scrollLeft = taskHeaderRef.current.scrollLeft;
		}
	};

	const handleSort = (selectedHeading: ITaskHeading) => {
		setSortBy(selectedHeading.sortBy);
		const mappedHeadings = headings.map((heading) => {
			if (heading.name !== selectedHeading.name) {
				heading.sortDirection = 'DESC';
			} else {
				const newSortDirection =
					selectedHeading.sortDirection === 'ASC' ? 'DESC' : 'ASC';
				setSortDirection(newSortDirection);
				heading.sortDirection = newSortDirection;
			}
			return heading;
		});
		setHeadings(mappedHeadings);
	};

	return (
		<div
			className='task-header-container'
			ref={taskHeaderRef}
			onScroll={handleScroll}
		>
			<div className='task-header-container-left'>
				{taskType === '' &&
					headings.map((heading) => {
						return (
							<div className={heading.class} key={heading.name}>
								<p>
									{heading.name}
									{heading.visible && (
										<img
											className='sorting-arrows'
											src={
												heading.sortDirection ===
													'ASC' &&
												heading.sortBy === sortBy
													? chevronUp
													: chevronDown
											}
											alt='sort direction'
											onClick={() => handleSort(heading)}
										/>
									)}
								</p>
							</div>
						);
					})}

				{taskType === 'checkTask' &&
					checkTaskHeadings.map((heading) => {
						return (
							<div className={heading.class} key={heading.name}>
								<p>
									{heading.name}
									{/* {heading.visible && (
										<img
											className='sorting-arrows'
											src={
												heading.sortDirection ===
													'ASC' &&
												heading.sortBy === sortBy
													? chevronUp
													: chevronDown
											}
											alt='sort direction'
											onClick={() => handleSort(heading)}
										/>
									)} */}
								</p>
							</div>
						);
					})}
			</div>
			<div className='task-header-container-right' />
		</div>
	);
};

export default TaskHeader;
