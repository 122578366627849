import React, { Dispatch, FC, SetStateAction } from 'react';
import './profile-section.styles.scss';
import arrowUp from '../../../assets/icons/chevron-up.svg';
import arrowDown from '../../../assets/icons/chevron-down.svg';

type Props = {
	children?: React.ReactNode;
	title: string;
	hideSide?: boolean;
	setShowSection?: Dispatch<SetStateAction<boolean>>;
	showSection?: boolean;
	expandable?: boolean;
};

const ProfileSection: FC<Props> = ({
	title,
	children,
	hideSide = false,
	setShowSection,
	showSection,
	expandable = false,
}) => {
	const handleShowSection = () => {
		if (!expandable) {
			return;
		}
		if (setShowSection) {
			setShowSection(!showSection);
		}
	};
	return (
		<div className='profile-section'>
			<div className='profile-section-title'>
				{!hideSide && (
					<div className='profile-section-title-space'></div>
				)}
				<div
					className='profile-section-title-text'
					onClick={handleShowSection}
				>
					<h4>{title}</h4>
					{expandable && (
						<img
							src={showSection ? arrowUp : arrowDown}
							alt='arrow'
						/>
					)}
				</div>
			</div>
			<div>{children}</div>
		</div>
	);
};

export default ProfileSection;
