import React, { FC } from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import SectionHeader from '../section-header/section-header.component';
import KeyVal from '../key-val/key-val.component';
import store from '../../../redux/store';
import { RootState } from '../../../types/root-state';

const styles = StyleSheet.create({
	section: {
		display: 'flex',
	},
	main: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		minWidth: '200px',
		marginRight: '24px',
	},
	image: {
		width: '220px',
		height: '180px',
	},
});

const Rtw: FC = () => {
	const state: RootState = store.getState();
	const { reportDocInfo } = state.profile;

	return (
		<View style={styles.section}>
			<SectionHeader title='Right to Work and ID Verification' />
			<div style={styles.main}>
				<div>
					<KeyVal title='Document Type' value={reportDocInfo.type} />
					<KeyVal
						title='Expiry Date'
						value={reportDocInfo.expiryDate}
					/>
					{reportDocInfo.code !== '' && (
						<KeyVal title='Share Code' value={reportDocInfo.code} />
					)}
				</div>
				<div>
					{reportDocInfo?.url?.length > 0 && (
						<Image
							src={reportDocInfo?.url}
							cache={false}
							style={styles.image}
						/>
					)}
				</div>
			</div>
		</View>
	);
};

export default Rtw;
