import React, { useEffect, useState, useRef, FC } from 'react';
import './progress-circle.styles.scss';

type Props = {
	size: number;
	progress: number;
	strokeWidth: number;
	circleOneStroke: string;
	circleTwoStroke: string;
	daysRemaining: number;
};

const ProgressCircle: FC<Props> = ({
	size,
	progress,
	strokeWidth,
	circleOneStroke,
	circleTwoStroke,
	daysRemaining,
}) => {
	const [offset, setOffset] = useState(0);
	const circleRef = useRef<SVGCircleElement>(null);
	const center = size / 2;
	const radius = size / 2 - strokeWidth / 2;
	const circumference = 2 * Math.PI * radius;

	useEffect(() => {
		const progressOffset = ((100 - progress) / 100) * circumference;
		setOffset(progressOffset);
		if (circleRef.current) {
			circleRef.current.style.transition =
				'stroke-dashoffset 850ms ease-in-out;';
		}
	}, [setOffset, circumference, progress, offset]);

	return (
		<div className='progress-circle-container'>
			<svg className='svg' width={size} height={size}>
				<circle
					className='svg-circle-bg'
					stroke={circleOneStroke}
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={strokeWidth}
				/>
				<circle
					className='svg-circle'
					ref={circleRef}
					stroke={circleTwoStroke}
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={strokeWidth}
					strokeDasharray={circumference}
					strokeDashoffset={offset}
				/>
				<text
					x={`${center}`}
					y={`${center}`}
					className='svg-circle-text'
				>
					{daysRemaining}
				</text>
			</svg>
		</div>
	);
};

export default ProgressCircle;
