import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import CertificateInfoItem from '../certificate-info-item/certificate-info-item.component';
import '../certificate-info/certificate-info.styles.scss';
import './certificate-clearance.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import { IApplicant } from '../../../types/interface';

type RootState = {
	profile: {
		applicant: IApplicant;
	};
};

const CertificateClearance: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	const assignedTo = () => {
		if (applicant.clearedAssignedTo) {
			return utils.capitalizeFirstLetterOfEach(
				applicant.clearedAssignedTo,
			);
		}

		if (applicant.assigned && applicant.assigned.length > 0) {
			const assignedNames = applicant.assigned.map((assigned) => {
				return `${assigned.name} ${assigned.surname}`;
			});

			if (assignedNames.length > 1) {
				return `${assignedNames.slice(0, -1).join(', ')} and ${assignedNames[assignedNames.length - 1]}`;
			} else {
				return assignedNames[0];
			}
		}

		return 'Not yet assigned';
	};
	return (
		<div className='certificate-info-container'>
			<div className='cert-section-title'>
				<p>Clearance Information</p>
			</div>
			<div className='certificate-info-container-main'>
				<div className='certificate-info-container-side'>
					<CertificateInfoItem
						label='Clearance Type'
						value={'Limited Screening'}
					/>
					<CertificateInfoItem
						label='Clearance Date'
						value={
							clearance?.provisionallyClearedDate
								? utils.formatDateForUser(
										clearance.provisionallyClearedDate,
									)
								: 'No limited screening'
						}
					/>
					<CertificateInfoItem
						label='Cleared by'
						value={
							clearance?.provisionallyClearedBy
								? clearance.provisionallyClearedBy
								: 'No limited screening'
						}
					/>
				</div>
				<div className='certificate-info-container-side'>
					<CertificateInfoItem
						label='Clearance Type'
						value={'Full Screening'}
					/>
					<CertificateInfoItem
						label='Clearance Date'
						value={
							clearance?.fullyClearedDate
								? utils.formatDateForUser(
										clearance.fullyClearedDate,
									)
								: 'Not fully cleared'
						}
					/>
					<CertificateInfoItem
						label='Cleared by'
						value={
							clearance?.fullyClearedBy
								? clearance.fullyClearedBy
								: 'Not fully cleared'
						}
					/>
				</div>
			</div>
			<div className='file-assigned-to'>
				<CertificateInfoItem
					label='File assigned to'
					value={assignedTo()}
				/>
			</div>
		</div>
	);
};

export default CertificateClearance;
