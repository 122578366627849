import './profile-criminal-bankruptcy.scss';

import React, { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { CriminalBankruptcyItem } from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';

interface CriminalSection {
	title: string;
	data: CriminalBankruptcyItem[];
	labelPrefix: string;
}

const ProfileCriminalBankruptcy: FC = () => {
	const criminalBankruptcy = useSelector(
		(state: RootState) => state.profile.criminalBankruptcy,
	);

	const [criminalSections, setCriminalSections] = useState<CriminalSection[]>(
		[],
	);

	useEffect(() => {
		const sectionTypes = [
			{
				type: 'conviction',
				title: 'Conviction, caution or written warnings within the last 12 months',
				labelPrefix: 'Conviction',
			},
			{
				type: 'awaiting trial',
				title: 'Offences that are currently awaiting trial',
				labelPrefix: 'Offence',
			},
			{
				type: 'bankruptcy',
				title: 'Bankruptcy proceedings and court judgments',
				labelPrefix: 'Caution',
			},
			{
				type: 'war crimes',
				title: 'Involvement in war crimes',
				labelPrefix: 'War Crime',
			},
			{
				type: 'terrorism',
				title: 'Involvement or support of terrorist activities',
				labelPrefix: 'Terrorism Activity',
			},
			{
				type: 'terrorist views',
				title: 'Expressed views that glorify terrorist acts',
				labelPrefix: 'Terrorist Views',
			},
			{
				type: 'bad character',
				title: 'Could you be considered of bad character',
				labelPrefix: 'Activity',
			},
		];

		const sections = sectionTypes.map((section) => ({
			title: section.title,
			data: criminalBankruptcy.filter((el) => el.type === section.type),
			labelPrefix: section.labelPrefix,
		}));

		setCriminalSections(sections);
	}, [criminalBankruptcy]);

	return (
		<div className='conviction-section'>
			{criminalSections.map((section, sectionIndex) => (
				<div key={sectionIndex} className='margin-bottom-16'>
					<p className='orka-profile-value'>{section.title}</p>
					{section.data.length > 0 ? (
						section.data.map((el, index) => (
							<div key={el.id} className='conviction-item'>
								<p className='orka-profile-label'>
									{section.labelPrefix} {index + 1}
								</p>
								<p className='orka-profile-value'>
									{el.sentence}
								</p>
								<p className='orka-profile-value'>
									{el.details}
								</p>
								<p className='orka-profile-value'>
									{utils.formatDateForUser(el.date)}
								</p>
							</div>
						))
					) : (
						<div>
							<p className='orka-profile-value na'>
								None Submitted
							</p>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default ProfileCriminalBankruptcy;
