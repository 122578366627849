import React, { useState, FC, useEffect } from 'react';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import './set-default-flow.styles.scss';
import { IDropdownStandard } from '../../../types/interface';
import useGetDefault from './useGetDefault.component';
import { getMasterFlow, updateMasterFlow } from '../../../api/api.misc';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { handleAlerts } from '../../../utils/utilsFunctions';

const SetDefaultFlow: FC = () => {
	const role = useSelector((state: RootState) => state.auth.role);
	const dispatch = useDispatch();
	const [defaultLocalData, setDefaultLocalData] =
		useState<IDropdownStandard>();
	const [flowStatus, setFlowStatus] = useState<string>('');
	const { dropdownData, defaultDataFromHook } = useGetDefault(
		'defaultFlowDropdown',
	);

	useEffect(() => {
		getMasterFlow()
			.then((res) => {
				if (res && res.data) {
					setFlowStatus(res.data[0].value);
				}
			})
			.catch((err) => {
				handleAlerts('error', err.message, dispatch);
			});
	}, []);

	useEffect(() => {
		if (flowStatus) {
			dropdownData.map((item: IDropdownStandard) => {
				if (item.dbValue === flowStatus) {
					setDefaultLocalData(item);
				}
			});
		}
	}, [flowStatus]);

	const getDropdownVal = (val: IDropdownStandard): void => {
		setDefaultLocalData(val);
		updateMasterFlow(val.dbValue);
	};

	return (
		<>
			{defaultDataFromHook && flowStatus && role === 'controller' && (
				<div className='default-flow-container'>
					<p>Default flow</p>
					<Dropdown
						type='defaultFlowDropdown'
						dropdownData={dropdownData}
						defaultData={
							defaultLocalData
								? defaultLocalData
								: defaultDataFromHook
						}
						getDropdownVal={getDropdownVal}
						width='249'
						height={34}
						mobileResponsive={false}
						position='top-center'
					/>
				</div>
			)}
		</>
	);
};

export default SetDefaultFlow;
