import React, { FC } from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	header: {
		marginTop: 40,
		marginBottom: 40,
	},
	headerTop: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	box: {
		height: '4px',
		width: '130px',
		backgroundColor: 'black',
	},
	headerRight: {
		display: 'flex',
		flexDirection: 'row',
	},
	textSmallBold: {
		fontWeight: 700,
		fontSize: '10px',
	},
	textSmall: {
		fontSize: '10px',
	},
	textHeaderBold: {
		fontWeight: 700,
		fontSize: '12px',
		marginBottom: '4px',
	},
	textHeaderNormal: {
		fontSize: '12px',
	},
	headerMain: {
		borderLeft: '1px solid black',
		padding: '8px 14px',
		marginTop: '16px',
	},
});

type Props = {
	clearance: {
		clearanceLevel: string;
		clearanceDate: string;
		clearedBy: string;
	};
};

const ReportHeader: FC<Props> = ({ clearance }) => {
	return (
		<View style={styles.header} fixed>
			<div style={styles.headerTop}>
				<div style={styles.box} />

				<div style={styles.headerRight}>
					<Text
						style={{ ...styles.textSmallBold, marginRight: '4px' }}
					>
						Deploi
					</Text>
					<Text style={styles.textSmall}>Screening Report</Text>
				</div>
			</div>

			<div style={styles.headerMain}>
				<Text style={styles.textHeaderBold}>
					{clearance.clearanceLevel}
				</Text>
				<Text style={styles.textHeaderNormal}>
					{clearance.clearanceDate}
				</Text>
			</div>
		</View>
	);
};

export default ReportHeader;
