import React, { FC, useState } from 'react';
import './reference-sidebar.styles.scss';
import { WorkHistoryItem } from '../../../types/interface';
import ReferenceStatus from '../reference-status/reference-status.component';
import * as apiApplicant from '../../../api/api.applicant';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import * as utils from '../../../utils/utilsFunctions';

type Props = {
	activityItem?: WorkHistoryItem;
};

const ReferenceSidebar: FC<Props> = ({ activityItem }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [refStatusDate, setRefStatusDate] = useState(
		activityItem?.reference?.statusUpdatedAt,
	);

	const updateActivity = (payload: any) => {
		return apiApplicant
			.updateWorkHistory(
				applicant.workerUuid,
				activityItem ? activityItem.uuid : '',
				payload,
			)
			.then(() => {
				setRefStatusDate(payload.reference.statusUpdatedAt);
				dispatch(
					setAlert({
						type: 'success',
						message: 'Updated activity reference status',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating reference status',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div>
			<div className='margin-bottom-32'>
				<ReferenceStatus
					activityItem={activityItem}
					updateActivity={updateActivity}
					setRefStatusDate={setRefStatusDate}
				/>
			</div>
			<div className='reference-status-last-chased'>
				<p className='orka-profile-label margin-bottom-4'>
					Status Chased
				</p>
				<div className='last-chased-date'>
					<p>
						{refStatusDate
							? utils.formatDateForUser(refStatusDate)
							: ''}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ReferenceSidebar;
