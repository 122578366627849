import React, { FC } from 'react';
import { WorkHistoryItem } from '../../../types/interface';
import './activity-original-item.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import FileList from '../file-list/file-list.component';

type Props = {
	originalActivity: WorkHistoryItem;
};

const ActivityOriginalItem: FC<Props> = ({ originalActivity }) => {
	const getAddress = () => {
		let address = '';
		if (originalActivity.address) {
			if (originalActivity.address.streetAddress) {
				address += `${originalActivity.address.streetAddress}`;
			}
			if (originalActivity.address.city) {
				address += `${originalActivity.address.city}`;
			}
			if (originalActivity.address.postcode) {
				address += `${originalActivity.address.postcode}`;
			}
		}
		return address;
	};
	return (
		<div className='original-activity-item'>
			<div className='original-activity-item-top'>
				<div className='original-activity-item-top-left'>
					<p className='semi-bold'>
						{utils.removeHyphen(
							utils.capitalizeFirstLetter(originalActivity.type),
						)}
					</p>
					{(originalActivity.type === 'employed' ||
						originalActivity.type === 'self-employed') && (
						<p>
							Work Pattern:
							{originalActivity.isPartTime
								? 'Part time'
								: 'Full time'}
						</p>
					)}
					{(originalActivity.type === 'employed' ||
						originalActivity.type === 'studying') && (
						<p>
							Company / Institution:
							{utils.capitalizeFirstLetter(originalActivity.name)}
						</p>
					)}
					{originalActivity.type === 'employed' && (
						<p>
							Job Title:
							{utils.capitalizeFirstLetter(
								originalActivity.jobTitle,
							)}
						</p>
					)}
				</div>

				<div className='original-activity-item-top-right'>
					<p className='semi-bold'>
						{utils.formatDateForUser(originalActivity.startAt)}
						-&nbsp;
					</p>
					<p className='semi-bold'>
						{originalActivity.endAt
							? utils.formatDateForUser(originalActivity.endAt)
							: 'Present'}
					</p>
				</div>
			</div>
			{getAddress().length > 0 && originalActivity.address && (
				<p>{getAddress()}</p>
			)}

			{originalActivity.reasonForLeaving && (
				<p>
					Reason for leaving
					{utils.capitalizeFirstLetter(
						originalActivity.reasonForLeaving,
					)}
				</p>
			)}
			{originalActivity.reasonForLeavingText && (
				<p>
					{utils.capitalizeFirstLetter(
						originalActivity.reasonForLeavingText,
					)}
				</p>
			)}
			{originalActivity.files.length > 0 && (
				<FileList
					fileArray={originalActivity.files}
					isOriginalActivity={true}
				/>
			)}
		</div>
	);
};

export default ActivityOriginalItem;
