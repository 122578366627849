import React, { FC } from 'react';
import Risk from '../risk/risk.component';
import { IRiskItem } from '../../../types/interfaces/stage.interface';
import { RootState } from '../../../types/root-state';
import store from '../../../redux/store';

type Props = {
	title: string;
};

const RiskContainer: FC<Props> = ({ title }) => {
	const state: RootState = store.getState();
	const { stageData } = state.profile;

	const risksForStage: IRiskItem[] =
		(stageData.find((stage) => stage.title === title) || {}).risks || [];

	return (
		<div style={{ marginTop: '16px' }}>
			{risksForStage.length > 0 &&
				risksForStage.map((risk) => (
					<Risk risk={risk} key={risk.uuid} />
				))}
		</div>
	);
};

export default RiskContainer;
