export const emailValidation = (email, type) => {
    const pattern = /\S+@\S+\.\S+/;
    if (!pattern.test(email)) {
        return { message: 'Email address is not valid', valid: false }
    } else {
        return { message: '', valid: true }
    }
};

export const phoneValidation = (phone) => {
	const pattern = /^(\+447\d{9}|07\d{9})/g;
    if (!pattern.test(phone)) {
        return { message: 'Phone number is not valid', valid: false }
    } else {
        return { message: '', valid: true }
    }
}

export const niValidation = (string) => {
    const removedWhiteSpace = string.replace(/\s/, '').toUpperCase();
    const regex = /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/;
    return {
        isValid: regex.test(removedWhiteSpace),
        message: regex.test(removedWhiteSpace) ? '' : 'Ni number is not valid'
    }
}
