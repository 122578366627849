import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './responsive-filters.styles.scss';
import arrowRight from '../../../assets/icons/dashboard/arrow-right.svg';
import filterSelected from '../../../assets/icons/dashboard/filter-selected.svg';
import backButton from '../../../assets/icons/back-blue.svg';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import checkboxBlueTick from '../../../assets/icons/checkbox-blue-tick.svg';
import { RootState } from '../../../types/root-state';
import { useDispatch, useSelector } from 'react-redux';
import { dropdownData } from '../../../utils/data';
import {
	setAssignVal,
	setGlobalSortBy,
	setStatusQuery,
	setSubmissionQuery,
	setFlowQuery,
	setCompanyQuery,
	clearFilters,
} from '../../../redux/Dashboard/dashboard.actions';
import { IStaffMember } from '../../../types/interfaces/staff.interface';
import { IDropdownStandard } from '../../../types/interface';
import { ICompanyItem } from '../../../types/interfaces/company.interface';

type Props = {
	setShowFilters: Dispatch<SetStateAction<boolean>>;
};

type IFilter = {
	section: string;
	show: boolean;
	icon: string;
};

type IFilterPropData = {
	dataArr: IDropdownStandard[];
	setStateAction: Dispatch<SetStateAction<any>> | null;
	setReduxVal: any | null;
	title: string;
};

const ResponsiveFilters: FC<Props> = ({ setShowFilters }) => {
	const dispatch = useDispatch();
	const assigned = useSelector(
		(state: RootState) => state.dashboard.assignedQuery,
	);
	const sort = useSelector((state: RootState) => state.dashboard.sortBy);
	const flow = useSelector((state: RootState) => state.dashboard.flowQuery);
	const company = useSelector(
		(state: RootState) => state.dashboard.companyQuery,
	);
	const submission = useSelector(
		(state: RootState) => state.dashboard.submissionQuery,
	);
	const status = useSelector(
		(state: RootState) => state.dashboard.statusQuery,
	);
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const allCompanies = useSelector((state: RootState) => state.auth.company);

	const [sortData, setSortData] = useState<IDropdownStandard[]>([]);
	const [submissionData, setSubmissionData] = useState<IDropdownStandard[]>(
		[],
	);
	const [flowData, setFlowData] = useState<IDropdownStandard[]>([]);
	const [statusData, setStatusData] = useState<IDropdownStandard[]>([]);
	const [staffData, setStaffData] = useState<IDropdownStandard[]>([]);
	const [companyData, setCompanyData] = useState<IDropdownStandard[]>([]);

	const [propData, setPropData] = useState<IFilterPropData>({
		dataArr: [],
		setStateAction: null,
		setReduxVal: null,
		title: '',
	});

	const [filterOptions, setFilterOptions] = useState<IFilter[]>([
		{ section: 'Submission Status', show: false, icon: arrowRight },
		{ section: 'Flow', show: false, icon: arrowRight },
		{ section: 'Compliance Status', show: false, icon: arrowRight },
		{ section: 'Company', show: false, icon: arrowRight },
		{ section: 'Sort', show: false, icon: arrowRight },
		{ section: 'Assigned', show: false, icon: arrowRight },
	]);

	useEffect(() => {
		handleSelection(
			dropdownData.submissionFilter,
			submission,
			setSubmissionData,
		);
		handleSelection(dropdownData.flowFilter, flow, setFlowData);
		handleSelection(dropdownData.statusFilter, status, setStatusData);
		handleSelection(getCompanyData(), company, setCompanyData);
		handleSelection(dropdownData.sortBy, sort, setSortData);
		handleSelection(getStaffData(), assigned, setStaffData);
	}, []);

	useEffect(() => {
		const filterUpdateIcons = filterOptions.map((item) => {
			if (item.section === 'Submission Status') {
				handleIconUpdate(item, submission);
			}
			if (item.section === 'Flow') {
				handleIconUpdate(item, flow);
			}
			if (item.section === 'Compliance Status') {
				handleIconUpdate(item, status);
			}
			if (item.section === 'Company') {
				handleIconUpdate(item, company);
			}
			if (item.section === 'Sort') {
				handleIconUpdate(item, sort);
			}
			if (item.section === 'Assigned') {
				handleIconUpdate(item, assigned);
			}
			return item;
		});
		setFilterOptions(filterUpdateIcons);
	}, [submission, flow, status, company, sort, assigned]);

	const handleIconUpdate = (item: IFilter, query: string) => {
		if (
			(item.section !== 'Sort' && query !== 'all') ||
			(item.section === 'Sort' && query !== 'createdAt')
		) {
			item.icon = filterSelected;
		} else {
			item.icon = arrowRight;
		}
		return item;
	};

	const getStaffData = () => {
		const validatedStaffData = allStaff.map(
			(staffMember: IStaffMember, index: number) => {
				return {
					id: index + 1,
					value: staffMember.name + ' ' + staffMember.surname,
					dbValue: staffMember.email ? staffMember.email : '',
					class: 'dropdown-grey',
					selected: false,
				};
			},
		);
		const defaultItem = {
			id: 0,
			value: 'All',
			dbValue: 'all',
			class: 'dropdown-grey',
			selected: true,
		};
		return [defaultItem, ...validatedStaffData];
	};

	const getCompanyData = () => {
		const defaultCompanyFilters = [
			{
				id: 1,
				value: 'All',
				dbValue: 'all',
				class: 'dropdown-grey',
			},
			{
				id: 2,
				value: 'External',
				dbValue: 'external',
				class: 'dropdown-grey',
			},
			{
				id: 3,
				value: 'Multiple',
				dbValue: 'multiple',
				class: 'dropdown-grey',
			},
			{
				id: 4,
				value: 'None',
				dbValue: 'none',
				class: 'dropdown-grey',
			},
		];

		const validatedCompanyData = allCompanies.map(
			(company: ICompanyItem, index: number) => {
				return {
					id: defaultCompanyFilters.length + index + 1,
					value: company.companyName,
					dbValue: company.id.toString(),
					class: 'dropdown-grey',
				};
			},
		);
		return [...defaultCompanyFilters, ...validatedCompanyData];
	};

	const handleSelection = (
		data: IDropdownStandard[],
		val: string,
		selectState: Dispatch<SetStateAction<any>>,
	) => {
		const selectedData = data.map((item: IDropdownStandard) => {
			item.selected = item.dbValue === val;
			return item;
		});
		selectState(selectedData);
	};

	const handlePopulatePropData = (val: string) => {
		switch (val) {
			case 'Submission Status':
				setPropData({
					dataArr: submissionData,
					setStateAction: setSubmissionData,
					setReduxVal: setSubmissionQuery,
					title: 'Submission Status',
				});
				break;
			case 'Flow':
				setPropData({
					dataArr: flowData,
					setStateAction: setFlowData,
					setReduxVal: setFlowQuery,
					title: 'Sort',
				});
				break;
			case 'Compliance Status':
				setPropData({
					dataArr: statusData,
					setStateAction: setStatusData,
					setReduxVal: setStatusQuery,
					title: 'Compliance Status',
				});
				break;
			case 'Company':
				setPropData({
					dataArr: companyData,
					setStateAction: setCompanyData,
					setReduxVal: setCompanyQuery,
					title: 'Company',
				});
				break;
			case 'Sort':
				setPropData({
					dataArr: sortData,
					setStateAction: setSortData,
					setReduxVal: setGlobalSortBy,
					title: 'Sort',
				});
				break;
			case 'Assigned':
				setPropData({
					dataArr: staffData,
					setStateAction: setStaffData,
					setReduxVal: setAssignVal,
					title: 'Assigned',
				});
				break;
		}
	};

	const handleSelectFilterSection = (item: IFilter) => {
		const selectedFilters = filterOptions.map((filterItem: IFilter) => {
			filterItem.show = filterItem.section === item.section;
			return filterItem;
		});
		setFilterOptions(selectedFilters);
		handlePopulatePropData(item.section);
	};

	const handleClear = () => {
		dispatch(clearFilters());
		setShowFilters(false);
	};

	return (
		<div className='filter-page-container'>
			{filterOptions.filter((element) => element.show).length === 0 && (
				<>
					<div className='heading'>
						<div className='filter-heading-top'>
							<img
								src={backButton}
								alt='back arrow'
								onClick={() => setShowFilters(false)}
							/>
							<div className='clear-btn'>
								<button onClick={handleClear}>Reset</button>
							</div>
						</div>
						<h2>Filters</h2>
					</div>

					{filterOptions.map((item: IFilter) => {
						return (
							<div
								key={item.section}
								className='aligned-flex filter-item'
								onClick={() => handleSelectFilterSection(item)}
							>
								<p>{item.section}</p>
								<img src={item.icon} alt='arrow right' />
							</div>
						);
					})}
				</>
			)}
			{filterOptions.map((item: IFilter) => {
				return (
					<div key={item.section}>
						{item.show && (
							<Filters
								setFilterOptions={setFilterOptions}
								filterOptions={filterOptions}
								propData={propData}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default ResponsiveFilters;

type FilterProps = {
	setFilterOptions: Dispatch<SetStateAction<IFilter[]>>;
	filterOptions: IFilter[];
	propData: IFilterPropData;
};

const Filters: FC<FilterProps> = ({
	setFilterOptions,
	filterOptions,
	propData,
}) => {
	const dispatch = useDispatch();
	const handleBack = () => {
		const newData = filterOptions.map((item: any) => {
			item.show = false;
			return item;
		});
		setFilterOptions(newData);
	};

	const handleSelectFilter = (item: IDropdownStandard) => {
		const newSelectedData = propData.dataArr.map((dataItem) => {
			dataItem.selected = item.dbValue === dataItem.dbValue;
			return dataItem;
		});
		if (propData.setStateAction) {
			propData.setStateAction(newSelectedData);
		}
		dispatch(propData.setReduxVal(item.dbValue));
	};
	return (
		<div>
			<div className='heading'>
				<img src={backButton} alt='back arrow' onClick={handleBack} />
				<h2>{propData.title}</h2>
			</div>
			{propData.dataArr.map((item) => {
				return (
					<div
						key={item.dbValue}
						className='aligned-flex filter-item'
						onClick={() => handleSelectFilter(item)}
					>
						<p>{item.value}</p>
						<img
							src={
								item.selected ? checkboxBlueTick : checkboxWhite
							}
							alt='checkbox'
						/>
					</div>
				);
			})}
		</div>
	);
};
