import React, { FC, useEffect, useState } from 'react';
import './dashboard-tags.styles.scss';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import { IApplicant } from '../../../types/interface';
import { ITagUser } from '../../../types/interfaces/tags.interface';
import { RootState } from '../../../types/root-state';
import { useSelector } from 'react-redux';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	applicant: IApplicant;
};

const DashboardTags: FC<Props> = ({ applicant }) => {
	const [plusTag, setPlusTags] = useState<string>('');
	const [applicantTags, setApplicantTags] = useState<ITagUser[]>([]);
	const role = useSelector((state: RootState) => state.auth.role);

	useEffect(() => {
		if (role !== 'ambassador') {
			let newArray: ITagUser[] = [];
			const urgentTag = applicant.tags.filter((tag: ITagUser) => {
				if (tag.tag.displayName.toLowerCase() === 'urgent') {
					return tag;
				}
			});

			const otherTags = applicant.tags.filter((tag: ITagUser) => {
				if (tag.tag.displayName.toLowerCase() !== 'urgent') {
					return tag;
				}
			});

			if (urgentTag.length > 0) {
				newArray = [...urgentTag];
			}

			newArray = [...newArray, ...otherTags];

			setApplicantTags(newArray);
		}
	}, [JSON.stringify(applicant)]);

	useEffect(() => {
		if (applicantTags.length > 1) {
			const moreTags = applicantTags
				.map((tag: ITagUser, index: number) => {
					if (index > 0) {
						return tag.tag.displayName;
					}
				})
				.filter((item: string | undefined) => {
					return item !== undefined;
				})
				.join('<br />');
			setPlusTags(moreTags);
		}
	}, [applicantTags.length]);

	return (
		<div className='dashboard-tag-section'>
			{applicantTags.length > 0 && (
				<div className='dashboard-tag-section-container'>
					<div
						className={
							applicantTags[0].tag.displayName.toLowerCase() ===
							'urgent'
								? 'dashboard-tag-item urgent'
								: 'dashboard-tag-item'
						}
					>
						<p>{applicantTags[0].tag.displayName}</p>
					</div>
					{applicantTags.length > 1 && (
						<TooltipLg
							title={
								<div
									dangerouslySetInnerHTML={{
										__html: plusTag,
									}}
								></div>
							}
						>
							<div className='extra-tags'>
								<p>+{applicantTags.length - 1}</p>
							</div>
						</TooltipLg>
					)}
				</div>
			)}
		</div>
	);
};

export default DashboardTags;
