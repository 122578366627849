import React, { useEffect, FC, useState, useContext } from 'react';
import ProfileContainer from '../../components/profile/profile-container/profile-container.component';
import './user-profile.styles.scss';
import * as apiApplicant from '../../api/api.applicant';
import * as apiStage from '../../api/api.stage';
import * as utils from '../../utils/utilsFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../redux/General/general.actions';
import {
	selectApplicant,
	setOverallSubmission,
	getChecklist,
	getLicenses,
	setNameHistory,
	setAddressHistory,
	setCriminalBankruptcy,
	getAmbassadorCheckStatus,
	setStageData,
	clearProfileData,
} from '../../redux/Profile/profile.actions';
import Layout from '../../components/general/layout-component/layout.component';
import { RootState } from '../../types/root-state';
import { IStageDataItem } from '../../types/interfaces/stage.interface';
import { useLocation, useNavigate } from 'react-router-dom';
import OrganisationMain from '../../components/organisation/organisation-main/organisation-main.component';
import OrganisationContext from '../../context/OrganisationContext';

const UserProfile: FC = () => {
	const activityActions = useContext(OrganisationContext);

	const { state }: any = useLocation();
	const navigate = useNavigate();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);

	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const role = useSelector((state: RootState) => state.auth.role);
	const dispatch = useDispatch();

	const [uuid, setUuid] = useState('');

	useEffect(() => {
		if (state && state.uuid) {
			setUuid(state.uuid);
		} else if (!state && isLoggedIn && window.location.pathname) {
			const uuid = window.location.pathname.replace(
				/\/check\/user\//,
				'',
			);
			setUuid(uuid);
		} else {
			navigate('/login', { replace: true });
			return;
		}
	}, []);

	useEffect(() => {
		if (uuid !== applicant.uuid) dispatch(clearProfileData());
	}, [uuid]);

	useEffect(() => {
		if (role !== 'ambassador' && uuid) {
			apiApplicant
				.getApplicant(uuid)
				.then((res) => {
					dispatch(selectApplicant(res.data));
				})
				.catch(() => {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to get applicant',
							isVisible: true,
						}),
					);
				});
		}
	}, [dispatch, isLoggedIn, role, uuid]);

	useEffect(() => {
		if (
			applicant &&
			applicant.workerUuid &&
			applicant.uuid &&
			applicant.uuid === uuid
		) {
			const getApplicantData = async () => {
				const overallSubmissionRes = await apiApplicant
					.getOverallSubmission(applicant.uuid)
					.catch((err) => {
						throw err;
					});
				const stageData = await apiStage
					.getStageData(applicant.workerUuid)
					.catch((err) => {
						throw err;
					});
				const checklistRes = await apiApplicant
					.getApplicantDataByWorkerUuid(
						applicant.workerUuid,
						'checklist',
					)
					.catch((err) => {
						throw err;
					});
				const [
					licencesRes,
					nameHistoryRes,
					addressHistoryRes,
					criminalRecordRes,
					ambassadorCheckStatus,
				] = await Promise.all([
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'licences',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'name-history',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'address-history',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'worker-declaration',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'face-to-face',
						)
						.catch((err) => {
							throw err;
						}),
				]);

				const actionList = [
					setOverallSubmission(overallSubmissionRes),
					getChecklist(checklistRes),
					getLicenses(licencesRes),
					setNameHistory(
						utils.sortByDate(
							nameHistoryRes.status !== 404 ? nameHistoryRes : [],
						),
					),
					setAddressHistory(
						utils.sortByDate(
							addressHistoryRes.status !== 404
								? addressHistoryRes
								: [],
						),
					),
					setCriminalBankruptcy(criminalRecordRes),
					getAmbassadorCheckStatus(ambassadorCheckStatus),
					setStageData(
						stageData.data.map((stage: IStageDataItem) => {
							if (stage.stageComplete === null) {
								stage.stageComplete = false;
							}
							return stage;
						}),
					),
				];

				actionList.forEach((action) => {
					dispatch(action);
				});
			};

			const getAmbassadorApplicantDetails = async () => {
				const [addressHistoryRes, ambassadorCheckStatus] =
					await Promise.all([
						apiApplicant
							.getApplicantDataByWorkerUuid(
								applicant.workerUuid,
								'address-history',
							)
							.catch((err) => {
								throw err;
							}),
						apiApplicant
							.getApplicantDataByWorkerUuid(
								applicant.workerUuid,
								'face-to-face',
							)
							.catch((err) => {
								throw err;
							}),
					]);

				const actionList = [
					setAddressHistory(utils.sortByDate(addressHistoryRes)),
					getAmbassadorCheckStatus(ambassadorCheckStatus),
				];

				actionList.forEach((action) => {
					dispatch(action);
				});
			};

			role === 'ambassador'
				? getAmbassadorApplicantDetails()
				: getApplicantData();
		}
	}, [applicant.uuid, applicant.workerUuid, dispatch, role]);

	return (
		<Layout long={true}>
			<div className='user-profile-page'>
				{Object.keys(applicant).length > 0 && stageData.length > 0 && (
					<>
						<ProfileContainer />
						{activityActions?.showOrganisationTable && (
							<OrganisationMain />
						)}
					</>
				)}
			</div>
		</Layout>
	);
};

export default UserProfile;
