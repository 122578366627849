import React, { useState, FC, Dispatch, SetStateAction } from 'react';
import './task-notes.styles.scss';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import { IApplicant } from '../../../types/interface';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';
import * as apiApplicant from '../../../api/api.applicant';

type Props = {
	setShowTaskNotes: Dispatch<SetStateAction<boolean>>;
	taskUuid: string;
	applicant: IApplicant;
	setRefetchTasks: Dispatch<SetStateAction<boolean>>;
	refetchTasks: boolean;
};

const TaskNotes: FC<Props> = ({
	setShowTaskNotes,
	applicant,
	setRefetchTasks,
	refetchTasks,
}) => {
	const [notes, setNotes] = useState<string>(
		applicant.notes ? applicant.notes : '',
	);
	const dispatch = useDispatch();

	const handleSaveNotes = () => {
		return apiApplicant
			.updateApplicant(applicant.uuid, { notes })
			.then(() => {
				dispatch(updateApplicantMultiple({ notes }));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated notes',
						isVisible: true,
					}),
				);
				setRefetchTasks(!refetchTasks);
				setShowTaskNotes(false);
			})
			.catch((err: Error) => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating notes',
						isVisible: true,
					}),
				);
				setShowTaskNotes(false);
				throw err;
			});
	};

	// useEffect(() => {
	// 	taskApi
	// 		.getApplicant(taskUuid)
	// 		.then((res) => {
	// 			setNotes(res.notes);
	// 		})
	// 		.catch(() => {
	// 			dispatch(
	// 				setAlert({
	// 					type: 'error',
	// 					message: 'Unable to get task notes',
	// 					isVisible: true,
	// 				}),
	// 			);
	// 		});
	// }, [dispatch, taskUuid]);

	return (
		<div className='task-notes-modal'>
			<div className='task-text-area'>
				<textarea
					value={notes}
					onChange={(e) => setNotes(e.target.value)}
				/>
			</div>

			<div className='modal-btn-section'>
				<OrkaButton
					buttonContent='Done'
					disabled={false}
					emitClicked={handleSaveNotes}
				/>
			</div>
		</div>
	);
};

export default TaskNotes;
