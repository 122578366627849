import { SET_FROM_ACTIVITY, CLEAR_NAVIGATION_STATE } from './navigation.types';

const INITIAL_STATE = {
    fromActivity: false,
	activityItem: null,
}

const navigationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case SET_FROM_ACTIVITY:
			return {
				...state,
				fromActivity: true,
				activityItem: action.activityItem
			};
		case CLEAR_NAVIGATION_STATE:
			return INITIAL_STATE;
        default:
            return state;
    }
}


export default navigationReducer;