import React, {
	useState,
	useEffect,
	FC,
	SetStateAction,
	Dispatch,
} from 'react';
import { useSelector } from 'react-redux';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import * as utils from '../../../utils/utilsFunctions';
import { RootState } from '../../../types/root-state';
import { WorkHistoryItem, IDropdownStandard } from '../../../types/interface';

type Props = {
	activityItem?: WorkHistoryItem;
	updateActivity: (payload: any) => Promise<void>;
	setRefStatusDate: Dispatch<
		SetStateAction<string | undefined | Date | null>
	>;
};

const ReferenceStatus: FC<Props> = ({ activityItem, updateActivity }) => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [dropdownData, setDropdownData] = useState<IDropdownStandard[]>([]);
	const [defaultData, setDefaultData] = useState<IDropdownStandard>();

	useEffect(() => {
		setDropdownData(utils.getDropdownData('referenceStatus', applicant));
	}, []);

	useEffect(() => {
		if (activityItem) {
			const data = dropdownData.filter((item) => {
				if (
					activityItem &&
					activityItem.reference &&
					activityItem.reference.status
				) {
					return item.dbValue === activityItem.reference.status;
				}
			});
			data.length > 0
				? setDefaultData(data[0])
				: setDefaultData(dropdownData[0]);
		}
	}, [JSON.stringify(dropdownData), JSON.stringify(activityItem)]);

	const getDropdownVal = (val: IDropdownStandard) => {
		const payload: Partial<WorkHistoryItem> = {
			reference: {
				status: val.dbValue,
				statusUpdatedAt: utils.formatDateForDb(new Date()),
			},
		};

		return updateActivity(payload)
			.then(() => {
				const data = dropdownData.filter((item) => {
					return item.dbValue === val.dbValue;
				});
				setDefaultData(data[0]);
			})
			.catch((err) => {
				throw err;
			});
	};

	return (
		<div>
			{defaultData && (
				<Dropdown
					type='referenceStatus'
					dropdownData={dropdownData}
					defaultData={defaultData}
					getDropdownVal={getDropdownVal}
					width='200'
					height={24}
					mobileResponsive={false}
					position='bottom-center'
				/>
			)}
		</div>
	);
};

export default ReferenceStatus;
