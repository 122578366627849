import React, { useEffect, FC, useState } from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { setIsMobile } from './redux/General/general.actions';
import Login from './pages/Login/login.component';
import CheckContainer from './components/check-container/check-container';
import Alert from './components/general/alert/alert.component';
import NotFound from './components/general/not-found/not-found.component';
import SignUp from './pages/Signup/sign-up.component';
import ResetPassword from './pages/Reset/reset-password.component';
import ForgotPassword from './pages/Forgot-password/forgot-password.component';
import { RootState } from './types/root-state';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/general/private-route/private-route.component';
import FullPageLoader from './components/general/full-page-loader/full-page-loader.component';
import ExternalCheckContainer from './components/external-dashboard/external-check-container/external-check-container';
import ErrorBoundary from './components/general/error-boundary/error-boundary.component';

const App: FC = () => {
	const dispatch = useDispatch();
	const alertInfo = useSelector(
		(state: RootState) => state.general.alertInfo,
	);
	const isLoading = useSelector(
		(state: RootState) => state.general.isLoading,
	);
	const applicantRole = useSelector((state: RootState) => state.auth.role);
	const [externalClient, setExternalClient] = useState(false);

	useEffect(() => {
		if (window.innerWidth > 700) {
			dispatch(setIsMobile(false));
		} else {
			dispatch(setIsMobile(true));
		}
	}, [dispatch]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 700) {
				dispatch(setIsMobile(false));
			} else {
				dispatch(setIsMobile(true));
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [dispatch]);

	useEffect(() => {
		const isExternal =
			applicantRole === 'manager' ||
			applicantRole === 'admin' ||
			applicantRole === 'viewer';
		setExternalClient(isExternal);
	}, [applicantRole]);

	return (
		<div className='App'>
			<ErrorBoundary applicantWorkerUuid='None'>
				{alertInfo.isVisible && <Alert alertData={alertInfo} />}
				<FullPageLoader loading={isLoading} />

				<BrowserRouter>
					<Routes>
						<Route path='/login' element={<Login />} />
						<Route path='/sign-up' element={<SignUp />} />
						<Route
							path='/reset-password/:token'
							element={<ResetPassword />}
						/>
						<Route
							path='/forgot-password'
							element={<ForgotPassword />}
						/>
						<Route
							path='/'
							element={<Navigate replace to='/check' />}
						/>

						<Route
							path='/check/*'
							element={
								<PrivateRoute>
									{externalClient ? (
										<ExternalCheckContainer />
									) : (
										<CheckContainer />
									)}
								</PrivateRoute>
							}
						/>

						<Route
							path='*'
							element={
								<NotFound type='noData' pageNotFound={true} />
							}
						/>
					</Routes>
				</BrowserRouter>
			</ErrorBoundary>
		</div>
	);
};

export default App;
