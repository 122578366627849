import React, { useEffect, useState, FC } from 'react';
import './rtw-expiry.styles.scss';
import { useSelector } from 'react-redux';

import Modal from '../../modals/modal/modal.component';
import editIcon from '../../../assets/icons/edit.svg';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import ProfileRtwExpiryModal from '../profile-rtw-expiry-modal/profile-rtw-expiry-modal.component';

import * as utils from '../../../utils/utilsFunctions';
import { RootState } from '../../../types/root-state';

const RtwExpiry: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [rtwExpiry, setRtwExpiry] = useState<string | Date | null>(null);

	useEffect(() => {
		setRtwExpiry(applicant.rtwExpiryDate);
	}, [applicant.rtwExpiryDate]);

	const handleEditRtw = () => {
		setShowModal(true);
	};

	return (
		<div className='rtw-expiry-container'>
			<ProfileDisplayItem
				label='Expiry Date'
				value={rtwExpiry ? utils.formatDateForUser(rtwExpiry) : 'Never'}
			/>
			{role !== 'ambassador' && (
				<img onClick={handleEditRtw} alt='edit' src={editIcon} />
			)}

			{showModal && (
				<Modal
					title='Edit RTW Expiry Date'
					setShowModal={setShowModal}
					showModal={showModal}
				>
					<ProfileRtwExpiryModal
						setShowModal={setShowModal}
						setRtwExpiry={setRtwExpiry}
					/>
				</Modal>
			)}
		</div>
	);
};

export default RtwExpiry;
