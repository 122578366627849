import { axiosInstance } from "./api.axiosInstance";

export interface RtwVerifiedRequest {
	applicantUuid: string;
	data?: {
		documentType: string;
		documentExpiry: string | null;
	};
};

export const acceptCandidateRtw = (options: RtwVerifiedRequest): any => {
	const url = `/admin/rtw-verified/${options.applicantUuid}`
	return axiosInstance()
		.post(url, options?.data)
		.catch((err) => {
			throw err;
		});
};

export const getCandidateRtwStatus = (data: RtwVerifiedRequest): any => {
	const url = `/admin/rtw-verified/${data.applicantUuid}`
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};