import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './add-candidate-modal.styles.scss';
import { INewCandidate } from '../../../types/interface';
import EditInput from '../../general/edit-input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as apiApplicant from '../../../api/api.applicant';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';

import { refetchApplicants } from '../../../redux/Dashboard/dashboard.actions';
import * as validations from '../../../utils/validations';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
};

const AddCandidateModal: FC<Props> = ({ setShowModal }) => {
	const dispatch = useDispatch();
	const [formState, setFormState] = useState<INewCandidate>({
		name: '',
		surname: '',
		email: '',
		contactNumber: '',
	});
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [emailValidationError, setEmailValidationError] = useState('');
	const [phoneValidationError, setPhoneValidationError] = useState('');

	useEffect(() => {
		if (
			!formState.name ||
			!formState.surname ||
			!formState.email ||
			!formState.contactNumber
		) {
			setButtonDisabled(true);
		} else {
			setButtonDisabled(false);
		}
	}, [
		formState.name,
		formState.surname,
		formState.email,
		formState.contactNumber,
	]);

	const emitValue = (val: any, inputName: keyof INewCandidate) => {
		setFormState({
			...formState,
			[inputName]: val,
		});
	};

	const handleAddCandidate = () => {
		if (!handleValidations()) {
			return;
		}

		return apiApplicant
			.addApplicant(formState)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully added candidate',
						isVisible: true,
					}),
				);
				dispatch(refetchApplicants());
				setShowModal(false);
			})
			.catch((err) => {
				if (err.status === 409) {
					setEmailValidationError(err.message);
					setTimeout(() => {
						setEmailValidationError('');
					}, 2000);
					return;
				}
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				setShowModal(false);
			});
	};

	const handleValidations = () => {
		let valid = true;
		const emailValidation = validations.emailValidation(formState.email);
		const phoneValidation = validations.phoneValidation(
			formState.contactNumber,
		);

		if (!emailValidation.valid) {
			setEmailValidationError(emailValidation.message);
			setTimeout(() => {
				setEmailValidationError('');
			}, 2000);
			valid = false;
		}

		if (!phoneValidation.valid) {
			setPhoneValidationError(phoneValidation.message);
			setTimeout(() => {
				setPhoneValidationError('');
			}, 2000);
			valid = false;
		}
		return valid;
	};

	return (
		<div className='add-candidate-modal-container'>
			<div>
				<p className='orka-profile-label'>
					Please enter the full legal name of the candidate shown on
					their ID
				</p>

				<div className='add-candidate-input-row'>
					<div className='add-candidate-col-left'>
						<EditInput
							inputName='name'
							placeholder='First Name'
							value={formState.name}
							title='First Name(s)'
							emitValue={emitValue}
						/>
					</div>

					<div className='add-candidate-col-right'>
						<EditInput
							inputName='surname'
							placeholder='Second Name'
							value={formState.surname}
							title='Second Name'
							emitValue={emitValue}
						/>
					</div>
				</div>

				<div className='add-candidate-input-row'>
					<div className='add-candidate-col-left'>
						<EditInput
							inputName='contactNumber'
							placeholder='Phone Number'
							value={formState.contactNumber}
							title='Phone Number'
							emitValue={emitValue}
							type='tel'
							validationError={phoneValidationError}
						/>
					</div>

					<div className='add-candidate-col-right'>
						<EditInput
							inputName='email'
							placeholder='Email'
							value={formState.email}
							title='Email'
							emitValue={emitValue}
							type='email'
							validationError={emailValidationError}
						/>
					</div>
				</div>

				<p className='orka-profile-label margin-top-24 margin-bottom-40'>
					Once invited you will find this candidate in the Awaiting
					Submission Bucket.
				</p>
			</div>

			<div className='add-candidate-btn-section'>
				<OrkaButton
					buttonContent='Invite'
					disabled={buttonDisabled}
					emitClicked={handleAddCandidate}
				/>
			</div>
		</div>
	);
};

export default AddCandidateModal;
