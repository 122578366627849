import './file-owner.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import plusCircle from '../../../assets/icons/plus-circle.svg';
import FileOwnerSearch from '../file-owner-search/file-owner-search-component';
import { useDispatch } from 'react-redux';
import { setAlert, setIsLoading } from '../../../redux/General/general.actions';
import { selectApplicant } from '../../../redux/Profile/profile.actions';
import * as apiApplicant from '../../../api/api.applicant';
import { ICompanyItem } from '../../../types/interfaces/company.interface';
import cross from '../../../assets/icons/cross.svg';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';
import * as utils from '../../../utils/utilsFunctions';

const FileOwner: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const companies = useSelector((state: RootState) => state.auth.company);

	const [companyNames, setCompanyNames] = useState<ICompanyItem[]>([]);
	const [showSearchOwner, setShowSearchOwner] = useState<boolean>(false);
	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);
	const [removeCompany, setRemoveCompany] = useState<ICompanyItem>();
	const dispatch = useDispatch();
	useEffect(() => {
		const matchingCompanies = applicant.clientIds.reduce<ICompanyItem[]>(
			(matches: ICompanyItem[], clientId) => {
				const company = companies.find(
					(c) => c.id.toString() === clientId,
				);
				if (company) {
					return [...matches, company];
				}
				return matches;
			},
			[],
		);
		setCompanyNames(matchingCompanies);
	}, [applicant.clientIds, companies]);

	const onSelectedCompany = async (company: ICompanyItem) => {
		toggleFileOwner('add', company);
	};

	const refetchApplicant = () => {
		return apiApplicant.getApplicant(applicant.uuid).then((res) => {
			dispatch(selectApplicant(res.data));
		});
	};

	const handleDeleteFileOwner = async (company: ICompanyItem | undefined) => {
		if (!company) return;
		toggleFileOwner('remove', company);
	};

	const toggleFileOwner = async (
		toggle: 'add' | 'remove',
		company: ICompanyItem,
	) => {
		try {
			const res = await apiApplicant.toggleFileOwnerEndpoint(
				toggle,
				applicant.uuid,
				{
					companyUuid: company.uuid,
				},
			);
			if (res.status === 404) {
				throw res;
			}
			dispatch(setIsLoading(true));
			setTimeout(async () => {
				await refetchApplicant();
				setShowSearchOwner(false);
				return dispatch(setIsLoading(false));
			}, 1000);
		} catch (err: any) {
			dispatch(
				setAlert({
					type: 'error',
					message:
						err.data && err.data.message
							? err.data.message
							: err.message,
					isVisible: true,
				}),
			);
			dispatch(setIsLoading(false));
		}
	};

	const handleGetValFromAlert = (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			return handleDeleteFileOwner(removeCompany);
		}
	};

	const showAlertConfirmation = (company: ICompanyItem) => {
		setRemoveCompany(company);
		setShowAlertConfirm(true);
	};

	return (
		<div className='file-owner-container'>
			<div className='file-owner-header margin-bottom-8'>
				<p className='orka-profile-label'>File Owner</p>
				<img
					src={plusCircle}
					onClick={() => setShowSearchOwner(!showSearchOwner)}
					alt='create'
				/>
			</div>
			{showSearchOwner && (
				<div className='tag-search-outer'>
					<FileOwnerSearch
						onSelectedCompany={onSelectedCompany}
						allCompanies={companies}
					/>
				</div>
			)}
			<div>
				{companyNames.map((company, index) => (
					<div key={index} className='file-owner-items'>
						<p>{company.companyName}</p>
						<img
							src={cross}
							alt='delete'
							onClick={() => showAlertConfirmation(company)}
						/>
					</div>
				))}
			</div>
			{showAlertConfirm && (
				<AlertConfirm
					title={`Are you sure you want to remove ${removeCompany?.companyName} as the file owner of ${utils.capitalizeFirstLetterOfEach(
						utils.getFullName(
							applicant.title,
							applicant.name,
							applicant.surname,
						),
					)}?`}
					message='This will revoke their access.'
					getVal={handleGetValFromAlert}
					confirm='Yes, Remove'
					reject='Cancel'
				/>
			)}
		</div>
	);
};

export default FileOwner;
