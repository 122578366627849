import React, { useState, FC, useEffect } from 'react';
import './profile-last-chased.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import * as utils from '../../../utils/utilsFunctions';
import * as apiApplicant from '../../../api/api.applicant';
import { RootState } from '../../../types/root-state';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';

const ProfileLastChased: FC = () => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const [lastChased, setLastChased] = useState<string | Date | null>(
		applicant.lastChased ? applicant.lastChased : '',
	);

	useEffect(() => {
		setLastChased(applicant.lastChased);
	}, [applicant.lastChased]);

	const handleLastChased = () => {
		const newDate: string | null = utils.formatDateForDb(
			utils.getDateNow(),
		);
		const payload = {
			lastChased: newDate,
		};

		apiApplicant
			.updateApplicant(applicant.workerUuid, payload)
			.then(() => {
				setLastChased(newDate);
				dispatch(updateApplicantMultiple({ lastChased: newDate }));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated last chased date',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'error updating last chased',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div className='profile-last-chased'>
			<p className='orka-profile-label margin-bottom-4'>Last Chased</p>
			<div onClick={handleLastChased} className='last-chased-date'>
				<p>{lastChased ? utils.formatDateForUser(lastChased) : ''}</p>
			</div>
		</div>
	);
};

export default ProfileLastChased;
