import React, { useEffect, useState, FC } from 'react';
import './stage.dropdown.styles.scss';
import arrowUp from '../../../assets/icons/chevron-up.svg';
import arrowDown from '../../../assets/icons/chevron-down.svg';
import error from '../../../assets/icons/risk-error.svg';
import flagGrey from '../../../assets/icons/flag-grey.svg';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';

type Props = {
	title: string;
	setShowSection: (val: any) => void;
	showSection: boolean;
	stageData?: IStageDataItem | null;
};

const StageDropdown: FC<Props> = ({
	title,
	setShowSection,
	showSection,
	stageData,
}) => {
	const [showFlag, setShowFlag] = useState<boolean>(false);

	useEffect(() => {
		switch (title) {
			case 'Activity History':
			case 'SIA Licences':
			case 'Companies House Check':
			case 'Public Record / Global Watchlist Check':
			case 'Disclosures':
				setShowFlag(true);
				break;
			default:
				setShowFlag(false);
		}
	}, [title]);

	const handleShowSection = () => {
		setShowSection(!showSection);
	};

	return (
		<div className='stage-dropdown-container' onClick={handleShowSection}>
			<p>{title}</p>
			<div className='dropdown-icons'>
				{showFlag && (
					<img
						src={
							stageData && stageData.risks.length > 0
								? error
								: flagGrey
						}
						alt='flag'
					/>
				)}

				<img src={showSection ? arrowUp : arrowDown} alt='arrow' />
			</div>
		</div>
	);
};

export default StageDropdown;
