import { axiosInstance } from './api.axiosInstance';

export const getAllCompanies = (): Promise<any> => {
	return axiosInstance()
		.get('/admin/companies')
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
