import {
	IUpdateStageItem,
	IRiskPayload,
} from '../types/interfaces/stage.interface';
import { axiosInstance } from './api.axiosInstance';

export const getStageData = (workerUuid: string): Promise<any> => {
	return axiosInstance()
		.get(`admin/applicants/${workerUuid}/stage-data`)
		.then(({ data }) => {
			// Grabbing rtw note and id note
			const rtwNote = data.data[0].notes;
			const idNote = data.data[7].notes;
			const combinedNotes =
				rtwNote && idNote
					? `${rtwNote}, ${idNote}`
					: rtwNote
						? rtwNote
						: idNote;
			data.data[7].notes = combinedNotes;
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const updateStage = (
	workerUuid: string,
	payload: Partial<IUpdateStageItem>,
): Promise<any> => {
	return axiosInstance()
		.patch(`admin/applicants/${workerUuid}/stage-data`, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const postRisk = (
	workerUuid: string,
	payload: IRiskPayload,
): Promise<any> => {
	return axiosInstance()
		.post(`admin/applicants/${workerUuid}/stage/risk`, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const updateRisk = (
	workerUuid: string,
	payload: Partial<IRiskPayload>,
): Promise<any> => {
	return axiosInstance()
		.patch(`admin/applicants/${workerUuid}/stage/risk`, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
