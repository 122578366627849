import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../../redux/General/general.actions';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';
import {
	getAmbassadorCheckStatus,
	getChecklist,
	getFiles,
	getLicenses,
	selectApplicant,
	setAddressHistory,
	setCriminalBankruptcy,
	setNameHistory,
	setOverallSubmission,
	setStageData,
	setWorksHistory,
	setYotiDetails,
} from '../../../redux/Profile/profile.actions';
import { IYotiData } from '../../../types/interfaces/rtw.interface';
import { IFileItem } from '../../../types/interfaces/document.interface';
import { IGap, WorkHistoryItem } from '../../../types/interface';
import { RootState } from '../../../types/root-state';

import moment from 'moment';

import * as apiApplicant from '../../../api/api.applicant';
import * as apiStage from '../../../api/api.stage';
import * as apiId from '../../../api/api.identity';
import * as docsApi from '../../../api/api.docs';
import * as utils from '../../../utils/utilsFunctions';

type Props = {
	uuid: string;
	workerUuid: string;
	onRendered?: () => void;
};

const ExternalDashboardGetReportData: FC<Props> = ({
	uuid,
	workerUuid,
	onRendered,
}) => {
	const dispatch = useDispatch();

	const files = useSelector((state: RootState) => state.profile.files);

	const findOverlap = (
		currentItem: WorkHistoryItem | IGap,
		nextItem: WorkHistoryItem | IGap,
	) => {
		const currentItemEnd = currentItem.endAt
			? moment(currentItem.endAt)
			: moment();
		const nextItemStart = moment(nextItem.startAt);

		currentItem.overlap = currentItemEnd > nextItemStart;
	};

	const getWorkHistory = async () => {
		try {
			const res = await apiApplicant.getApplicantDataByWorkerUuid(
				workerUuid,
				'work-history',
			);
			let workHistory = utils.sortByDate(res);
			workHistory = workHistory.map(
				(item: WorkHistoryItem | IGap, index: number) => {
					if (index >= 1) {
						findOverlap(item, workHistory[index - 1]);
					}
					return item;
				},
			);
			dispatch(setWorksHistory(workHistory));
		} catch {
			dispatch(setWorksHistory([]));
		}
	};

	const fetchData = async () => {
		try {
			const applicantRes = await apiApplicant.getApplicant(uuid);
			dispatch(selectApplicant(applicantRes.data));

			const [
				overallSubmissionRes,
				checklistRes,
				licencesRes,
				nameHistoryRes,
				addressHistoryRes,
				criminalRecordRes,
				ambassadorCheckStatus,
				stageData,
			] = await Promise.all([
				apiApplicant.getOverallSubmission(uuid),
				apiApplicant.getApplicantDataByWorkerUuid(
					workerUuid,
					'checklist',
				),
				apiApplicant.getApplicantDataByWorkerUuid(
					workerUuid,
					'licences',
				),
				apiApplicant.getApplicantDataByWorkerUuid(
					workerUuid,
					'name-history',
				),
				apiApplicant.getApplicantDataByWorkerUuid(
					workerUuid,
					'address-history',
				),
				apiApplicant.getApplicantDataByWorkerUuid(
					workerUuid,
					'worker-declaration',
				),
				apiApplicant.getApplicantDataByWorkerUuid(
					workerUuid,
					'face-to-face',
				),
				apiStage.getStageData(workerUuid),
			]);

			const actionList = [
				setOverallSubmission(overallSubmissionRes),
				getChecklist(checklistRes),
				getLicenses(licencesRes),
				setNameHistory(
					utils.sortByDate(
						nameHistoryRes.status !== 404 ? nameHistoryRes : [],
					),
				),
				setAddressHistory(
					utils.sortByDate(
						addressHistoryRes.status !== 404
							? addressHistoryRes
							: [],
					),
				),
				setCriminalBankruptcy(criminalRecordRes),
				getAmbassadorCheckStatus(ambassadorCheckStatus),
				setStageData(
					stageData.data.map((stage: IStageDataItem) => {
						if (stage.stageComplete === null) {
							stage.stageComplete = false;
						}
						return stage;
					}),
				),
			];

			actionList.forEach(dispatch);
		} catch (error) {
			return false;
		}
	};

	const getYotiDetails = async () => {
		try {
			const { data } = await apiId.yotiDetails(workerUuid);
			const { candidate } = data.data || '';
			if (candidate) {
				const yotiData: IYotiData = {
					override: candidate.override,
					flow: candidate.yotiSession.flow,
					sessionStatus: candidate.yotiSession.sessionStatus,
					sessionId: candidate.yotiSession.sessionId,
					sessionUuId: candidate.yotiSession.uuid,
				};
				dispatch(setYotiDetails(yotiData));
			}
		} catch (error) {
			return false;
		}
	};

	const getDocuments = async () => {
		try {
			const { data } = await docsApi.getDocuments(workerUuid);
			const files = data.data.filter(
				(file: IFileItem) => file.deletedAt === null,
			);
			dispatch(getFiles(files));
		} catch (error) {
			return false;
		}
	};

	useEffect(() => {
		getWorkHistory();
		fetchData();
		getYotiDetails();
		getDocuments();
	}, [dispatch, uuid, workerUuid]);

	useEffect(() => {
		const processFiles = () => {
			if (files && files.length > 0) {
				files.map((file: any) => {
					if (typeof file.tags === 'string') {
						file.tags = file.tags
							.replace(/(?!,)\W/g, '')
							.split(',');
					}
					file.status = file.tags[0];
					file.docType = file.tags[1];
					return file;
				});
			}
		};
		processFiles();
	}, [JSON.stringify(files)]);

	useEffect(() => {
		dispatch(setIsLoading(true));
		if (onRendered) {
			const timeoutId = setTimeout(() => {
				onRendered();
			}, 3000);
			return () => clearTimeout(timeoutId);
		}
	}, [onRendered]);

	return null;
};

export default ExternalDashboardGetReportData;
