import React, { useEffect, useState } from 'react';
import './toggle-switch.styles.scss';
import { RootState } from '../../../types/root-state';
import { useDispatch, useSelector } from 'react-redux';
import { setAssignVal, setPage } from '../../../redux/Dashboard/dashboard.actions';

const ToggleSwitch = () => {
	const dispatch = useDispatch();
	const [selected, setSelected] = useState('All');

	const globalAssigned = useSelector(
		(state: RootState) => state.dashboard.assignedQuery,
	);
	const userEmail = useSelector(
		(state: RootState) => state.auth.user.email,
	);

	const handleToggle = (toggleType: 'Mine' | 'All') => {
		setSelected(toggleType);
		const assignedTo = toggleType === 'Mine' ? userEmail : 'all';
		dispatch(setPage(1));
		dispatch(setAssignVal(assignedTo));
	};

	useEffect(() => {
		handleToggle(globalAssigned === userEmail ? 'Mine' : 'All');
	}, [globalAssigned]);

	return (
	<div className='toggle-container'>
		<div
			className={`toggle-option ${selected === 'All' ? 'active' : ''}`}
			onClick={() => handleToggle('All')}
		>
			All
		</div>
		<div
			className={`toggle-option ${selected === 'Mine' ? 'active' : ''}`}
			onClick={() => handleToggle('Mine')}
		>
			Mine
		</div>
		<div
			className={`toggle-indicator ${selected === 'Mine' ? 'right' : 'left'}`}
		></div>
	</div>
	);
};

export default ToggleSwitch;
