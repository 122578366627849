import React, { FC } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import SectionHeader from '../section-header/section-header.component';
import KeyVal from '../key-val/key-val.component';
import store from '../../../redux/store';
import { RootState } from '../../../types/root-state';

const styles = StyleSheet.create({
	section: {
		display: 'flex',
	},
	main: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	column: {
		marginRight: '5px',
	}
});

const GeneralInformation: FC = () => {
	const state: RootState = store.getState();
	const { applicant } = state.profile;
	return (
		<View style={styles.section}>
			<SectionHeader title='General Information' />
			<div style={styles.main}>
				<div style={styles.column}>
					<KeyVal title='First Name' value={applicant.name} />
					<KeyVal title='Last Name' value={applicant.surname} />
					<KeyVal title='Email' value={applicant.email} />
				</div>
				<div style={styles.column}>
					<KeyVal
						title='DOB'
						value={
							applicant.DOB
								? applicant.DOB.toString()
								: 'None Set'
						}
					/>
					<KeyVal title='Gender' value='Male' />
					<KeyVal
						title='Phone Number'
						value={applicant.contactNumber}
					/>
				</div>
			</div>
		</View>
	);
};

export default GeneralInformation;
