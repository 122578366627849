import React, { FC, SetStateAction, Dispatch } from 'react';
import '../task-row/task-row.styles.scss';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import * as utils from '../../../utils/utilsFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import TaskActions from '../task-actions/task-actions.component';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	taskData: any;
	setRefetchTasks: Dispatch<SetStateAction<boolean>>;
	refetchTasks: boolean;
	taskType: string;
};

const TaskCheckRow: FC<Props> = ({
	taskData,
	setRefetchTasks,
	refetchTasks,
	taskType = '',
}) => {
	const dispatch = useDispatch();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const copyToClipboard = (text: string) => {
		const copyFunction = (e: ClipboardEvent) => {
			if (e && e.clipboardData) {
				e.clipboardData.setData('text/plain', text);
				e.preventDefault();
				dispatch(
					setAlert({
						type: 'success',
						message: `${taskData.user.email} copied to clipboard`,
						isVisible: true,
					}),
				);
				document.removeEventListener('copy', copyFunction);
			}
		};
		document.addEventListener('copy', copyFunction);
		document.execCommand('copy');
	};

	return (
		<div className='task-row'>
			<div className='task-row-left'>
				<div className='col-md'>
					<p className='check-review-col'>Review</p>
				</div>
				<div className='col-lg'>
					<TooltipLg
						title={utils.removeUnderscore(taskData.user.email)}
					>
						<button
							className='email-button'
							onClick={() => copyToClipboard(taskData.user.email)}
						>
							{utils.minimizeString(
								taskData.user.email,
								isMobile ? 18 : 50,
							)}
						</button>
					</TooltipLg>
				</div>
			</div>

			<div className='task-row-right'>
				<TaskActions
					applicant={taskData}
					setRefetchTasks={setRefetchTasks}
					refetchTasks={refetchTasks}
					taskType={taskType}
				/>
			</div>
		</div>
	);
};

export default TaskCheckRow;
