import React, { FC, useState, useEffect } from 'react';
import { Document, PDFViewer } from '@react-pdf/renderer';
import './downloadable-report.styles.scss';
import Layout from '../../components/downloadable-report/layout/layout.component';
import FrontPage from '../../components/downloadable-report/front-page/front-page.component';
import { RootState } from '../../types/root-state';
import * as utils from '../../utils/utilsFunctions';
import store from '../../redux/store';
import GeneralInformation from '../../components/downloadable-report/general-information/general-information';
import Certificate from '../../components/downloadable-report/certificate/certificate.component';
import Rtw from '../../components/downloadable-report/rtw/rtw.component';
import Sia from '../../components/downloadable-report/sia/sia.component';
import Activities from '../../components/downloadable-report/activities/activities.component';
import Address from '../../components/downloadable-report/address/address.component';
import Disclosures from '../../components/downloadable-report/disclosures/disclosures.component';
import NotesOnly from '../../components/downloadable-report/notes-only/notes-only.component';
import AdditionalDocs from '../../components/downloadable-report/additional-docs/additional-docs.component';
import Consent from '../../components/downloadable-report/consent/consent.component';
import FullPageLoader from '../../components/general/full-page-loader/full-page-loader.component';

const DownloadableReport: FC = () => {
	const [clearance, setClearance] = useState({
		clearanceLevel: '',
		clearanceDate: '',
		clearedBy: '',
	});
	const [consentFile, setConsentFile] = useState<any>([]);
	const state: RootState = store.getState();
	const { applicant, files } = state.profile;

	useEffect(() => {
		switch (applicant.clearance?.clearanceStatus) {
			case 'fully_compliant':
				setClearance({
					clearanceLevel: 'Fully Cleared',
					clearanceDate: utils.formatDateForUser(
						applicant.clearance.fullyClearedDate,
					),
					clearedBy: applicant.clearance.fullyClearedBy,
				});
				break;
			case 'partially_compliant':
				setClearance({
					clearanceLevel: 'Limited Clearance',
					clearanceDate: utils.formatDateForUser(
						applicant.clearance.provisionallyClearedDate,
					),
					clearedBy: applicant.clearance.provisionallyClearedBy,
				});
				break;
			default:
				break;
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			const iframe = document.querySelector('iframe');
			if (iframe) {
				iframe.style.zIndex = '9999';
			}
		}, 20000);
	}, []);

	useEffect(() => {
		const consentFile = files.filter((file) =>
			file.tags.includes('consentSignature'),
		);
		setConsentFile(consentFile.length > 0 ? consentFile : []);
	}, [files]);

	return (
		<div>
			<div className='loading'>
				<FullPageLoader loading={true} />
			</div>
			{clearance.clearanceLevel !== '' && (
				<PDFViewer>
					<Document>
						<FrontPage clearance={clearance} />
						<Layout clearance={clearance}>
							<GeneralInformation />
							<Certificate />
						</Layout>
						<Layout clearance={clearance}>
							<Rtw />
							<Sia />
						</Layout>
						<Layout clearance={clearance}>
							<Activities />
						</Layout>
						<Layout clearance={clearance}>
							<Address />
						</Layout>
						<Layout clearance={clearance}>
							<Disclosures />
						</Layout>
						<Layout clearance={clearance}>
							<NotesOnly title='Companies House Check' />
							<NotesOnly title='Global Watchlist' />
						</Layout>
						{files.length > 0 && consentFile.length > 0 && (
							<Layout clearance={clearance}>
								<Consent
									file={consentFile[consentFile.length - 1]}
								/>
							</Layout>
						)}

						{files.length > 0 && (
							<Layout clearance={clearance}>
								<AdditionalDocs />
							</Layout>
						)}
					</Document>
				</PDFViewer>
			)}
		</div>
	);
};
export default DownloadableReport;
