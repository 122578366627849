import React, {
	useEffect,
	useRef,
	FC,
	Dispatch,
	SetStateAction,
	RefObject,
} from 'react';
import './organisation-sub-header.styles.scss';

type Props = {
	getRef: Dispatch<SetStateAction<RefObject<HTMLDivElement>>>;
	organisationsRef: RefObject<HTMLDivElement>;
};

const ReferenceSubHeader: FC<Props> = ({ getRef, organisationsRef }) => {
	const organisationsHeaderRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		getRef(organisationsHeaderRef);
	}, [getRef]);

	const handleScroll = () => {
		if (organisationsHeaderRef.current && organisationsRef.current) {
			organisationsRef.current.scrollLeft =
				organisationsHeaderRef.current.scrollLeft;
		}
	};
	return (
		<div
			className='organisations-sub-header-container'
			ref={organisationsHeaderRef}
			onScroll={handleScroll}
		>
			<div className='organisations-sub-header-container-left'>
				<div className='col-md'>
					<p>Company</p>
				</div>
				<div className='col-md'>
					<p className='days'>Region</p>
				</div>
				<div className='col-md'>
					<p>Phone</p>
				</div>
				<div className='col-lg'>
					<p>Email</p>
				</div>
			</div>
			<div className='organisations-sub-header-container-right'></div>
		</div>
	);
};

export default ReferenceSubHeader;
