import { useEffect } from 'react';
import * as utils from './utilsFunctions';
import {
	setIdCheckData,
	setReportDocInfo,
} from '../redux/Profile/profile.actions';
import { RootState } from '../types/root-state';
import * as apiId from '../api/api.identity';
import store from '../redux/store';

const useGetRtwReport: any = () => {
	const state: RootState = store.getState();
	const { applicant } = state.profile;
	useEffect(() => {
		if (!applicant.workerUuid) return;

		const fetchData = async () => {
			try {
				let shareCodeData = null;
				let idCheckData = null;
				const shareCodeRes = await apiId.getShareCode(
					applicant.workerUuid,
				);
				if (shareCodeRes.status !== 404) {
					shareCodeData = shareCodeRes.data.data;
				}

				const idCheckRes = await apiId.getApplicantIdCheck(
					applicant.workerUuid,
				);

				if (idCheckRes.status && idCheckRes.status === 404) {
					store.dispatch(setIdCheckData(null));
					idCheckData = null;
				} else {
					store.dispatch(setIdCheckData(idCheckRes.data.data));
					idCheckData = idCheckRes.data.data;
				}

				if (shareCodeData) {
					const reportDocInfo = {
						type: 'Share Code',
						expiryDate: shareCodeData
							? utils.formatDateForUser(shareCodeData.expiryDate)
							: 'None set',
						code: shareCodeData.shareCode,
					};
					store.dispatch(setReportDocInfo(reportDocInfo));
				} else if (
					idCheckData &&
					idCheckData.documents &&
					idCheckData.reports &&
					idCheckData.documents.front &&
					idCheckData.reports.properties
				) {
					let expiry;
					if (
						idCheckData.documents.front.type === 'passport' &&
						idCheckData.reports.properties.issuing_country === 'GBR'
					) {
						expiry = 'Never';
					} else {
						expiry = utils.formatDateForUser(
							idCheckData.reports.properties.date_of_expiry,
						);
					}
					const reportDocInfo = {
						type: idCheckData.documents.front.type,
						expiryDate: expiry,
						code: '',
						url: idCheckData.documents.front.url,
					};
					store.dispatch(setReportDocInfo(reportDocInfo));
				} else {
					apiId.getIdResult(applicant.workerUuid).then((res) => {
						if (
							res &&
							res.data &&
							res.data.data &&
							res.data.data.passed
						) {
							const reportDocInfo = {
								type: 'Passed (Manually Overridden)',
								expiryDate: applicant.rtwExpiryDate
									? utils.formatDateForUser(
											applicant.rtwExpiryDate,
										)
									: 'Never',
								code: '',
								url: '',
							};
							store.dispatch(setReportDocInfo(reportDocInfo));
						}
					});
				}
			} catch (error) {
				return false;
			}
		};

		fetchData();
	}, [applicant.workerUuid]);
};

export default useGetRtwReport;
