import './external-dashboard.styles.scss';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearProfileData } from '../../redux/Profile/profile.actions';

import ExternalCheckHeader from '../../components/external-dashboard/external-check-header/external-check-header.component';
import ExternalTabs from '../../components/external-dashboard/external-tabs/external-tabs.component';
import DashboardPagination from '../../components/dashboard/dashboard-pagination/dashboard-pagination.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';

const ExternalDashboard: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearProfileData());
	}, []);

	return (
		<div className='external-dashboard'>
			<ErrorBoundary applicantWorkerUuid='None'>
				<ExternalCheckHeader />
			</ErrorBoundary>
			<ErrorBoundary applicantWorkerUuid='None'>
				<ExternalTabs />
			</ErrorBoundary>
			<ErrorBoundary applicantWorkerUuid='None'>
				<DashboardPagination />
			</ErrorBoundary>
		</div>
	);
};

export default ExternalDashboard;
