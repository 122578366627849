import React, { useState, useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import './face-to-face-section.styles.scss';
import edit from '../../../assets/icons/edit.svg';
import Modal from '../../modals/modal/modal.component';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import FaceToFaceModal from '../../modals/face-to-face-modal/face-to-face-modal.component';
import * as utils from '../../../utils/utilsFunctions';
import * as apiApplicant from '../../../api/api.applicant';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import { FaceToFaceStatus } from '../../../types/interface';
import { setF2FStatus } from '../../../redux/Profile/profile.actions';

const FaceToFaceSection: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const dispatch = useDispatch();
	const [dropdownData, setDropdownData] = useState<FaceToFaceStatus[]>([]);
	const [defaultData, setDefaultData] = useState<FaceToFaceStatus>();
	const [openF2fModal, setOpenF2fModal] = useState<boolean>(false);
	const { ambassadorCheckStatus } = useSelector(
		(state: RootState) => state.profile,
	);

	useEffect(() => {
		const dropdownDefault = utils.getDropdownData(
			'faceToFaceActions',
			applicant,
		);

		setDropdownData(dropdownDefault);
	}, [applicant]);

	useEffect(() => {
		const data = dropdownData.filter((item) => {
			return item.dbValue === ambassadorCheckStatus.status;
		});
		setDefaultData(data[0]);
	}, [dropdownData, ambassadorCheckStatus.status, ambassadorCheckStatus]);

	const getDropdownVal = (val: FaceToFaceStatus) => {
		if (val.dbValue === 'none') {
			return;
		}
		const payload = {
			status: val.dbValue,
			workerUuid: applicant.workerUuid,
		};
		apiApplicant
			.updateFaceToFaceStatus(applicant.workerUuid, payload)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				dispatch(setF2FStatus(val.dbValue));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated face to face status',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating face to face status',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div>
			<div className='ambassador-details-section'>
				<div className='f2f-details'>
					<div className='rtw-item'>
						<ProfileDisplayItem
							label='Ambassador Name'
							value={
								applicant.f2fCompletedBy
									? applicant.f2fCompletedBy
									: 'Missing Info'
							}
						/>
					</div>

					<div className='rtw-item'>
						<ProfileDisplayItem
							label='Date Checked'
							value={
								applicant.f2fCompletedDate
									? utils.formatDateForUser(
											applicant.f2fCompletedDate,
										)
									: 'Missing Info'
							}
						/>
					</div>
				</div>
				<div className='f2f-edit'>
					<img
						src={edit}
						alt='edit'
						onClick={() => setOpenF2fModal(true)}
					/>
				</div>
				{openF2fModal && (
					<Modal
						title='Update Ambassador Details'
						setShowModal={setOpenF2fModal}
						showModal={openF2fModal}
					>
						<FaceToFaceModal setShowModal={setOpenF2fModal} />
					</Modal>
				)}
			</div>
			<div className='f2f-dropdown-section'>
				{typeof ambassadorCheckStatus === 'string' ? (
					<ProfileDisplayItem
						label='Face to face status'
						value='Applicant has not started flow'
					/>
				) : (
					defaultData && (
						<Dropdown
							type='faceToFaceActions'
							dropdownData={dropdownData}
							defaultData={defaultData}
							getDropdownVal={getDropdownVal}
							width='160'
							height={24}
							mobileResponsive={false}
							position='bottom-center'
						/>
					)
				)}
			</div>
		</div>
	);
};

export default FaceToFaceSection;
