import React, {
	useState,
	useEffect,
	FC,
	Dispatch,
	SetStateAction,
	FormEvent,
} from 'react';
import './profile-rtw-expiry-modal.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';
import EditInput from '../../general/edit-input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as utils from '../../../utils/utilsFunctions';
import * as apiApplicant from '../../../api/api.applicant';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	setRtwExpiry: (rtwExpiry: string | Date | null) => void;
};

const ProfileRtwExpiryModal: FC<Props> = ({ setShowModal, setRtwExpiry }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [radioVal, setRadioVal] = useState<string>('never');
	const [newExpiry, setNewExpiry] = useState<string | Date | null>(
		applicant.rtwExpiryDate ? new Date(applicant.rtwExpiryDate) : '',
	);

	useEffect(() => {
		applicant.rtwExpiryDate ? setRadioVal('date') : setRadioVal('never');
	}, [applicant.rtwExpiryDate]);

	const handleUpdateRtwExpiry = () => {
		const payload = {
			rtwExpiryDate: newExpiry,
		};

		return apiApplicant
			.updateApplicant(applicant.workerUuid, payload)
			.then(() => {
				setShowModal(false);
				setRtwExpiry(newExpiry);
				dispatch(updateApplicantMultiple(payload));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated RTW expiry date',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to update RTW expiry',
						isVisible: true,
					}),
				);
			});
	};

	const handleChange = (event: FormEvent<HTMLInputElement>) => {
		if (event.currentTarget.id === 'never') {
			setNewExpiry(null);
		} else {
			setNewExpiry(new Date());
		}
		setRadioVal(event.currentTarget.id);
	};

	const emitValue = (val: string | Date) => {
		const date = utils.formatDateForDb(val);
		setNewExpiry(date);
	};

	return (
		<div className='rtw-expiry-modal'>
			<p>When does the Right to Work and ID Verification expire?</p>

			<div className='rtw-expiry-modal-radio-section'>
				<div className='rtw-expiry-modal-radio-item'>
					<label htmlFor='never'>Never</label>
					<input
						id='never'
						type='radio'
						onChange={handleChange}
						checked={radioVal === 'never' ? true : false}
					/>
				</div>

				<div className='rtw-expiry-modal-radio-item'>
					<label htmlFor='date'>Select Date</label>
					<input
						id='date'
						type='radio'
						onChange={handleChange}
						checked={radioVal === 'date' ? true : false}
					/>
				</div>
			</div>

			{radioVal === 'date' && (
				<div className='calendar-section'>
					<EditInput
						title='RTW Expiry Date'
						value={newExpiry ? new Date(newExpiry) : new Date()}
						emitValue={emitValue}
						placeholder='Click Calendar'
						inputName='rtwExpiryDate'
					></EditInput>
				</div>
			)}

			<div className='modal-button'>
				<OrkaButton
					disabled={false}
					emitClicked={handleUpdateRtwExpiry}
					buttonContent='Update'
				/>
			</div>
		</div>
	);
};

export default ProfileRtwExpiryModal;
