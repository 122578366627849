import React, { useEffect, useState, FC } from 'react';
import '../dashboard-sort-assign/dashboard-sort-assign.styles.scss';
import { useDispatch } from 'react-redux';
import { IDropdownStandard } from '../../../types/interface';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import * as utils from '../../../utils/utilsFunctions';
import { setPage } from '../../../redux/Dashboard/dashboard.actions';

type Props = {
	filterQuery: string;
	setReduxVal: any;
	label: string;
	position: string;
	globalQuery: string;
};

const DashboardStandardFilter: FC<Props> = ({
	filterQuery,
	setReduxVal,
	label,
	position,
	globalQuery,
}) => {
	const dispatch = useDispatch();
	const [dataArray, setDataArray] = useState<IDropdownStandard[]>([]);
	const [defaultData, setDefaultData] = useState<IDropdownStandard>();

	useEffect(() => {
		setDataArray(utils.getDropdownData(filterQuery, null));
		if (dataArray.length > 0) {
			const defaultDataFromQuery = dataArray.filter((item) => {
				return item.dbValue === globalQuery;
			});
			defaultDataFromQuery.length > 0
				? setDefaultData(defaultDataFromQuery[0])
				: setDefaultData(dataArray[0]);
			dispatch(setReduxVal(globalQuery));
		}
	}, [dataArray.length, globalQuery]);

	const getDropdownValue = (val: IDropdownStandard) => {
		dispatch(setPage(1));
		dispatch(setReduxVal(val.dbValue));
		setDefaultData(val);
	};

	return (
		<div className='show-assigned-container margin-right-24'>
			{dataArray.length > 0 && defaultData && (
				<div className='show-assigned'>
					<div className='show-assigned-text'>
						<p>{label}</p>
					</div>
					<Dropdown
						type=''
						dropdownData={dataArray}
						defaultData={defaultData}
						getDropdownVal={getDropdownValue}
						width='140'
						mobileResponsive={false}
						position={position}
						height={24}
					/>
				</div>
			)}
		</div>
	);
};

export default DashboardStandardFilter;
