import React, { FC } from 'react';
import './certificate-footer.styles.scss';
import logo from '../../../assets/logos/deploi-logo.svg';
import * as utils from '../../../utils/utilsFunctions';

const CertificateFooter: FC = () => {
	return (
		<div className='certificate-footer'>
			<div className='footer-text'>
				<p>
					In accordance with BS7858:2019 code of practise, subject to
					the client inspecting original copies
					<br />
					{/* eslint-disable-next-line react/no-unescaped-entities */}
					of the candidate's documents and completing all necessary
					Right to Work and ID Verification checks
				</p>
			</div>
			<div className='footer-images'>
				<div>
					<p>
						<em>
							Downloaded {utils.formatDateForUser(new Date())}
						</em>
					</p>
				</div>
				<img src={logo} alt='logo' />
			</div>
		</div>
	);
};

export default CertificateFooter;
