import { Image, StyleSheet } from '@react-pdf/renderer';
import React, { FC } from 'react';
import store from '../../../redux/store';
import { RootState } from '../../../types/root-state';
import KeyVal from '../key-val/key-val.component';
import SectionHeader from '../section-header/section-header.component';

const styles = StyleSheet.create({
	section: {
		display: 'flex',
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '200px',
		marginRight: '24px',
	},
	image: {
		width: '200px',
		height: 'auto',
		marginBottom: '16px',
		marginRight: '16px',
	},
	imageContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		paddingBottom: '16px',
	},
	pdf: {
		marginBottom: '8px',
		paddingBottom: '8px',
		borderBottom: '1px',
		borderStyle: 'solid',
		borderColor: '#FFFFFF',
	},
});

const AdditionalDocs: FC = () => {
	const state: RootState = store.getState();
	const { files } = state.profile;
	return (
		<div style={styles.main}>
			<SectionHeader title='Additional Documents' />
			<div>
				{files.length > 0 &&
					files.map((file: any) => {
						if (file.status != 'rejected' && !file.deletedAt) {
							return (
								<div key={file.uuid}>
									{file.readUrl &&
										!file.tags.includes(
											'consentSignature',
										) &&
										!file.tags.includes('proofOfAddress') &&
										!file.deletedAt &&
										file.contentType !==
											'application/pdf' && (
											<div style={styles.pdf}>
												<KeyVal
													title='File'
													value={`${file.tags[1]}: ${file.fileName}`}
												/>
												<Image
													src={file.readUrl}
													cache={false}
													style={styles.image}
												/>
											</div>
										)}
									{file.contentType === 'application/pdf' && (
										<div style={styles.pdf}>
											<KeyVal
												title='PDF File: (Deploi Appendix)'
												value={`${file.tags[1]}: ${file.fileName}`}
											/>
										</div>
									)}
								</div>
							);
						}
					})}
			</div>
		</div>
	);
};

export default AdditionalDocs;
