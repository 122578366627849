import React, { useState, FC } from 'react';
import './sign-up.styles.scss';
import SignUpInputs from '../../components/login/sign-up-inputs/sign-up-inputs';
import SignUpEmail from '../../components/login/sign-up-email/sign-up-email.component';
import LoginLayout from '../../components/general/login-layout/login-layout.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';

const SignUp: FC = () => {
	const [showEmail, setShowEmail] = useState<boolean>(true);
	const [email, setEmail] = useState<string>('');

	return (
		<LoginLayout>
			<ErrorBoundary applicantWorkerUuid='None'>
				{email.length > 0 && (
					<div className='user-info'>
						<p>{email}</p>
					</div>
				)}

				{showEmail ? (
					<SignUpEmail
						setShowEmail={setShowEmail}
						setEmail={setEmail}
					/>
				) : (
					<SignUpInputs email={email} />
				)}
			</ErrorBoundary>
		</LoginLayout>
	);
};

export default SignUp;
