import React, { FC } from 'react';
import AddStaffInfo from '../add-staff-info/add-staff-info.component';
import './add-staff-row.styles.scss';
import { IStaffMember } from '../../../types/interfaces/staff.interface';

type Props = {
	staffMember: IStaffMember;
};

const AddStaffRow: FC<Props> = ({ staffMember }) => {
	return (
		<div className='add-staff-row'>
			<div className='add-staff-row-left'>
				<div className='add-staff-info'>
					<AddStaffInfo header={false} user={staffMember} />
				</div>
				<div className='email'>
					<p>{staffMember.email}</p>
				</div>
			</div>
		</div>
	);
};

export default AddStaffRow;
