import React, {
	Dispatch,
	FC,
	RefObject,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import './assigned-dropdown.styles.scss';
import tickCircle from '../../../assets/icons/dashboard/tick-circle.svg';
import { IStaffMember } from '../../../types/interfaces/staff.interface';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicant } from '../../../types/interface';
import { setAlert } from '../../../redux/General/general.actions';
import * as apiStaff from '../../../api/api.staff';
import { RootState } from '../../../types/root-state';

type Props = {
	setShowDropdown: Dispatch<SetStateAction<boolean>>;
	position: string;
	showDropdown: boolean;
	applicantFromDash?: IApplicant;
	setAssignees: Dispatch<SetStateAction<any>>;
	assignees: any;
	availableStaff: IStaffMember[];
	setStaff: any;
	fromProfile: boolean;
	setCancelClick: Dispatch<SetStateAction<boolean>> | null;
};

const AssignedDropdown: FC<Props> = ({
	setShowDropdown,
	position,
	showDropdown,
	applicantFromDash,
	setAssignees,
	assignees,
	availableStaff,
	setStaff,
	fromProfile,
	setCancelClick,
}) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const profileApplicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [localApplicant, setLocalApplicant] = useState<IApplicant | null>(
		null,
	);
	const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
		useEffect(() => {
			/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
			const handleClickOutside = (event: any) => {
				setTimeout(() => {
					if (ref.current && !ref.current.contains(event.target)) {
						if (showDropdown) {
							setShowDropdown(false);
							if (setCancelClick !== null) {
								setCancelClick(true);
							}
						}
					}
				}, 200);
			};
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	useOutsideAlerter(dropdownRef);

	useEffect(() => {
		if (dropdownRef && dropdownRef.current) {
			const elementHeight =
				dropdownRef.current.getClientRects()[0].height;
			if (position === 'top-center') {
				dropdownRef.current.style.transform = `translateY(-${
					elementHeight + 16
				}px)`;
			}
		}
	}, []);

	useEffect(() => {
		if (applicantFromDash) {
			setLocalApplicant(applicantFromDash);
		} else {
			setLocalApplicant(profileApplicant);
		}
	}, []);

	const handleAssignStaff = (staffMember: IStaffMember) => {
		const selectStaff = availableStaff.map((s) => {
			if (staffMember.uuid === s.uuid) {
				s.selected = !s.selected;
			}
			return s;
		});
		setStaff(selectStaff);

		const selected = selectStaff.filter(
			(selectS) => selectS.uuid === staffMember.uuid,
		)[0];

		if (selected && selected.selected === true && localApplicant) {
			handleAssignStaffMember(selected)
				.then((res) => {
					selected.assignedUuid = res.data.uuid;
					setAssignees([...assignees, selected]);
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully assigned staff member',
							isVisible: true,
						}),
					);
				})
				.catch((err) => {
					dispatch(
						setAlert({
							type: 'error',
							message: err.message,
							isVisible: true,
						}),
					);
				});
		}

		if (
			selected &&
			selected.assignedUuid &&
			selected.selected === false &&
			localApplicant
		) {
			handleUnAssignStaffMember(selected.assignedUuid)
				.then(() => {
					const filteredAssignees = assignees.filter(
						(assignee: any) => {
							return assignee.uuid !== selected.uuid;
						},
					);
					setAssignees(filteredAssignees);
				})
				.catch((err) => {
					throw err;
				});
		}
	};

	const handleUnAssignStaffMember = (staffUuid: string) => {
		return apiStaff
			.unAssignStaffMember(staffUuid)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully un-assigned staff member',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	const handleAssignStaffMember = (staff: IStaffMember) => {
		const payload = {
			name: staff.name,
			surname: staff.surname,
			role: staff.role,
			email: staff.email,
		};
		if (localApplicant) {
			return apiStaff
				.assignStaffMember(localApplicant.uuid, payload)
				.catch((err) => {
					throw err;
				});
		} else {
			return new Promise((resolve, reject) => {
				reject();
			});
		}
	};

	return (
		<div
			className={`assigned-dropdown-container ${position} ${
				fromProfile ? 'dropdown-aligned-right' : ''
			}`}
			ref={dropdownRef}
		>
			{availableStaff.length > 0 &&
				availableStaff.map((staff) => {
					return (
						<div
							key={staff.uuid}
							className='assigned-dropdown-item'
							onClick={() => handleAssignStaff(staff)}
						>
							<p className={staff.color}>
								{staff.name + ' ' + staff.surname}
							</p>
							{staff.selected && (
								<img src={tickCircle} alt='selected' />
							)}
						</div>
					);
				})}
		</div>
	);
};

export default AssignedDropdown;
