type IHeaders = {
	'content-type': string;
};

type IUrls = {
	GATEWAY_URL: string | undefined;
};

export const getDefaultHeaders = (): IHeaders => {
	return {
		'content-type': 'application/json',
	};
};

export const getUrls = (): IUrls => {
	return {
		GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL,
	};
};
