import React, { FC } from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

// Font.register({
// 	family: 'Open Sans',
// 	fonts: [
// 		{
// 			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
// 		},
// 		{
// 			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
// 			fontWeight: 600,
// 		},
// 		{
// 			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
// 			fontWeight: 700,
// 		},
// 	],
// });

const styles = StyleSheet.create({
	section: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '16px',
	},
	textBold: {
		// fontFamily: 'Open Sans',
		fontSize: '10px',
		fontWeight: 700,
		marginRight: '24px',
		minWidth: '80px',
	},
	textNormal: {
		// fontFamily: 'Open Sans',
		fontSize: '10px',
		fontWeight: 600,
		maxWidth: '400px',
	},
});

type Props = {
	title: string;
	value: string;
	danger?: boolean;
	flexDirection?: string;
};

const KeyVal: FC<Props> = ({ title, value, danger = false, flexDirection }) => {
	return (
		<div
			style={{
				...styles.section,
				flexDirection: flexDirection === 'col' ? 'column' : 'row',
			}}
		>
			<Text style={styles.textBold}>{title}</Text>
			<Text
				style={{
					...styles.textNormal,
					color: danger ? '#BE5952' : 'black',
					fontWeight: danger ? 700 : 400,
				}}
			>
				{value}
			</Text>
		</div>
	);
};

export default KeyVal;
