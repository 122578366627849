import React, {
	useState,
	FC,
	Dispatch,
	SetStateAction,
	useEffect,
} from 'react';
import EditInput from '../../general/edit-input/input.component';
import InviteStaffModalSection from '../invite-staff-modal-section/invite-staff-modal-section.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import './invite-staff-modal.styles.scss';
import * as apiAuth from '../../../api/api.auth';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import InviteStaffCompany from '../invite-staff-company/invite-staff-company.component';
import { IDropdownStandard } from '../../../types/interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
};

const InviteStaffModal: FC<Props> = ({ setShowModal }) => {
	const dispatch = useDispatch();
	const role = useSelector((state: RootState) => state.auth.role);
	const [state, setState] = useState({
		name: '',
		surname: '',
		email: '',
	});
	const [selectedCompany, setSelectedCompany] = useState<IDropdownStandard>();

	const handleGetInfo = (val: string, input: string): void => {
		setState({
			...state,
			[input]: input === 'email' ? val.toLowerCase() : val,
		});
	};

	const checkDisabled = (): boolean => {
		if (
			state.email === '' ||
			state.name === '' ||
			state.surname === '' ||
			access === ''
		) {
			return true;
		} else {
			return false;
		}
	};

	const [access, setAccess] = useState('');

	useEffect(() => {
		setAccess('');
	}, [selectedCompany]);

	const handleEmitClicked = (val: string): void => {
		if (val === 'cancel') {
			setShowModal(false);
			return;
		}

		if (!selectedCompany || selectedCompany?.value === 'Company') {
			dispatch(
				setAlert({
					type: 'error',
					message: 'Please select a company from the dropdown',
					isVisible: true,
				}),
			);
			return;
		}
		if (role === 'screener') {
			dispatch(
				setAlert({
					type: 'error',
					message: `Wrong permission type for ${
						selectedCompany?.value === 'Orka Works'
							? 'internal'
							: 'external'
					} company`,
					isVisible: true,
				}),
			);
			return;
		}

		const payload = {
			...state,
			role: access,
		};

		apiAuth
			.invite(payload)
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully sent invite',
						isVisible: true,
					}),
				);
				setShowModal(false);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				setShowModal(false);
			});
	};

	return (
		<div className='invite-staff-modal-content'>
			<>
				<div className='name-inputs'>
					<div className='name-input first'>
						<EditInput
							inputName='name'
							placeholder='First Name'
							value={state.name}
							title=''
							emitValue={handleGetInfo}
						/>
					</div>

					<div className='name-input second'>
						<EditInput
							inputName='surname'
							placeholder='Surname'
							value={state.surname}
							title=''
							emitValue={handleGetInfo}
						/>
					</div>
				</div>

				<div className='email-input'>
					<EditInput
						inputName='email'
						placeholder='Enter email address'
						value={state.email}
						title=''
						emitValue={handleGetInfo}
					/>
				</div>
				<InviteStaffCompany
					selectedCompany={selectedCompany}
					getDropdownValue={setSelectedCompany}
				/>
				{(!selectedCompany?.value ||
					selectedCompany?.value === 'Company' ||
					selectedCompany?.value === 'Orka Works') && (
					<>
						<InviteStaffModalSection
							title='Internal: Controller (Full Access)'
							checkVal='Controller'
							contentText='Controllers will have full access to the platform. 
                    As well as the ability to invite, edit and remove staff.'
							checked={access === 'Controller' ? true : false}
							setAccess={setAccess}
						/>

						<InviteStaffModalSection
							title='Internal: Screener'
							checkVal='Screener'
							contentText='Admins will have full access to the platform. 
                    But they will not have the ability to invite, edit and remove staff.'
							checked={access === 'Screener' ? true : false}
							setAccess={setAccess}
						/>
					</>
				)}
				{selectedCompany?.value !== 'Orka Works' && (
					<>
						<InviteStaffModalSection
							title='External: Manager (Full Access)'
							checkVal='Manager'
							contentText='Managers have full access to the platform, and are able to approve risks, add or edit staff and add new candidates.'
							checked={access === 'Manager' ? true : false}
							setAccess={setAccess}
						/>

						<InviteStaffModalSection
							title='External: Admin'
							checkVal='Admin'
							contentText='Admins are able to view files and add new candidates.'
							checked={access === 'Admin' ? true : false}
							setAccess={setAccess}
						/>

						<InviteStaffModalSection
							title='External: Viewer'
							checkVal='Viewer'
							contentText='Viewers are able to view all the same areas and information as the other permissions but cannot perform any actions.'
							checked={access === 'Viewer' ? true : false}
							setAccess={setAccess}
						/>
					</>
				)}

				<div className='invite-modal-footer'>
					<div className='outline-button'>
						<OrkaButton
							buttonContent='Cancel'
							disabled={false}
							emitClicked={() => handleEmitClicked('cancel')}
						/>
					</div>

					<div className='invite-button'>
						<OrkaButton
							buttonContent='Invite'
							disabled={checkDisabled()}
							emitClicked={() => handleEmitClicked('invite')}
						/>
					</div>
				</div>
			</>
		</div>
	);
};

export default InviteStaffModal;
