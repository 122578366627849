import React, { useRef, useEffect, FC } from 'react';
import './layout.styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

type Props = {
	children: React.ReactNode;
	long?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Layout: FC<Props> = ({ children, long = false }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const sidebarIsOpen = useSelector(
		(state: RootState) => state.dashboard.sidebarIsOpen,
	);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.style.marginLeft = sidebarIsOpen
				? '366px'
				: '116px';
		}
	}, [sidebarIsOpen]);

	return (
		<div ref={containerRef} className='layout-container'>
			{children}
		</div>
	);
};

export default Layout;
