import React, { FC } from 'react';
import { View, StyleSheet, Font } from '@react-pdf/renderer';
import SectionHeader from '../section-header/section-header.component';
import store from '../../../redux/store';
import { RootState } from '../../../types/root-state';
import CertificateItem from './certificate-item.component';

Font.register({
	family: 'Open Sans',
	fonts: [
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
			fontWeight: 600,
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
			fontWeight: 700,
		},
	],
});

const styles = StyleSheet.create({
	section: {
		display: 'flex',
		marginTop: '16px',
		marginBottom: '40px',
	},
});

const Certificate: FC = () => {
	const state: RootState = store.getState();
	const { stageData } = state.profile;
	return (
		<View style={styles.section}>
			<SectionHeader title='Deploi Certificate' />
			<div>
				{stageData.map((item) => {
					return <CertificateItem stageItem={item} key={item.uuid} />;
				})}
			</div>
		</View>
	);
};

export default Certificate;
