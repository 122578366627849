import axios from 'axios';
import { axiosInstance } from './api.axiosInstance';

export const sendLog = (payload: string): Promise<any> => {
	const headers = {
		'content-type': 'application/json',
		authorization: process.env.REACT_APP_LOGGING_MS_KEY,
	};
	return axios
		.post(`${process.env.REACT_APP_LOGGING_URL}`, payload, {
			headers,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getMasterFlow = (): Promise<any> => {
	const url = `control/config`;

	return axiosInstance()
		.get(url)
		.then((data) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const updateMasterFlow = (masterFlow: string): Promise<any> => {
	const url = `control/config`;
	const payload = [
		{
			key: 'masterFlow',
			value: masterFlow,
		},
	];

	return axiosInstance()
		.patch(url, payload)
		.then((data) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
