import React, { FC } from 'react';
import { disclosureQuestions } from '../../../utils/data';
import SectionHeader from '../section-header/section-header.component';
import RiskContainer from '../risk-container/risk-container.component';
import { StyleSheet } from '@react-pdf/renderer';
import { RootState } from '../../../types/root-state';
import store from '../../../redux/store';
import KeyVal from '../key-val/key-val.component';
import * as utils from '../../../utils/utilsFunctions';

const styles = StyleSheet.create({
	section: {
		display: 'flex',
	},
	main: {
		display: 'flex',
		width: '100%',
		marginRight: '24px',
		borderBottom: '1px',
		borderStyle: 'solid',
		borderColor: '#FFFFFF',
		marginBottom: '16px',
		paddingBottom: '16px',
	},
});

const Disclosures: FC = () => {
	const state: RootState = store.getState();
	const { criminalBankruptcy } = state.profile;

	const getLengthOfDisclosures = (type: string) => {
		return criminalBankruptcy.filter((item) => item.type === type).length;
	};

	return (
		<div>
			<SectionHeader title='Disclosures' />
			{disclosureQuestions.map((question) => {
				return (
					<div key={question.type} style={styles.main}>
						<div style={{ marginBottom: '4px' }}>
							<KeyVal
								title='Question'
								value={question.question}
							/>
						</div>

						{criminalBankruptcy.map((item) => {
							if (item.type === question.type) {
								return (
									<div key={item.uuid}>
										<KeyVal
											title='Sentence'
											value={item.sentence}
										/>
										<KeyVal
											title='Description'
											value={item.details}
										/>
										<KeyVal
											title='Date'
											value={utils.formatDateForUser(
												item.date,
											)}
										/>
									</div>
								);
							}
						})}
						{getLengthOfDisclosures(question.type) === 0 && (
							<KeyVal title='N/A' value='None Recorded' />
						)}
					</div>
				);
			})}
			<RiskContainer title='Disclosures' />
		</div>
	);
};

export default Disclosures;
