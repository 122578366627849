import React, { FC } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import SectionHeader from '../section-header/section-header.component';
import KeyVal from '../key-val/key-val.component';
import store from '../../../redux/store';
import { RootState } from '../../../types/root-state';
import * as utils from '../../../utils/utilsFunctions';
import RiskContainer from '../risk-container/risk-container.component';

const styles = StyleSheet.create({
	section: {
		display: 'flex',
		marginTop: '40px',
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
	},
});

const Sia: FC = () => {
	const state: RootState = store.getState();
	const { licences } = state.profile;
	return (
		<View style={styles.section}>
			<SectionHeader title='Sia Licences' />
			<div style={styles.main}>
				{licences.map((licence) => {
					return (
						<div
							key={licence.number}
							style={{
								marginBottom: '16px',
								borderBottom: '1px solid white',
							}}
						>
							<KeyVal title='Number' value={licence.number} />
							<KeyVal title='Status' value={licence.status} />
							<KeyVal
								title='Expiry'
								value={utils.formatDateForUser(
									licence.expiryDate,
								)}
							/>
						</div>
					);
				})}
			</div>
			<RiskContainer title='SIA Licences' />
		</View>
	);
};

export default Sia;
