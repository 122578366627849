import React, { useState, FC, useEffect, useRef } from 'react';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';
import StageComplete from '../stage-complete/stage-complete.component';
import StageDropdown from '../stage-dropdown/stage-dropdown.component';
import './stage.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { backFromActivity } from '../../../redux/Profile/profile.actions';

type Props = {
	children: React.ReactNode;
	title: string;
	stageData?: IStageDataItem | null;
	clickable?: boolean;
	handleSiaClicked?: () => Promise<any> | undefined;
};

const Stage: FC<Props> = ({
	title,
	children,
	stageData = null,
	clickable = true,
	// handleSiaClicked,
}) => {
	const dispatch = useDispatch();
	const [showSection, setShowSection] = useState(false);
	const backFromActivityVal = useSelector(
		(state: RootState) => state.profile.backFromActivity,
	);
	const stageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const storedStageTitle = localStorage.getItem('activeStageTitle');
		if (storedStageTitle === title) {
			handleShowSection(true);
		}
	}, []);

	useEffect(() => {
		if (title === 'Activity History' && stageRef.current) {
			if (backFromActivityVal) {
				setShowSection(true);
				stageRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else {
				setShowSection(false);
			}
		}
	}, [backFromActivityVal]);

	const handleShowSection = (val: boolean) => {
		// if (title === 'SIA Licences' && val && handleSiaClicked) {
		// 	handleSiaClicked();
		// }
		if (val) {
			localStorage.setItem('activeStageTitle', title);
		} else {
			localStorage.removeItem('activeStageTitle');
		}
		if (title === 'Activity History' && !val) {
			dispatch(backFromActivity(false));
		}
		setShowSection(val);
	};

	return (
		<>
			<div className='stage-container' ref={stageRef}>
				<StageComplete clickable={clickable} stageData={stageData} />
				<StageDropdown
					title={title}
					setShowSection={handleShowSection}
					showSection={showSection}
					stageData={stageData}
				/>
			</div>
			{showSection && <div>{children}</div>}
		</>
	);
};

export default Stage;
