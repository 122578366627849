import React, { useState, FC, FormEvent } from 'react';
import './stage-notes.styles.scss';
import * as stageApi from '../../../api/api.stage';
import { useSelector, useDispatch } from 'react-redux';
import { setIndividualStage } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import * as utils from '../../../utils/utilsFunctions';
import { RootState } from '../../../types/root-state';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';
import OrkaButton from '../../general/orka-button/orka-button.component';

type Props = {
	stage: IStageDataItem;
	rtwStage?: any;
};

const StageNotes: FC<Props> = ({ stage, rtwStage }) => {
	const [stageNotes, setStageNotes] = useState<string>(
		stage.notes ? stage.notes : '',
	);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const dispatch = useDispatch();

	const manageStage = () => {
		if (rtwStage && rtwStage.title === 'Right to work' && rtwStage.notes) {
			const payload = {
				uuid: rtwStage.uuid,
				notes: '',
				notesUpdated: new Date(),
			};
			handleBlur(payload);
		}
		const payload = {
			uuid: stage.uuid,
			notes: stageNotes,
			notesUpdated: new Date(),
		};
		handleBlur(payload);
	};

	const handleBlur = (payload: any) => {
		stageApi
			.updateStage(applicant.workerUuid, payload)
			.then((res) => {
				if (res.status !== 404) {
					dispatch(setIndividualStage(res.data));
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully added note',
							isVisible: true,
						}),
					);
				} else {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to set note',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	const handleChange = (e: FormEvent<HTMLTextAreaElement>) => {
		setStageNotes(e.currentTarget.value);
	};

	return (
		<div className='stage-notes'>
			<textarea onChange={handleChange} value={stageNotes} />
			<div className='stage-notes-bottom'>
				<h4>
					Last updated:
					{stage.notesUpdated
						? utils.formatDateForUser(stage.notesUpdated)
						: 'N/A'}
				</h4>
				<OrkaButton
					buttonContent='Save'
					disabled={false}
					emitClicked={manageStage}
				/>
			</div>
		</div>
	);
};

export default StageNotes;
