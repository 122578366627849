import React, { useRef, FC } from 'react';
import { useSelector } from 'react-redux';
import CertificateFooter from '../../components/certificate/certificate-footer/certificate-footer.component';
import CertificateHeader from '../../components/certificate/certificate-header/certificate-header.component';
import CertificateInfo from '../../components/certificate/certificate-info/certificate-info.component';
import CertificateList from '../../components/certificate/certificate-list/certificate-list.component';
import './clearance-certificate.styles.scss';
import cross from '../../assets/icons/certificate/cross.svg';
import download from '../../assets/icons/certificate/download.svg';
import { useReactToPrint } from 'react-to-print';
import CertificateActivity from '../../components/certificate/certificate-activity/certificate-activity.component';
import { RootState } from '../../types/root-state';
import CertificateClearance from '../../components/certificate/certificate-clearance/certificate-clearance.component';
import { useNavigate } from 'react-router-dom';

const ClearanceCertificatePage: FC = () => {
	const navigate = useNavigate();
	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	const componentRef = useRef<HTMLDivElement>(null);
	const handleBack = () => {
		return navigate(`/check/user/${applicant.uuid}`, {
			state: {
				workerUuid: applicant.workerUuid,
				uuid: applicant.uuid,
			},
		});
	};

	const handleDownload = useReactToPrint({
		// onBeforePrint: () => {
		// },
		content: () => componentRef.current,
		// onAfterPrint: () => {
		// }
	});
	return (
		<div className='pdf-container'>
			<div className='close-certificate'>
				<img src={cross} alt='close certificate' onClick={handleBack} />
			</div>
			<div className='download-certificate' onClick={handleDownload}>
				<img src={download} alt='close certificate' />
				<div className='download-text'>
					<p>Download</p>
				</div>
			</div>
			<div className='page-content' ref={componentRef}>
				<CertificateHeader />
				<CertificateInfo />
				{(clearance?.clearanceStatus === 'fully_compliant' ||
					clearance?.clearanceStatus === 'partially_compliant') && (
					<CertificateClearance />
				)}
				<CertificateList stageData={stageData} />
				<CertificateActivity />
				<CertificateFooter />
			</div>
		</div>
	);
};

export default ClearanceCertificatePage;
