import React, { useState, useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import * as utils from '../../../utils/utilsFunctions';
import Modal from '../../modals/modal/modal.component';
import SuspendModal from '../../modals/suspend-modal/suspend-modal.component';
import { RootState } from '../../../types/root-state';
import NotesModal from '../notes-modal/notes-modal.component';
import {
	updateApplicant as updateApplicantRedux,
	updateApplicantMultiple,
} from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import * as apiApplicant from '../../../api/api.applicant';
import {
	refetchApplicants,
	updateApplicantField,
} from '../../../redux/Dashboard/dashboard.actions';

const ProfileActionDropdown: FC = () => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const overallSubmission = useSelector(
		(state: RootState) => state.profile.overallSubmission,
	);

	const [dropdownData, setDropdownData] = useState<any>([]);
	const [showSuspendedModal, setShowSuspendedModal] = useState(false);
	const [openModal, setOpenModal] = useState<boolean>(false);

	useEffect(() => {
		if (overallSubmission) {
			const dataFromFile = utils
				.getDropdownData('actionList', applicant)
				.map((item: any) => {
					item.disabled =
						(overallSubmission.data.status === 'Not yet created' ||
							overallSubmission.data.status === 'cancelled') &&
						item.value === 'Pause';
					return item;
				});
			setDropdownData(dataFromFile);
		}
	}, [overallSubmission, applicant.suspendedAt]);

	useEffect(() => {
		if (dropdownData.length > 0) {
			const updatedDropDownData = dropdownData.map((item: any) => {
				if (!item.value.toLowerCase().includes('suspend')) {
					item.value = applicant.paused ? 'Unpause' : 'Pause';
				}
				return item;
			});
			setDropdownData(updatedDropDownData);
		}
	}, [applicant.paused, dropdownData.length]);

	const getDropdownVal = (val: { value: string }) => {
		if (val.value.toLowerCase().includes('suspend')) {
			setShowSuspendedModal(true);
		}

		if (val.value.toLowerCase().includes('pause')) {
			setOpenModal(true);
		}
	};

	const emitSuspendedClicked = () => {
		setShowSuspendedModal(false);
	};

	const handleNoteUpdate = (payload: any) => {
		setOpenModal(false);
		if (applicant.paused) {
			payload.pausedNotes = null;
			payload.paused = false;
		} else {
			payload.paused = true;
		}
		return updateApplicant(payload)
			.then(() => {
				if (payload.paused) {
					dispatch(
						updateApplicantField(
							applicant.workerUuid,
							'assigned',
							[],
						),
					);
					dispatch(updateApplicantMultiple({ assigned: [] }));
					dispatch(updateApplicantRedux('assigned', []));
					actionUpdateOverallSubmission();
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	const actionUpdateOverallSubmission = () => {
		const payload = {
			status: 'rejected',
			uuid: applicant.uuid,
		};
		apiApplicant
			.updateOverallSubmission(applicant.workerUuid, payload)
			.then(() => {
				dispatch(refetchApplicants());
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating overall submission status',
						isVisible: true,
					}),
				);
			});
	};

	const updateApplicant = (payload: any) => {
		return apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then(() => {
				dispatch(updateApplicantMultiple(payload));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated paused status',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	return (
		<div className='profile-action-dropdown'>
			<Dropdown
				type='actionList'
				dropdownData={dropdownData}
				getDropdownVal={getDropdownVal}
				width='100'
				position='bottom-left'
			/>

			{showSuspendedModal && (
				<Modal
					title={
						applicant.suspendedAt
							? 'Unsuspend Applicant'
							: 'Suspend Applicant'
					}
					setShowModal={setShowSuspendedModal}
					showModal={showSuspendedModal}
				>
					<SuspendModal
						emitClicked={emitSuspendedClicked}
						applicant={applicant}
					/>
				</Modal>
			)}
			{openModal && (
				<NotesModal
					handleClicked={handleNoteUpdate}
					buttonContent={
						applicant.paused
							? 'Unpause Applicant'
							: 'Pause Applicant'
					}
					setShowModal={setOpenModal}
					showModal={openModal}
					noteValue={applicant.pausedNotes}
					type='pausedNotes'
					modalTitle={
						applicant.paused
							? 'Unpause Application'
							: 'Pause Application'
					}
					placeholder='Please note why the applicant has been paused'
					modalInfo={
						applicant.paused ? (
							<p>Screener will need to be manually reassigned.</p>
						) : (
							<p>
								Screener will be unassigned from this
								application.
							</p>
						)
					}
					disableTextBox={applicant.paused ? true : false}
				/>
			)}
		</div>
	);
};

export default ProfileActionDropdown;
