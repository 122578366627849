import React, {
	useEffect,
	useState,
	useRef,
	FC,
	SetStateAction,
	Dispatch,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as utils from '../../../utils/utilsFunctions';
import OrkaButton from '../../general/orka-button/orka-button.component';
import './profile-declaration-modal.styles.scss';
import { useReactToPrint } from 'react-to-print';
import { RootState } from '../../../types/root-state';
import { setIsLoading } from '../../../redux/General/general.actions';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
};

type SubmissionData = {
	html: string;
	image: string;
	date: string;
};

const ProfileDeclarationModal: FC<Props> = ({ setShowModal }) => {
	const dispatch = useDispatch();
	const overallSubmission = useSelector(
		(state: RootState) => state.profile.overallSubmission,
	);
	const isLoading = useSelector(
		(state: RootState) => state.general.isLoading,
	);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const [submissionData, setSubmissionData] = useState<SubmissionData>();
	const componentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const formatData = async () => {
			if (overallSubmission.data) {
				const string = overallSubmission.data.formSigned.substring(
					1,
					overallSubmission.data.formSigned.length - 1,
				);

				let html = await atob(string);

				html = html.replace(/â¢/g, '&#8226;');
				html = html.replace(/â/g, '-');
				const image = overallSubmission.data.signatureUrl;
				const date = utils.formatDateForUser(
					overallSubmission.data.signedDate,
				);

				const data = {
					html,
					image,
					date,
				};

				setSubmissionData(data);
			}
		};
		formatData();
	}, [
		overallSubmission.data.formSigned,
		overallSubmission.data.signedDate,
		overallSubmission.data.signatureUrl,
		overallSubmission.data,
	]);

	const handleDownload = useReactToPrint({
		onBeforePrint: () => {
			dispatch(setIsLoading(true));
		},
		content: () => componentRef.current,
		onAfterPrint: () => {
			dispatch(setIsLoading(false));
			setShowModal(false);
		},
	});

	return (
		<>
			{!isLoading && (
				<div>
					{submissionData && (
						<div ref={componentRef} className='declaration-content'>
							<div className='print-heading'>
								<h1>Worker Declaration</h1>
							</div>

							<div
								dangerouslySetInnerHTML={{
									__html: submissionData.html,
								}}
							></div>
							<div>
								<p>
									{utils.getFullName(
										null,
										applicant.name,
										applicant.surname,
									)}
								</p>
								<img
									src={submissionData.image}
									alt='signature'
								/>
								<p>
									{utils.formatDateForUser(
										overallSubmission.data.signedDate,
									)}
								</p>
							</div>
						</div>
					)}

					<div className='declaration-modal-button'>
						<OrkaButton
							buttonContent='Download'
							disabled={false}
							emitClicked={handleDownload}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default ProfileDeclarationModal;
