import React, { useState, FC } from 'react';
import './stage-risk.styles.scss';
import Modal from '../../modals/modal/modal.component';
import RiskModal from '../risk-modal/risk-modal.component';
import riskIcon from '../../../assets/icons/risk-error.svg';
import RiskItemComponent from '../risk-item/risk-item.component';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';

type Props = {
	stage: IStageDataItem;
};

const StageRisk: FC<Props> = ({ stage }) => {
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleAddRisk = () => {
		setShowModal(true);
	};

	return (
		<div className='risk-container'>
			<div className='risk-items-list'>
				{stage.risks.length > 0 &&
					stage.risks.map((risk) => {
						return (
							<div
								className='mapped-risk-container'
								key={risk.uuid}
							>
								{!risk.deletedAt && (
									<RiskItemComponent
										risk={risk}
										stage={stage}
									/>
								)}
							</div>
						);
					})}
			</div>

			<div className='add-risk-btn'>
				<button onClick={handleAddRisk}>Add Risk</button>
			</div>
			{showModal && (
				<Modal
					title='Add Risk'
					setShowModal={setShowModal}
					showModal={showModal}
					modalContentHeight='60vh'
					titleIcon={riskIcon}
				>
					<RiskModal setShowModal={setShowModal} stage={stage} />
				</Modal>
			)}
		</div>
	);
};

export default StageRisk;
