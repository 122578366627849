import React, { FC } from 'react';
import './add-staff-info.styles.scss';
import { useSelector } from 'react-redux';
import * as utils from '../../../utils/utilsFunctions';
import { User } from '../../../types/interface';
import { IStaffMember } from '../../../types/interfaces/staff.interface';
import { RootState } from '../../../types/root-state';

type Props = {
	header: boolean;
	user: IStaffMember | User;
};

const AddStaffInfo: FC<Props> = ({ header = true, user }) => {
	const role = useSelector((state: RootState) => state.auth.role);
	return (
		<div className='add-staff-info-container'>
			{user.name && user.surname && (
				<>
					<div className='add-staff-avatar'>
						<p
							className={
								header
									? ' add-staff-avatar-letter color-blue'
									: `add-staff-avatar-letter ${user.color}`
							}
						>
							{`${user.name[0].toUpperCase()}${user.surname[0].toUpperCase()}`}
						</p>
					</div>

					<div className='add-staff-user-info'>
						<p
							className={
								header ? 'orka-profile-label' : 'add-staff-name'
							}
						>
							{header
								? utils.capitalizeFirstLetter(role)
								: utils.getFullName(
										'',
										utils.capitalizeFirstLetter(user.name),
										utils.capitalizeFirstLetter(
											user.surname,
										),
									)}
						</p>
						<p
							className={
								header ? 'add-staff-name' : 'add-staff-role'
							}
						>
							{header
								? utils.getFullName(
										'',
										utils.capitalizeFirstLetter(user.name),
										utils.capitalizeFirstLetter(
											user.surname,
										),
									)
								: utils.capitalizeFirstLetter(user.role)}
						</p>
					</div>
				</>
			)}
		</div>
	);
};

export default AddStaffInfo;
