import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import * as utils from '../../../utils/utilsFunctions';
import * as apiApplicant from '../../../api/api.applicant';
import fully from '../../../assets/icons/dropdown-fully.svg';
import none from '../../../assets/icons/dropdown-none.svg';
import limited from '../../../assets/icons/dropdown-limited.svg';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import { IApplicant, ComplianceStatus } from '../../../types/interface';
import {
	updateApplicantMultiple,
	updateClearance,
} from '../../../redux/Profile/profile.actions';

type ClearanceDateObject = {
	[key: string]: string | null;
};

type ClearanceCallInfo = {
	payload: { clearanceStatus: string };
	val: ComplianceStatus;
};

const ProfileClearanceStatus: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	const dispatch = useDispatch();
	const [dropdownData, setDropdownData] = useState<ComplianceStatus[]>([]);
	const [defaultData, setDefaultData] = useState<ComplianceStatus>();

	useEffect(() => {
		setDropdownData(utils.getDropdownData('complianceStatus', applicant));
	}, [clearance?.clearanceStatus]);

	useEffect(() => {
		if (applicant && dropdownData.length > 0) {
			const data = dropdownData.filter((item) => {
				return (
					item.dbValue ===
					(clearance ? clearance.clearanceStatus : 'not_compliant')
				);
			});

			if (data.length > 0) {
				getIcon(data);
				setDefaultData(data[0]);
			}
		}
	}, [JSON.stringify(dropdownData), JSON.stringify(applicant)]);

	const getIcon = (data: ComplianceStatus[]) => {
		switch (data[0].dbValue) {
			case 'partially_compliant':
				data[0].icon = limited;
				return;
			case 'fully_compliant':
				data[0].icon = fully;
				return;
			default:
				data[0].icon = none;
				return;
		}
	};

	const getDropdownVal = (val: ComplianceStatus) => {
		if (applicant) {
			const payload = {
				clearanceStatus: val.dbValue,
			};

			if (!clearance || clearance.clearanceStatus === 'not_compliant') {
				const data = {
					payload,
					val,
				};

				continueClearance(data);
				return;
			} else {
				return updateClearanceStatus(payload, applicant);
			}
		}
	};

	const continueClearance = (complianceCallData: ClearanceCallInfo) => {
		if (complianceCallData) {
			return updateClearanceStatus(
				complianceCallData.payload,
				applicant,
			).catch((err) => {
				throw err;
			});
		} else {
			return new Promise((resolve, reject) => {
				reject();
			});
		}
	};

	const updateClearanceStatus = (payload: any, applicant: IApplicant) => {
		const updatedPayload = validatePayload(payload.clearanceStatus);
		return apiApplicant
			.updateApplicantApi(applicant.uuid, updatedPayload)
			.then((res: any) => {
				if (res.status === 404) {
					throw res;
				}
				const data = dropdownData.filter((item) => {
					return item.dbValue === payload.clearanceStatus;
				});
				setDefaultData(data[0]);

				if (applicant.assigned.length > 0) {
					const assigned = `${applicant.assigned[0].name} ${applicant.assigned[0].surname}`;
					dispatch(
						updateApplicantMultiple({
							clearedAssignedTo:
								utils.capitalizeFirstLetterOfEach(assigned),
						}),
					);
				}

				let clearedBy;
				if (getClearedByString(payload.clearanceStatus)) {
					clearedBy = getClearedByString(payload.clearanceStatus);
					updatedPayload[clearedBy] = clearedBy;
				}
				dispatch(updateClearance(updatedPayload));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated compliance status',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating compliance status',
						isVisible: true,
					}),
				);
			});
	};

	const getClearedByString = (status: string) => {
		switch (status) {
			case 'partially_compliant':
				return 'provisionallyClearedBy';
			case 'fully_compliant':
				return 'fullyClearedBy';
			default:
				return '';
		}
	};

	const validatePayload = (status: string) => {
		const payload: ClearanceDateObject = {
			clearanceStatus: status,
		};
		if (status === 'partially_compliant') {
			payload['provisionallyClearedDate'] = utils.formatDateForDb(
				new Date(),
			);
			payload['fullyClearedDate'] = null;
			payload['fullyClearedBy'] = null;
		}

		if (status === 'fully_compliant') {
			payload['fullyClearedDate'] = utils.formatDateForDb(new Date());
		}

		if (status === 'not_compliant') {
			payload['fullyClearedDate'] = null;
			payload['provisionallyClearedDate'] = null;
			payload['provisionallyClearedBy'] = null;
			payload['fullyClearedBy'] = null;
		}
		return payload;
	};

	return (
		<div>
			{defaultData && (
				<Dropdown
					type='complianceStatus'
					dropdownData={dropdownData}
					defaultData={defaultData}
					getDropdownVal={getDropdownVal}
					width='180'
					height={24}
					mobileResponsive={false}
					position='bottom-center'
				/>
			)}
		</div>
	);
};

export default ProfileClearanceStatus;
