import React, { useEffect, useState, FC } from 'react';
import * as apiId from '../../../api/api.identity';
import { useSelector, useDispatch } from 'react-redux';
import './profile-manual-approve.styles.scss';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import { IIdCheckData } from '../../../types/interfaces/identity.interface';

type Props = {
	idCheckData: IIdCheckData;
};

const ProfileManualApprove: FC<Props> = ({ idCheckData }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const passIdCheck = useSelector(
		(state: RootState) => state.profile.passIdCheck,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const [idStatus, setIdStatus] = useState<{ val: string; class: string }>({
		val: '',
		class: '',
	});

	useEffect(() => {
		let isCancelled = false;
		apiId
			.getIdResult(applicant.workerUuid)
			.then((res) => {
				if (!isCancelled) {
					res && res.data && res.data.data && res.data.data.passed
						? setIdStatus({
								val: 'Passed',
								class: 'tag btn-passed',
							})
						: setIdStatus({
								val: 'Failed',
								class: 'tag btn-failed',
							});

					if (
						idCheckData &&
						idCheckData.check &&
						idCheckData.check.data
					) {
						if (idCheckData.check.data.status === 'in_progress') {
							setIdStatus({
								val: 'In Progress',
								class: 'tag btn-pending',
							});
						}
					}
				}
			})
			.catch((err) => {
				throw err;
			});

		return () => {
			isCancelled = true;
		};
	}, [applicant.workerUuid, idCheckData]);

	useEffect(() => {
		if (passIdCheck) {
			setIdStatus({
				val: 'Passed',
				class: 'tag btn-passed',
			});
		}
	}, [passIdCheck]);

	const handleManualApprove = async () => {
		if (role === 'ambassador') {
			return;
		}

		await getApplicant()
			.then((res) => {
				if (res.status === 404) {
					return createApplicant().catch((err) => {
						throw err;
					});
				}
			})
			.catch((err) => {
				throw err;
			});

		apiId
			.manualOverride(applicant.workerUuid)
			.then(() => {
				setIdStatus({
					val: 'Passed',
					class: 'tag btn-passed',
				});

				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully approved id check',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to approve ID check',
						isVisible: true,
					}),
				);
			});
	};

	const getApplicant = () => {
		return apiId.getApplicantIdCheck(applicant.workerUuid).catch((err) => {
			throw err;
		});
	};

	const createApplicant = () => {
		const payload = {
			worker: {
				user: {
					uuid: applicant.workerUuid,
					name: applicant.name,
					surname: applicant.surname,
				},
			},
		};

		return apiId.createOnfidoApplicant(payload).catch((err) => {
			throw err;
		});
	};

	return (
		<div className='manually-approve' data-cy='manual-approve'>
			<button className={idStatus.class} onClick={handleManualApprove}>
				{idStatus.val}
			</button>
			<p>
				{role === 'ambassador'
					? 'Right to Work and ID Verification status'
					: 'Click to manually approve Onfido'}
			</p>
		</div>
	);
};

export default ProfileManualApprove;
