import React, { FC } from 'react';
import './profile-header.styles.scss';
import backButton from '../../../assets/icons/back-blue.svg';
import ProfileActionDropdown from '../profile-action-dropdown/profile-action-dropdown.component';
import * as utils from '../../../utils/utilsFunctions';
import {
	backFromActivity,
	setShowActivity,
} from '../../../redux/Profile/profile.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import DashboardRowAssigned from '../../dashboard/dashboard-row-assigned/dashboard-row-assigned.component';
import { useNavigate } from 'react-router-dom';
import ProfileLastChased from '../profile-last-chased/profile-last-chased.component';
import { setAlert } from '../../../redux/General/general.actions';

const ProfileHeader: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const role = useSelector((state: RootState) => state.auth.role);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const showActivity = useSelector(
		(state: RootState) => state.profile.showActivity,
	);
	const handleBackToDashboard = () => {
		navigate('/check');
	};

	const handleBack = () => {
		const data = {
			showActivity: false,
			activityItem: null,
		};
		dispatch(
			setAlert({
				type: 'success',
				message: 'Activity successfully updated',
				isVisible: false,
			}),
		);
		dispatch(backFromActivity(true));
		dispatch(setShowActivity(data));
	};

	return (
		<div className='profile-header'>
			{role !== 'ambassador' && (
				<div className='profile-header-screener'>
					<div className='profile-header-info-section'>
						<div>
							{showActivity ? (
								<div className='back-btn' onClick={handleBack}>
									<img src={backButton} alt='back' />
								</div>
							) : (
								<div className='profile-header-info-actions'>
									<ProfileActionDropdown />
								</div>
							)}
						</div>
						<h1>
							{utils.capitalizeFirstLetterOfEach(
								utils.getFullName(
									applicant.title,
									applicant.name,
									applicant.surname,
								),
							)}
						</h1>
					</div>

					<div className='profile-header-assigned-section'>
						<ProfileLastChased />
						<DashboardRowAssigned
							applicantFromDash={applicant}
							fromProfile={true}
							setCancelClick={null}
						/>
					</div>
				</div>
			)}

			{role === 'ambassador' && (
				<div className='profile-header-content-ambassadors'>
					<div className='back-btn' onClick={handleBackToDashboard}>
						<img src={backButton} alt='back' />
					</div>
					<h1>
						{utils.capitalizeFirstLetterOfEach(
							utils.getFullName(
								applicant.title,
								applicant.name,
								applicant.surname,
							),
						)}
					</h1>
				</div>
			)}
		</div>
	);
};

export default ProfileHeader;
