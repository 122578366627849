import './dashboard-company-filter.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { RootState } from '../../../types/root-state';
import { ICompanyItem } from '../../../types/interfaces/company.interface';
import { IDropdownStandard } from '../../../types/interface';
import { useDispatch, useSelector } from 'react-redux';
import {
	setCompanyQuery,
	setPage,
} from '../../../redux/Dashboard/dashboard.actions';

import Dropdown from '../../general/orka-dropdown/dropdown.component';

const defaultItems = [
	{
		id: 1,
		value: 'All',
		dbValue: 'all',
		class: 'dropdown-grey',
	},
	{
		id: 2,
		value: 'External',
		dbValue: 'external',
		class: 'dropdown-grey',
	},
	{
		id: 3,
		value: 'Multiple',
		dbValue: 'multiple',
		class: 'dropdown-grey',
	},
	{
		id: 4,
		value: 'None',
		dbValue: 'none',
		class: 'dropdown-grey',
	},
];

const DashboardCompanyFilter: FC = () => {
	const dispatch = useDispatch();
	const [company, setCompany] = useState<IDropdownStandard[]>([]);
	const [defaultCompany, setDefaultCompany] = useState<IDropdownStandard>();
	const allCompanies = useSelector((state: RootState) => state.auth.company);
	const globalCompanyQuery = useSelector(
		(state: RootState) => state.dashboard.companyQuery,
	);

	useEffect(() => {
		const dropdownData = allCompanies.map(
			(company: ICompanyItem, index: number) => {
				return {
					id: defaultItems.length + index + 1,
					value: company.companyName,
					dbValue: company.id.toString(),
					class: 'dropdown-grey',
				};
			},
		);

		setCompany([...defaultItems, ...dropdownData]);

		if (company.length > 0 && allCompanies.length > 0) {
			const defaultDataFromQuery = company.filter((item) => {
				return item.dbValue === globalCompanyQuery;
			});

			defaultDataFromQuery.length > 0
				? setDefaultCompany(defaultDataFromQuery[0])
				: setDefaultCompany(defaultItems[0]);
			dispatch(setCompanyQuery(globalCompanyQuery));
		}
	}, [allCompanies.length, company.length, globalCompanyQuery]);

	const getDropdownValue = (val: IDropdownStandard) => {
		dispatch(setPage(1));
		dispatch(setCompanyQuery(val.dbValue));
		setDefaultCompany(val);
	};

	return (
		<div className='company-filter-container'>
			{company.length > 0 && defaultCompany && (
				<div className='company-filter'>
					<div className='company-filter-text'>
						<p>Company</p>
					</div>
					<Dropdown
						type=''
						dropdownData={company}
						defaultData={defaultCompany}
						getDropdownVal={getDropdownValue}
						width='140'
						mobileResponsive={false}
						position='bottom-right'
						height={24}
					/>
				</div>
			)}
		</div>
	);
};

export default DashboardCompanyFilter;
