import React, { FC, Dispatch, SetStateAction } from 'react';
import './task-selector.styles.scss';
import { setTaskPage } from '../../../redux/Dashboard/dashboard.actions';
import { useDispatch } from 'react-redux';
type Props = {
	setTaskType: Dispatch<SetStateAction<string>>;
	setTaskData: Dispatch<SetStateAction<any>>;
	setTotalPages: Dispatch<SetStateAction<number>>;
	taskType: string;
};

const TaskSelector: FC<Props> = ({
	setTaskType,
	setTaskData,
	setTotalPages,
	taskType = '',
}) => {
	const dispatch = useDispatch();
	const handleSelectTask = (val: string) => {
		setTaskData([]);
		setTotalPages(1);
		dispatch(setTaskPage(1));
		setTaskType(val);
	};
	return (
		<div className='task-selector'>
			<button
				onClick={() => handleSelectTask('')}
				className={taskType === '' ? 'selected' : ''}
				disabled={taskType === ''}
			>
				Community
			</button>
			<button
				onClick={() => handleSelectTask('checkTask')}
				className={taskType === 'checkTask' ? 'selected' : ''}
				disabled={taskType === 'checkTask'}
			>
				Check
			</button>
		</div>
	);
};

export default TaskSelector;
