import React, { FC } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import ReportHeader from '../report-header/report-header.component';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: '32px',
		backgroundColor: '#E5E5E5',
	},
	section: {
		flexGrow: 1,
	},
	header: {
		height: 100,
	},
	footerText: {
		position: 'absolute',
		bottom: 5,
		right: 20,
		zIndex: 1,
		color: '#7B7DFC',
		fontSize: '12px',
		fontWeight: 700,
	},
	footer: {
		height: '100px',
	},
	bottomSectionLeft: {
		backgroundColor: '#7B7DFC',
		transform: `rotate(25deg)`,
		position: 'absolute',
		bottom: -180,
		left: -150,
		width: 300,
		height: 200,
		zIndex: 0,
	},
});

type Props = {
	children: React.ReactNode;
	clearance: {
		clearanceLevel: string;
		clearanceDate: string;
		clearedBy: string;
	};
};

const Layout: FC<Props> = ({ children, clearance }) => {
	return (
		<Page size='A4' style={styles.page}>
			<View>
				<ReportHeader clearance={clearance} />
				{children}
			</View>

			<View style={styles.footer} fixed>
				<Text
					style={styles.footerText}
					render={({ pageNumber }) => pageNumber}
				/>

				<div style={styles.bottomSectionLeft} />
			</View>
		</Page>
	);
};

export default Layout;
