import React, { useState, useEffect, FC, useCallback, FormEvent } from 'react';
import Dropdown from '../orka-dropdown/dropdown.component';
import './search-container.styles.scss';
import Search from '../search/search.component';
import { setGlobalSearchBy } from '../../../redux/Dashboard/dashboard.actions';
import * as utils from '../../../utils/utilsFunctions';
import { RootState } from '../../../types/root-state';
import { SearchByItem } from '../../../types/interface';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSearchTerm } from '../../../redux/Dashboard/dashboard.actions';
import { clearProfileData } from '../../../redux/Profile/profile.actions';
import { useNavigate } from 'react-router-dom';

const SearchContainer: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const role = useSelector((state: RootState) => state.auth.role);
	const [searchByData, setSearchByData] = useState<SearchByItem[]>([]);
	const searchBy = useSelector(
		(state: RootState) => state.dashboard.searchBy,
	);
	const [searchByDefaultData, setSearchByDefaultData] =
		useState<SearchByItem>();

	useEffect(() => {
		setSearchByData(utils.getDropdownData('searchBy', null));
		dispatch(setGlobalSearchBy(searchBy));
	}, [dispatch]);

	useEffect(() => {
		if (searchByData.length > 0) {
			setSearchByDefaultData(searchByData[2]);
		}
	}, [searchByData.length]);

	const getSearchByDropdownVal = (val: SearchByItem): void => {
		const data = searchByData.map((item: SearchByItem) => {
			item.selected = item.dbValue === val.dbValue;
			return item;
		});
		setSearchByData(data);
		setSearchByDefaultData(val);
		dispatch(setGlobalSearchBy(val.dbValue));
	};

	const searchTerm = useSelector(
		(state: RootState) => state.dashboard.searchTerm,
	);
	const [search, setSearch] = useState<string>('');

	const sendQuery = (query: string) => {
		dispatch(setGlobalSearchTerm(query));
	};

	useEffect(() => {
		if (searchTerm) {
			setSearch(searchTerm);
		}
	}, [searchTerm]);

	const delayedQuery = useCallback(
		debounce(() => {
			sendQuery(search);
		}, 500),
		[search],
	);

	const handleChange = (e: FormEvent<HTMLInputElement>): void => {
		setSearch(e.currentTarget.value);
		dispatch(clearProfileData());
		navigate('/check');
	};

	useEffect(() => {
		delayedQuery();
		return delayedQuery.cancel;
	}, [search, delayedQuery]);

	return (
		<div className='dashboard-sub-menu-search'>
			<div className='dashboard-sub-menu-search-input'>
				<Search
					handleChange={handleChange}
					search={search}
					placeholder='Search Applicants'
				/>
			</div>
			{searchByDefaultData && role !== 'ambassador' && (
				<Dropdown
					type='searchBy'
					dropdownData={searchByData}
					defaultData={searchByDefaultData}
					getDropdownVal={getSearchByDropdownVal}
					width='12'
					position='bottom-right'
					arrowOnly={true}
				/>
			)}
		</div>
	);
};

export default SearchContainer;
