import {
	IApplicant,
	ILicencePayload,
	ILicenceItem,
	WorkHistoryItem,
	INewCandidate,
	NiNumber,
} from '../types/interface';
import {
	IGetApplicantsResponse,
	IOverallSubmissionResponse,
} from '../types/interfaces/responses.interface';

import { axiosInstance } from './api.axiosInstance';

export const getApplicants = (
	bucket: string,
	searchTerm: string | number | boolean,
	sortBy: string,
	searchBy: string,
	page: number,
	sortDirection: string,
	assignedQuery: string,
	submissionQuery: string,
	statusQuery: string,
	flowQuery: string,
	companyQuery: string,
): Promise<IGetApplicantsResponse> => {
	const sort = sortBy ? `&sortBy=${sortBy}` : '';
	const direction = sortDirection ? `&sort=${sortDirection}` : '';
	const find = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : '';
	const findBy = searchBy && searchTerm ? `&searchBy=${searchBy}` : '';
	const bucketParam = `&bucket=${bucket ? bucket : 'all'}`;
	const assigned = `&assigned=${encodeURIComponent(assignedQuery)}`;
	const status = `&clearanceStatus=${encodeURIComponent(statusQuery)}`;
	const submission = `&overallSubmissionStatus=${encodeURIComponent(
		submissionQuery,
	)}`;
	const flow = `&flow=${encodeURIComponent(flowQuery)}`;
	const company = `&company=${encodeURIComponent(companyQuery)}`;

	return axiosInstance()
		.get(
			`admin/applicants?page=${page}${sort}${bucketParam}${find}${findBy}${direction}${assigned}${status}${submission}${flow}${company}`,
		)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const updateApplicant = (
	workerUuid: string,
	payload: Partial<IApplicant>,
): Promise<{ data: IApplicant }> => {
	return axiosInstance()
		.patch(`admin/applicants/${workerUuid}`, payload)
		.then((data) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const addApplicant = (payload: INewCandidate): Promise<any> => {
	return axiosInstance()
		.post(`admin/applicants`, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
export const checkPassportDetails = (payload: INewCandidate): Promise<any> => {
	return axiosInstance()
		.post(`admin/applicants/details-match`, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const updateApplicantApi = (
	uuid: string,
	payload: Partial<IApplicant>,
): Promise<{ data: IApplicant; status: number }> => {
	return axiosInstance()
		.patch(`admin/applicants/${uuid}/field`, payload)
		.then((data) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getApplicant = (
	workerUuid: string,
): Promise<{ data: IApplicant }> => {
	return axiosInstance()
		.get(`admin/applicants/${workerUuid}`)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getAmbassadorApplicant = (
	uuid: string,
): Promise<{ data: IApplicant[]; status: number }> => {
	return axiosInstance()
		.get(`admin/applicants/${uuid}/ambassador`)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getOverallSubmission = (
	uuid: string,
): Promise<{ data: IOverallSubmissionResponse }> => {
	return axiosInstance()
		.get(`admin/applicants/${uuid}/overall-submission`)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getApplicantDataByWorkerUuid = (
	workerUuid: string,
	type: string,
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/${type}`;

	return axiosInstance()
		.get(url)
		.then((data) => {
			return data.status === 404 ? data : data.data.data;
		})
		.catch((err) => {
			throw err;
		});
};

export const updateApplicantDetails = (
	workerUuid: string,
	payload: {
		id: number;
		checklistValue: number;
	},
	type: string,
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/${type}`;

	return axiosInstance()
		.patch(url, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getAllLicences = (
	workerUuid: string,
	payload: ILicencePayload,
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/licences`;

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const saveLicences = (
	workerUuid: string,
	payload: { qualifications: ILicenceItem[] },
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/licences/save`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const updateOverallSubmission = (
	workerUuid: string,
	payload: {
		uuid: string;
		status: string;
	},
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/overall-submission`;

	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const updateWorkHistory = (
	workerUuid: string,
	workHistoryItemUuid: string,
	payload: Partial<WorkHistoryItem>,
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/work-history/${workHistoryItemUuid}`;
	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const getOriginalActivityHistory = (
	workerUuid: string,
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/work-history/original`;

	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const createWorkHistoryItem = (
	workerUuid: string,
	payload: Partial<WorkHistoryItem>,
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/work-history`;

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const deleteWorkHistoryItem = (
	workerUuid: string,
	activityUuid: string,
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/work-history/${activityUuid}`;

	return axiosInstance()
		.delete(url)
		.catch((err) => {
			throw err;
		});
};

export const getFaceToFaceStatus = (workerUuid: string): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/face-to-face`;

	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const updateFaceToFaceStatus = (
	workerUuid: string,
	payload: {
		status: string;
		workerUuid: string;
	},
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/face-to-face`;

	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const toggleFileOwnerEndpoint = (
	toggle: 'add' | 'remove',
	workerUuid: string,
	payload: { companyUuid: string },
): Promise<any> => {
	const url = `admin/applicants/${workerUuid}/${toggle}-file-owner`;

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const getProjectIds = (): Promise<any> => {
	return axiosInstance()
		.get('/admin/timekit/projects')
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const applicantByNi = (nI: NiNumber): Promise<any> => {
	const payload = { nationalInsuranceNumber: nI };
	const url = `admin/applicant/exists`;

	return axiosInstance()
		.post(url, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
