import React, { FC } from 'react';
import './external-passport-card.styles.scss';
import deploiLogo from '../../../../assets/logos/deploi-logo-sm.svg';
import { PassportDetails } from '../../../../types/interface';

type Props = {
	halfCard?: boolean;
	passportDetails: PassportDetails;
};

const ExternalPassportCard: FC<Props> = ({ halfCard, passportDetails }) => {
	return (
		<div
			className={`passport-card-wrapper ${
				halfCard ? 'passport-half' : 'passport-full'
			}`}
		>
			<div className='passport-card'>
				<p className='passport-title text-right'>
					Passport <img src={deploiLogo} alt='deploi logo' />
				</p>
				<p className='passport-title'>{passportDetails?.NiNumber}</p>

				<div className='passport-wrapper-details'>
					<div className='left-column'>
						<p className='orka-card-label'>First Name (s)</p>
						<p className='orka-card-text'>
							{passportDetails?.firstName}
						</p>

						<p className='orka-card-label'>Date of birth</p>
						<p className='orka-card-text'>
							{
								passportDetails?.DOB instanceof Date
									? passportDetails?.DOB.toDateString() // Handle Date case
									: passportDetails?.DOB || 'N/A' // Handle string or undefined case
							}
						</p>

						<p className='orka-card-label'>Phone number</p>
						<p className='orka-card-text'>
							{passportDetails?.contactNumber}
						</p>
					</div>
					<div>
						<p className='orka-card-label'>Last Name</p>
						<p className='orka-card-text'>
							{passportDetails?.lastName}
						</p>

						<p className='orka-card-label'>Email</p>
						<p className='orka-card-text'>
							{passportDetails?.email}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExternalPassportCard;
