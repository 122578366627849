import './external-user-profile.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import { setAlert } from '../../redux/General/general.actions';
import { IStageDataItem } from '../../types/interfaces/stage.interface';
import {
	clearProfileData,
	getAmbassadorCheckStatus,
	getChecklist,
	getLicenses,
	selectApplicant,
	setAddressHistory,
	setCriminalBankruptcy,
	setNameHistory,
	setOverallSubmission,
	setStageData,
} from '../../redux/Profile/profile.actions';
import {
	getStatusBackgroundColour,
	getStatusClassName,
	getStatusExplainerText,
	mapStatusTab,
	UserProfileInfoToolTip,
} from '../../utils/externalUtilsFunction';
import {
	getFiles,
	setShareCodeData,
	setYotiDetails,
} from '../../redux/Profile/profile.actions';

import * as apiId from '../../api/api.identity';
import * as docsApi from '../../api/api.docs';
import * as identityApi from '../../api/api.identity';
import * as apiApplicant from '../../api/api.applicant';
import * as apiStage from '../../api/api.stage';
import * as apiRtwVerified from '../../api/api.rtw-verified';
import * as utils from '../../utils/utilsFunctions';

import useGetWorkHistory from '../../utils/useGetWorkHistory.component';
import ExternalUserProfileTabs from '../../components/external-user-profile/external-user-profile-tabs/external-user-profile-tabs.component';
import ExternalProfileSidebar from '../../components/external-user-profile/external-profile-sidebar/external-profile-sidebar.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import { IApplicant, YotiDocumentDetails } from '../../types/interface';
import InfoIconRtw from '../../assets/icons/info-icon-rtw.svg';
import InfoIconBs7858 from '../../assets/icons/info-icon-bs7858.svg';
import InfoIconRtwBs7858 from '../../assets/icons/info-icon-rtwbs7858.svg';
import { IYotiData } from '../../types/interfaces/rtw.interface';
import { setRtwVerifiedData } from '../../redux/Profile/profile.actions';
import { IFileItem, YotiDocs } from '../../types/interfaces/document.interface';
import { CheckType, RtwDocType } from '../../types/enum';

const ExternalUserProfile: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { state }: any = useLocation();

	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

	const role = useSelector((state: RootState) => state.auth.role);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	// File arrays
	const files = useSelector((state: RootState) => state.profile.files);

	const [rtwFilesArray, setRtwFilesArray] = useState<IFileItem[]>([]);
	const [rtwManualDocArray, setRtwManualDocArray] = useState<IFileItem[]>([]);
	const [addressFileArray, setAddressFileArray] = useState<IFileItem[]>([]);
	const [additionalFileArray, setAdditionalFileArray] = useState<IFileItem[]>(
		[],
	);
	const [yotiFaceToFaceArray, setYotiFaceToFaceArray] = useState<YotiDocs[]>(
		[],
	);
	const [yotiRtwArray, setYotiRtwArray] = useState<YotiDocs[]>([]);
	const [faceToFaceFileArray, setFaceToFaceFileArray] = useState<IFileItem[]>(
		[],
	);
	const [niFileArray, setNiFileArray] = useState<IFileItem[]>([]);
	const [yotiDocumentDetails, setYotiDocumentDetails] =
		useState<YotiDocumentDetails>();

	const [uuid, setUuid] = useState('');
	
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [reviewModalType, setReviewModalType] = useState<RtwDocType>(RtwDocType.NONE);

	useEffect(() => {
		if (applicant.workerUuid) {
			getDocuments();
			getYotiDetails();
			getYotiDocuments();
			getShareCodeDetails();
		}
	}, [applicant.workerUuid, dispatch, applicant.uuid]);

	useEffect(() => {
		const processFiles = () => {
			const rtwFiles: IFileItem[] = [];
			const rtwManualDocFiles: IFileItem[] = [];
			const addressFiles: IFileItem[] = [];
			const additionalFiles: IFileItem[] = [];
			const faceToFaceFiles: IFileItem[] = [];
			const niFiles: IFileItem[] = [];
			if (files && files.length > 0) {
				let allFiles = files.map((file: any) => {
					if (typeof file.tags === 'string') {
						file.tags = file.tags
							.replace(/(?!,)\W/g, '')
							.split(',');
					}
					file.status = file.tags[0];
					file.docType = file.tags[1];
					return file;
				});

				allFiles = allFiles.filter((file) => file.deletedAt === null);
				allFiles.forEach((f) => {
					if (f.docType === 'rightToWork') {
						rtwFiles.push(f);
					} else if (f.docType === 'rightToWorkManual') {
						rtwManualDocFiles.push(f);
					} else if (
						f.docType === 'proofOfCurrentAddress' ||
						f.docType === 'proofOfAddress'
					) {
						addressFiles.push(f);
					} else if (f.docType === 'additionalDocs') {
						additionalFiles.push(f);
					} else if (
						f.docType === 'proofOfNINumber' ||
						f.docType === 'proofOfNI'
					) {
						niFiles.push(f);
					} else if (f.docType === 'faceToFace') {
						faceToFaceFiles.push(f);
					}
				});
				setRtwFilesArray(rtwFiles);
				setRtwManualDocArray(rtwManualDocFiles);
				setAddressFileArray(addressFiles);
				setAdditionalFileArray(additionalFiles);
				setNiFileArray(niFiles);
				setFaceToFaceFileArray(faceToFaceFiles);
			}
		};
		processFiles();
	}, [JSON.stringify(files)]);
	const getYotiDetails = () => {
		apiId
			.yotiDetails(applicant.workerUuid)
			.then(({ data }) => {
				const { candidate } = data.data || '';
				if (candidate) {
					const yotiData: IYotiData = {
						override: candidate.override,
						flow: candidate.yotiSession.flow,
						sessionStatus: candidate.yotiSession.sessionStatus,
						sessionId: candidate.yotiSession.sessionId,
						sessionUuId: candidate.yotiSession.uuid,
					};
					dispatch(setYotiDetails(yotiData));
					if (
						candidate.yotiSession &&
						candidate.yotiSession.details
					) {
						setYotiDocumentDetails(candidate.yotiSession.details);
					}
				}
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to get RTW details',
						isVisible: false,
					}),
				);
			});
	};

	const getYotiDocuments = () => {
		apiId
			.yotiDocuments(applicant.workerUuid)
			.then(({ data }) => {
				const { yotiRtwArray, yotiFaceToFaceArray } =
					utils.mapYotiDocsImages(data);
				setYotiRtwArray(yotiRtwArray);
				setYotiFaceToFaceArray(yotiFaceToFaceArray);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error getting yoti documents',
						isVisible: true,
					}),
				);
			});
	};

	const getDocuments = () => {
		docsApi
			.getDocuments(applicant.workerUuid)
			.then(({ data }) => {
				const files = data.data.filter(
					(file: IFileItem) => file.deletedAt === null,
				);
				dispatch(getFiles(files));
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error getting documents',
						isVisible: false,
					}),
				);
			});
	};

	const getShareCodeDetails = () => {
		identityApi
			.getShareCode(applicant.workerUuid)
			.then((res) => {
				const data = res.status === 404 ? null : res.data.data;
				dispatch(setShareCodeData(data));
			})
			.catch((err) => {
				throw err;
			});
	};
	
	const toggleModal = (isOpen: boolean) => {
		if (!isOpen) {
			setIsModalVisible(true);
		} else {
			setIsModalVisible(false);
		}
    };

 	useGetWorkHistory(applicant.workerUuid);

	const getInfoIconSrc = (status: string) => {
		switch (status) {
			case CheckType.RTW:
				return InfoIconRtw;
			case CheckType.BS7858:
				return InfoIconBs7858;
			case CheckType.RTWBS7858:
				return InfoIconRtwBs7858;
			default:
				return '';
		}
	};

	const getStatus = (applicant: IApplicant, statusType: string) => {
		let badgeStyles: { outerClassName: string, innerClassName: string, badgeText: string, badgeStyle: string, infoIconSrc?: string, badgeExplainer?: string };
		if (statusType === 'applicantStatus') {
			badgeStyles = {
				outerClassName: 'external-user-profile-status-type',
				innerClassName: getStatusClassName(
					applicant.submissionStatusTab,
				),
				badgeText: mapStatusTab(applicant.submissionStatusTab),
				badgeStyle: getStatusBackgroundColour(
					applicant.submissionStatusTab,
				),
			}
		} else {
			badgeStyles = {
				outerClassName: 'check-status-type external-user-profile-status-type',
				innerClassName: applicant.checkType,
				badgeText: mapStatusTab(applicant.checkType),
				badgeStyle: getStatusBackgroundColour(
					applicant.checkType,
				),
				infoIconSrc: getInfoIconSrc(applicant.checkType),
				badgeExplainer: getStatusExplainerText(applicant.checkType),
			}
		}
		return (
			<div
				className={badgeStyles.outerClassName}
				style={{ backgroundColor: `${badgeStyles.badgeStyle}` }}
			>
				<span className={badgeStyles.innerClassName}>
					{badgeStyles.badgeText}
				</span>
				{statusType === 'checkType' && <img src={badgeStyles.infoIconSrc} className='explainer-info-icon' />}
			</div>
		)
	};

	const handleReviewModalType = (reviewModalType: RtwDocType) => {
		setReviewModalType(reviewModalType)
	};

	useEffect(() => {
		if (state && state.uuid) {
			setUuid(state.uuid);
		} else if (!state && isLoggedIn && window.location.pathname) {
			const uuid = window.location.pathname.replace(
				/\/check\/user\//,
				'',
			);
			setUuid(uuid);
		} else {
			navigate('/login', { replace: true });
			return;
		}
	}, []);

	useEffect(() => {
		if (uuid !== applicant.uuid) dispatch(clearProfileData());
	}, [uuid]);

	useEffect(() => {
		if (uuid) {
			apiApplicant
				.getApplicant(uuid)
				.then((res) => {
					dispatch(selectApplicant(res.data));
				})
				.catch(() => {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to get applicant',
							isVisible: true,
						}),
					);
				});
		}
	}, [dispatch, isLoggedIn, role, uuid]);

	useEffect(() => {
		if (
			applicant &&
			applicant.workerUuid &&
			applicant.uuid &&
			applicant.uuid === uuid
		) {
			const getApplicantData = async () => {
				const [
					overallSubmissionRes,
					checklistRes,
					licencesRes,
					nameHistoryRes,
					addressHistoryRes,
					criminalRecordRes,
					ambassadorCheckStatus,
					stageData,
				] = await Promise.all([
					apiApplicant
						.getOverallSubmission(applicant.uuid)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'checklist',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'licences',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'name-history',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'address-history',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'worker-declaration',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'face-to-face',
						)
						.catch((err) => {
							throw err;
						}),
					apiStage.getStageData(applicant.workerUuid).catch((err) => {
						throw err;
					}),
				]);

				const actionList = [
					setOverallSubmission(overallSubmissionRes),
					getChecklist(checklistRes),
					getLicenses(licencesRes),
					setNameHistory(
						utils.sortByDate(
							nameHistoryRes.status !== 404 ? nameHistoryRes : [],
						),
					),
					setAddressHistory(
						utils.sortByDate(
							addressHistoryRes.status !== 404
								? addressHistoryRes
								: [],
						),
					),
					setCriminalBankruptcy(criminalRecordRes),
					getAmbassadorCheckStatus(ambassadorCheckStatus),
					setStageData(
						stageData.data.map((stage: IStageDataItem) => {
							if (stage.stageComplete === null) {
								stage.stageComplete = false;
							}
							return stage;
						}),
					),
				];

				actionList.forEach((action) => {
					dispatch(action);
				});
			};
			const getRtwVerified = async () => {
				const rtwVerified = await apiRtwVerified
					.getCandidateRtwStatus({applicantUuid: uuid})
					.then((res: any) => {return res.data.data});
				if (rtwVerified.status === true) {
					dispatch(
						setRtwVerifiedData({
							...rtwVerified,
						})
					);
				}
			};
			getApplicantData();
			getRtwVerified();
		}
	}, [applicant.uuid, applicant.workerUuid, dispatch, role]);

	return (
		<>
			<div className='external-user-profile-container'>
				<div className='external-user-profile-header-container'>
					<div className='external-user-profile-header'>
						{applicant.name + ' ' + applicant.surname}
					</div>
					<div className='external-user-profile-statuses'>
						<UserProfileInfoToolTip 
							title={getStatusExplainerText(applicant.checkType)}
							placement='bottom-start'
						>
							{applicant.checkType && getStatus(applicant, 'checkType')}
						</UserProfileInfoToolTip>
						{getStatus(applicant, 'applicantStatus')}
					</div>
				</div>
				<ErrorBoundary
					applicantWorkerUuid={
						applicant ? applicant.workerUuid : 'None'
					}
				>
					<ExternalUserProfileTabs 
						toggleModal={toggleModal} 
						handleReviewModalType={handleReviewModalType}
						rtwFilesArray={rtwFilesArray}
						rtwManualDocArray={rtwManualDocArray}
						addressFileArray={addressFileArray}
						additionalFileArray={additionalFileArray}
						yotiFaceToFaceArray={yotiFaceToFaceArray}
						yotiRtwArray={yotiRtwArray}
						yotiDocumentDetails={yotiDocumentDetails}
						faceToFaceFileArray={faceToFaceFileArray}
						niFileArray={niFileArray}
					/>
				</ErrorBoundary>
			</div >
			<ErrorBoundary
				applicantWorkerUuid={applicant ? applicant.workerUuid : 'None'}
			>
				<ExternalProfileSidebar 
					reviewModalType={reviewModalType}
					isModalVisible={isModalVisible}
					rtwFilesArray={rtwFilesArray}
					niFileArray={niFileArray}

				/>
			</ErrorBoundary>
		</>
	);
};

export default ExternalUserProfile;
