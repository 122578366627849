import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './dashboard-row-assigned.styles.scss';
import plusCircle from '../../../assets/icons/dashboard/plus-circle.svg';
import AssignedDropdown from '../../general/assigned-dropdown/assigned-dropdown.component';
import { IApplicant } from '../../../types/interface';
import { IStaffMember } from '../../../types/interfaces/staff.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import moment from 'moment';

type Props = {
	applicantFromDash?: IApplicant;
	applicantNumber?: number;
	applicantLength?: number;
	fromProfile?: boolean;
	setCancelClick: Dispatch<SetStateAction<boolean>> | null;
};

const DashboardRowAssigned: FC<Props> = ({
	applicantFromDash,
	applicantNumber = 0,
	applicantLength = 20,
	fromProfile = false,
	setCancelClick,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [assignees, setAssignees] = useState<IStaffMember[]>([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const globalStaff = useSelector((state: RootState) => state.auth.staff);
	const [staff, setStaff] = useState<IStaffMember[]>([]);
	const [extraAssigned, setExtraAssigned] = useState<IStaffMember[]>();
	const [lastChased, setLastChased] = useState('');

	useEffect(() => {
		if (globalStaff.length > 0) {
			const staffCopy = globalStaff.map((item) => {
				return {
					...item,
				};
			});
			setStaff(staffCopy);
		}
	}, [staff.length]);

	useEffect(() => {
		if (
			applicantFromDash &&
			applicantFromDash.assigned &&
			staff.length > 0
		) {
			const findSelectedStaff = staff.map((staffMember: IStaffMember) => {
				if (
					applicantFromDash.assigned.find(
						(el: IStaffMember) => el.email === staffMember.email,
					)
				) {
					const foundAssigned = applicantFromDash.assigned.find(
						(el: IStaffMember) => el.email === staffMember.email,
					);
					staffMember.selected = true;
					if (foundAssigned) {
						staffMember.assignedUuid = foundAssigned.uuid;
					}
				} else {
					staffMember.selected = false;
				}
				return staffMember;
			});

			const filteredSelectedApplicants = findSelectedStaff.filter(
				(staffMember: IStaffMember) => {
					return staffMember.selected;
				},
			);

			setStaff(findSelectedStaff);
			setAssignees(filteredSelectedApplicants);
		}
	}, [staff.length, JSON.stringify(applicantFromDash?.assigned)]);

	useEffect(() => {
		switch (assignees.length) {
			case 0:
			case 1:
			case 2:
				setExtraAssigned([]);
				break;
			default:
				setExtraAssigned(assignees.slice(2));
				break;
		}
	}, [assignees.length]);

	useEffect(() => {
		if (setCancelClick !== null) {
			showDropdown ? setCancelClick(true) : setCancelClick(false);
		}
	}, [showDropdown]);

	useEffect(() => {
		if (applicantFromDash && applicantFromDash.lastChased) {
			const days = moment().diff(
				new Date(applicantFromDash.lastChased),
				'days',
			);

			setLastChased(`${days} ${days === 1 ? 'day' : 'days'} ago`);
		} else {
			setLastChased('');
		}
	}, []);

	const getPosition = () => {
		let position;
		if (applicantNumber > 2 && applicantLength - applicantNumber <= 4) {
			position = 'top-center';
		} else {
			position = 'bottom-center';
		}

		return position;
	};

	const handleClicked = (event: any) => {
		event.stopPropagation();
		if (!showDropdown) {
			setShowDropdown(true);
		}
		if (setCancelClick !== null) {
			setCancelClick(true);
		}
	};

	return (
		<div className='dashboard-row-assigned-container'>
			{assignees.length === 0 ? (
				<img
					src={plusCircle}
					alt='open assigned'
					onClick={handleClicked}
					className='open-assigned'
				/>
			) : (
				<div className='row-assigned-section' onClick={handleClicked}>
					{assignees.map((assignee: IStaffMember, index) => {
						if (index < 2) {
							return (
								<div
									key={assignee.uuid}
									className='row-assigned-circle'
								>
									<p className={assignee.color}>
										{assignee.name[0].toUpperCase() +
											assignee.surname[0].toUpperCase()}
									</p>
								</div>
							);
						}
					})}
					{extraAssigned && extraAssigned.length > 0 && (
						<p className='extra-assigned'>
							+ {extraAssigned.length}
						</p>
					)}
				</div>
			)}
			{!fromProfile && (
				<div className='last-chased-container'>
					<p>{lastChased}</p>
				</div>
			)}
			{showDropdown && staff.length > 0 && (
				<AssignedDropdown
					applicantFromDash={applicantFromDash}
					setShowDropdown={setShowDropdown}
					position={getPosition()}
					showDropdown={showDropdown}
					setAssignees={setAssignees}
					assignees={assignees}
					availableStaff={staff}
					setStaff={setStaff}
					fromProfile={fromProfile}
					setCancelClick={setCancelClick}
				/>
			)}
		</div>
	);
};

export default DashboardRowAssigned;
