import React, { useRef, FC } from 'react';
import ProfilePrimaryContainer from '../profile-primary-container/profile-primary-container.component';
import ProfileSecondaryContainer from '../profile-secondary-container/profile-secondary-container.component';
import './profile-container.styles.scss';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../../general/error-boundary/error-boundary.component';
import { RootState } from '../../../types/root-state';
import ProfileHeader from '../profile-header/profile-header.component';

const ProfileContainer: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const profileRef = useRef(null);

	return (
		<div className='profile-container' ref={profileRef}>
			<ErrorBoundary
				applicantWorkerUuid={applicant ? applicant.workerUuid : 'None'}
			>
				<ProfileHeader />
			</ErrorBoundary>
			<ErrorBoundary
				applicantWorkerUuid={applicant ? applicant.workerUuid : 'None'}
			>
				<ProfilePrimaryContainer />
			</ErrorBoundary>

			<ErrorBoundary
				applicantWorkerUuid={applicant ? applicant.workerUuid : 'None'}
			>
				<ProfileSecondaryContainer />
			</ErrorBoundary>
		</div>
	);
};

export default ProfileContainer;
