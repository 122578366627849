import {
	AUTH,
	SET_USER,
	CLEAR_AUTH,
	LOGIN,
	CLEAR_LOGGED_IN,
	SET_ROLE,
	SET_ALL_STAFF,
	SET_ALL_COMPANY,
} from './auth.types';

export const setUser = (user) => {
    return {
        type: SET_USER,
        user
    }
}

export const clearAuth = () => {
    return {
        type: CLEAR_AUTH
    }
}

export const login = () => {
    return {
        type: LOGIN
    };
};

export const clearLogin = () => {
    return {
        type: CLEAR_LOGGED_IN
    };
};

export const setRole = (role) => {
    return {
        type: SET_ROLE,
        role
    };
};

export const setAllStaff = (staff) => {
	return {
		type: SET_ALL_STAFF,
		staff,
	};
};

export const setAllCompany = (company) => {
	return {
		type: SET_ALL_COMPANY,
		company,
	};
};
