import React, { FC } from 'react';
import { Text, StyleSheet, Image } from '@react-pdf/renderer';
import * as utils from '../../../utils/utilsFunctions';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';
import completeTick from '../../../assets/icons/certificate/circle-tick.png';
import incompleteTick from '../../../assets/icons/certificate/incomplete.png';
const styles = StyleSheet.create({
	section: {
		display: 'flex',
	},
	certificateItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '8px 0',
		borderBottom: '1px',
		borderStyle: 'solid',
		borderColor: '#FFFFFF',
	},
	certificateItemRight: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	textBold: {
		fontSize: '10px',
		fontWeight: 700,
		marginRight: '24px',
	},
	textNormal: {
		fontSize: '10px',
	},
	incomplete: {
		color: '#9AA5B1',
	},
	tick: {
		width: '15px',
		height: '10px',
		paddingRight: '5px',
	},
});

type Props = {
	stageItem: IStageDataItem;
};

const CertificateItem: FC<Props> = ({ stageItem }) => {
	return (
		<div style={styles.certificateItem}>
			<div>
				<Text
					style={{
						...styles.textBold,
						color: stageItem.stageComplete ? 'black' : '#9AA5B1',
					}}
				>
					{stageItem.title}
				</Text>
			</div>
			<div style={styles.certificateItemRight}>
				<Image 
					src={stageItem.stageComplete ? completeTick : incompleteTick}
					style={styles.tick}
				/>
				<Text
					style={{
						...styles.textBold,
						color: stageItem.stageComplete ? 'black' : '#9AA5B1',
					}}
				>
					{stageItem.stageComplete ? 'Complete' : 'Incomplete'}
				</Text>
				<Text style={{ ...styles.textNormal, minWidth: '80px' }}>
					{stageItem.stageCompletedAt
						? utils.formatDateForUser(stageItem.stageCompletedAt)
						: ''}
				</Text>
			</div>
		</div>
	);
};

export default CertificateItem;
