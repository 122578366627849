import React, { FC } from 'react';
import './rtw-image-doc.styles.scss';

type Props = {
	imgSrc: string;
};

const RtwImageDoc: FC<Props> = ({ imgSrc }) => {
	return (
		<div className='rtw-manual-image-wrapper'>
			<a target={'_blank'} rel='noopener noreferrer' href={imgSrc}>
				<img src={imgSrc} />
			</a>
		</div>
	);
};

export default RtwImageDoc;
