import React, { FC } from 'react';
import './profile-tag.styles.scss';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import { IApplicant } from '../../../types/interface';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	type: string;
	text: string;
	cypressTag: string;
	applicant?: IApplicant;
};

const ProfileTag: FC<Props> = ({ type, text, cypressTag, applicant }) => {
	return (
		<>
			{text === 'Suspended' && applicant ? (
				<TooltipLg
					title={`Date Suspended: ${applicant.suspendedAt}. Message: ${applicant.suspendedMessage}`}
				>
					<div
						className={`profile-tag ${type}-profile-tag`}
						data-cy={cypressTag}
					>
						<p>{text}</p>
					</div>
				</TooltipLg>
			) : (
				<div
					className={`profile-tag
                    ${type}-profile-tag`}
					data-cy={cypressTag}
				>
					<p>{text}</p>
				</div>
			)}
		</>
	);
};

export default ProfileTag;
