import React, {
	FC,
	useState,
	useCallback,
	useEffect,
	Dispatch,
	SetStateAction,
} from 'react';
import EditInput from '../../general/edit-input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import debounce from 'lodash.debounce';
import './organisation-create.styles.scss';
import { IOrganization } from '../../../types/interfaces/reference.interface';
import companyExists from '../../../assets/icons/company-exists.svg';
import * as referenceApi from '../../../api/api.reference';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import plusCircleDark from '../../../assets/icons/activity/add-staff-dark.svg';
import { RootState } from '../../../types/root-state';
import { WorkHistoryItem } from '../../../types/interface';
import * as apiApplicant from '../../../api/api.applicant';
import { setShowActivity } from '../../../redux/Profile/profile.actions';
import FullPageLoader from '../../general/full-page-loader/full-page-loader.component';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	setRefetch?: Dispatch<SetStateAction<boolean>>;
	refetch?: boolean | null;
	prePopulatedItem?: ILocalState | null;
	activityItem?: WorkHistoryItem;
	edit?: boolean;
};

type ILocalState = {
	company: string;
	email: string;
	contactNumber: string;
	address: string;
	region: string;
	city: string;
	postcode: string;
	country: string;
};

const OrganisationCreate: FC<Props> = ({
	setShowModal,
	setRefetch,
	refetch,
	prePopulatedItem,
	activityItem,
	edit = false,
}) => {
	const [state, setState] = useState({
		company: '',
		email: '',
		contactNumber: '',
		address: '',
		region: '',
		city: '',
		postcode: '',
		country: '',
	});
	const [search, setSearch] = useState<string>('');
	const [organisations, setOrganisations] = useState<IOrganization[]>([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	useEffect(() => {
		if (prePopulatedItem) {
			setState(prePopulatedItem);
			handleSearch(prePopulatedItem.company);
		}
	}, [JSON.stringify(prePopulatedItem)]);

	const handleGetInfo = (val: string, input: string): void => {
		if (input === 'company') {
			handleSearch(val);
		}
		setState({
			...state,
			[input]: val,
		});
	};

	const checkDisabled = () => {
		return (
			!state.region || !state.email || !state.company || !state.postcode
		);
	};

	const sendQuery = (query: string) => {
		if (query) {
			referenceApi
				.getOrganisations(1, query)
				.then((res: any) => {
					if (res.data && res.data.data && res.data.data.items) {
						setOrganisations(res.data.data.items);
					} else {
						setOrganisations([]);
					}
				})
				.catch((err) => {
					throw err;
				});
		} else {
			setOrganisations([]);
		}
	};

	const delayedQuery = useCallback(
		debounce(() => {
			sendQuery(search);
		}, 500),
		[search],
	);

	useEffect(() => {
		delayedQuery();
		return delayedQuery.cancel;
	}, [search, delayedQuery]);

	const handleSearch = (val: string) => {
		setSearch(val);
	};

	const handleCreate = () => {
		return referenceApi
			.createOrganisation(state)
			.then(() => {
				if (refetch !== null && setRefetch !== undefined) {
					setShowModal(false);
					setRefetch(!refetch);
					setLoading(false);
					dispatch(
						setAlert({
							type: 'success',
							message: 'Created Organisation',
							isVisible: true,
						}),
					);
				}

				if (prePopulatedItem && activityItem) {
					const payload = {
						name: state.company,
						address: {
							streetAddress: state.address,
							city: state.city,
							postcode: state.postcode,
							country: state.country,
							region: state.region,
						},
						email: state.email,
						contactNumber: state.contactNumber,
						reference: {
							addedManually: false,
						},
					};
					return updateActivity(
						payload,
						activityItem.uuid,
						applicant.workerUuid,
					)
						.then(() => {
							setLoading(false);
							dispatch(
								setAlert({
									type: 'success',
									message:
										'Successfully verified this reference address',
									isVisible: true,
								}),
							);
						})
						.catch((err) => {
							setLoading(false);
							dispatch(
								setAlert({
									type: 'error',
									message: err.message,
									isVisible: true,
								}),
							);
						});
				}
			})
			.catch(() => {
				setLoading(false);
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error creating organisation',
						isVisible: true,
					}),
				);
			});
	};

	const getClass = (index: number) => {
		let classString = 'company-exists-container ';
		if (index === organisations.length - 1) {
			classString += ' margin-bottom-16';
		}
		if (prePopulatedItem) {
			classString += ' pre-populated';
		}

		return classString;
	};

	const handleAddToApplicant = (organisation: IOrganization) => {
		if (activityItem && applicant) {
			const payload = {
				name: organisation.company,
				address: {
					streetAddress: organisation.address,
					city: organisation.city,
					postcode: organisation.postcode,
					region: organisation.region,
					country: organisation.country,
				},
				email: organisation.email,
				contactNumber: organisation.contactNumber,
				reference: {
					addedManually: false,
				},
			};
			return updateActivity(
				payload,
				activityItem.uuid,
				applicant.workerUuid,
			);
		}
	};

	const handleUpdate = () => {
		referenceApi
			.updateOrganisation(state)
			.then(() => {
				if (setRefetch) {
					setShowModal(false);
					setRefetch(!refetch);
					setLoading(false);
					dispatch(
						setAlert({
							type: 'success',
							message: 'successfully updated Organisation',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const updateActivity = (
		payload: any,
		itemUuid: string,
		workerUuid: string,
	) => {
		return apiApplicant
			.updateWorkHistory(workerUuid, itemUuid, payload)
			.then(() => {
				const newActivity = {
					...activityItem,
					reference: {
						...activityItem?.reference,
					},
				};
				newActivity.address = payload.address;
				newActivity.name = payload.name;
				newActivity.email = payload.email;
				newActivity.contactNumber = payload.contactNumber;
				newActivity.reference = {
					...newActivity.reference,
					addedManually: false,
				};
				const data = {
					showActivity: true,
					activityItem: newActivity,
				};

				dispatch(setShowActivity(data));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Activity successfully updated',
						isVisible: true,
					}),
				);
				setShowModal(false);
			})
			.catch((err) => {
				throw err;
			});
	};

	return (
		<div>
			<div>
				<FullPageLoader loading={loading} />
				<div className='organisation-create-top'>
					<EditInput
						inputName='company'
						placeholder='Organisation Name'
						value={state.company ? state.company : ''}
						title='Organisation Name *'
						emitValue={handleGetInfo}
						autocomplete='off'
						type='search'
					/>
					{organisations.length > 0 &&
						organisations.map(
							(organisation: IOrganization, index: number) => {
								return (
									<div
										key={organisation.uuid}
										className={getClass(index)}
										onClick={() =>
											handleAddToApplicant(organisation)
										}
									>
										<div className='company-exists-info'>
											<img
												src={
													prePopulatedItem
														? plusCircleDark
														: companyExists
												}
												alt='alert'
											/>
											{prePopulatedItem ? (
												<p>Is this the company?</p>
											) : (
												<p>Company Exists</p>
											)}
										</div>
										<div>
											<p>
												<span className='name'>
													{organisation.company}
												</span>
												:&nbsp;
												{organisation.region}
											</p>
										</div>
										<div>
											<p>
												<span className='city'>
													{organisation.city}
												</span>
												&nbsp;
												{organisation.contactNumber}
											</p>
										</div>
									</div>
								);
							},
						)}

					<EditInput
						inputName='email'
						placeholder='Email'
						value={state.email ? state.email : ''}
						title='Email *'
						emitValue={handleGetInfo}
					/>

					<EditInput
						inputName='contactNumber'
						placeholder='Contact Number'
						value={state.contactNumber ? state.contactNumber : ''}
						title='Contact Number'
						emitValue={handleGetInfo}
					/>
				</div>

				<div className='organisation-create-bottom'>
					<EditInput
						inputName='address'
						placeholder='Address Line 1'
						value={state.address ? state.address : ''}
						title='Address Line 1'
						emitValue={handleGetInfo}
					/>

					<EditInput
						inputName='city'
						placeholder='City'
						value={state.city ? state.city : ''}
						title='City'
						emitValue={handleGetInfo}
					/>

					<EditInput
						inputName='region'
						placeholder='Region'
						value={state.region ? state.region : ''}
						title='Region *'
						emitValue={handleGetInfo}
					/>

					<div className='organisation-create-flex'>
						<div className='organisation-create-item'>
							<EditInput
								inputName='country'
								placeholder='Country'
								value={state.country ? state.country : ''}
								title='Country'
								emitValue={handleGetInfo}
							/>
						</div>

						<div className='organisation-create-item'>
							<EditInput
								inputName='postcode'
								placeholder='Postcode'
								value={state.postcode ? state.postcode : ''}
								title='Postcode *'
								emitValue={handleGetInfo}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='modal-btn-section'>
				<OrkaButton
					buttonContent={edit ? 'Update' : 'Create'}
					disabled={checkDisabled()}
					emitClicked={edit ? handleUpdate : handleCreate}
				/>
			</div>
		</div>
	);
};

export default OrganisationCreate;
