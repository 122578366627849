import React, { FC, useEffect, useState } from 'react';
import './share-code.styles.scss';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import * as utils from '../../../utils/utilsFunctions';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import EditInput from '../../general/edit-input/input.component';
import * as idApi from '../../../api/api.identity';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import edit from '../../../assets/icons/edit.svg';
import { setAlert } from '../../../redux/General/general.actions';
import { IDropdownStandard } from '../../../types/interface';
import { setShareCodeData } from '../../../redux/Profile/profile.actions';
import { IShareCodePayload } from '../../../types/interfaces/identity.interface';

const ShareCode: FC = () => {
	const dispatch = useDispatch();
	const [dropdownData, setDropdownData] = useState<IDropdownStandard[]>([]);
	const [defaultData, setDefaultData] = useState<IDropdownStandard>();
	const [openEditCalendar, setOpenEditCalendar] = useState(false);
	const { applicant, shareCodeData } = useSelector(
		(state: RootState) => state.profile,
	);

	useEffect(() => {
		setDropdownData(utils.getDropdownData('shareCodeActions', null));
	}, []);

	useEffect(() => {
		if (dropdownData.length > 0) {
			const foundStatus = dropdownData.filter((item: any) => {
				return item.dbValue === shareCodeData.status;
			});
			setDefaultData(foundStatus[0]);
		}
	}, [dropdownData.length]);

	const getDropdownVal = (val: IDropdownStandard) => {
		if (!shareCodeData.expiryDate) {
			dispatch(
				setAlert({
					type: 'error',
					message: 'Please first set expiry date of share code',
					isVisible: true,
				}),
			);
			return;
		}
		
		const payload: IShareCodePayload = {
			status: val.dbValue,
			expiryDate: shareCodeData.expiryDate,
		};

		return idApi
			.actionShareCode(payload, applicant.workerUuid, shareCodeData.uuid)
			.then((res) => {
				dispatch(setShareCodeData(res.data.data));
				setDefaultData(val);
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated share code information',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	const emitValue = (val: any) => {
		if (!val || (val && !val.isValid())) {
			return;
		}
		const payload = {
			expiryDate: utils.formatDateForDb(val),
			status: shareCodeData.status,
		};
		return idApi
			.actionShareCode(payload, applicant.workerUuid, shareCodeData.uuid)
			.then((res) => {
				dispatch(setShareCodeData(res.data.data));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated share code information',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	return (
		<div className='share-code-section'>
			<div>
				<ProfileDisplayItem
					label='Share code'
					value={shareCodeData.shareCode.toUpperCase()}
				/>

				<div>
					{openEditCalendar ? (
						<div className='share-code-edit-section share-calendar'>
							<EditInput
								title='Share code expiry date'
								value={
									shareCodeData.expiryDate
										? new Date(shareCodeData.expiryDate)
										: null
								}
								emitValue={emitValue}
								placeholder='Click Calendar'
								inputName='expiryDate'
							/>
							<img
								src={edit}
								alt='edit'
								onClick={() =>
									setOpenEditCalendar(!openEditCalendar)
								}
							/>
						</div>
					) : (
						<div className='share-code-edit-section'>
							<ProfileDisplayItem
								label='Share code expiry date'
								value={
									shareCodeData.expiryDate
										? utils.formatDateForUser(
												shareCodeData.expiryDate,
											)
										: 'None Set'
								}
							/>
							<img
								src={edit}
								alt='edit'
								onClick={() =>
									setOpenEditCalendar(!openEditCalendar)
								}
							/>
						</div>
					)}
				</div>
			</div>

			<div>
				{dropdownData.length > 0 && defaultData && (
					<div className='margin-bottom-16'>
						<p className='orka-profile-label margin-bottom-8'>
							Share code status
						</p>
						<Dropdown
							type='employmentType'
							dropdownData={dropdownData}
							defaultData={defaultData}
							getDropdownVal={getDropdownVal}
							width='180'
							position='bottom-center'
							height={24}
						/>
					</div>
				)}

				<ProfileDisplayItem
					label='Date of birth'
					value={
						shareCodeData.dob
							? utils.refactorHyphenDate(shareCodeData.dob)
							: 'No data'
					}
				/>
			</div>
		</div>
	);
};

export default ShareCode;
