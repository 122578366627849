import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './dashboard-row-content.styles.scss';
import { withStyles } from 'mui-styles';
import { Tooltip } from '@mui/material';
import { IApplicant } from '../../../types/interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import * as utils from '../../../utils/utilsFunctions';
import DashboardRowAssigned from '../dashboard-row-assigned/dashboard-row-assigned.component';
import DashboardNotes from '../dashboard-notes/dashboard-notes.component';
import DashboardTags from '../dashboard-tags/dashboard-tags.component';
import notCompliantDark from '../../../assets/icons/sidebar/not-compliant-dark.svg';
import pausedBlue from '../../../assets/icons/paused-blue.svg';
import fullScreening from '../../../assets/icons/dashboard/full-screening.svg';
import limitedScreening from '../../../assets/icons/dashboard/limited-screening.svg';
import noScreening from '../../../assets/icons/dashboard/no-screening.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	applicant: IApplicant;
	applicantNumber?: number;
	applicantLength?: number;
	setHideCancel: Dispatch<SetStateAction<boolean>>;
	setCancelClick: Dispatch<SetStateAction<boolean>>;
	cancelClick: boolean;
};

const DashboardRowContent: FC<Props> = ({
	applicant,
	applicantNumber,
	applicantLength,
	setHideCancel,
	setCancelClick,
	cancelClick,
}) => {
	const navigate = useNavigate();
	const dynamicTableData = useSelector(
		(state: RootState) => state.dashboard.dynamicTableData,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	// eslint-disable-next-line
	const [statusIcon, setStatusIcon] = useState(noScreening);
	const [city, setCity] = useState('');
	const [daysAgoSubmitted, setDaysAgoSubmitted] = useState('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [toolTip, setToolTip] = useState<string>(
		applicant.suspendedMessage ? applicant.suspendedMessage : '',
	);

	useEffect(() => {
		const getIcon = (): void => {
			switch (applicant.clearance?.clearanceStatus) {
				case 'fully_compliant':
					setStatusIcon(fullScreening);
					return;
				case 'partially_compliant':
					setStatusIcon(limitedScreening);
					return;
				default:
					setStatusIcon(noScreening);
					return;
			}
		};
		getIcon();
	}, [applicant.clearance]);

	useEffect(() => {
		if (applicant && applicant.address) {
			const currentAddress = utils.sortByDate(applicant.address)[0];
			if (currentAddress) {
				setCity(
					currentAddress.address.city
						? currentAddress.address.city
						: '',
				);
			}
		}
	}, [applicant.address && applicant.address.length]);

	useEffect(() => {
		if (
			applicant &&
			applicant.overallSubmission &&
			applicant.overallSubmission.createdAt
		) {
			const days = moment().diff(
				new Date(applicant.overallSubmission.createdAt),
				'days',
			);

			setDaysAgoSubmitted(`${days} ${days === 1 ? 'day' : 'days'} ago`);
		} else {
			setDaysAgoSubmitted('');
		}
	}, []);

	const handleSelectApplicant = () => {
		if (!cancelClick) {
			return navigate(`user/${applicant.uuid}`, {
				state: {
					workerUuid: applicant.workerUuid,
					uuid: applicant.uuid,
				},
			});
		} else {
			return;
		}
	};

	function getApplicantStatus(status: string): string {
		if (status === 'fully_compliant') {
			return '#c9fef8';
		} else if (status === 'partially_compliant') {
			return '#ffecb8';
		} else if (status === 'not_compliant' || !status) {
			return '#f7f7f7';
		} else {
			return '';
		}
	}

	function getApplicantStatusAcronym(status: string): string {
		if (status === 'fully_compliant') {
			return 'F';
		} else if (status === 'partially_compliant') {
			return 'LS';
		} else if (status === 'not_compliant' || !status) {
			return 'N';
		} else {
			//throw error - not regonised status
			return '';
		}
	}

	return (
		<div
			className={`dashboard-row-content ${
				cancelClick ? '' : 'dashboard-cursor-pointer'
			}`}
			onClick={handleSelectApplicant}
		>
			<div className='compliance-status-indicator'>
				<div className='col col-sm'>
					{!applicant.paused &&
						applicant?.overallSubmission?.status !==
							'cancelled' && (
							<div
								className='compliance-container'
								style={{
									backgroundColor: `${getApplicantStatus(
										applicant.clearance?.clearanceStatus ||
											'',
									)}`,
								}}
							>
								<span
									className={`status-letter-${getApplicantStatusAcronym(
										applicant.clearance?.clearanceStatus ||
											'',
									)}`}
								>
									{getApplicantStatusAcronym(
										applicant.clearance?.clearanceStatus ||
											'',
									)}
								</span>
							</div>
						)}
					{applicant?.overallSubmission?.status === 'cancelled' && (
						<TooltipLg
							title={
								applicant.cancelledNotes
									? applicant.cancelledNotes
									: ''
							}
						>
							<div className='pause-cancel-tag'>
								<img src={notCompliantDark} alt='cancelled' />
								<p>Cancelled</p>
							</div>
						</TooltipLg>
					)}
					{applicant.paused && (
						<TooltipLg
							title={
								applicant.pausedNotes
									? applicant.pausedNotes
									: ''
							}
						>
							<div className='pause-cancel-tag'>
								<img src={pausedBlue} alt='paused' />
								<p className='paused'>Paused</p>
							</div>
						</TooltipLg>
					)}
				</div>
				{/* blocked */}
				<TooltipLg
					title={`${utils
						.getDateFromNow(
							applicant.clearance?.provisionallyClearedDate,
							84,
						)
						.toLocaleDateString('eb-GB')}`}
					arrow
				>
					<div className='status-label'>
						{applicant.clearance?.clearanceStatus ===
							'partially_compliant' &&
							`${utils.calculateDaysLeftToClear(applicant)}`}
					</div>
				</TooltipLg>
			</div>

			<div className='col col-lg email'>
				<p className='orka-demi-p'>
					{utils.minimizeString(
						utils.getFullName(
							applicant.title,
							applicant.name,
							applicant.surname,
						),
						18,
					)}
				</p>
				<p className='orka-semi-p email-text mobile-hide'>
					{applicant.email}
				</p>
			</div>

			<div className='col col-md text-left'>
				<p className='orka-semi-p'>
					{utils.capitalizeFirstLetter(city)}
				</p>
			</div>

			{dynamicTableData && dynamicTableData.length > 0 && (
				<>
					{dynamicTableData[1].values.map(
						(value: keyof IApplicant) => {
							if (
								value === 'suspendedMessage' &&
								role !== 'ambassador'
							) {
								return (
									<div key={value} className='col-md'>
										<TooltipLg title={toolTip}>
											<p className='orka-semi-p tooltip'>
												{applicant[value]}
											</p>
										</TooltipLg>
									</div>
								);
							} else if (
								value === 'assigned' &&
								role !== 'ambassador'
							) {
								return (
									<div key={value} className='col-sm'>
										<DashboardRowAssigned
											applicantFromDash={applicant}
											applicantNumber={applicantNumber}
											applicantLength={applicantLength}
											setCancelClick={setCancelClick}
										/>
									</div>
								);
							} else if (
								value === 'overallSubmission' &&
								role !== 'ambassador'
							) {
								return applicant.overallSubmission ? (
									<div
										key={value}
										className='col-md submitted-date tablet-hide'
									>
										<p className='orka-demi-p'>
											{utils.formatDateForUser(
												applicant.overallSubmission
													.createdAt,
											)}
										</p>
										<p className='days-ago'>
											{daysAgoSubmitted}
										</p>
									</div>
								) : (
									<div
										key={value}
										className='col-md submitted-date tablet-hide'
									>
										<p className='orka-semi-p submitted-no-data'>
											No Data
										</p>
									</div>
								);
							} else if (
								value === 'notes' &&
								role !== 'ambassador'
							) {
								return (
									<div
										key={value}
										className='col-sm tablet-hide'
									>
										<DashboardNotes
											applicantFromDash={applicant}
											setHideCancel={setHideCancel}
											setCancelClick={setCancelClick}
										/>
									</div>
								);
							} else if (
								value === 'tags' &&
								role !== 'ambassador'
							) {
								return (
									<div
										key={value}
										className='col-md tablet-hide'
									>
										<DashboardTags applicant={applicant} />
									</div>
								);
							} else if (role !== 'ambassador') {
								return (
									<div
										key={value}
										className='col-md tablet-hide'
									>
										<p className='orka-semi-p'>
											{utils.capitalizeFirstLetter(
												utils.removeHyphen(
													applicant[value],
												),
											)}
										</p>
									</div>
								);
							}
						},
					)}
				</>
			)}
		</div>
	);
};

export default DashboardRowContent;
