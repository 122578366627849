import React, { useEffect, useState, FC } from 'react';
import './sign-up-inputs.scss';
import password from '../../../assets/icons/login/password.svg';
import phone from '../../../assets/icons/login/phone.svg';
import Input from '../login-input/login-input.component';
import * as authApi from '../../../api/api.auth';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import { SignUpCredentials } from '../../../types/interface';
import { useNavigate } from 'react-router-dom';

type Props = {
	email: string;
};

const SignUpInputs: FC<Props> = ({ email }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [validPassword, setValidPassword] = useState<boolean>(false);
	const [phoneValid, setPhoneValid] = useState<boolean>(false);
	const [passwordErrorClass, setPasswordErrorClass] = useState<string>('');
	const [phoneErrorClass, setPhoneErrorClass] = useState<string>('');

	const [state, setState] = useState<SignUpCredentials>({
		mobileNumber: '',
		password: '',
	});

	useEffect(() => {
		setValidPassword(validationHelper(state.password, 'password'));
	}, [state.password]);

	useEffect(() => {
		setPhoneValid(validationHelper(state.mobileNumber, 'phone'));
	}, [state.mobileNumber]);

	const handleSignUp = () => {
		if (!checkValidation()) {
			return;
		}

		const payload = {
			...state,
		};

		payload.email = email;

		authApi
			.create(payload)
			.then(() => {
				navigate('/login', { replace: true });
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	const getValue = (name: keyof SignUpCredentials, value: string) => {
		setState({
			...state,
			[name]: value,
		});
	};

	const checkValidation = () => {
		setPasswordErrorClass(
			!validPassword ? 'password-validation-error' : '',
		);
		setPhoneErrorClass(!phoneValid ? 'phone-validation-error' : '');
		return validPassword && phoneValid ? true : false;
	};

	const validationHelper = (val: string, type: string) => {
		const validation =
			type === 'password'
				? /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z\d\s:]).{8,}$/
				: /^(\+44)(\d{10})$/;
		const regex = new RegExp(validation);
		return regex.test(val) ? true : false;
	};

	return (
		<div className='sign-up-inputs-container'>
			<div className='inputs'>
				<Input
					name='password'
					getValue={getValue}
					value={state.password}
					placeholder='Create Password'
					icon={password}
					type='password'
					clearValidation={setPasswordErrorClass}
				/>
				<div className={`validation-info ${passwordErrorClass}`}>
					<p>
						Password must contain at least 1 number, 1 capital
						letter, 1 non-alphanumeric character and be between 8
						and be between 8 and 16 characters long.
					</p>
				</div>

				<Input
					name='mobileNumber'
					getValue={getValue}
					value={state.mobileNumber}
					placeholder='Phone Number'
					icon={phone}
					// validationClass={phoneErrorClass}
					clearValidation={setPhoneErrorClass}
				/>

				<div className={`validation-info ${phoneErrorClass}`}>
					<p>Phone number must begin +44.</p>
				</div>
			</div>

			<div className='sign-up-button'>
				<OrkaButton
					buttonContent='Sign Up'
					emitClicked={handleSignUp}
					disabled={false}
				></OrkaButton>
			</div>
		</div>
	);
};

export default SignUpInputs;
