import React, { useState, FC } from 'react';
import './guidance-toggle.styles.scss';
import ChevronIcon from '../../../assets/icons/chevron-down-purple.svg';
import { getGuidanceExplainerText } from '../../../utils/externalUtilsFunction';

type Props = {
	guidanceType: string;
}

const GuidanceToggle: FC<Props> = ({ guidanceType }) => {
	const [isOpen, setIsOpen] = useState(false);
	const collapsibleSectionStyle = { display: isOpen ? 'block' : 'none' };

	return (
		<div>
			<div className='guidance-toggle-container' onClick={() => setIsOpen(!isOpen)}>
				<div className='guidance-toggle-title' >
					<p className='guidance-toggle-text demi-bold'>Guidance</p>
					<img src={ChevronIcon} />
				</div>
				<div style={collapsibleSectionStyle}>
					<p className='guidance-toggle-text' >{getGuidanceExplainerText(guidanceType)}</p>
				</div>
			</div>
		</div>
	);
};

export default GuidanceToggle;
