import React, { useState, useEffect, FC, FormEvent } from 'react';
import ActivityAddress from '../activity-address/activity-address.component';
import ActivityDate from '../activity-dates/activity-date.component';
import ActivityMain from '../activity-main/activity-main.component';
import ProfileSection from '../profile-section/profile-section.component';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import {
	setShowActivity,
	setWorksHistory,
} from '../../../redux/Profile/profile.actions';
import * as apiApplicant from '../../../api/api.applicant';
import './activity-details.styles.scss';
import {
	WorkHistoryItem,
	ActivityFormState,
	IGap,
} from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as utils from '../../../utils/utilsFunctions';
import trashIcon from '../../../assets/icons/trash.svg';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';
import ActivityContact from '../activity-contact/activity-contact.component';
import ActivityDocuments from '../activity-documents/activity-documents.components';
import moment from 'moment';
import { FormStateType } from '../../../types/enum';

type Props = {
	item?: WorkHistoryItem;
};

const ActivityDetails: FC<Props> = ({ item = null }) => {
	const [formState, setFormState] = useState<ActivityFormState>({
		type: item ? item.type : '',
		isPartTime: item ? item.isPartTime : false,
		name: item ? item.name : '',
		jobTitle: item && item.jobTitle ? item.jobTitle : '',
		streetAddress:
			item && item.address && item.address.streetAddress
				? item.address.streetAddress
				: '',
		postcode:
			item && item.address && item.address.postcode
				? item.address.postcode
				: '',
		city:
			item && item.address && item.address.city ? item.address.city : '',
		startAt: item ? new Date(item.startAt) : null,
		endAt: item && item.endAt ? new Date(item.endAt) : null,
		reasonForLeaving:
			item && item.reasonForLeaving ? item.reasonForLeaving : null,
		reasonForLeavingText:
			item && item.reasonForLeavingText ? item.reasonForLeavingText : '',
		careerBreakReason:
			item && item.careerBreakReason ? item.careerBreakReason : '',
	});

	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [edit, setEdit] = useState(!!item);
	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);
	const [hasDateValidationError, setHasDateValidationError] =
		useState<boolean>(false);
	const [dateValidationErrorText, setDateValidationErrorText] =
		useState<string>('');
	const [referenceNotes, setReferenceNotes] = useState(
		item?.reference?.notes ? item?.reference?.notes : '',
	);

	useEffect(() => {
		if (item) {
			setEdit(true);
		} else {
			setEdit(false);
		}
	}, [item]);

	const emitValue = (val: string, inputName: keyof ActivityFormState) => {
		setFormState({
			...formState,
			[inputName]: val,
		});
	};

	const validateDates = () => {
		// if there is a start date end date cant be before
		if (formState.endAt && formState.startAt) {
			if (new Date(formState.startAt) > new Date(formState.endAt)) {
				setHasDateValidationError(true);
				setDateValidationErrorText(
					'Start Date cannot be after end date',
				);
				setTimeout(() => {
					setHasDateValidationError(false);
					setDateValidationErrorText('');
				}, 3000);
				return true;
			}
		}
		return false;
	};

	const updateActivity = (payload: any) => {
		return apiApplicant
			.updateWorkHistory(
				applicant.workerUuid,
				item ? item.uuid : '',
				payload,
			)
			.then(async (res) => {
				const data = {
					showActivity: true,
					activityItem: res.data.data,
				};
				if (
					Object.keys(payload)[0] === 'startAt' ||
					Object.keys(payload)[0] === 'endAt'
				) {
					await refetchWorkHistory();
				}
				dispatch(setShowActivity(data));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Updated activity item field',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating work history item',
						isVisible: true,
					}),
				);
			});
	};

	const buildPayload = (formObject: any) => {
		if (formObject.type === 'employed' || formObject.type === 'studying') {
			formObject.address = {
				streetAddress: formState.streetAddress
					? formState.streetAddress
					: null,
				city: formState.city ? formState.city : null,
				postcode: formState.postcode ? formState.postcode : null,
			};
		}

		if (
			(!formObject.endAt || isCurrentJob(formObject.endAt)) &&
			formObject.type === 'employed'
		) {
			formObject.reasonForLeaving = formState.reasonForLeaving;

			if (formState.reasonForLeaving === 'Other') {
				formObject.reasonForLeavingText =
					formState.reasonForLeavingText;
			}
		}

		if (formObject.type === 'employed') {
			formObject.name = formState.name;
			formObject.jobTitle = formState.jobTitle;
		}

		if (formObject.type === 'studying') {
			formObject.name = formState.name;
		}

		if (formObject.type === 'another-reason') {
			formObject.careerBreakReason = formState.careerBreakReason;
		}

		return formObject;
	};

	const isCurrentJob = (endAt: any) => {
		return (
			new Date(endAt).setHours(0, 0, 0, 0) <
			new Date().setHours(0, 0, 0, 0)
		);
	};

	const handleCreateActivity = () => {
		if (validateDates()) {
			return;
		}
		const formObject = {
			type: formState.type,
			isPartTime: formState.isPartTime,
			startAt: utils.formatDateForDb(formState.startAt),
			endAt: formState.endAt
				? utils.formatDateForDb(formState.endAt)
				: null,
			status: 'pending',
			workerUuid: applicant.workerUuid,
			canContactEmployer: true,
			reference: {
				addedManually: true,
			},
		};

		const payload = buildPayload(formObject);

		return apiApplicant
			.createWorkHistoryItem(applicant.workerUuid, payload)
			.then(async (res) => {
				if (res.status === 404) {
					throw res;
				}
				await refetchWorkHistory();
				setEdit(false);

				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully created activity',
						isVisible: true,
					}),
				);

				const data = {
					showActivity: true,
					activityItem: res.data.data,
				};

				dispatch(setShowActivity(data));
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	const handleDeleteActivity = () => {
		if (item) {
			return apiApplicant
				.deleteWorkHistoryItem(applicant.workerUuid, item.uuid)
				.then(async () => {
					await refetchWorkHistory();
					const data = {
						showActivity: false,
						activityItem: null,
					};

					dispatch(setShowActivity(data));
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully deleted activity',
							isVisible: true,
						}),
					);
				})
				.catch(() => {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to delete activity',
							isVisible: true,
						}),
					);
				});
		}
	};

	const refetchWorkHistory = () => {
		apiApplicant
			.getApplicantDataByWorkerUuid(applicant.workerUuid, 'work-history')
			.then((res) => {
				let workHistory = utils.sortByDate(res);
				workHistory = workHistory.map(
					(item: WorkHistoryItem | IGap, index: number) => {
						if (index >= 1) {
							findOverlap(item, workHistory[index - 1]);
						}
						return item;
					},
				);
				dispatch(setWorksHistory(workHistory));
			})
			.catch(() => {
				setWorksHistory([]);
			});
	};

	const findOverlap = (
		currentItem: WorkHistoryItem | IGap,
		nextItem: WorkHistoryItem | IGap,
	) => {
		const currentItemEnd = currentItem.endAt
			? moment(currentItem.endAt)
			: moment();
		const nextItemStart = moment(nextItem.startAt);

		currentItem.overlap = currentItemEnd > nextItemStart;
	};

	const handleGetValFromAlert = (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			return handleDeleteActivity();
		}
	};

	const handleNotesBlur = () => {
		const payload = {
			reference: {
				notes: referenceNotes,
			},
		};

		return updateActivity(payload).catch((err) => {
			throw err;
		});
	};

	const handleNotesChange = (event: FormEvent<HTMLTextAreaElement>) => {
		setReferenceNotes(event.currentTarget.value);
	};

	const checkIsDisabled = () => {
		let disabled = false;

		if (!formState.startAt || !formState.type) {
			disabled = true;
		}

		if (
			formState.type === FormStateType.EMPLOYED &&
			(!formState.name || !formState.jobTitle)
		) {
			disabled = true;
		}

		if (
			(formState.type === FormStateType.EMPLOYED ||
				formState.type === FormStateType.SELF_EMPLOYED) &&
			formState.isPartTime === null
		) {
			disabled = true;
		}

		if (formState.type === FormStateType.STUDYING && !formState.name) {
			disabled = true;
		}

		if (formState.type === FormStateType.EMPLOYED && formState.endAt) {
			if (!formState.reasonForLeaving) {
				disabled = true;
			}
			if (
				formState.reasonForLeaving === 'Other' &&
				!formState.reasonForLeavingText
			) {
				disabled = true;
			}
		}

		if (
			formState.type === FormStateType.ANOTHER_REASON &&
			!formState.careerBreakReason
		) {
			disabled = true;
		}
		return disabled;
	};

	return (
		<div className='activity-details-section'>
			<div className='activity-header'>
				<h2>Activity</h2>
				{edit && (
					<img
						src={trashIcon}
						alt='trash'
						onClick={() => setShowAlertConfirm(true)}
					/>
				)}
			</div>

			{showAlertConfirm && (
				<AlertConfirm
					message='Are you sure you want to delete this activity?'
					getVal={handleGetValFromAlert}
					confirm='Delete'
					reject='Cancel'
				/>
			)}

			<ActivityMain
				item={item}
				formState={formState}
				emitValue={emitValue}
				updateActivity={updateActivity}
				edit={edit}
			/>

			<ProfileSection title='Dates' hideSide={true}>
				<ActivityDate
					item={item}
					emitValue={emitValue}
					updateActivity={updateActivity}
					formState={formState}
					edit={edit}
					hasDateError={hasDateValidationError}
					dateValidationText={dateValidationErrorText}
				/>
			</ProfileSection>

			{edit && item?.reference && (
				<>
					{formState.type === 'studying' ||
						(formState.type === 'employed' && (
							<ProfileSection
								title='Reference Address'
								hideSide={true}
							>
								<ActivityAddress item={item} />
							</ProfileSection>
						))}

					<ProfileSection title='Contact Information' hideSide={true}>
						<ActivityContact
							activityItem={item}
							updateActivity={updateActivity}
							formStateType={formState.type}
						/>
					</ProfileSection>
				</>
			)}

			{edit && item && <ActivityDocuments item={item} />}

			{edit && item?.reference && (
				<ProfileSection title='Reference Notes' hideSide={true}>
					<div className='reference-notes'>
						<textarea
							onChange={handleNotesChange}
							onBlur={handleNotesBlur}
							value={referenceNotes}
						/>
					</div>
				</ProfileSection>
			)}
			{!edit && !item && (
				<div className='create-activity'>
					<OrkaButton
						buttonContent='Create'
						disabled={checkIsDisabled()}
						emitClicked={handleCreateActivity}
					/>
				</div>
			)}
		</div>
	);
};

export default ActivityDetails;
