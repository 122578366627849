import React, { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import CertificateInfoItem from '../certificate-info-item/certificate-info-item.component';
import './certificate-info.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import { IApplicant } from '../../../types/interface';

type RootState = {
	profile: {
		applicant: IApplicant;
	};
};

const CertificateInfo: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);

	const [dateCleared, setDateCleared] = useState('');

	useEffect(() => {
		switch (clearance?.clearanceStatus) {
			case 'fully_compliant':
				setDateCleared(
					utils.formatDateForUser(clearance.fullyClearedDate),
				);
				break;
			case 'partially_compliant':
				setDateCleared(
					utils.formatDateForUser(clearance.provisionallyClearedDate),
				);
				break;
			default:
				setDateCleared('N/A');
				break;
		}
	}, [
		clearance?.clearanceStatus,
		clearance?.fullyClearedDate,
		clearance?.provisionallyClearedDate,
	]);

	return (
		<div className='certificate-info-container'>
			<div className='cert-section-title'>
				<p>General Information</p>
			</div>
			<div className='certificate-info-container-main'>
				<div className='certificate-info-container-side'>
					<CertificateInfoItem
						label='Full Name'
						value={utils.getFullName(
							applicant.title,
							applicant.name,
							applicant.surname,
						)}
					/>
					<CertificateInfoItem
						label='NI Number'
						value={
							applicant.nationalInsuranceNumber
								? applicant.nationalInsuranceNumber.toUpperCase()
								: 'NI unknown'
						}
					/>
				</div>
				<div className='certificate-info-container-side'>
					<CertificateInfoItem
						label='Date started'
						value={utils.formatDateForUser(applicant.createdAt)}
					/>
					<CertificateInfoItem
						label='Date cleared'
						value={dateCleared}
					/>
				</div>
			</div>
			<div className='certificate-info-container-main'>
				<CertificateInfoItem label='Email' value={applicant.email} />
			</div>
		</div>
	);
};

export default CertificateInfo;
