import React, {
	useEffect,
	useState,
	FC,
	useRef,
	Dispatch,
	SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';

import DashboardHeader from '../dashboard-header/dashboard-header.component';
import DashboardPagination from '../dashboard-pagination/dashboard-pagination.component';
import DashboardRow from '../dashboard-row/dashboard-row.component';
import DashboardSubMenu from '../dashboard-sub-menu/dashboard-sub-menu';
import DashboardTableHeader from '../dashboard-table-header/dashboard-table.header.component';
import NotFound from '../../general/not-found/not-found.component';
import './dashboard-container.styles.scss';
import Layout from '../../general/layout-component/layout.component';
import { RootState } from '../../../types/root-state';

type Props = {
	showFilters: boolean;
	setShowFilters: Dispatch<SetStateAction<boolean>>;
};

const DashboardContainer: FC<Props> = ({ showFilters, setShowFilters }) => {
	const applicants = useSelector(
		(state: RootState) => state.dashboard.allApplicants,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const isLoading = useSelector(
		(state: RootState) => state.general.isLoading,
	);

	const [cancelClick, setCancelClick] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const dashboardContainerRef = useRef<HTMLDivElement>(null);

	const resizeFunction = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', resizeFunction);
		return () => window.removeEventListener('resize', resizeFunction);
	}, []);

	useEffect(() => {
		if (dashboardContainerRef.current !== null) {
			let subMenuHeight = '96px';

			if (windowWidth <= 1300) {
				subMenuHeight = '155px';
			} else if (windowWidth <= 1440) {
				subMenuHeight = '130px';
			}

			if (showFilters) {
				dashboardContainerRef.current.style.gridTemplateAreas =
					'"dashboard-header" "dashboard-sub-menu" "dashboard-main-content" "pagination-component"';
				dashboardContainerRef.current.style.gridTemplateRows = `${
					windowWidth >= 700 ? '96px' : '160px'
				} ${subMenuHeight} auto 64px`;
			} else {
				dashboardContainerRef.current.style.gridTemplateAreas =
					'"dashboard-header" "dashboard-main-content" "pagination-component"';
				dashboardContainerRef.current.style.gridTemplateRows = `${
					windowWidth >= 700 ? '96px' : '160px'
				} auto 64px`;
			}
		}
	}, [showFilters, windowWidth]);

	return (
		<Layout>
			<div className='dashboard-container' ref={dashboardContainerRef}>
				<DashboardHeader
					setShowFilters={setShowFilters}
					showFilters={showFilters}
				/>
				{showFilters && <DashboardSubMenu />}
				{!isLoading && (
					<>
						{applicants.length > 0 ? (
							<div className='dashboard-main-content'>
								{role !== 'ambassador' && (
									<DashboardTableHeader />
								)}
								{applicants.map((applicant, index) => {
									return (
										<div key={applicant.uuid}>
											<DashboardRow
												applicant={applicant}
												setCancelClick={setCancelClick}
												applicantNumber={index}
												cancelClick={cancelClick}
											/>
										</div>
									);
								})}
							</div>
						) : (
							<>
								{role !== 'ambassadors' && (
									<NotFound type='noUsers' />
								)}
							</>
						)}
						{role !== 'ambassadors' && <DashboardPagination />}
					</>
				)}
			</div>
		</Layout>
	);
};

export default DashboardContainer;
