import React, { FC } from 'react';
import './loading-spinner.styles.scss';
import deploiLogo from '../../../assets/logos/deploi-logo-sm.svg';

const LoadingSpinner: FC = () => {
	return (
		<div className='loader'>
			<div className='loading-anim'>
				<img src={deploiLogo} alt='deploi logo' />
			</div>
		</div>
	);
};

export default LoadingSpinner;
