import React, {
	useState,
	FC,
	SetStateAction,
	Dispatch,
	useContext,
} from 'react';
import './organisation-actions.styles.scss';
import editPencil from '../../../assets/icons/edit.svg';
import Modal from '../../modals/modal/modal.component';
import { IOrganization } from '../../../types/interfaces/reference.interface';
import OrganisationNotes from '../organisation-notes/organisation-notes.component';
import OrganisationInfo from '../organisation-info/organisation-info.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { WorkHistoryItem } from '../../../types/interface';
import * as apiApplicant from '../../../api/api.applicant';
import * as apiReferences from '../../../api/api.reference';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	backFromActivity,
	setShowActivity,
} from '../../../redux/Profile/profile.actions';
import FullPageLoader from '../../general/full-page-loader/full-page-loader.component';
import OrganisationCreate from '../organisation-create/organisation-create.component';
import trashBlue from '../../../assets/icons/trash-blue.svg';
import { handleAlerts } from '../../../utils/utilsFunctions';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../../redux/General/general.actions';
import OrganisationContext from '../../../context/OrganisationContext';

type Props = {
	organisation: IOrganization;
	fromActivity: boolean;
	setOrganisationState: Dispatch<SetStateAction<IOrganization>>;
	activityItem: WorkHistoryItem | null;
	setRefetch: Dispatch<SetStateAction<boolean>>;
	refetch: boolean;
};

const OrganisationActions: FC<Props> = ({
	organisation,
	setOrganisationState,
	fromActivity,
	activityItem,
	setRefetch,
	refetch,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showOrganisationNotes, setShowOrganisationNotes] =
		useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const activityActions = useContext(OrganisationContext);

	const handleAddToApplicant = () => {
		if (activityItem && applicant) {
			const payload = {
				name: organisation.company,
				address: {
					streetAddress: organisation.address,
					city: organisation.city,
					postcode: organisation.postcode,
					region: organisation.region,
				},
				email: organisation.email,
				contactNumber: organisation.contactNumber,
				reference: {
					addedManually: false,
				},
			};
			return updateActivity(
				payload,
				activityItem.uuid,
				applicant.workerUuid,
			);
		}
	};

	const updateActivity = (
		payload: any,
		itemUuid: string,
		workerUuid: string,
	) => {
		return apiApplicant
			.updateWorkHistory(workerUuid, itemUuid, payload)
			.then(() => {
				return apiApplicant
					.getApplicantDataByWorkerUuid(
						applicant.workerUuid,
						'work-history',
					)
					.then((res) => {
						const item = res.filter(
							(item: WorkHistoryItem) => item.uuid === itemUuid,
						);
						const newActivity = {
							...item[0],
						};

						newActivity.address = payload.address;
						newActivity.email = payload.email;
						newActivity.contactNumber = payload.contactNumber;
						newActivity.name = payload.name;
						newActivity.reference = {
							...newActivity.reference,
							addedManually: false,
						};

						setLoading(false);

						const data = {
							showActivity: true,
							activityItem: newActivity,
						};

						dispatch(setShowActivity(data));
						dispatch(backFromActivity(true));
						dispatch(
							setAlert({
								type: 'success',
								message: 'Updated organisation successfully',
								isVisible: true,
							}),
						);
						activityActions?.toggleOrganisationTable(false);
						navigate(`/check/user/${applicant.uuid}`);
					})
					.catch((err) => {
						setLoading(false);
						handleAlerts('error', err.message, dispatch);
						throw err;
					});
			})
			.catch((err) => {
				setLoading(false);
				handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleDeleteOrganisation = () => {
		return apiReferences
			.removeOrganisation(organisation.uuid)
			.then(() => {
				setRefetch(!refetch);
			})
			.catch((err: Error) => {
				handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	return (
		<div className='organisation-actions'>
			<button className='edit' onClick={handleDeleteOrganisation}>
				<img src={trashBlue} alt='delete organisation' />
			</button>
			<button
				className='edit'
				onClick={() => setShowOrganisationNotes(true)}
			>
				<img src={editPencil} alt='pencil' />
			</button>
			{fromActivity && (
				<OrkaButton
					buttonContent='Add'
					disabled={false}
					emitClicked={handleAddToApplicant}
				/>
			)}

			{showOrganisationNotes && (
				<Modal
					title='Organisation Information'
					setShowModal={setShowOrganisationNotes}
					showModal={showOrganisationNotes}
					modalContentHeight='80vh'
				>
					<button
						onClick={() => setShowEdit(!showEdit)}
						className='show-organisation-info-button'
					>
						{showEdit ? 'Show Info' : 'Edit Organisation'}
					</button>
					{showEdit ? (
						<OrganisationCreate
							setShowModal={setShowOrganisationNotes}
							prePopulatedItem={organisation}
							edit={true}
							refetch={refetch}
							setRefetch={setRefetch}
						/>
					) : (
						<>
							<OrganisationInfo organisation={organisation} />
							<OrganisationNotes
								setShowOrganisationNotes={
									setShowOrganisationNotes
								}
								organisation={organisation}
								setOrganisationState={setOrganisationState}
							/>
						</>
					)}
				</Modal>
			)}
			<FullPageLoader loading={loading} />
		</div>
	);
};

export default OrganisationActions;
