import React, { useState, FC } from 'react';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import './profile-general-info.styles.scss';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import edit from '../../../assets/icons/edit.svg';
import ProfileEditUserModal from '../profile-edit-user-modal/profile-edit-user-modal';
import Modal from '../../modals/modal/modal.component';
import { IApplicant } from '../../../types/interface';
import { RootState } from '../../../types/root-state';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	applicant: IApplicant;
};

const ProfileGeneralInfo: FC<Props> = ({ applicant }) => {
	const role = useSelector((state: RootState) => state.auth.role);
	const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);

	const handleEditUser = () => {
		window.scrollTo(0, 0);
		setTimeout(() => {
			setShowEditUserModal(true);
		}, 700);
	};

	const emitEditUserClicked = () => {
		setShowEditUserModal(false);
	};

	return (
		<div className='profile-general-info'>
			<div className='profile-general-info-item'>
				{role !== 'ambassador' && (
					<div className='edit-user'>
						<p className='orka-profile-label'>
							Edit User Information
						</p>
						<img src={edit} alt='edit' onClick={handleEditUser} />
					</div>
				)}
			</div>
			<div className='profile-general-info-item'>
				<ProfileDisplayItem
					label='Phone Number'
					value={
						applicant.contactNumber
							? applicant.contactNumber
							: 'N/A'
					}
				/>
			</div>

			<div className='profile-general-info-item'>
				<ProfileDisplayItem
					label='Date of Birth'
					value={applicant.DOB ? applicant.DOB : 'N/A'}
				/>
			</div>

			{applicant.email && (
				<div className='profile-general-info-item'>
					<p className='orka-profile-label'>Email</p>
					<TooltipLg title={applicant.email}>
						<a
							href={`mailto:${applicant.email}?subject=Your%20orka%20background%20check%20needs%20attention`}
							target='_blank'
							rel='noopener noreferrer'
							data-cy='email'
							className='email'
						>
							{applicant.email}
						</a>
					</TooltipLg>
				</div>
			)}

			{showEditUserModal && (
				<Modal
					title='Edit User'
					setShowModal={setShowEditUserModal}
					showModal={showEditUserModal}
				>
					<ProfileEditUserModal emitClicked={emitEditUserClicked} />
				</Modal>
			)}
		</div>
	);
};

export default ProfileGeneralInfo;
