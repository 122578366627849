import {
	BUCKETS,
	APPLICANTS,
	SET_BUCKET,
	SET_SEARCH_TERM,
	SET_SORT_VALUE,
	SET_SEARCH_BY_VALUE,
	TOTAL_PAGES,
	SET_PAGE,
	SET_DYNAMIC_TABLE,
	UPDATE_BUCKETS,
	SET_OVERALL_SUBMISSION,
	SET_SELECTED_BUCKET_DATA,
	SIDEBAR_STATE,
	SIDEBAR_BUCKET_DATA,
	FETCH_APPLICANTS,
	RESET_DASHBOARD_STATE,
	SET_SORT_DIRECTION,
	SET_ASSIGN_VAL,
	UPDATE_APPLICANT_FIELD,
	SET_SUBMISSION_QUERY,
	SET_STATUS_QUERY,
	SET_FLOW_QUERY,
	CLEAR_FILTERS,
	SET_TASK_PAGE,
	SET_ORGANISATION_PAGE,
	SUBMISSION_STATUSES_TOTAL,
	SUBMISSION_STATUS_TAB,
	SET_EXTERNAL_TAB,
	SET_COMPANY_QUERY,
} from './dashboard.types';

const INITIAL_STATE = {
	bucketData: [],
	allApplicants: [],
	selectedBucket: 'all',
	searchTerm: '',
	sortBy: 'createdAt',
	searchBy: 'email',
	totalPages: 0,
	sortDirection: 'DESC',
	page: 1,
	dynamicTableData: [],
	updateBuckets: false,
	overallSubmission: null,
	selectedBucketData: null,
	sidebarIsOpen: null,
	sidebarBucketData: [],
	refreshGetApplicants: false,
	assignedQuery: 'all',
	submissionQuery: 'all',
	statusQuery: 'all',
	flowQuery: 'all',
	taskPage: 1,
	organisationPage: 1,
	submissionStatusesTotal: [],
	submissionStatusTab: 'inProgress',
	externalTab: '3',
	companyQuery: 'all',
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BUCKETS:
			return {
				...state,
				bucketData: action.bucketData,
			};
		case APPLICANTS:
			return {
				...state,
				allApplicants: action.allApplicants,
			};
		case SET_BUCKET:
			return {
				...state,
				selectedBucket: action.selectedBucket,
			};
		case SET_SEARCH_TERM:
			return {
				...state,
				searchTerm: action.searchTerm,
			};
		case SET_SORT_VALUE:
			return {
				...state,
				sortBy: action.sortBy,
			};
		case SET_ASSIGN_VAL:
			return {
				...state,
				assignedQuery: action.assignedQuery,
			};
		case SET_SUBMISSION_QUERY:
			return {
				...state,
				submissionQuery: action.submissionQuery,
			};
		case SET_STATUS_QUERY:
			return {
				...state,
				statusQuery: action.statusQuery,
			};
		case SET_FLOW_QUERY:
			return {
				...state,
				flowQuery: action.flowQuery,
			};
		case SET_SORT_DIRECTION:
			return {
				...state,
				sortDirection: action.sortDirection,
			};
		case SET_SEARCH_BY_VALUE:
			return {
				...state,
				searchBy: action.searchBy,
			};
		case TOTAL_PAGES:
			return {
				...state,
				totalPages: action.totalPages,
			};
		case SET_PAGE:
			return {
				...state,
				page: action.page,
			};
		case SET_DYNAMIC_TABLE:
			return {
				...state,
				dynamicTableData: action.dynamicTableData,
			};
		case UPDATE_BUCKETS:
			return {
				...state,
				updateBuckets: action.updateBuckets,
			};
		case SET_OVERALL_SUBMISSION:
			return {
				...state,
				overallSubmission: action.overallSubmission,
			};
		case SET_SELECTED_BUCKET_DATA:
			return {
				...state,
				selectedBucketData: action.selectedBucketData,
			};
		case SIDEBAR_STATE:
			return {
				...state,
				sidebarIsOpen: action.sidebarIsOpen,
			};
		case SIDEBAR_BUCKET_DATA:
			return {
				...state,
				sidebarBucketData: action.sidebarBucketData,
			};
		case FETCH_APPLICANTS:
			return {
				...state,
				refreshGetApplicants: !state.refreshGetApplicants,
			};
		case UPDATE_APPLICANT_FIELD:
			const newApplicants = state.allApplicants.map((applicant) => {
				if (applicant.workerUuid === action.workerUuid) {
					applicant[action.field] = action.data;
				}
				return applicant;
			});
			return {
				...state,
				allApplicants: newApplicants,
			};
		case SET_TASK_PAGE:
			return {
				...state,
				taskPage: action.taskPage,
			};
		case SET_ORGANISATION_PAGE:
			return {
				...state,
				organisationPage: action.organisationPage,
			};
		case RESET_DASHBOARD_STATE:
			return INITIAL_STATE;
		case CLEAR_FILTERS:
			return {
				...state,
				assignedQuery: 'all',
				submissionQuery: 'all',
				statusQuery: 'all',
				flowQuery: 'all',
				companyQuery: 'all',
				sortBy: 'createdAt',
			};
		case SUBMISSION_STATUSES_TOTAL:
			return {
				...state,
				submissionStatusesTotal: action.submissionStatusesTotal,
			};
		case SUBMISSION_STATUS_TAB:
			return {
				...state,
				submissionStatusTab: action.submissionStatusTab,
			};
		case SET_EXTERNAL_TAB:
			return {
				...state,
				externalTab: action.externalTab,
			};
		case SET_COMPANY_QUERY:
			return {
				...state,
				companyQuery: action.companyQuery,
			};
		default:
			return state;
	}
};

export default dashboardReducer;
