import React, { FC } from 'react';
import './certificate-info-item.styles.scss';

type Props = {
	label: string;
	value: string;
};

const CertificateInfoItem: FC<Props> = ({ label, value }) => {
	return (
		<div className='certificate-info-item-container'>
			<div className='label'>
				<p>{label}</p>
			</div>
			<div className='value'>
				<p>{value}</p>
			</div>
		</div>
	);
};

export default CertificateInfoItem;
