import React, {
	useEffect,
	useState,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import '../../task-center/task-notes/task-notes.component';
import './organisation-notes.styles.scss';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import { IOrganization } from '../../../types/interfaces/reference.interface';
import * as referenceApi from '../../../api/api.reference';

type Props = {
	setShowOrganisationNotes: Dispatch<SetStateAction<boolean>>;
	organisation: IOrganization;
	setOrganisationState: Dispatch<SetStateAction<IOrganization>>;
};

const OrganisationNotes: FC<Props> = ({
	setShowOrganisationNotes,
	organisation,
	setOrganisationState,
}) => {
	const [notes, setNotes] = useState<string>('');
	const dispatch = useDispatch();

	const handleSaveNotes = () => {
		const payload = {
			notes,
			uuid: organisation.uuid,
		};
		referenceApi
			.updateOrganisation(payload)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				} else {
					const updatedOrg = {
						...organisation,
						notes,
					};
					setOrganisationState(updatedOrg);
					dispatch(
						setAlert({
							type: 'success',
							message: 'Note saved',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
		setShowOrganisationNotes(false);
	};

	useEffect(() => {
		setNotes(organisation.notes ? organisation.notes : '');
	}, []);

	return (
		<div className='task-notes-modal'>
			<div className='task-text-area'>
				<textarea
					value={notes}
					onChange={(e) => setNotes(e.target.value)}
					className='organisations-notes-textarea'
				/>
			</div>

			<div className='modal-btn-section'>
				<OrkaButton
					buttonContent='Done'
					disabled={false}
					emitClicked={handleSaveNotes}
				/>
			</div>
		</div>
	);
};

export default OrganisationNotes;
