import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './external-invite-applicant-details.styles.scss';
import {
	ExtInviteModalFlow,
	INewCandidate,
	InviteRespSubmit,
	NiApplicantFound,
} from '../../../../types/interface';
import EditInput from '../../../general/edit-input/input.component';
import OrkaButton from '../../../general/orka-button/orka-button.component';
import * as utils from '../../../../utils/utilsFunctions';
import * as apiApplicant from '../../../../api/api.applicant';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../redux/General/general.actions';
import { refetchApplicants } from '../../../../redux/Dashboard/dashboard.actions';
import * as validations from '../../../../utils/validations';
import blueTick from '../../../../assets/icons/tick-blue-circle.svg';
import ExternalPassportCard from '../external-passport-card/external-passport-card.component';
import { dateToText } from '../../../../utils/externalUtilsFunction';

type Props = {
	setApplicantFlow: Dispatch<SetStateAction<ExtInviteModalFlow>>;
	setPassportFormData: Dispatch<SetStateAction<INewCandidate>>;
	foundApplicant: NiApplicantFound;
	responseSubmit: (e: InviteRespSubmit) => void;
};

const ExtInviteApplicantDetails: FC<Props> = ({
	setApplicantFlow,
	foundApplicant,
	responseSubmit,
	setPassportFormData,
}) => {
	const dispatch = useDispatch();
	const [formState, setFormState] = useState<INewCandidate>({
		name: '',
		surname: '',
		email: '',
		contactNumber: '',
		nationalInsuranceNumber: '',
	});
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [emailValidationError, setEmailValidationError] = useState('');
	const [phoneValidationError, setPhoneValidationError] = useState('');

	useEffect(() => {
		emitValue(
			foundApplicant.passportDetails?.NiNumber,
			'nationalInsuranceNumber',
		);
		if (
			!formState.name ||
			!formState.surname ||
			!formState.email ||
			!formState.contactNumber
		) {
			setButtonDisabled(true);
		} else {
			setButtonDisabled(false);
		}
	}, [
		formState.name,
		formState.surname,
		formState.email,
		formState.contactNumber,
	]);

	const emitValue = (val: any, inputName: keyof INewCandidate) => {
		setFormState({
			...formState,
			[inputName]: val,
		});
	};

	const handleAddCandidate = () => {
		if (!handleValidations()) {
			return;
		}

		const { passportDetails } = foundApplicant;
		if (foundApplicant.exists) {
			apiApplicant
				.checkPassportDetails(formState)
				.then((res) => {
					if (res.status === 404) {
						throw res;
					}
					const { contactDetails, exists, nameDetails, applicant } =
						res.data || null;

					foundApplicant.exists = exists;
					setPassportFormData(formState);
					if (exists && applicant && passportDetails) {
						passportDetails.DOB = applicant.dateOfBirth
							? dateToText(applicant.dateOfBirth)
							: '**/**/****';
						passportDetails.contactNumber = applicant.contactNumber;
						passportDetails.email = applicant.email;
						passportDetails.firstName = utils.capitalizeFirstLetter(
							applicant.name,
						);
						passportDetails.lastName = utils.capitalizeFirstLetter(
							applicant.surname,
						);
					}

					if (exists && contactDetails && nameDetails && applicant) {
						responseSubmit('FullMatch');
					} else if (
						exists &&
						nameDetails &&
						applicant &&
						!contactDetails
					) {
						responseSubmit('WrongContact');
					} else if (
						exists &&
						!nameDetails &&
						!applicant &&
						!contactDetails
					) {
						responseSubmit('WrongAll');
					} else if (
						exists &&
						!nameDetails &&
						!applicant &&
						contactDetails
					) {
						responseSubmit('WrongName');
					}
				})
				.catch((err) => {
					dispatch(
						setAlert({
							type: 'error',
							message: err.message,
							isVisible: true,
						}),
					);
				});
		} else {
			return apiApplicant
				.addApplicant(formState)
				.then((res) => {
					if (res.status === 404) {
						throw res;
					}
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully added candidate',
							isVisible: false,
						}),
					);
					if (passportDetails) {
						passportDetails.firstName = utils.capitalizeFirstLetter(
							formState.name,
						);
						passportDetails.lastName = utils.capitalizeFirstLetter(
							formState.surname,
						);
						responseSubmit('Invited');
					}
					dispatch(refetchApplicants());
				})
				.catch((err) => {
					dispatch(
						setAlert({
							type: 'error',
							message:
								err.data && err.data.message
									? err.data.message
									: err.message,
							isVisible: true,
						}),
					);
				});
		}
	};

	const handleValidations = () => {
		let valid = true;
		const emailValidation = validations.emailValidation(formState.email);
		const phoneValidation = validations.phoneValidation(
			formState.contactNumber,
		);

		if (!emailValidation.valid) {
			setEmailValidationError(emailValidation.message);
			setTimeout(() => {
				setEmailValidationError('');
			}, 2000);
			valid = false;
		}

		if (!phoneValidation.valid) {
			setPhoneValidationError(phoneValidation.message);
			setTimeout(() => {
				setPhoneValidationError('');
			}, 2000);
			valid = false;
		}
		return valid;
	};

	return (
		<div className='external-invite-applicant-details'>
			<div>
				{foundApplicant.exists ? (
					<>
						<p className='potential-match'>
							We have found a potential match{' '}
							<img src={blueTick}></img>
						</p>

						{foundApplicant.passportDetails && (
							<ExternalPassportCard
								passportDetails={foundApplicant.passportDetails}
								halfCard={true}
							/>
						)}

						<p className='margin-top-32 margin-bottom-8'>
							Provide the applicant’s details to continue
						</p>
						<p className='orka-p-small'>
							If these initials match applicant’s first and last
							name click
							<span className='semi'> continue</span>. If not
							click
							<span className='semi'> start again</span>.<br></br>
							<span className='semi'>
								Please ensure you provide the applicant’s legal
								full name, and if relevant, the contact details
								they have signed up to Deploi with previously.
							</span>
						</p>
					</>
				) : (
					<>
						<p className='semi margin-bottom-8'>
							NI number
							<span className='color-primary'>
								&nbsp;
								{foundApplicant?.passportDetails?.NiNumber}
								&nbsp;
							</span>
							doesn’t exist in our system
						</p>
						<p className='orka-p-small'>
							Provide further details below to finish inviting the
							applicant.<br></br>
							<span className='semi'>
								If you believe this applicant should already
								have a Deploi Passport, please double check the
								NI number and start again
							</span>
						</p>
					</>
				)}

				<div className='add-candidate-input-row'>
					<div className='add-candidate-col-left'>
						<EditInput
							inputName='name'
							placeholder='First Name'
							value={formState.name}
							title='First Name(s)'
							emitValue={emitValue}
						/>
					</div>

					<div className='add-candidate-col-right'>
						<EditInput
							inputName='surname'
							placeholder='Last Name'
							value={formState.surname}
							title='Last Name'
							emitValue={emitValue}
						/>
					</div>
				</div>

				<div className='add-candidate-input-row'>
					<div className='add-candidate-col-left'>
						<EditInput
							inputName='contactNumber'
							placeholder='Phone Number'
							value={formState.contactNumber}
							title='Phone Number'
							emitValue={emitValue}
							type='tel'
							validationError={phoneValidationError}
						/>
					</div>

					<div className='add-candidate-col-right'>
						<EditInput
							inputName='email'
							placeholder='Email'
							value={formState.email}
							title='Email'
							emitValue={emitValue}
							type='email'
							validationError={emailValidationError}
						/>
					</div>
				</div>
			</div>

			<div className='inline-buttons'>
				<OrkaButton
					buttonContent='Start Again'
					disabled={false}
					emitClicked={() => setApplicantFlow(1)}
				/>
				<OrkaButton
					buttonContent={
						foundApplicant.exists ? 'Continue' : 'Invite'
					}
					disabled={buttonDisabled}
					emitClicked={handleAddCandidate}
				/>
			</div>
		</div>
	);
};

export default ExtInviteApplicantDetails;
