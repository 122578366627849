import React, { FC } from 'react';
import SectionHeader from '../section-header/section-header.component';
import KeyVal from '../key-val/key-val.component';
import store from '../../../redux/store';
import { RootState } from '../../../types/root-state';
import * as utils from '../../../utils/utilsFunctions';
import { Image, StyleSheet, Text } from '@react-pdf/renderer';
import { WorkHistoryItem } from '../../../types/interface';
import moment from 'moment';

import RiskContainer from '../risk-container/risk-container.component';

const styles = StyleSheet.create({
	section: {
		display: 'flex',
	},
	main: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		minWidth: '200px',
		width: '100%',
		marginRight: '24px',
		borderBottom: '1px',
		borderStyle: 'solid',
		borderColor: '#FFFFFF',
		marginBottom: '16px',
		paddingBottom: '16px',
		position: 'relative',
	},
	image: {
		width: '200px',
		height: 'auto',
		marginBottom: '16px',
	},
	imageContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		maxWidth: '300px',
		paddingBottom: '16px',
	},
	noDoc: {
		width: '120px',
		height: '80px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	gap: {
		backgroundColor: '#EC8079',
		width: '126px',
		padding: '6px 16px',
		borderRadius: '6px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	},
	overlap: {
		backgroundColor: 'white',
		width: '126px',
		padding: '6px 16px',
		borderRadius: '6px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		position: 'absolute',
		top: '-28px',
		left: '0',
	},
	overlapText: {
		fontSize: '10px',
		fontWeight: 600,
	},
});

const Activities: FC = () => {
	const state: RootState = store.getState();
	const { workHistory } = state.profile;

	const findOverlap = (
		currentItem: WorkHistoryItem,
		nextItem: WorkHistoryItem,
	): void => {
		const currentItemEnd = currentItem.endAt
			? moment(currentItem.endAt)
			: moment();
		const nextItemStart = moment(nextItem.startAt);

		if (currentItemEnd > nextItemStart) {
			currentItem.overlap = true;
			currentItem.overlapDays = `Overlap: ${currentItemEnd.diff(nextItemStart, 'days') + 1
				} days`;
		} else {
			currentItem.overlap = false;
		}
	};

	const sortedWorkHistory = utils
		.sortByDate(workHistory)
		.map((item: WorkHistoryItem, index: number) => {
			if (index >= 1) {
				findOverlap(item, workHistory[index - 1]);
			}
			return item;
		});

	const localHistory = sortedWorkHistory;

	let imageCount = 0;
	for (const historyItem of localHistory) {
		if (historyItem.files)
			imageCount += historyItem.files.reduce(
				(acc: any, file: any) =>
					acc +
					(file?.contentType?.includes('image') &&
						file.status !== 'rejected' &&
						!file.deletedAt
						? 1
						: 0),
				0,
			);
	}

	const renderImages =
		imageCount <=
		Number(process.env.REACT_APP_ACTIVITY_HISTORY_IMAGE_LIMIT);

	return (
		<div>
			<SectionHeader title='Activity History' />
			{localHistory.length > 0 &&
				localHistory.map((activityItem: any) => {
					const startDate = utils.formatDateForUser(
						activityItem.startAt,
					);
					const endDate = activityItem.endAt
						? utils.formatDateForUser(activityItem.endAt)
						: 'Present';

					if (activityItem.type !== 'gap') {
						return (
							<div key={activityItem.uuid} style={styles.main}>
								<div>
									{activityItem.overlap && (
										<div style={styles.overlap}>
											<Text style={styles.overlapText}>
												{activityItem.overlapDays}
											</Text>
										</div>
									)}
									<KeyVal
										title='Dates'
										value={`${startDate} - ${endDate}`}
									/>
									<KeyVal
										title='Activity'
										value={utils.capitalizeFirstLetter(
											activityItem.type,
										)}
									/>
									{activityItem.name && (
										<KeyVal
											title='Organisation'
											value={utils.capitalizeFirstLetter(
												activityItem.name,
											)}
										/>
									)}
									{activityItem.reference &&
										activityItem.reference.status && (
											<KeyVal
												title='Reference'
												value={utils.capitalizeFirstLetter(
													activityItem.reference.status.replace(
														/([a-z])([A-Z])/g,
														'$1 $2',
													),
												)}
											/>
										)}
								</div>
								<div style={styles.imageContainer}>
									{activityItem.files &&
										activityItem.files.length > 0 &&
										activityItem.files.map((file: any) => {
											if (
												file.status != 'rejected' &&
												!file.deletedAt
											) {
												return (
													<div
														key={file.uuid}
														style={{
															marginRight: '16px',
														}}
													>
														{file.fileUrl &&
															file?.contentType?.includes(
																'image',
															) &&
															renderImages && (
																<Image
																	src={
																		file.fileUrl
																	}
																	cache={
																		false
																	}
																	style={
																		styles.image
																	}
																/>
															)}

														{(file.contentType ===
															'application/pdf' ||
															!renderImages) && (
																<div
																	style={{
																		width: '250px',
																	}}
																>
																	<KeyVal
																		title={
																			file.contentType ===
																				'application/pdf'
																				? 'PDF: (Check Appendix)'
																				: file?.contentType?.includes(
																					'image',
																				)
																					? 'Image:'
																					: 'File:'
																		}
																		value={file.fileName.replace(
																			'work-history-proof/',
																			'file: ',
																		)}
																		flexDirection='col'
																	/>
																</div>
															)}
													</div>
												);
											}
										})}
								</div>
							</div>
						);
					}

					if (activityItem.type === 'gap') {
						return (
							<div style={styles.main} key={activityItem.startAt}>
								<div style={styles.gap}>
									<Text
										style={{
											color: 'white',
											fontSize: '10px',
										}}
									>{`Gap ${activityItem.gapDays} days`}</Text>
								</div>
							</div>
						);
					}
				})}
			<RiskContainer title='Activity History' />
		</div>
	);
};

export default Activities;
