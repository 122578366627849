import React, { useEffect, useState, FC } from 'react';
import {
	IIdCheckBreakdownItem,
	IIdCheckData,
} from '../../../types/interfaces/identity.interface';
import ProfileIdCheckBreakdownSection from '../profile-id-check-breakdown-section/profile-id-check-breakdown-section';
import './profile-id-check-breakdown.styles.scss';

type Props = {
	checkData: IIdCheckData;
};

const ProfileIdCheckBreakdown: FC<Props> = ({ checkData }) => {
	const [ageValidation, setAgeValidation] = useState<IIdCheckBreakdownItem>();
	const [compromisedDocument, setCompromisedDocument] =
		useState<IIdCheckBreakdownItem>();
	const [dataComparison, setDataComparison] =
		useState<IIdCheckBreakdownItem>();
	const [dataConsistency, setDataConsistency] =
		useState<IIdCheckBreakdownItem>();
	const [dataValidation, setDataValidation] =
		useState<IIdCheckBreakdownItem>();
	const [imageIntegrity, setImageIntegrity] =
		useState<IIdCheckBreakdownItem>();
	const [policeRecord, setPoliceRecord] = useState<IIdCheckBreakdownItem>();
	const [visualAuthenticity, setVisualAuthenticity] =
		useState<IIdCheckBreakdownItem>();

	useEffect(() => {
		setAgeValidation(checkData.reports.breakdown.age_validation);
		setCompromisedDocument(
			checkData.reports.breakdown.compromised_document,
		);
		setDataComparison(checkData.reports.breakdown.data_comparison);
		setDataConsistency(checkData.reports.breakdown.data_consistency);
		setDataValidation(checkData.reports.breakdown.data_validation);
		setImageIntegrity(checkData.reports.breakdown.image_integrity);
		setPoliceRecord(checkData.reports.breakdown.police_record);
		setVisualAuthenticity(checkData.reports.breakdown.visual_authenticity);
	}, []);

	return (
		<div className='breakdowns' data-cy='identity-check-breakdown'>
			<div className='breakdown-side'>
				{ageValidation && (
					<ProfileIdCheckBreakdownSection
						title='Age Validation'
						validationType={ageValidation}
					/>
				)}
				{compromisedDocument && (
					<ProfileIdCheckBreakdownSection
						title='Compromised Document'
						validationType={compromisedDocument}
					/>
				)}

				{dataComparison && (
					<ProfileIdCheckBreakdownSection
						title='Data Comparison'
						validationType={dataComparison}
					/>
				)}

				{dataConsistency && (
					<ProfileIdCheckBreakdownSection
						title='Data Consistency'
						validationType={dataConsistency}
					/>
				)}
			</div>

			<div className='breakdown-side'>
				{dataValidation && (
					<ProfileIdCheckBreakdownSection
						title='data Validation'
						validationType={dataValidation}
					/>
				)}

				{imageIntegrity && (
					<ProfileIdCheckBreakdownSection
						title='Image Integrity'
						validationType={imageIntegrity}
					/>
				)}

				{policeRecord && (
					<ProfileIdCheckBreakdownSection
						title='Police Record'
						validationType={policeRecord}
					/>
				)}

				{visualAuthenticity && (
					<ProfileIdCheckBreakdownSection
						title='Visual Authenticity'
						validationType={visualAuthenticity}
					/>
				)}
			</div>
		</div>
	);
};

export default ProfileIdCheckBreakdown;
