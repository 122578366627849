import React, { useState, FC } from 'react';
import './profile-clearance-information.styles.scss';
import { useSelector } from 'react-redux';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import * as utils from '../../../utils/utilsFunctions';
import edit from '../../../assets/icons/edit.svg';
import ClearanceDate from '../../modals/clearance-date/clearance-date.component';
import Modal from '../../modals/modal/modal.component';
import { RootState } from '../../../types/root-state';
import useGetWorkHistory from '../../../utils/useGetWorkHistory.component';
import { useNavigate } from 'react-router-dom';
import useGetRtwReport from '../../../utils/useGetRtwReport.component';

const ProfileClearanceInformation: FC = () => {
	const navigate = useNavigate();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	const [openProvisionalModal, setOpenProvisionalModal] =
		useState<boolean>(false);
	const [openFullyModal, setOpenFullyModal] = useState<boolean>(false);

	useGetWorkHistory(applicant.workerUuid);
	useGetRtwReport();

	const handleViewCertificate = () => {
		return navigate('/check/certificate');
	};

	const handleViewReport = () => {
		navigate('/check/report');
	};

	return (
		<div className='clearance-info'>
			{clearance &&
				clearance.clearanceStatus === 'partially_compliant' && (
					<div className='partially-cleared-info margin-bottom-16 margin-top-16'>
						<div className='display-edit'>
							<ProfileDisplayItem
								label='Date of Limited Screening'
								value={utils.formatDateForUser(
									clearance.provisionallyClearedDate,
								)}
							/>
							<img
								src={edit}
								alt='edit'
								onClick={() => setOpenProvisionalModal(true)}
							/>
						</div>

						<div className='days-left'>
							<p>{utils.calculateDaysLeftToClear(applicant)}</p>
						</div>
						{openProvisionalModal && (
							<Modal
								title='Update Limited Screening Date'
								setShowModal={setOpenProvisionalModal}
								showModal={openProvisionalModal}
							>
								<ClearanceDate
									setShowModal={setOpenProvisionalModal}
									clearanceType={'provisionallyClearedDate'}
								/>
							</Modal>
						)}
					</div>
				)}
			{clearance && clearance.clearanceStatus === 'fully_compliant' && (
				<div className='margin-bottom-8 margin-top-16'>
					<div className='display-edit'>
						<ProfileDisplayItem
							label='Date Fully Cleared'
							value={utils.formatDateForUser(
								clearance.fullyClearedDate,
							)}
						/>
						<img
							src={edit}
							alt='edit'
							onClick={() => setOpenFullyModal(true)}
						/>
					</div>

					{openFullyModal && (
						<Modal
							title='Update Fully Cleared Date'
							setShowModal={setOpenFullyModal}
							showModal={openFullyModal}
						>
							<ClearanceDate
								setShowModal={setOpenFullyModal}
								clearanceType={'fullyClearedDate'}
							/>
						</Modal>
					)}
				</div>
			)}

			<div
				className='view-certificate margin-top-16'
				onClick={handleViewCertificate}
			>
				<p>View Certificate</p>
			</div>
			{applicant.flow === 'non-works' &&
				clearance &&
				clearance.clearanceStatus !== 'not_compliant' && (
					<div
						className='view-certificate margin-top-24'
						onClick={handleViewReport}
					>
						<p>View Report</p>
					</div>
				)}
		</div>
	);
};

export default ProfileClearanceInformation;
