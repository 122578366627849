import React, { FC } from 'react';
import './profile-address-history.styles.scss';
import { useSelector } from 'react-redux';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import * as utils from '../../../utils/utilsFunctions';
import { RootState } from '../../../types/root-state';

const ProfileAddressHistory: FC = () => {
	const addressHistory = useSelector(
		(state: RootState) => state.profile.addressHistory,
	);

	return (
		<div className='address-history-container'>
			{addressHistory.length > 0 ? (
				<div>
					{addressHistory.map((address, index) => {
						if (address.type === 'gap') {
							return (
								<div key={index}>
									<p className='orka-profile-value'>
										There is a gap between
									</p>
									<p className='orka-profile-value'>
										{utils.formatDateForUser(
											address.startAt,
										)}
										-
										{address.endAt
											? utils.formatDateForUser(
													address.endAt,
												)
											: 'Present'}
									</p>
								</div>
							);
						} else {
							return (
								<div
									key={address.uuid}
									className='margin-bottom-16'
								>
									<p className='orka-profile-label'>
										Address {index + 1}
									</p>
									<p className='orka-profile-value'>
										{`${address.address.streetAddress}, `}
										{`${address.address.city}, `}
										{address.address.postcode}
									</p>
									<p className='orka-profile-value'>
										{utils.formatDateForUser(
											address.startAt,
										)}
										-
										{address.endAt
											? utils.formatDateForUser(
													address.endAt,
												)
											: 'Present'}
									</p>
								</div>
							);
						}
					})}
				</div>
			) : (
				<ProfileDisplayItem
					label='Address History'
					value='None Submitted'
				/>
			)}
		</div>
	);
};

export default ProfileAddressHistory;
