import React, { useState, useEffect, FC } from 'react';
import './liveness-check.styles.scss';
import arrowLeft from '../../../assets/icons/dashboard/arrow-left.svg';
import arrowRight from '../../../assets/icons/dashboard/arrow-right.svg';
import { YotiDocs } from '../../../types/interfaces/document.interface';
type Props = {
	fileArray: YotiDocs[];
	showContainerClass: boolean;
};

const LivenessCheck: FC<Props> = ({ fileArray, showContainerClass }) => {
	const [currentImage, setCurrentImage] = useState<any>(fileArray[0]);
	const [disabledRight, setDisabledRight] = useState<any>(false);
	const [disabledLeft, setDisabledLeft] = useState<any>(false);
	useEffect(() => {
		const index = getIndex();
		if (index === 0) {
			setDisabledLeft(true);
		} else {
			setDisabledLeft(false);
		}
		if (index === fileArray.length - 1) {
			setDisabledRight(true);
		} else {
			setDisabledRight(false);
		}
	}, [currentImage]);

	const getIndex = () => {
		const index = fileArray.findIndex((element) => {
			return element?.uuid === currentImage?.uuid;
		});
		return index;
	};

	const handleImage = (direction: 'next' | 'prev') => {
		const index = getIndex();
		if (direction === 'next') {
			const nextImage = fileArray[index + 1]
				? fileArray[index + 1]
				: null;
			if (nextImage) {
				setCurrentImage(nextImage);
			}
		} else if (direction === 'prev' && index > 0) {
			const prevImage = fileArray[index - 1];
			setCurrentImage(prevImage);
		}
	};
	return (
		<div
			className={
				showContainerClass
					? 'liveness-check-container'
					: 'external-user-profile-sub-section-liveness'
			}
		>
			<div onClick={() => handleImage('prev')} className='left-button'>
				<img
					className={disabledLeft ? 'disable' : 'visible'}
					src={arrowLeft}
				/>
			</div>
			<div className='each-slide-effect'>
				<a
					target={'_blank'}
					rel='noopener noreferrer'
					href={currentImage.readUrl}
				>
					<img src={currentImage.readUrl} />
				</a>
			</div>
			<div onClick={() => handleImage('next')} className='right-button'>
				<img
					className={disabledRight ? 'disable' : 'visible'}
					src={arrowRight}
				/>
			</div>
		</div>
	);
};

export default LivenessCheck;
