import React, { useState, useEffect, FC, FormEvent } from 'react';
import './activity-main.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import { useSelector } from 'react-redux';

import Dropdown from '../../general/orka-dropdown/dropdown.component';
import ActivityItem from '../activity-item/activity-item.component';
import EditInput from '../../general/edit-input/input.component';
import {
	ActivityFormState,
	EmploymentTypes,
	WorkHistoryItem,
	WorkPattern,
} from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import ActivityVerified from '../activity-verified/activity-verified.component';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import notVerified from '../../../assets/icons/activity/not-verified.svg';

type Props = {
	item: WorkHistoryItem | null;
	formState: ActivityFormState;
	emitValue: any;
	updateActivity: (payload: any) => Promise<void>;
	edit: boolean;
};

const ActivityMain: FC<Props> = ({
	item,
	formState,
	emitValue,
	updateActivity,
	edit,
}) => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [dropdownData, setDropdownData] = useState<EmploymentTypes[]>([]);
	const [defaultData, setDefaultData] = useState<EmploymentTypes>();
	const [workPatternData, setWorkPatternData] = useState<WorkPattern[]>([]);
	const [defaultWorkPatternData, setDefaultWorkPatternData] =
		useState<WorkPattern>();

	const [tempItem, setTempItem] = useState<any>(item ? item : null);
	const [showVerified, setShowVerified] = useState(false);

	useEffect(() => {
		setTempItem(item ? item : null);
	}, [JSON.stringify(item)]);

	useEffect(() => {
		const temporary = {
			...tempItem,
			addedManually:
				item && item.reference && item.reference.addedManually !== true
					? false
					: true,
		};

		setTempItem(temporary);
	}, [JSON.stringify(tempItem)]);

	useEffect(() => {
		setDropdownData(utils.getDropdownData('employmentTypes', applicant));
		setWorkPatternData(utils.getDropdownData('workPattern', applicant));
	}, [applicant]);

	useEffect(() => {
		if (
			item &&
			(item.type === 'employed' ||
				item.type === 'studying' ||
				item.type === 'volunteering')
		) {
			setShowVerified(true);
		} else {
			setShowVerified(false);
		}
	}, [JSON.stringify(item)]);

	const isVerified = (type: string) => {
		if (
			type === 'employed' ||
			type === 'studying' ||
			type === 'volunteering'
		) {
			setShowVerified(true);
		} else {
			setShowVerified(false);
		}
	};

	useEffect(() => {
		if (dropdownData.length > 0) {
			if (item) {
				const data = dropdownData.filter((dropdownItem) => {
					return dropdownItem.dbValue === item.type;
				});
				setDefaultData(data[0]);
			} else {
				setDefaultData(dropdownData[0]);
			}
		}
	}, [dropdownData.length, JSON.stringify(item)]);

	useEffect(() => {
		if (workPatternData.length > 0) {
			item && item.isPartTime
				? setDefaultWorkPatternData(workPatternData[2])
				: setDefaultWorkPatternData(workPatternData[1]);
		}
	}, [workPatternData.length, JSON.stringify(item)]);

	const getDropdownVal = (val: any) => {
		emitValue(val.dbValue, 'type');
		if (edit) {
			const payload = {
				type: val.dbValue,
				reference: {
					addedManually: true,
				},
			};

			updateActivity(payload).then(() => {
				setDefaultData(val);
				isVerified(val.dbValue);
			});
		} else {
			setDefaultData(val);
		}
	};

	const handleBlur = (update: any) => {
		if (edit) {
			return updateActivity(update);
		}
	};

	const handleCareerBreakReason = (e: FormEvent<HTMLTextAreaElement>) => {
		emitValue(e.currentTarget.value, 'careerBreakReason');
	};

	const getWorkPatternData = (obj: any) => {
		const update = {
			isPartTime: obj.value !== 'Full Time',
		};
		emitValue(update.isPartTime, 'isPartTime');
		if (edit) {
			updateActivity({ isPartTime: update.isPartTime }).then(() => {
				setDefaultWorkPatternData(obj);
			});
		} else {
			setDefaultWorkPatternData(obj);
		}
	};

	return (
		<div className='activity-main-container'>
			{showVerified && <ActivityVerified activity={tempItem} />}
			{defaultData && (
				<>
					<ActivityItem label='What were they doing?'>
						<Dropdown
							type='employmentType'
							dropdownData={dropdownData}
							defaultData={defaultData}
							getDropdownVal={getDropdownVal}
							width='220'
							position='bottom-center'
						/>
					</ActivityItem>

					{defaultData.dbValue === 'another-reason' && (
						<textarea
							onChange={handleCareerBreakReason}
							value={
								formState.careerBreakReason
									? formState.careerBreakReason
									: ''
							}
							onBlur={() =>
								handleBlur({
									careerBreakReason:
										formState.careerBreakReason,
								})
							}
							placeholder='Please give more information'
						/>
					)}
				</>
			)}

			{defaultWorkPatternData &&
				defaultData &&
				(defaultData.dbValue === 'employed' ||
					defaultData.dbValue === 'self-employed') && (
					<div className='work-pattern'>
						<ActivityItem>
							<Dropdown
								type='workPattern'
								dropdownData={workPatternData}
								defaultData={defaultWorkPatternData}
								getDropdownVal={getWorkPatternData}
								width='220'
								position='bottom-center'
							/>
						</ActivityItem>
					</div>
				)}

			<div className='main-container-info'>
				{(formState.type === 'employed' ||
					formState.type === 'volunteering' ||
					formState.type === 'studying') && (
					<ActivityItem
						label='Organisation Name'
						icon={
							item && item?.reference?.addedManually
								? notVerified
								: ''
						}
					>
						{edit ? (
							<ProfileDisplayItem
								label=''
								value={item ? item.name : ''}
							/>
						) : (
							<EditInput
								title=''
								value={formState.name}
								emitValue={emitValue}
								placeholder='Organisation Name'
								inputName='name'
								handleBlur={handleBlur}
							/>
						)}
					</ActivityItem>
				)}

				{formState.type === 'employed' && (
					<ActivityItem label='Job Title'>
						<EditInput
							title=''
							value={formState.jobTitle}
							emitValue={emitValue}
							placeholder='Job Title'
							inputName='jobTitle'
							handleBlur={handleBlur}
						/>
					</ActivityItem>
				)}
			</div>
		</div>
	);
};

export default ActivityMain;
