export const BUCKETS = 'BUCKETS';
export const APPLICANTS = 'APPLICANTS';
export const SET_BUCKET = 'SET_BUCKET';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_SORT_VALUE = 'SET_SORT_VALUE';
export const SET_SEARCH_BY_VALUE = 'SET_SEARCH_BY_VALUE';
export const TOTAL_PAGES = 'TOTAL_PAGES';
export const SET_PAGE = 'SET_PAGE';
export const SET_DYNAMIC_TABLE = 'SET_DYNAMIC_TABLE';
export const UPDATE_BUCKETS = 'UPDATE_BUCKETS';
export const SET_OVERALL_SUBMISSION = 'SET_OVERALL_SUBMISSION';
export const SET_SELECTED_BUCKET_DATA = 'SET_SELECTED_BUCKET_DATA';
export const SIDEBAR_STATE = 'SIDEBAR_STATE';
export const SIDEBAR_BUCKET_DATA = 'SIDEBAR_BUCKET_DATA';
export const FETCH_APPLICANTS = 'FETCH_APPLICANTS';
export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE';
export const SET_SORT_DIRECTION = 'SET_SORT_DIRECTION';
export const SET_ASSIGN_VAL = 'SET_ASSIGN_VAL';
export const UPDATE_APPLICANT_FIELD = 'UPDATE_APPLICANT_FIELD';
export const SET_STATUS_QUERY = 'SET_STATUS_QUERY';
export const SET_SUBMISSION_QUERY = 'SET_SUBMISSION_QUERY';
export const SET_FLOW_QUERY = 'SET_FLOW_QUERY';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_TASK_PAGE = 'SET_TASK_PAGE';
export const SET_ORGANISATION_PAGE = 'SET_ORGANISATION_PAGE';
export const SUBMISSION_STATUSES_TOTAL = 'SUBMISSION_STATUSES_TOTAL';
export const SUBMISSION_STATUS_TAB = 'SUBMISSION_STATUS_TAB';
export const SET_EXTERNAL_TAB = 'SET_EXTERNAL_TAB';
export const SET_COMPANY_QUERY = 'SET_COMPANY_QUERY';
