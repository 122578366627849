import React, { FC, useState, useEffect, FormEvent } from 'react';
import './activity-date.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import {
	ActivityFormState,
	ReasonForLeavingDataItem,
	WorkHistoryItem,
} from '../../../types/interface';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import EditInput from '../../general/edit-input/input.component';

type Props = {
	item: WorkHistoryItem | null;
	emitValue: any;
	formState: ActivityFormState;
	updateActivity: (payload: any) => Promise<void>;
	edit: boolean;
	hasDateError: boolean;
	dateValidationText: string;
};

const ActivityDate: FC<Props> = ({
	item,
	emitValue,
	formState,
	updateActivity,
	edit,
	hasDateError,
	dateValidationText,
}) => {
	const [reasonForLeavingData, setReasonForLeavingData] = useState<
		ReasonForLeavingDataItem[]
	>([]);
	const [leavingDefaultData, setLeavingDefaultData] =
		useState<ReasonForLeavingDataItem>();
	const [hasDateValidationError, setHasDateValidationError] =
		useState<boolean>(false);
	const [dateValidationErrorText, setDateValidationErrorText] =
		useState<string>('');

	useEffect(() => {
		setReasonForLeavingData(
			utils.getDropdownData('reasonsForLeaving', null),
		);
	}, []);

	useEffect(() => {
		if (hasDateError) {
			setHasDateValidationError(true);
			setDateValidationErrorText(dateValidationText);
		} else {
			setHasDateValidationError(false);
			setDateValidationErrorText('');
		}
	}, [hasDateError, dateValidationText]);

	useEffect(() => {
		if (reasonForLeavingData.length > 0) {
			if (item && item.reasonForLeaving) {
				const leavingItem = reasonForLeavingData.filter((reason) => {
					return reason.dbValue === item.reasonForLeaving;
				});
				setLeavingDefaultData(leavingItem[0]);
			} else {
				setLeavingDefaultData(reasonForLeavingData[0]);
			}
		}
	}, [reasonForLeavingData.length]);

	const getDropdownVal = (val: ReasonForLeavingDataItem) => {
		emitValue(val.dbValue, 'reasonForLeaving');
		if (edit) {
			updateActivity({
				reasonForLeaving: val.dbValue,
				reasonForLeavingText:
					val.dbValue === 'Other'
						? formState.reasonForLeavingText
						: null,
			}).then(() => {
				if (val.dbValue !== 'Other') {
					emitValue('', 'reasonForLeavingText');
				}
				setLeavingDefaultData(val);
			});
		} else {
			setLeavingDefaultData(val);
		}
	};

	const handleTextareaChange = (e: FormEvent<HTMLTextAreaElement>) => {
		emitValue(e.currentTarget.value, 'reasonForLeavingText');
	};

	const handleBlur = () => {
		if (edit) {
			updateActivity({
				reasonForLeavingText: formState.reasonForLeavingText,
			});
		}
	};

	const emitDates = (val: any, input: any) => {
		if (edit) {
			if (validateDates(val, input)) {
				return;
			}
		}

		const updateVal = val ? utils.formatDateForDb(val) : null;

		if (
			input === 'startAt' &&
			(updateVal === null || updateVal === 'Invalid date')
		) {
			return;
		}

		emitValue(val, input);

		if (updateVal !== 'Invalid date' && edit) {
			updateActivity({ [input]: updateVal });
		}
	};

	const validateDates = (val: any, input: any) => {
		if (input === 'startAt') {
			if (formState.endAt && val > new Date(formState.endAt)) {
				setHasDateValidationError(true);
				setDateValidationErrorText(
					'Start Date cannot be after end date',
				);

				setTimeout(() => {
					setHasDateValidationError(false);
					setDateValidationErrorText('');
				}, 3000);
				return true;
			}
		}

		if (input === 'endAt' && val !== null) {
			if (formState.startAt && val < new Date(formState.startAt)) {
				setHasDateValidationError(true);
				setDateValidationErrorText(
					'End Date cannot be before start date',
				);

				setTimeout(() => {
					setHasDateValidationError(false);
					setDateValidationErrorText('');
				}, 3000);
				return true;
			}
		}

		return false;
	};

	return (
		<div className='activity-date-section'>
			<div className='activity-date-section-top'>
				<div className='first-item'>
					<EditInput
						title='Start Date'
						value={formState.startAt}
						emitValue={emitDates}
						placeholder='Click Calendar'
						inputName='startAt'
					></EditInput>
				</div>

				<div className='item'>
					<EditInput
						title='End Date'
						value={formState.endAt}
						emitValue={emitDates}
						placeholder='Click Calendar'
						inputName='endAt'
					></EditInput>
				</div>
			</div>
			{hasDateValidationError && (
				<div className='error-validation'>
					<p>{dateValidationErrorText}</p>
				</div>
			)}
			{leavingDefaultData &&
				formState.endAt &&
				formState.type === 'employed' && (
					<div className='reason-for-leaving'>
						<p className='orka-profile-label margin-bottom-8'>
							Reason for leaving
						</p>
						<Dropdown
							type='reasonsForLeaving'
							dropdownData={reasonForLeavingData}
							defaultData={leavingDefaultData}
							getDropdownVal={getDropdownVal}
							width='220'
							mobileResponsive={false}
							position='bottom-left'
						/>

						{leavingDefaultData.dbValue === 'Other' && (
							<div className='other-reason'>
								<textarea
									value={formState.reasonForLeavingText}
									onChange={handleTextareaChange}
									placeholder='Please enter a reason leaving'
									onBlur={handleBlur}
								/>
							</div>
						)}
					</div>
				)}
		</div>
	);
};

export default ActivityDate;
