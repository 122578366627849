import React, { FC } from 'react';
import { WorkHistoryItem } from '../../../types/interface';
import ActivityItem from '../activity-item/activity-item.component';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import './activity-address.styles.scss';
import notVerified from '../../../assets/icons/activity/not-verified.svg';

type Props = {
	item: WorkHistoryItem | null;
};

const ActivityAddress: FC<Props> = ({ item }) => {
	return (
		<div className='activity-address-section'>
			{item && item.address && item.address.streetAddress && (
				<ActivityItem
					label='Address Line One'
					icon={item?.reference?.addedManually ? notVerified : ''}
				>
					<ProfileDisplayItem
						label=''
						value={item.address.streetAddress}
					/>
				</ActivityItem>
			)}

			{item && item.address && item.address.city && (
				<ActivityItem
					label='City'
					icon={item?.reference?.addedManually ? notVerified : ''}
				>
					<ProfileDisplayItem label='' value={item.address.city} />
				</ActivityItem>
			)}

			{item && item.address && item.address.city && (
				<ActivityItem
					label='Region'
					icon={item?.reference?.addedManually ? notVerified : ''}
				>
					<ProfileDisplayItem
						label=''
						value={item.address.region ? item.address.region : ''}
					/>
				</ActivityItem>
			)}

			{item && item.address && item.address.postcode && (
				<ActivityItem
					label='Postcode'
					icon={item?.reference?.addedManually ? notVerified : ''}
				>
					<ProfileDisplayItem
						label=''
						value={item.address.postcode.toUpperCase()}
					/>
				</ActivityItem>
			)}
		</div>
	);
};

export default ActivityAddress;
