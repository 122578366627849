export const tabs = [
	{
		id: '1',
		tabTitle: 'All',
		textColor: '#4A4A4A',
	},
	{
		id: '2',
		tabTitle: 'Awaiting Submission',
		textColor: '#9AA5B1',
	},
	{
		id: '3',
		tabTitle: 'In Progress',
		textColor: '#485BEB',
	},
	{
		id: '4',
		tabTitle: 'Limited',
		textColor: '#ECB442',
	},
	{
		id: '5',
		tabTitle: 'Full',
		textColor: '#09AD99',
	},
	{
		id: '6',
		tabTitle: 'Cancelled',
		textColor: '#EC8079',
	},
];

export const tabData = [
	{ status: 'all', total: 0 },
	{ status: 'awaitingSubmission', total: 0 },
	{ status: 'inProgress', total: 0 },
	{ status: 'limited', total: 0 },
	{ status: 'full', total: 0 },
	{ status: 'cancelled', total: 0 },
];

export const emailRecipientOptions = [
	{
		id: '1',
		label: 'Limited Screened',
		value: 'limitedScreened',
		selected: true,
	},
	{
		id: '2',
		label: 'Fully Compliant',
		value: 'fullyCompliant',
		selected: true,
	},
	{
		id: '3',
		label: 'CC into submission chasers emails',
		value: 'ccSubmission',
		selected: false,
	},
	{
		id: '4',
		label: 'CC into reference chasers emails',
		value: 'ccReference',
		selected: false,
	},
	{
		id: '5',
		label: 'Acceptance of Risk',
		value: 'acceptanceOfRisk',
		selected: true,
	},
];

export const tableClientData = [
	{
		id: 1,
		firstName: 'Simone',
		lastName: 'Melidoni',
		email: 'simone.melidoni@orka.group',
		permissions: 'Manager',
		emailRecipient: 'All',
	},
	{
		id: 2,
		firstName: 'Jack',
		lastName: 'Clarke',
		email: 'jack.clarke@orka.group',
		permissions: 'Manager',
		emailRecipient: 'All',
	},
	{
		id: 3,
		firstName: 'Samuel',
		lastName: 'Parfait',
		email: 'samuel.parfait@orka.group',
		permissions: 'Admin',
		emailRecipient: 'Some',
	},
	{
		id: 4,
		firstName: 'Ishaaq',
		lastName: 'Iqbal',
		email: 'ishaaq.iqbal@orka.group',
		permissions: 'Admin',
		emailRecipient: 'Some',
	},
	{
		id: 5,
		firstName: 'Cassie',
		lastName: 'Watson',
		email: 'cassie.watson@orka.group',
		permissions: 'Manager',
		emailRecipient: 'Some',
	},
	{
		id: 6,
		firstName: 'John',
		lastName: 'Doe',
		email: 'john.doe@orka.group',
		permissions: 'Admin',
		emailRecipient: 'All',
	},
];
