import React, {
	useEffect,
	useState,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import ProgressCircle from '../progress-circle/progress-circle.component';
import './task-row.styles.scss';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import * as utils from '../../../utils/utilsFunctions';
import TaskActions from '../task-actions/task-actions.component';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import hasShifts from '../../../assets/icons/task-list/shifts.svg';
import noShifts from '../../../assets/icons/task-list/no-shifts.svg';
import provisionally from '../../../assets/icons/dropdown-limited.svg';
import fully from '../../../assets/icons/dropdown-fully.svg';
import none from '../../../assets/icons/dropdown-none.svg';
import { RootState } from '../../../types/root-state';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	applicant: any;
	setRefetchTasks: Dispatch<SetStateAction<boolean>>;
	refetchTasks: boolean;
	taskType: string;
};

const TaskRow: FC<Props> = ({
	applicant,
	refetchTasks,
	setRefetchTasks,
	taskType = '',
}) => {
	const dispatch = useDispatch();
	// eslint-disable-next-line no-unused-vars
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [percentage, setPercentage] = useState(
		(applicant.daysRemaining / applicant.daysToCheck) * 100,
	);
	const [acceptedApplicationCount, setAcceptedApplicationCount] = useState(0);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const [statusIcon, setStatusIcon] = useState(none);

	useEffect(() => {
		const acceptedCountObject = applicant.user.metadata.find(
			(el: { key: string }) =>
				el.key === 'futureAcceptedApplicationsCount',
		);

		acceptedCountObject && acceptedCountObject.value > 0
			? setAcceptedApplicationCount(acceptedCountObject.value)
			: setAcceptedApplicationCount(0);
	}, []);

	useEffect(() => {
		const getIcon = () => {
			switch (applicant.user.clearance?.clearanceStatus) {
				case 'fully_compliant':
					setStatusIcon(fully);
					return;
				case 'partially_compliant':
					setStatusIcon(provisionally);
					return;
				default:
					setStatusIcon(none);
					return;
			}
		};
		getIcon();
	}, []);

	const copyToClipboard = (text: string) => {
		const copyFunction = (e: ClipboardEvent) => {
			if (e && e.clipboardData) {
				e.clipboardData.setData('text/plain', text);
				e.preventDefault();
				dispatch(
					setAlert({
						type: 'success',
						message: `${applicant.user.email} copied to clipboard`,
						isVisible: true,
					}),
				);
				document.removeEventListener('copy', copyFunction);
			}
		};
		document.addEventListener('copy', copyFunction);
		document.execCommand('copy');
	};

	return (
		<div className='task-row'>
			<div className='task-row-left'>
				<TooltipLg title='Accepted Shifts'>
					<div className='col-sm center accepted-shifts help'>
						<img
							src={
								acceptedApplicationCount > 0
									? hasShifts
									: noShifts
							}
							alt='briefcase'
						/>
						<p
							className={
								acceptedApplicationCount > 0
									? 'has-shifts'
									: 'no-shifts'
							}
						>
							{acceptedApplicationCount}
						</p>
					</div>
				</TooltipLg>
				<div className='col-sm'>
					<ProgressCircle
						progress={applicant.daysRemaining < 0 ? 0 : percentage}
						size={isMobile ? 40 : 55}
						strokeWidth={isMobile ? 2 : 4}
						circleOneStroke={
							applicant.priority ? '#ec81795b' : '#F7F7F7'
						}
						circleTwoStroke={
							applicant.priority ? '#EC8079' : '#485BEB'
						}
						daysRemaining={applicant.daysRemaining}
					/>
				</div>
				<div className='col-md'>
					<p className={applicant.task}>
						{utils.validateTaskType(applicant.task)}
					</p>
				</div>

				<div className='col-lg'>
					<TooltipLg
						title={utils.removeUnderscore(applicant.user.email)}
					>
						<button
							className='email-button'
							onClick={() =>
								copyToClipboard(applicant.user.email)
							}
						>
							{utils.minimizeString(
								applicant.user.email,
								isMobile ? 18 : 50,
							)}
						</button>
					</TooltipLg>
				</div>

				<div className='col-sm center help mobile-hide'>
					<TooltipLg
						title={utils.removeUnderscore(
							applicant.user.clearance?.clearanceStatus ||
								'not_compliant',
						)}
					>
						<img src={statusIcon} alt='status' />
					</TooltipLg>
				</div>
			</div>

			<div className='task-row-right'>
				<TaskActions
					applicant={applicant}
					refetchTasks={refetchTasks}
					setRefetchTasks={setRefetchTasks}
					taskType={taskType}
				/>
			</div>
		</div>
	);
};

export default TaskRow;
