import React, { useState, FC, Dispatch, SetStateAction } from 'react';
import './task-actions.styles.scss';
import editPencil from '../../../assets/icons/edit.svg';
import blueBin from '../../../assets/icons/trash-blue.svg';
import Modal from '../../modals/modal/modal.component';
import SuspendModal from '../../modals/suspend-modal/suspend-modal.component';
import TaskNotes from '../task-notes/task-notes.component';
import TaskUser from '../../../assets/icons/task-list/task-profile.svg';
import { ITaskApplicant } from '../../../types/interfaces/task.interface';
import { useNavigate } from 'react-router-dom';
import * as taskApi from '../../../api/api.task';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import OrkaButton from '../../general/orka-button/orka-button.component';

type Props = {
	applicant: ITaskApplicant;
	setRefetchTasks: Dispatch<SetStateAction<boolean>>;
	refetchTasks: boolean;
	taskType: string;
};

const TaskActions: FC<Props> = ({
	applicant,
	setRefetchTasks,
	refetchTasks,
	taskType = '',
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showSuspendedModal, setShowSuspendedModal] =
		useState<boolean>(false);
	const [showTaskNotes, setShowTaskNotes] = useState<boolean>(false);
	const [suspendedAt, setSuspendedAt] = useState<boolean>(
		applicant.user.suspendedAt ? true : false,
	);
	const [showDeleteTaskModal, setShowDeleteTaskModal] =
		useState<boolean>(false);

	const handleSelectApplicant = () => {
		navigate(`/check/user/${applicant.user.uuid}`, {
			state: {
				workerUuid: applicant.user.workerUuid,
				uuid: applicant.user.uuid,
			},
		});
	};

	const handleSuspendApplicant = () => {
		setShowSuspendedModal(false);
	};

	const handleUserSuspended = () => {
		setSuspendedAt(true);
	};

	const handleSnooze = () => {
		return taskApi
			.updateCheckTaskInfo({ snoozed: true }, applicant.uuid)
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully snoozed task',
						isVisible: true,
					}),
				);
				setRefetchTasks(!refetchTasks);
			})
			.catch((err: Error) => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to snooze task',
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	const handleDelete = () => {
		return taskApi
			.deleteTask(applicant.uuid)
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully deleted task',
						isVisible: true,
					}),
				);
				setShowDeleteTaskModal(false);
				setRefetchTasks(!refetchTasks);
			})
			.catch((err: Error) => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to delete task',
						isVisible: true,
					}),
				);
				setShowDeleteTaskModal(false);
				throw err;
			});
	};

	return (
		<div className='task-actions'>
			<button className='profile' onClick={handleSelectApplicant}>
				<img src={TaskUser} alt='pencil' />
			</button>

			<button className='edit' onClick={() => setShowTaskNotes(true)}>
				<img src={editPencil} alt='pencil' />
			</button>
			{taskType === '' && (
				<>
					{suspendedAt ? (
						<div className='suspend'>
							<button>Suspended</button>
						</div>
					) : (
						<div className='suspend'>
							<button onClick={() => setShowSuspendedModal(true)}>
								Suspend
							</button>
						</div>
					)}
				</>
			)}
			{taskType === 'checkTask' && (
				<div className='suspend'>
					<button onClick={handleSnooze}>Snooze</button>
				</div>
			)}
			{taskType === '' && (
				<div className='edit delete'>
					<button onClick={() => setShowDeleteTaskModal(true)}>
						<img src={blueBin} alt='delete' />
					</button>
				</div>
			)}

			{showSuspendedModal && (
				<Modal
					title='Suspend Applicant'
					setShowModal={setShowSuspendedModal}
					showModal={showSuspendedModal}
				>
					<SuspendModal
						emitClicked={handleSuspendApplicant}
						applicant={applicant.user}
						wasSuspended={handleUserSuspended}
					/>
				</Modal>
			)}
			{showTaskNotes && (
				<Modal
					title='Notes'
					setShowModal={setShowTaskNotes}
					showModal={showTaskNotes}
					modalContentHeight='50vh'
				>
					<TaskNotes
						setShowTaskNotes={setShowTaskNotes}
						taskUuid={applicant.uuid}
						applicant={applicant.user}
						setRefetchTasks={setRefetchTasks}
						refetchTasks={refetchTasks}
					/>
				</Modal>
			)}
			{showDeleteTaskModal && (
				<Modal
					title=''
					setShowModal={setShowDeleteTaskModal}
					showModal={showDeleteTaskModal}
					modalContentHeight='20vh'
					modalContentWidth='22vw'
				>
					<h2>Are you sure you want to delete?</h2>
					<div className='delete-modal-btn'>
						<OrkaButton
							buttonContent='Confirm'
							disabled={false}
							emitClicked={handleDelete}
						></OrkaButton>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default TaskActions;
