import React, { useEffect, useState, FC } from 'react';
import './profile-submission-status.styles.scss';

import * as utils from '../../../utils/utilsFunctions';
import * as apiApplicant from '../../../api/api.applicant';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import { setAlert } from '../../../redux/General/general.actions';
import {
	updateApplicant,
	updateApplicantMultiple,
	updateOverallSubmission as updateOverallSubmissionStore,
} from '../../../redux/Profile/profile.actions';
import {
	refetchApplicants,
	updateApplicantField,
} from '../../../redux/Dashboard/dashboard.actions';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicant, SubmissionDropdown } from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import NotesModal from '../notes-modal/notes-modal.component';

type Props = {
	applicantFromDash?: IApplicant;
};

const ProfileSubmissionStatus: FC<Props> = ({ applicantFromDash = null }) => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	const dispatch = useDispatch();
	const [dropdownData, setDropdownData] = useState<SubmissionDropdown[]>([]);
	const [defaultData, setDefaultData] = useState<SubmissionDropdown>();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const overallSubmission = useSelector(
		(state: RootState) => state.profile.overallSubmission,
	);

	useEffect(() => {
		let submissionDropdownData = utils.getDropdownData(
			'submissionStatus',
			applicant,
		);

		submissionDropdownData = submissionDropdownData.map(
			(dropdownItem: SubmissionDropdown) => {
				if (handleDisableDropdown(dropdownItem.dbValue)) {
					dropdownItem.disabled = true;
				}
				return dropdownItem;
			},
		);
		setDropdownData(submissionDropdownData);
	}, [applicant, applicantFromDash]);

	useEffect(() => {
		if (overallSubmission?.data && overallSubmission?.data?.status) {
			const data = getDefaultData(overallSubmission.data.status);
			setDefaultData(data[0]);
		}
	}, [JSON.stringify(dropdownData), overallSubmission.data.status]);

	const getDropdownVal = (val: SubmissionDropdown) => {
		if (val.dbValue === 'cancelled') {
			setOpenModal(true);
		} else {
			return actionUpdateOverallSubmission(val.dbValue);
		}
	};

	const handleDisableDropdown = (dropdownItem: string) => {
		if (
			(clearance?.clearanceStatus === 'fully_compliant' &&
				dropdownItem === 'cancelled') ||
			overallSubmission.data.status === 'cancelled'
		) {
			return true;
		} else {
			return false;
		}
	};

	const getDefaultData = (val: string) => {
		return dropdownData.filter((item: SubmissionDropdown) => {
			return item.dbValue === val;
		});
	};

	const handleNoteUpdate = (payload: any) => {
		setOpenModal(false);
		if (overallSubmission.data.status === 'cancelled') {
			return updateApplicantApi(payload);
		} else {
			return actionUpdateOverallSubmission('cancelled')
				.then(() => {
					dispatch(
						updateApplicantField(
							applicant.workerUuid,
							'assigned',
							[],
						),
					);
					dispatch(updateApplicant('assigned', []));
					return updateApplicantApi(payload);
				})
				.catch((err) => {
					throw err;
				});
		}
	};

	const actionUpdateOverallSubmission = (val: string) => {
		const payload = {
			status: val,
			uuid: applicant.uuid,
		};

		return apiApplicant
			.updateOverallSubmission(applicant.workerUuid, payload)
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message:
							'Successfully updated overall submission status',
						isVisible: true,
					}),
				);
				dispatch(updateOverallSubmissionStore(val));

				const data = getDefaultData(val);
				setDefaultData(data[0]);
				dispatch(refetchApplicants());
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating overall submission status',
						isVisible: true,
					}),
				);
			});
	};

	const updateApplicantApi = (payload: any) => {
		return apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then(() => {
				dispatch(updateApplicantMultiple(payload));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated cancellation status',
						isVisible: true,
					}),
				);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	return (
		<div>
			{defaultData && (
				<Dropdown
					type='submissionStatus'
					dropdownData={dropdownData}
					defaultData={defaultData}
					getDropdownVal={getDropdownVal}
					width='160'
					height={24}
					mobileResponsive={false}
					position='bottom-center'
				/>
			)}
			{openModal && (
				<NotesModal
					handleClicked={handleNoteUpdate}
					buttonContent={
						overallSubmission?.data?.status === 'cancelled'
							? 'Update Notes'
							: 'Cancel Application'
					}
					setShowModal={setOpenModal}
					showModal={openModal}
					noteValue={applicant.cancelledNotes}
					type='cancelledNotes'
					modalTitle={
						overallSubmission?.data?.status === 'cancelled'
							? 'Update Cancel Notes'
							: 'Cancel Application'
					}
					placeholder='Please note why the application has been cancelled'
					modalInfo={
						<>
							<p>
								Screener will be unassigned from this
								application.
							</p>
							<br />
							<p>
								Application will be invoiced with a
								<strong> cancellation fee.</strong>
							</p>
						</>
					}
				/>
			)}
		</div>
	);
};

export default ProfileSubmissionStatus;
