import React, { FC } from 'react';
import searchIcon from '../../../assets/icons/search.svg';
import './search.styles.scss';

type Props = {
	placeholder?: string;
	handleChange: (e: any) => void;
	search: string;
	origin?: string;
};

const Search: FC<Props> = ({
	placeholder = 'Search...',
	handleChange,
	search,
}) => {
	return (
		<div className='search-component'>
			<img src={searchIcon} alt='search' />
			<input
				type='text'
				onChange={handleChange}
				value={search}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default Search;
