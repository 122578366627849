import React, { FC, useState } from 'react';
import './profile-employment.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import ProfileEmploymentItem from '../profile-employment-item/profile-employment-item.component';
import { RootState } from '../../../types/root-state';
import { setShowActivity } from '../../../redux/Profile/profile.actions';
import Modal from '../../modals/modal/modal.component';
import ActivityOriginalModal from '../activity-original-modal/activity-original-modal.component';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import eye from '../../../assets/icons/eye.svg';
import useGetWorkHistory from '../../../utils/useGetWorkHistory.component';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

const ProfileEmployment: FC = () => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	useGetWorkHistory(applicant.workerUuid);
	const workHistory = useSelector(
		(state: RootState) => state.profile.workHistory,
	);

	const [openOriginalActivityModal, setOpenOriginalActivityModal] =
		useState<boolean>(false);

	const createActivity = () => {
		const data = {
			showActivity: true,
			activityItem: null,
		};
		dispatch(setShowActivity(data));
	};

	const viewOriginalActivities = () => {
		setOpenOriginalActivityModal(true);
	};

	return (
		<div>
			{workHistory && workHistory.length > 0 && <ProfileEmploymentItem />}
			<div className='add-activity-btn margin-bottom-32'>
				<button onClick={createActivity}>Create activity</button>
				<TooltipLg title='View Original Submission'>
					<img
						onClick={viewOriginalActivities}
						src={eye}
						alt='view all'
					/>
				</TooltipLg>
			</div>

			{openOriginalActivityModal && (
				<Modal
					title='Original Activity Submission'
					setShowModal={setOpenOriginalActivityModal}
					showModal={openOriginalActivityModal}
				>
					<ActivityOriginalModal />
				</Modal>
			)}
		</div>
	);
};

export default ProfileEmployment;
