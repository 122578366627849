import React, { FC, useEffect, useState } from 'react';
import './activity-documents.styles.scss';
import ProfileSection from '../profile-section/profile-section.component';
import ProfileDocumentBucket from '../profile-document-bucket/profile-document-bucket.component';
import { IFileItem } from '../../../types/interfaces/document.interface';
import { WorkHistoryItem, WorkHistoryItemFile } from '../../../types/interface';

type Props = {
	item: WorkHistoryItem;
};

const ActivityDocuments: FC<Props> = ({ item }) => {
	const [fileToAdd, setFileToAdd] = useState<
		WorkHistoryItemFile | IFileItem
	>();
	const [documentStartFiles, setDocumentStartFiles] = useState<
		WorkHistoryItemFile[]
	>([]);
	const [documentEndFiles, setDocumentEndFiles] = useState<
		WorkHistoryItemFile[]
	>([]);
	const [documentReferenceFiles, setDocumentReferenceFiles] = useState<
		WorkHistoryItemFile[]
	>([]);

	const handleGetFiles = (file: IFileItem) => {
		setFileToAdd(file);
	};

	useEffect(() => {
		if (item) {
			const docStartFiles = item.files.filter((file) => {
				if (file.tags && file.deletedAt === null) {
					return file.tags.includes('documentStart');
				} else if (file.tags === null) {
					return file;
				}
			});
			setDocumentStartFiles(docStartFiles);

			const docEndFiles = item.files.filter((file) => {
				if (file.tags && file.deletedAt === null) {
					return file.tags.includes('documentEnd');
				}
			});
			setDocumentEndFiles(docEndFiles);

			const docRefFiles = item.files.filter((file) => {
				if (file.tags && file.deletedAt === null) {
					return file.tags.includes('documentReference');
				}
			});
			setDocumentReferenceFiles(docRefFiles);
		}
	}, []);

	useEffect(() => {
		if (fileToAdd) {
			if (fileToAdd.tags && fileToAdd.tags.includes('documentStart')) {
				const docStartArray = documentStartFiles.filter((file: any) => {
					return file.uuid !== fileToAdd.uuid;
				});
				setDocumentStartFiles([...docStartArray, fileToAdd]);
			}

			if (fileToAdd.tags && fileToAdd.tags.includes('documentEnd')) {
				const docEndArray = documentEndFiles.filter((file: any) => {
					return file.uuid !== fileToAdd.uuid;
				});
				setDocumentEndFiles([...docEndArray, fileToAdd]);
			}

			if (
				fileToAdd.tags &&
				fileToAdd.tags.includes('documentReference')
			) {
				const docRefArray = documentReferenceFiles.filter(
					(file: any) => {
						return file.uuid !== fileToAdd.uuid;
					},
				);
				setDocumentReferenceFiles([...docRefArray, fileToAdd]);
			}
		}
	}, [JSON.stringify(fileToAdd)]);

	const emitFileToDelete = (fileUuid: string) => {
		const startDocArray = documentStartFiles.filter(
			(file: WorkHistoryItemFile) => file.uuid !== fileUuid,
		);
		setDocumentStartFiles([...startDocArray]);

		const endDocArray = documentEndFiles.filter(
			(file: WorkHistoryItemFile) => file.uuid !== fileUuid,
		);
		setDocumentEndFiles([...endDocArray]);

		const refDocArray = documentReferenceFiles.filter(
			(file: WorkHistoryItemFile) => file.uuid !== fileUuid,
		);
		setDocumentReferenceFiles([...refDocArray]);
	};

	return (
		<div className='activity-documents-section'>
			<ProfileSection title='Documents' hideSide={true}>
				<div className='margin-bottom-24 margin-top-24'>
					<p className='orka-profile-label'>
						Doc 1 (beginning of employment)
					</p>
					<ProfileDocumentBucket
						tag='documentStart'
						fileArray={documentStartFiles}
						isWork={true}
						workItemUuid={item.uuid}
						handleGetFiles={handleGetFiles}
						emitFileToDelete={emitFileToDelete}
					/>
				</div>

				<div className='margin-bottom-24'>
					<p className='orka-profile-label'>
						Doc 2 (end of employment)
					</p>
					<ProfileDocumentBucket
						tag='documentEnd'
						fileArray={documentEndFiles}
						isWork={true}
						workItemUuid={item.uuid}
						handleGetFiles={handleGetFiles}
						emitFileToDelete={emitFileToDelete}
					/>
				</div>

				<div className='margin-bottom-24'>
					<p className='orka-profile-label'>Doc 3 (References)</p>
					<ProfileDocumentBucket
						tag='documentReference'
						fileArray={documentReferenceFiles}
						isWork={true}
						workItemUuid={item.uuid}
						handleGetFiles={handleGetFiles}
						emitFileToDelete={emitFileToDelete}
					/>
				</div>
			</ProfileSection>
		</div>
	);
};

export default ActivityDocuments;
