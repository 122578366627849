import { axiosInstance } from './api.axiosInstance';

export const getTasks = (
	page: number,
	sortBy: string,
	sortDirection: string,
	taskType: string,
): Promise<any> => {
	let queryString = `?page=${page}&sortDirection=${sortDirection}`;
	queryString +=
		taskType && taskType === 'checkTask'
			? '&taskPage=checkTask'
			: `&completed=FALSE&sortBy=${sortBy}`;

	return axiosInstance()
		.get(`admin/tasks${queryString}`)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const getApplicant = (uuid: string): Promise<any> => {
	return axiosInstance()
		.get(`admin/tasks/${uuid}`)
		.then(({ data }) => {
			return data.data;
		})
		.catch((err) => {
			throw err;
		});
};

export const updateCheckTaskInfo = (
	payload: { snoozed: boolean },
	uuid: string,
): Promise<any> => {
	return axiosInstance()
		.patch(`admin/tasks/chasers/${uuid}`, payload)
		.catch((err) => {
			throw err;
		});
};

export const deleteTask = (uuid: string): Promise<any> => {
	return axiosInstance()
		.delete(`admin/task/${uuid}`)
		.catch((err) => {
			throw err;
		});
};
