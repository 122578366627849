import React, { useState, FC } from 'react';
import './add-staff.styles.scss';
import AddStaffHeader from '../../components/settings/add-staff-header/add-staff-header.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import Modal from '../../components/modals/modal/modal.component';
import InviteStaffModal from '../../components/settings/invite-staff-modal/invite-staff-modal.component';
import Layout from '../../components/general/layout-component/layout.component';
import AddStaffRow from '../../components/settings/add-staff-row/add-staff-row.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import AddCandidateModal from '../../components/settings/add-candidate-modal/add-candidate-modal.component';
import SetDefaultFlow from '../../components/settings/set-default-flow/set-default-flow.component';

const AddStaff: FC = () => {
	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showAddCandidateModal, setShowAddCandidateModal] = useState(false);
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const role = useSelector((state: RootState) => state.auth.role);

	return (
		<Layout>
			<ErrorBoundary applicantWorkerUuid='None'>
				<div className='settings-container'>
					<AddStaffHeader
						setShowInviteModal={setShowInviteModal}
						setShowAddCandidateModal={setShowAddCandidateModal}
					/>
					<div className='staff-rows'>
						{allStaff.length > 0 && (
							<>
								<div className='staff-added'>
									<p>Staff added</p>
								</div>
								{allStaff.map((staff) => {
									return (
										<div key={staff.uuid}>
											<AddStaffRow staffMember={staff} />
										</div>
									);
								})}
							</>
						)}
					</div>
					{role === 'controller' && <SetDefaultFlow />}
				</div>

				{showInviteModal && (
					<Modal
						title='Invite Staff Member'
						setShowModal={setShowInviteModal}
						showModal={showInviteModal}
					>
						<InviteStaffModal setShowModal={setShowInviteModal} />
					</Modal>
				)}
				{showAddCandidateModal && (
					<Modal
						title='Invite a Candidate'
						setShowModal={setShowAddCandidateModal}
						showModal={showAddCandidateModal}
					>
						<AddCandidateModal
							setShowModal={setShowAddCandidateModal}
						/>
					</Modal>
				)}
			</ErrorBoundary>
		</Layout>
	);
};

export default AddStaff;
