import './profile-secondary-container.styles.scss';

import React, { useState, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

import * as utils from '../../../utils/utilsFunctions';
import ProfileClearanceInformation from '../profile-clearance-information/profile-clearance-information.component';
import ProfileClearanceStatus from '../profile-clearance-status/profile-clearance-status';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import ProfileSubmissionStatus from '../profile-submission-status/profile-submission-status.component';
import ProfileGeneralInfo from '../profile-general-info/profile-general-info.component';
import ProfileNames from '../profile-names/profile-names.component';
import ProfileDeclaration from '../profile-declaration/profile-declaration.component';
import Tags from '../tags/tags.component';
import ReferenceSidebar from '../reference-sidebar/reference-sidebar.component';
import ProfileFlow from '../profile-flow/profile-flow.component';
import NotesSection from '../notes-section/notes-section.component';
import FileOwner from '../file-owner/file-owner.component';

const ProfileSecondaryContainer: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [showSubmission, setShowSubmission] = useState<boolean>(false);
	const showActivity = useSelector(
		(state: RootState) => state.profile.showActivity,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const activityItem = useSelector(
		(state: RootState) => state.profile.activityItem,
	);
	const overallSubmission = useSelector(
		(state: RootState) => state.profile.overallSubmission,
	);
	const [showNotes, setShowNotes] = useState<boolean>(true);

	useEffect(() => {
		overallSubmission?.data?.status === 'Not yet created'
			? setShowSubmission(false)
			: setShowSubmission(true);
	}, []);

	return (
		<div className='profile-secondary-container'>
			{!showActivity && (
				<div className='secondary-container-selection'>
					<button
						className={showNotes ? 'selected' : ''}
						onClick={() => setShowNotes(true)}
					>
						Notes
					</button>
					<button
						className={!showNotes ? 'selected' : ''}
						onClick={() => setShowNotes(false)}
					>
						Info
					</button>
				</div>
			)}
			<div className='profile-secondary-container-inner'>
				{!showActivity ? (
					<>
						{showNotes ? (
							<NotesSection />
						) : (
							<>
								{role !== 'ambassador' && (
									<>
										<Tags />
										<FileOwner />
										<div className='secondary-container-item'>
											<ProfileDisplayItem
												label='Created'
												value={utils.formatDateForUser(
													applicant.createdAt,
												)}
											/>
										</div>

										<div className='submission-details'>
											<div className='secondary-container-item'>
												<p className='orka-profile-label margin-bottom-8'>
													Flow
												</p>
												<ProfileFlow
													applicant={applicant}
												/>
											</div>
											{showSubmission ? (
												<div className='secondary-container-item submission-status'>
													<p className='orka-profile-label margin-bottom-8'>
														Submission status
													</p>
													<ProfileSubmissionStatus />
												</div>
											) : (
												<div className='secondary-container-item'>
													<ProfileDisplayItem
														label='Submission Status'
														value={
															overallSubmission
																?.data
																?.status ===
															'Incomplete'
																? 'Incomplete'
																: 'Not started'
														}
													/>
												</div>
											)}
											{applicant.flow === 'non-works' ? (
												<a
													href={
														overallSubmission.data
															.signatureUrl
													}
													className='orka-link orka-profile-value margin-bottom-8'
													target='_blank'
													rel='noopener noreferrer'
												>
													View Declaration
												</a>
											) : (
												<ProfileDeclaration />
											)}
										</div>

										<div className='secondary-container-item margin-bottom-16 final-status'>
											<p className='orka-profile-label margin-bottom-8'>
												Clearance Status
											</p>
											<ProfileClearanceStatus />
										</div>

										<div className='secondary-container-item'>
											<ProfileClearanceInformation />
										</div>
									</>
								)}
								<ProfileGeneralInfo applicant={applicant} />

								{role !== 'ambassador' && <ProfileNames />}
							</>
						)}
					</>
				) : (
					<>
						{role !== 'ambassador' &&
							activityItem &&
							activityItem.reference && (
								<ReferenceSidebar activityItem={activityItem} />
							)}
					</>
				)}
			</div>
		</div>
	);
};

export default ProfileSecondaryContainer;
