import {
	ISaveDocumentPayload,
	ICreateOnfidoApplicant,
	IShareCodePayload,
	ICandidateRTWDetails,
} from '../types/interfaces/identity.interface';
import { axiosInstance } from './api.axiosInstance';

export const manualOverride = (workerUuid: string): Promise<any> => {
	const url = `admin/onfido/applicant/${workerUuid}/check`;
	const payload = {
		workerUuid: workerUuid,
	};

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const getApplicantIdCheck = (workerUuid: string): Promise<any> => {
	const url = `admin/onfido/applicant/${workerUuid}/check`;
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const getIdResult = (workerUuid: string): Promise<any> => {
	const url = `/admin/onfido/applicant/${workerUuid}`;
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const getSignedUrl = (payload: {
	contentType: string;
}): Promise<any> => {
	const url = `/admin/onfido/document`;

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const saveDocument = (
	payload: Partial<ISaveDocumentPayload>,
): Promise<any> => {
	const url = `/admin/onfido/document/upload`;

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const createOnfidoApplicant = (
	payload: ICreateOnfidoApplicant,
): Promise<any> => {
	const url = `/admin/onfido/applicant`;

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const createCheck = (applicantUuid: string): Promise<any> => {
	const url = `/admin/onfido/applicant/${applicantUuid}/create-check`;

	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const getShareCode = (workerUuid: string): Promise<any> => {
	const url = `/admin/applicants/${workerUuid}/sharecode`;
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const actionShareCode = (
	payload: Partial<IShareCodePayload>,
	workerUuid: string,
	shareCodeUuid: string,
): Promise<any> => {
	const url = `/admin/applicants/${workerUuid}/sharecode/${shareCodeUuid}`;
	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const yotiOverwrite = (applicantUuid: string): Promise<any> => {
	const url = `/admin/yoti/${applicantUuid}/override`;

	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const yotiDetails = (applicantUuid: string): Promise<any> => {
	const url = `/admin/yoti/${applicantUuid}/sessions`;
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};
export const yotiDocuments = (applicantUuid: string): Promise<any> => {
	const url = `/admin/yoti/${applicantUuid}/candidates`;
	return axiosInstance()
		.get(url)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
export const yotiUpdateDetails = (
	sessionUuid: string,
	payload: Partial<ICandidateRTWDetails>,
): Promise<any> => {
	const url = `/admin/yoti/${sessionUuid}/candidate-details`;
	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const rtwResetSection = (
	applicantUuid: string,
	resetType: string,
): Promise<any> => {
	if (resetType === 'reset-rtw') resetType = 'RTW';
	const url = `/admin/applicants/${applicantUuid}/${
		resetType === 'RTW' ? 'reset-rtw' : 'reset-identity'
	}`;
	return axiosInstance()
		.patch(url)
		.catch((err) => {
			throw err;
		});
};
