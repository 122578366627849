import React, { useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateFiles,
	resetSelectedFiles,
} from '../../../redux/Profile/profile.actions';
import './floating-footer.styles.scss';
import * as docsApi from '../../../api/api.docs';
import blueTick from '../../../assets/icons/tick-blue-circle.svg';
import blueCross from '../../../assets/icons/cross-blue-circle.svg';
import cross from '../../../assets/icons/cross.svg';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import { IFileItem } from '../../../types/interfaces/document.interface';

type Props = {
	items: IFileItem[];
	resetFiles: () => void;
	isWork?: boolean;
};

const FloatingFooter: FC<Props> = ({ items, resetFiles, isWork = false }) => {
	const [sendButton, showSendButton] = useState<boolean>(false);
	const selectedFiles = useSelector(
		(state: RootState) => state.profile.selectedFiles,
	);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const dispatch = useDispatch();

	const handleAction = (action: string) => {
		if (action === 'rejected') {
			showSendButton(true);
			selectedFiles.forEach(async (file) => {
				await handleUpdateDocumentItem(action, file).catch((err) => {
					throw err;
				});
			});
		} else {
			selectedFiles.forEach(async (file) => {
				await handleUpdateDocumentItem(action, file);
			});
			dispatch(resetSelectedFiles());
		}
	};

	const handleSendAmendments = () => {
		window.open(
			`mailto:${applicant.email}?subject=Broadstone%20Communication`,
			'_blank',
		);
		dispatch(resetSelectedFiles());
	};

	const handleClose = () => {
		dispatch(resetSelectedFiles());
		resetFiles();
	};

	const handleUpdateDocumentItem = (action: string, file: IFileItem) => {
		// noinspection TypeScriptValidateTypes
		if (!isWork) {
			// eslint-disable-next-line no-unused-vars
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const type = file.tags[1];
			file.tags = [action, type];
		}
		file.status = action;
		return docsApi
			.updateDocumentStatus(file, applicant, isWork)
			.then(() => {
				file.originalStatus = action;
				dispatch(updateFiles(file));
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated document status',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updating document status',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div className='floating-footer'>
			<div className='floating-footer-header'>
				<img src={cross} alt='close' onClick={handleClose} />
			</div>

			<div className='floating-footer-body'>
				<div className='floating-footer-item-list'>
					{items.length > 0 && (
						<>
							{items.map((item, index) => {
								return (
									<div
										key={index}
										className='floating-footer-item'
									></div>
								);
							})}
						</>
					)}
				</div>

				<div className='floating-footer-actions'>
					{sendButton ? (
						<button onClick={handleSendAmendments}>
							Send Amendments
						</button>
					) : (
						<div className='footer-actions'>
							<div
								onClick={() => handleAction('rejected')}
								className='footer-action'
							>
								<img src={blueCross} alt='reject' />
								<p>Decline</p>
							</div>

							<div
								onClick={() => handleAction('accepted')}
								className='footer-action'
							>
								<img src={blueTick} alt='accepted' />
								<p>Approve</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FloatingFooter;
