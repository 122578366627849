import React, { useState, useEffect, FC, FormEvent, useCallback } from 'react';
import './external-search-container.styles.scss';
import { RootState } from '../../../types/root-state';
import { useDispatch, useSelector } from 'react-redux';
import { clearProfileData } from '../../../redux/Profile/profile.actions';
import { useNavigate } from 'react-router-dom';
import {
	setExternalTab,
	setGlobalSearchTerm,
} from '../../../redux/Dashboard/dashboard.actions';
import debounce from 'lodash.debounce';
import ExternalSearch from '../external-search/external-search.component';

const ExternalSearchContainer: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const searchTerm = useSelector(
		(state: RootState) => state.dashboard.searchTerm,
	);

	const [search, setSearch] = useState<string>('');

	const sendQuery = (query: string) => {
		dispatch(setGlobalSearchTerm(query));
	};

	useEffect(() => {
		if (searchTerm) {
			setSearch(searchTerm);
		}
	}, [searchTerm]);

	const delayedQuery = useCallback(
		debounce(() => {
			sendQuery(search);
		}, 500),
		[search],
	);

	const handleChange = (e: FormEvent<HTMLInputElement>): void => {
		dispatch(setExternalTab('1'));
		setSearch(e.currentTarget.value);
		dispatch(clearProfileData());
		navigate('/check');
	};

	useEffect(() => {
		delayedQuery();
		return delayedQuery.cancel;
	}, [search, delayedQuery]);

	return (
		<div className='dashboard-sub-menu-search'>
			<div className='dashboard-sub-menu-search-input'>
				<ExternalSearch
					handleChange={handleChange}
					search={search}
					placeholder='Search'
				/>
			</div>
		</div>
	);
};

export default ExternalSearchContainer;
