import React, { FC } from 'react';

// @types.organisation.ts
interface IOrganisationContext {
	toggleOrganisationTable: (value: boolean) => void;
	showOrganisationTable: boolean | null;
}

interface Props {
	children: React.ReactNode;
}

const OrganisationContext = React.createContext<IOrganisationContext | null>(
	null,
);

export const OrganisationProvider: FC<Props> = ({ children }) => {
	const [showOrganisationTable, setShoworganisationTable] =
		React.useState<boolean>(false);

	const toggleOrganisationTable = () => {
		setShoworganisationTable(!showOrganisationTable);
	};

	return (
		<OrganisationContext.Provider
			value={{ toggleOrganisationTable, showOrganisationTable }}
		>
			{children}
		</OrganisationContext.Provider>
	);
};

export default OrganisationContext;
