import '../external-user-profile-sections/external-user-profile-sections.styles.scss';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { formatDateToText } from '../../../utils/externalUtilsFunction';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';
import { User } from '../../../types/interface';

import OrkaButton from '../../general/orka-button/orka-button.component';
import Modal from '../../modals/modal/modal.component';
import RiskModalAssigned from '../../profile/risk-modal-assigned/risk-modal-assigned.component';

import flagRed from '../../../assets/icons/flag-red.svg';

interface IStageRisksProps {
	stageData: IStageDataItem;
	loggedInUser: User;
}

const ExternalUserProfileRisks: FC<IStageRisksProps> = ({
	stageData,
	loggedInUser,
}) => {
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const applicantRole = useSelector((state: RootState) => state.auth.role);

	const [showModals, setShowModals] = useState<boolean[]>([]);

	const getStaffName = (email: string | undefined) => {
		const staffMember = allStaff.find((staff) => staff.email === email);
		if (staffMember) {
			return `${staffMember.name} ${staffMember.surname}`;
		} else {
			return email;
		}
	};

	const handleRiskModal = (index: number) => {
		setShowModals((prevShowModals) => {
			const newShowModals = [...prevShowModals];
			newShowModals[index] = true;
			return newShowModals;
		});
	};

	const renderRisks = () => {
		return stageData.risks.map((risk, index) => {
			const showModal = showModals[index];

			const originalRisk = risk.riskNotes.split(
				'(assignedComments >)',
			)[0];
			const assignedComments = risk.riskNotes.split(
				'(assignedComments >)',
			)[1];

			return (
				<section
					key={index}
					className='external-user-profile-sub-section-container'
				>
					<div className='external-user-profile-sub-section-title-container remove-pointer'>
						<img
							className='external-user-profile-sub-section-risk-icon'
							src={flagRed}
							alt='red-flag'
						></img>
						<p className='external-user-profile-sub-section-risk-title'>
							{risk.risk.title}
						</p>
						{risk.risk.title === 'Risk' && (
							<p
								className='external-user-profile-sub-section-status'
								style={{
									color:
										risk.status === 'failed' ||
										risk.status === 'accepted'
											? '#485BEB'
											: '#F8C96A',
								}}
							>
								{risk.status === 'failed' ||
								risk.status === 'accepted'
									? 'Cleared'
									: 'Pending'}
							</p>
						)}
					</div>
					<div className='external-user-profile-sub-section-subtitle-container'>
						<p className='external-user-profile-sub-section-subtitle-risk-note'>
							Issue raised:{' ' + originalRisk}
						</p>
					</div>
					<div className='external-user-profile-sub-section-subtitle-container'>
						{assignedComments && (
							<p className='external-user-profile-sub-section-subtitle-risk-assigned-note'>
								Assigned user notes:{' ' + assignedComments}
							</p>
						)}
					</div>
					<div className='external-user-profile-sub-section-body-risk'>
						<div className='external-user-profile-sub-section-text-risk-container'>
							{risk.risk.title === 'Risk' && (
								<>
									<div className='external-user-profile-sub-section-text-light-risk'>
										Assigned to:{' '}
										{getStaffName(risk.assignedTo)}
									</div>
									<div className='external-user-profile-sub-section-text-light-risk'>
										Created by:{' '}
										{getStaffName(risk.createdBy)}
									</div>
								</>
							)}
							<div className='last-verified'>
								Last Verified on{' '}
								{risk.createdAt &&
									formatDateToText(
										new Date(risk.createdAt)
											.toISOString()
											.substring(0, 10),
									)}
							</div>
						</div>
						<div className='external-user-profile-sub-section-review-risk'>
							{!risk.status &&
								risk.assignedTo === loggedInUser.email &&
								applicantRole === 'manager' && (
									<OrkaButton
										buttonContent='Review'
										emitClicked={() =>
											handleRiskModal(index)
										}
										disabled={false}
									></OrkaButton>
								)}
						</div>
					</div>

					{showModal && (
						<Modal
							title='Accept Risk'
							setShowModal={() => {
								setShowModals((prevShowModals) => {
									const newShowModals = [...prevShowModals];
									newShowModals[index] = false;
									return newShowModals;
								});
							}}
							showModal={showModal}
							modalContentHeight='80vh'
							modalContentWidth='480px'
						>
							<RiskModalAssigned
								setShowModal={() => {
									setShowModals((prevShowModals) => {
										const newShowModals = [
											...prevShowModals,
										];
										newShowModals[index] = false;
										return newShowModals;
									});
								}}
								stage={stageData}
								risk={risk}
							/>
						</Modal>
					)}
					<div className='user-profile-section-divider-line'></div>
				</section>
			);
		});
	};

	return <div>{renderRisks()}</div>;
};

export default ExternalUserProfileRisks;
