import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './external-invite-applicant-ni.styles.scss';
import { NiApplicantFound, NiNumber } from '../../../../types/interface';
import EditInput from '../../../general/edit-input/input.component';
import OrkaButton from '../../../general/orka-button/orka-button.component';
import * as validations from '../../../../utils/validations';
import * as apiApplicant from '../../../../api/api.applicant';
import { setAlert } from '../../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import * as utils from '../../../../utils/utilsFunctions';
type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	nextAction: (e: NiApplicantFound) => void;
};

const ExtInviteApplicantNi: FC<Props> = ({ setShowModal, nextAction }) => {
	const [formState, setFormState] = useState<{ niNumber: NiNumber }>({
		niNumber: '',
	});
	const [niValidationError, setNiValidationError] = useState('');
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!formState.niNumber) {
			setButtonDisabled(true);
		} else {
			setButtonDisabled(false);
		}
	}, [formState.niNumber]);

	const emitValue = (val: any) => {
		setFormState({ niNumber: val });
	};

	const handleAddCandidate = () => {
		if (!handleValidations()) {
			return;
		}
		const niNumberStrip = utils.removeSpaces(formState.niNumber);
		// Placeholder to call the get applicant Ni details
		return apiApplicant
			.applicantByNi(niNumberStrip)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				nextAction({
					exists: res.data.exists,
					passportDetails: {
						firstName: utils.capitalizeFirstLetter(
							res.data.firstName,
						),
						lastName: utils.capitalizeFirstLetter(
							res.data.lastName,
						),
						NiNumber: niNumberStrip,
					},
				});
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message:
							err && err.data && err.data.message
								? err.data.message
								: 'Applicant`s Ni number not found.',
						isVisible: true,
					}),
				);
			});
	};

	const handleValidations = () => {
		let valid = true;
		const niValidation = validations.niValidation(formState.niNumber);

		if (!niValidation.isValid) {
			setNiValidationError(niValidation.message);
			setTimeout(() => {
				setNiValidationError('');
			}, 2000);
			valid = false;
		}

		return valid;
	};

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<div>
			<div>
				<p className='margin-bottom-24'>
					Enter Applicant’s National Insurance number to continue
				</p>

				<div className='add-candidate-input-row'>
					<div className='add-candidate-col-left'>
						<EditInput
							title='Applicant’s NI number'
							value={formState.niNumber}
							emitValue={emitValue}
							placeholder='QQ123456C'
							inputName='NiNumber'
							validationError={niValidationError}
						></EditInput>
					</div>
				</div>

				<p className='orka-profile-label margin-top-24 margin-bottom-40'>
					National insurance numbers (NINo) should appear in the
					following combination of letters and numbers - two letters,
					six numbers, one letter. For example: QQ123456C. <br></br>
					<br></br>
					Please note that the letters in NINo are not case sensitive.
				</p>
			</div>

			<div className='inline-buttons'>
				<OrkaButton
					buttonContent='Cancel'
					disabled={false}
					emitClicked={closeModal}
				/>
				<OrkaButton
					buttonContent='Next'
					disabled={buttonDisabled}
					emitClicked={handleAddCandidate}
				/>
			</div>
		</div>
	);
};

export default ExtInviteApplicantNi;
