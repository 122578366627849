import React, { FC } from 'react';
import { Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { RootState } from '../../../types/root-state';
import store from '../../../redux/store';
import * as utils from '../../../utils/utilsFunctions';
import KeyVal from '../key-val/key-val.component';
import deploiLogo from '../../../assets/logos/deploi-logo.png';

type Props = {
	clearance: {
		clearanceLevel: string;
		clearanceDate: string;
		clearedBy: string;
	};
};
const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: '#E5E5E5',
		padding: '32px',
	},
	bottomSection: {
		backgroundColor: '#B9B9F9',
		transform: `rotate(30deg)`,
		position: 'absolute',
		bottom: -120,
		left: -200,
		width: 600,
		height: 300,
	},
	bottomSectionRight: {
		backgroundColor: '#7B7DFC',
		transform: `rotate(166deg)`,
		position: 'absolute',
		bottom: -120,
		right: -200,
		width: 800,
		height: 220,
	},
	boxOuter: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 64,
		alignItems: 'flex-end',
	},
	box: {
		height: '4px',
		width: '130px',
		backgroundColor: '#000000',
		textAlign: 'right',
	},
	coverHeaderText: {
		paddingTop: '4px',
		marginLeft: '12px',
		marginBottom: '4px',
		fontWeight: 800,
	},
	footer: {},
	header: {
		height: 100,
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'row',
		borderTop: '1px',
		borderTopStyle: 'solid',
		borderColor: '#000000',
		marginBottom: '140px',
	},
	headerSection: {
		borderLeft: '1px',
		borderLeftStyle: 'solid',
		borderColor: '#000000',
		padding: '8px 46px 8px 14px',
		minHeight: '80px',
		marginTop: '16px',
	},
	logo: {
		width: '100px',
		height: 'auto',
		margin: '0',
	},
	section: {
		flexGrow: 1,
		fontSize: '18px',
	},
});

const FrontPage: FC<Props> = ({ clearance }) => {
	const state: RootState = store.getState();
	const { applicant } = state.profile;
	return (
		<Page size='A4' style={styles.page}>
			<View>
				<div style={styles.boxOuter}>
					<div style={styles.box}></div>
				</div>
				<View style={styles.header}>
					<div style={styles.headerSection}>
						<Text>Full Name</Text>
						<Text>
							{utils.getFullName(
								applicant.title,
								applicant.name,
								applicant.surname,
							)}
						</Text>
					</div>
					<div style={styles.headerSection}>
						<KeyVal
							title='Clearance: '
							value={clearance.clearanceLevel}
						/>
						<KeyVal
							title='Screened by: '
							value={clearance.clearedBy}
						/>
						<KeyVal
							title='Screened on: '
							value={clearance.clearanceDate}
						/>
					</div>
				</View>
			</View>
			<View style={styles.section}>
				<Image src={deploiLogo} cache={false} style={styles.logo} />
				<div style={styles.coverHeaderText}>
					<Text style={{ fontWeight: 800 }}> Screening Report </Text>
				</div>
			</View>
			<View style={styles.footer}>
				<div style={styles.bottomSection} />
				<div style={styles.bottomSectionRight} />
			</View>
		</Page>
	);
};

export default FrontPage;
