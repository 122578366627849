import React, { FC } from 'react';
import ProfileSection from '../profile-section/profile-section.component';
import './rtw-section.styles.scss';
import ProfileDocumentBucket from '../profile-document-bucket/profile-document-bucket.component';
import RtwExpiry from '../rtw-expiry/rtw-expiry.component';
import { useSelector } from 'react-redux';
import ProfileIdentity from '../profile-identity/profile-identity.component';
import { RootState } from '../../../types/root-state';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';

type Props = {
	rtwFileArray: IFileItem[];
	rtwManualDocArray: IFileItem[];
	niFileArray: IFileItem[];
	yotiFileArray: YotiDocs[];
};

const RtwSection: FC<Props> = ({
	rtwFileArray,
	niFileArray,
	rtwManualDocArray,
	yotiFileArray,
}) => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	return (
		<div className='rtw-section-container'>
			<ProfileSection title='Documents'>
				<ProfileDocumentBucket
					tag='rightToWork'
					fileArray={rtwFileArray}
				/>
				<RtwExpiry />
			</ProfileSection>

			<ProfileSection title='NI Number'>
				{applicant.nationalInsuranceNumber && (
					<div className='ni-number'>
						<p>
							{applicant.nationalInsuranceNumber
								? applicant.nationalInsuranceNumber.toUpperCase()
								: 'NI unknown'}
						</p>
					</div>
				)}

				<ProfileDocumentBucket
					tag='proofOfNINumber'
					fileArray={niFileArray}
				/>
			</ProfileSection>

			<ProfileSection title='Right to Work'>
				<ProfileIdentity
					fileArray={rtwManualDocArray}
					fileArrayYoti={yotiFileArray}
				/>
			</ProfileSection>
		</div>
	);
};

export default RtwSection;
