import React, {
	useEffect,
	useState,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import './file-list.styles.scss';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import checkboxBlueTick from '../../../assets/icons/checkbox-blue-tick.svg';
import checkboxCross from '../../../assets/icons/checkbox-fail.svg';
import checkboxBlue from '../../../assets/icons/checkbox-blue.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateFiles,
	setSelectedFiles,
	resetSelectedFiles,
	removeSelectedFile,
} from '../../../redux/Profile/profile.actions';
import trashIcon from '../../../assets/icons/trash.svg';
import FloatingFooter from '../../general/floating-footer/floating-footer.component';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';
import * as docsApi from '../../../api/api.docs';
import * as utils from '../../../utils/utilsFunctions';
import { setAlert } from '../../../redux/General/general.actions';
import { WorkHistoryItemFile } from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import { IFileItem } from '../../../types/interfaces/document.interface';

type Props = {
	fileArray: IFileItem[] | WorkHistoryItemFile[];
	isWork?: boolean;
	setOpenFooter?: Dispatch<SetStateAction<boolean>>;
	openFooter?: boolean;
	isOriginalActivity?: boolean;
	emitFileToDelete?: (uuid: string) => void;
};

const FileList: FC<Props> = ({
	fileArray,
	isWork = false,
	setOpenFooter,
	openFooter,
	isOriginalActivity = false,
	emitFileToDelete,
}) => {
	const dispatch = useDispatch();
	const selectedFiles = useSelector(
		(state: RootState) => state.profile.selectedFiles,
	);
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);
	const [fileToDelete, setFileToDelete] = useState<IFileItem>();
	const [stringLength, setStringLength] = useState<number>();

	useEffect(() => {
		dispatch(resetSelectedFiles());
	}, [dispatch]);

	useEffect(() => {
		if (selectedFiles && setOpenFooter) {
			selectedFiles.length > 0
				? setOpenFooter(true)
				: setOpenFooter(false);
		}
	}, [selectedFiles.length, selectedFiles, setOpenFooter]);

	const handleGetUrl = (file: IFileItem) => {
		if (file.readUrl) {
			return file.readUrl;
		} else {
			return file.fileUrl;
		}
	};

	const handleCheckbox = (file: IFileItem) => {
		switch (file.status) {
			case 'accepted':
				return checkboxBlueTick;
			case 'rejected':
				return checkboxCross;
			case 'selected':
				return checkboxBlue;
			default:
				return checkboxWhite;
		}
	};

	const handleSelectFile = (file: IFileItem, removeSelected = false) => {
		if (file.status !== 'selected' && !removeSelected) {
			file.originalStatus = file.status;
			file.status = 'selected';
			dispatch(setSelectedFiles(file));
		} else {
			file.status = file.originalStatus;
			dispatch(removeSelectedFile(file));
		}
	};

	const handleUpdateItem = (file: IFileItem) => {
		if (!openFooter) {
			setShowAlertConfirm(true);
			setFileToDelete(file);
		}
	};

	useEffect(() => {
		window.innerWidth > 400 ? setStringLength(0) : setStringLength(40);
	}, [dispatch]);

	useEffect(() => {
		const handleResize = () => {
			window.innerWidth > 400 ? setStringLength(0) : setStringLength(40);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [dispatch]);

	const deleteWorkFile = (deletedAt: any, fileToDelete: any) => {
		const payload = {
			deletedAt,
			fileUuid: fileToDelete.uuid,
		};

		return docsApi
			.deleteWorkDocument(applicant, payload)
			.then(() => {
				if (emitFileToDelete) {
					emitFileToDelete(payload.fileUuid);
				}
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully deleted document',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to delete document',
						isVisible: true,
					}),
				);
			});
	};
	const deleteFile = () => {
		if (fileToDelete) {
			const deletedAt = utils.formatDateWithHyphen(new Date());
			if (isWork) {
				return deleteWorkFile(deletedAt, fileToDelete);
			} else {
				docsApi
					.deleteDocument(fileToDelete, applicant, deletedAt)
					.then(() => {
						if (fileArray.length > 0) {
							const updatedFileArray = (fileArray as any[]).map(
								(f: IFileItem) => {
									if (f.uuid === fileToDelete.uuid) {
										f.deletedAt = deletedAt;
									}
									return f;
								},
							);
							dispatch(updateFiles(updatedFileArray));
							dispatch(
								setAlert({
									type: 'success',
									message: 'Successfully deleted document',
									isVisible: true,
								}),
							);
						}
					})
					.catch(() => {
						dispatch(
							setAlert({
								type: 'error',
								message: 'Error deleting document',
								isVisible: true,
							}),
						);
					});
			}
		}
	};

	const handleGetValFromAlert = (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			return deleteFile();
		}
	};

	const resetFiles = () => {
		selectedFiles.forEach((file) => {
			handleSelectFile(file, true);
		});
	};

	return (
		<div className='file-list-container'>
			{(fileArray as any).map((file: IFileItem, index: number) => {
				return (
					<div
						className={
							!isOriginalActivity
								? 'file-item'
								: 'file-item no-padding'
						}
						key={index}
					>
						<div className='file-item-left'>
							{!isOriginalActivity && (
								<div className='checkbox-area'>
									{role !== 'ambassador' && (
										<img
											src={handleCheckbox(file)}
											onClick={() =>
												handleSelectFile(file)
											}
											alt='checkbox'
										/>
									)}
								</div>
							)}

							<div
								className={
									!isOriginalActivity
										? 'file-link'
										: 'file-link no-padding'
								}
							>
								{isWork &&
									!isOriginalActivity &&
									file.contentType &&
									!file.contentType.includes('pdf') && (
										<img
											src={
												file.fileUrl
													? file.fileUrl
													: file.readUrl
											}
											alt='file'
											className='preview-image'
										/>
									)}
								<a
									href={handleGetUrl(file)}
									target='_blank'
									rel='noopener noreferrer'
								>
									{stringLength && stringLength > 0
										? utils.minimizeString(
												file.fileName.replace(
													/deep-dive-documents|work-history-proof/gm,
													'check',
												),
												stringLength,
											)
										: file.fileName.replace(
												/deep-dive-documents|work-history-proof/gm,
												'check',
											)}
								</a>
							</div>
						</div>
						{!isOriginalActivity && role !== 'ambassador' && (
							<>
								<div className='file-item-right'>
									<img
										src={trashIcon}
										onClick={() => handleUpdateItem(file)}
										alt='delete'
									/>
								</div>
								{showAlertConfirm && (
									<AlertConfirm
										message='Are you sure you want to delete this file?'
										getVal={handleGetValFromAlert}
										confirm='Delete'
										reject='Cancel'
									/>
								)}
							</>
						)}
					</div>
				);
			})}
			{openFooter && (
				<FloatingFooter
					items={selectedFiles}
					resetFiles={resetFiles}
					isWork={isWork}
				/>
			)}
		</div>
	);
};

export default FileList;
