import React, { useState, FC } from 'react';
import './profile-sub-section.styles.scss';
import arrowUp from '../../../assets/icons/chevron-up.svg';
import arrowDown from '../../../assets/icons/chevron-down.svg';

type Props = {
	children: React.ReactNode;
	title: string;
};

const ProfileSubSection: FC<Props> = ({ title, children }) => {
	const [showSection, setShowSection] = useState<boolean>(false);
	return (
		<div className='profile-subsection-container'>
			<div
				className='subsection-dropdown-top'
				onClick={() => setShowSection(!showSection)}
			>
				<p>{title}</p>
				<img src={showSection ? arrowUp : arrowDown} alt='arrow' />
			</div>
			{showSection && (
				<div className='subsection-children'>{children}</div>
			)}
		</div>
	);
};

export default ProfileSubSection;
