import {
    SET_ALERT_INFO,
    CLEAR_ALERT_INFO,
    SET_IS_MOBILE,
    SET_IS_LOADING,
} from './general.types';

const INITIAL_STATE = {
    alertInfo: {
        type: '',
        isVisible: false,
        message: ''
    },
    isLoading: false,
    isMobile: false
}

const generalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_ALERT_INFO:
            return {
               ...state,
               alertInfo: action.alertInfo
            };
        case SET_IS_LOADING: 
            return {
                ...state,
                isLoading: action.isLoading,
            }
        case CLEAR_ALERT_INFO:
            return {
                ...state, 
                alertInfo: {
                    type: '',
                    isVisible: false,
                    message: ''
                }

            }
        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile
            }
        default:
            return state;
    }
}


export default generalReducer;