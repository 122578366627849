/* eslint-disable prettier/prettier */
import React, { useEffect, useState, FC } from 'react';
import './profile-id-check-breakdown-section.scss';
import clear from '../../../assets/icons/checkbox-tick.svg';
import consider from '../../../assets/icons/checkbox-fail.svg';
import nullVal from '../../../assets/icons/checkbox-white.svg';
import {
    IIdCheckBreakdownItem,
    IIdCheckBreakdownProperty,
    IIdCheckBreakdownSubItem,
} from '../../../types/interfaces/identity.interface';

type Props = {
    title: string;
    validationType: IIdCheckBreakdownItem;
};

const ProfileIdCheckBreakdownSection: FC<Props> = ({
    title,
    validationType,
}) => {
    const [breakdownArray, setBreakdownArray] = useState([]);

    useEffect(() => {
        let localBreakdownArray: any = [];
        if (validationType) {
            let key: keyof IIdCheckBreakdownSubItem;
            for (key in validationType.breakdown) {
                const objKey = key.replace(/_/g, ' ');
                const objVal = validationType.breakdown[key].result;

                const properties = validationType.breakdown[key].properties;

                let propArray: any = [];
                if (properties) {
                    // eslint-disable-next-line prefer-const
                    for (let prop in properties) {
                        const propItem = prop.replace(/_/g, ' ');
                        propArray = [
                            ...propArray,
                            { item: [propItem], value: properties[prop] },
                        ];
                    }
                }
                localBreakdownArray = [
                    ...localBreakdownArray,
                    { item: objKey, value: objVal, properties: propArray },
                ];
            }
        }
        setBreakdownArray(localBreakdownArray);
    }, [JSON.stringify(validationType)]);


    return (
        <div className="breakdown-item-container">
            <div className="breakdown-item">
                <div className="item first">
                    {
                        validationType.result === null &&
                        (
                            <img src={nullVal} alt='checkbox result'/>
                        )
                    }
                    {
                        validationType.result === 'clear' &&
                        (
                            <img src={clear} alt='checkbox result'/>
                        )
                    }
                    {
                        validationType.result === 'consider' &&
                        (
                            <img src={consider} alt='checkbox result'/>
                        )
                    }
 
                    <p>{title}</p>
    
                </div>

                {
                    breakdownArray.length > 0 && 
                    breakdownArray.map((item: IIdCheckBreakdownSubItem, index: any) => {
                        return (
                            <div key={index}>
                                <div className="item">
                                    <div className="vertical"></div>
                                    {
                                        item.value === null &&
                                        (
                                            <img src={nullVal} alt='checkbox result'/>
                                        )
                                    }
                                    {
                                        item.value  === 'clear' &&
                                        (
                                            <img src={clear} alt='checkbox result'/>
                                        )
                                    }
                                    {
                                        item.value  === 'consider' &&
                                        (
                                            <img src={consider} alt='checkbox result'/>
                                        )
                                    }
                                    <div className="horizontal"></div>
                                    <p className="list-text">{item.item}</p>
                                </div>

                                {
                                    item.properties.length > 0 &&
                                    (
                                        <div>
                                            {
                                                item.properties.map((property: IIdCheckBreakdownProperty, propertyIndex: any) => {
                                                    return (
                                                        <div key={propertyIndex}>
                                                            {
                                                                !propertyIndex[0] 
                                                                && !propertyIndex[propertyIndex.length -1]
                                                                && !index[index.length -1] &&
                                                                (
                                                                    <div className="sub-item-last" ></div>
                                                                )
                                                            }
                                                            <div className="item sub-item">
                                                                {
                                                                    !propertyIndex[0] &&
                                                                    (
                                                                        <>
                                                                            <div className="vertical-first"></div>
                                                                            <div className="vertical"></div>
                                                                        </>
                                                                    )

                                                                }
                                                                {
                                                                    property.value === null &&
                                                                    (
                                                                        <img src={nullVal} alt='checkbox result'/>
                                                                    )
                                                                }
                                                                {
                                                                    property.value  === 'clear' &&
                                                                    (
                                                                        <img src={clear} alt='checkbox result'/>
                                                                    )
                                                                }
                                                                {
                                                                    property.value  === 'consider' &&
                                                                    (
                                                                        <img src={consider} alt='checkbox result'/>
                                                                    )
                                                                }
                                                                <div className="horizontal"></div>
                                                               
                                                          
                                                                <p 
                                                                    className={property.item[0].length > 18 ? 'list-text help': 'list-text'}           
                                                                    // nz-tooltip [nzTooltipTitle]="property.item[0].length > 18 ? property.item : ''"
                                                                >
                                                                    {property.item[0].length > 18 ? property.item[0].substring(0, 15) + '...' : property.item}
                                                                </p>
                                                                
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    )
                                }

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProfileIdCheckBreakdownSection;