import React, { FC, useState, useEffect, useContext } from 'react';
import './activity-verified.styles.scss';
import verified from '../../../assets/icons/activity/verified.svg';
import notVerified from '../../../assets/icons/activity/not-verified.svg';
import { WorkHistoryItem } from '../../../types/interface';
import OrganisationInfo from '../../organisation/organisation-info/organisation-info.component';
import Modal from '../../modals/modal/modal.component';
import OrganisationCreate from '../../organisation/organisation-create/organisation-create.component';
import { useDispatch } from 'react-redux';
import { setFromActivity } from '../../../redux/Navigation/navigation.actions';
import OrganisationContext from '../../../context/OrganisationContext';

type Props = {
	activity: WorkHistoryItem;
};

type PrePopulatedReference = {
	company: string;
	email: string;
	contactNumber: string;
	address: string;
	region: string;
	city: string;
	postcode: string;
	country: string;
};

const ActivityVerified: FC<Props> = ({ activity }) => {
	const dispatch = useDispatch();
	const [statusData, setStatusData] = useState({
		text: 'Organisation not verified',
		icon: notVerified,
	});
	const [showEdit, setShowEdit] = useState<boolean>(false);
	const [openCreateOrganisation, setOpenCreateOrganisation] =
		useState<boolean>(false);
	const [prePopulatedItem, setPrePopulatedItem] =
		useState<PrePopulatedReference | null>(null);

	const activityActions = useContext(OrganisationContext);

	useEffect(() => {
		activity.reference && activity.reference.addedManually === false
			? setStatusData({
					text: 'Organisation is verified',
					icon: verified,
				})
			: setStatusData({
					text: 'Can’t find organisation in the system',
					icon: notVerified,
				});
	}, [JSON.stringify(activity)]);

	const handleAdd = () => {
		setPrePopulatedItem({
			company: activity.name ? activity.name : '',
			email: activity.email ? activity.email : '',
			contactNumber: '',
			address: activity.address?.streetAddress
				? activity.address?.streetAddress
				: '',
			region: activity.address?.region ? activity.address?.region : '',
			city: activity.address?.city ? activity.address?.city : '',
			country: activity.address?.country ? activity.address?.country : '',
			postcode: activity.address?.postcode
				? activity.address?.postcode
				: '',
		});
		setOpenCreateOrganisation(true);
	};

	const handleUseExisting = () => {
		dispatch(setFromActivity(activity));
		activityActions?.toggleOrganisationTable(true);
	};

	const handleEdit = () => {
		setShowEdit(!showEdit);
	};

	return (
		<div className='activity-verified-container'>
			<div className='activity-verified-top'>
				<div className='activity-verified-top-left'>
					<img src={statusData.icon} alt='reference verified' />
					<p
						className={
							activity?.reference?.addedManually
								? 'not-verified'
								: 'verified'
						}
					>
						{statusData.text}
					</p>
				</div>
				{!activity?.reference?.addedManually && (
					<div className='activity-verified-top-right'>
						<button onClick={handleEdit}>Edit</button>
					</div>
				)}
			</div>
			{(showEdit || activity?.reference?.addedManually) && (
				<>
					<OrganisationInfo activityItem={activity} />
					<div className='activity-verified-actions'>
						<button
							className='activity-verified-action'
							onClick={handleAdd}
						>
							Add
						</button>
						<button
							className='activity-verified-action'
							onClick={handleUseExisting}
						>
							Use Existing
						</button>
					</div>
				</>
			)}
			{openCreateOrganisation && (
				<Modal
					title='Create Organisation'
					setShowModal={setOpenCreateOrganisation}
					showModal={openCreateOrganisation}
					modalContentWidth='400px'
				>
					<OrganisationCreate
						setShowModal={setOpenCreateOrganisation}
						prePopulatedItem={prePopulatedItem}
						activityItem={activity}
						refetch={null}
					/>
				</Modal>
			)}
		</div>
	);
};

export default ActivityVerified;
