import React, { useEffect, useState, FC } from 'react';
import './suspend-modal.styles.scss';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import checkboxTick from '../../../assets/icons/checkbox-tick.svg';
import * as apiApplicant from '../../../api/api.applicant';
import * as utils from '../../../utils/utilsFunctions';

import { updateApplicant } from '../../../redux/Profile/profile.actions';
import { suspendReasonList } from '../../../utils/data';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import { IApplicant, SuspendReasons } from '../../../types/interface';
import OrkaButton from '../../general/orka-button/orka-button.component';

type Props = {
	emitClicked: () => void;
	applicant: IApplicant;
	wasSuspended?: () => void;
};

const SuspendModal: FC<Props> = ({
	emitClicked,
	applicant,
	wasSuspended = null,
}) => {
	const dispatch = useDispatch();
	const [reasonList, setReasonList] = useState<SuspendReasons[]>([]);
	const [selectedReason, setSelectedReason] = useState('');
	const [otherReason, setOtherReason] = useState('');

	useEffect(() => {
		setReasonList(suspendReasonList);
	}, []);

	const handleChecklist = (item: SuspendReasons) => {
		setSelectedReason(item.reason);
		if (item.reason !== 'Other') {
			setOtherReason('');
		}
		const updatedReasonList = reasonList.map((reason) => {
			reason.checked = false;
			if (reason.reason === item.reason) {
				reason.checked = !reason.checked;
			}
			return reason;
		});

		setReasonList(updatedReasonList);
	};

	const handleTextareaChange = (e: any) => {
		setOtherReason(e.target.value);
	};

	const isDisabled = () => {
		if (!applicant.suspendedAt) {
			if (selectedReason === 'Other' && !otherReason) {
				return true;
			}
			if (!selectedReason) {
				return true;
			}
		}
		return false;
	};

	const handleSuspension = () => {
		let suspendedMessage: string | null;
		if (selectedReason === 'Other') {
			suspendedMessage = otherReason;
		} else {
			suspendedMessage = selectedReason;
		}

		const suspendedAt = !applicant.suspendedAt
			? utils.formatDateForDb(new Date())
			: null;
		suspendedMessage = !applicant.suspendedAt ? suspendedMessage : null;
		const payload = {
			suspendedAt,
			suspendedMessage,
		};

		apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then(() => {
				dispatch(updateApplicant('suspendedAt', suspendedAt));
				dispatch(updateApplicant('suspendedMessage', suspendedMessage));
				clearState();
				if (suspendedAt && wasSuspended) {
					wasSuspended();
				}
				emitClicked();
				dispatch(
					setAlert({
						type: 'success',
						message: `Successfully ${
							suspendedAt ? 'suspended' : 'unsuspended'
						} applicant`,
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				emitClicked();
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to suspend/unsuspend applicant',
						isVisible: true,
					}),
				);
			});
	};

	const clearState = () => {
		setOtherReason('');
		setSelectedReason('');
		const clearReasonList = reasonList.map((item) => {
			item.checked = false;
			return item;
		});
		setReasonList(clearReasonList);
	};

	return (
		<div className='suspended-modal'>
			{applicant && !applicant.suspendedAt ? (
				<>
					<p className='subheading'>
						Please give a reason why this user has been suspended.
					</p>

					<div>
						{reasonList.map((item) => {
							return (
								<div
									key={item.reason}
									className='suspended-item'
								>
									<img
										src={
											item.checked
												? checkboxTick
												: checkboxWhite
										}
										onClick={() => handleChecklist(item)}
										alt='checkbox'
									/>
									<p>{item.reason}</p>
								</div>
							);
						})}
					</div>

					<div className='other-text'>
						{selectedReason.toLowerCase() === 'other' && (
							<textarea
								value={otherReason}
								onChange={handleTextareaChange}
								placeholder='Please enter a reason for the suspension'
							/>
						)}
					</div>
				</>
			) : (
				<div className='unsuspend'>
					<p>
						This applicant has been suspended for the following
						reason:
					</p>
					<p>
						<em>{applicant.suspendedMessage}</em>
					</p>
					<p>
						Date Suspended:
						{utils.formatDateForUser(applicant.suspendedAt)}
					</p>
				</div>
			)}

			<div className='suspend-button-section'>
				<OrkaButton
					emitClicked={handleSuspension}
					buttonContent={
						applicant && applicant.suspendedAt
							? 'Unsuspend'
							: 'Suspend'
					}
					disabled={isDisabled()}
				/>
			</div>
		</div>
	);
};

export default SuspendModal;
