import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './dashboard-notes-styles.scss';
import editPencil from '../../../assets/icons/edit.svg';
import { IApplicant } from '../../../types/interface';
import NotesModal from '../../profile/notes-modal/notes-modal.component';
import * as apiApplicant from '../../../api/api.applicant';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import { updateApplicantField } from '../../../redux/Dashboard/dashboard.actions';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';

type Props = {
	applicantFromDash: IApplicant;
	setHideCancel: Dispatch<SetStateAction<boolean>> | null;
	setCancelClick: Dispatch<SetStateAction<boolean>> | null;
	fromProfile?: boolean;
};

const DashboardNotes: FC<Props> = ({
	applicantFromDash,
	setHideCancel,
	setCancelClick,
	fromProfile,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		if (setHideCancel) {
			openModal ? setHideCancel(true) : setHideCancel(false);
		}
		if (setCancelClick !== null) {
			openModal ? setCancelClick(true) : setCancelClick(false);
		}
		return () => {
			if (setCancelClick) setCancelClick(false);
		};
	}, [openModal]);

	const handleOpenModal = (event: any) => {
		event.stopPropagation();
		setOpenModal(true);
	};

	const handleUpdateNotes = (val: { notes: string }) => {
		return apiApplicant
			.updateApplicant(applicantFromDash.workerUuid, val)
			.then(() => {
				if (fromProfile) {
					dispatch(updateApplicantMultiple({ notes: val.notes }));
				} else {
					dispatch(
						updateApplicantField(
							applicantFromDash.workerUuid,
							'notes',
							val.notes,
						),
					);
				}

				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated notes',
						isVisible: true,
					}),
				);
				setOpenModal(false);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to update notes',
						isVisible: true,
					}),
				);
				throw err;
			});
	};
	return (
		<div className='dashboard-notes-container'>
			<button className='edit-notes' onClick={handleOpenModal}>
				<img src={editPencil} alt='edit' />
			</button>
			{openModal && (
				<NotesModal
					handleClicked={handleUpdateNotes}
					buttonContent='Save'
					setShowModal={setOpenModal}
					showModal={openModal}
					noteValue={applicantFromDash.notes}
					type='notes'
					modalTitle='Notes'
					placeholder='Applicant notes'
					disableTextBox={false}
				/>
			)}
		</div>
	);
};

export default DashboardNotes;
