import React, { useEffect, useState, FC } from 'react';
import './stage-complete.styles.scss';
import * as stageApi from '../../../api/api.stage';
import { useDispatch, useSelector } from 'react-redux';
import { setIndividualStage } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import stageTick from '../../../assets/icons/stage-tick.svg';
import { RootState } from '../../../types/root-state';
import { IStageDataItem } from '../../../types/interfaces/stage.interface';

type Props = {
	clickable?: boolean;
	stageData?: IStageDataItem | null;
};

const StageComplete: FC<Props> = ({ clickable, stageData }) => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const stageDataList = useSelector(
		(state: RootState) => state.profile.stageData,
	);
	const [stageCompleteClass, setStageCompleteClass] = useState<string>('');
	const role = useSelector((state: RootState) => state.auth.role);

	const dispatch = useDispatch();

	useEffect(() => {
		if (stageData) {
			stageData.stageComplete
				? setStageCompleteClass('stage-complete')
				: setStageCompleteClass('stage-incomplete');
		}
	}, [JSON.stringify(stageData)]);

	const manageStage = () => {
		if (!clickable || role === 'ambassador') {
			return;
		}

		if (stageData && stageDataList.length > 0) {
			const stageComplete = !stageData.stageComplete;

			if (stageData?.title === 'Right to work') {
				const found = stageDataList.filter((element) => {
					return element.title === 'ID Verification';
				});
				const payload = {
					uuid: found[0].uuid,
					stageComplete,
					stageCompletedAt: stageComplete ? new Date() : null,
				};
				handleCompleteStage(payload);
			}

			const payload = {
				uuid: stageData.uuid,
				stageComplete,
				stageCompletedAt: stageComplete ? new Date() : null,
			};
			handleCompleteStage(payload);
		}
	};

	const handleCompleteStage = (payload: any) => {
		stageApi
			.updateStage(applicant.workerUuid, payload)
			.then((res) => {
				if (res.status !== 404) {
					dispatch(setIndividualStage(res.data));
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully completed stage',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div
			className={
				clickable
					? `stage-complete-container ${stageCompleteClass}`
					: 'stage-complete-container not-clickable'
			}
			onClick={manageStage}
		>
			{clickable && role !== 'ambassador' && (
				<div className={'stage-complete-inner'}>
					{stageData && stageData.stageComplete && (
						<img src={stageTick} alt='tick' />
					)}
				</div>
			)}
		</div>
	);
};

export default StageComplete;
