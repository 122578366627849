import React, { FC, useEffect, useState } from 'react';
import './dashboard-sortby.styles.scss';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { SortByDataItem } from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';
import {
	setGlobalSortBy,
	setGlobalSortDir,
	setPage,
	setStatusQuery,
} from '../../../redux/Dashboard/dashboard.actions';

const DashboardSortby: FC = () => {
	const dispatch = useDispatch();
	const role = useSelector((state: RootState) => state.auth.role);
	const [sortByData, setSortByData] = useState<SortByDataItem[]>([]);
	const [sortByDefaultData, setSortByDefaultData] =
		useState<SortByDataItem>();
	const globalSortBy = useSelector(
		(state: RootState) => state.dashboard.sortBy,
	);
	const globalSortDir = useSelector(
		(state: RootState) => state.dashboard.sortDirection,
	);

	useEffect(() => {
		setSortByData(utils.getDropdownData('sortBy', null));
		dispatch(setGlobalSortBy(globalSortBy));
		dispatch(setGlobalSortDir(globalSortDir));
	}, [dispatch]);

	useEffect(() => {
		if (sortByData.length > 0) {
			const defaultDataFromQuery = sortByData.filter((item) => {
				return item.dbValue === globalSortBy;
			});
			defaultDataFromQuery.length > 0
				? setSortByDefaultData(defaultDataFromQuery[0])
				: setSortByDefaultData(sortByData[0]);
		}
	}, [sortByData.length, globalSortBy, globalSortDir]);

	const forceLimitedClearance = (val: string): void => {
		if (val === 'provisionallyCleared') {
			dispatch(setStatusQuery('partially_compliant'));
		} else {
			dispatch(setStatusQuery('all'));
		}
	};

	const getSortByDropdownVal = (val: SortByDataItem): void => {
		const sortDirection = val.sortAsc ? 'ASC' : 'DESC';
		setSortByDefaultData(val);
		dispatch(setPage(1));
		dispatch(setGlobalSortBy(val.dbValue));
		dispatch(setGlobalSortDir(sortDirection));
		forceLimitedClearance(val.dbValue);
	};

	return (
		<div>
			{sortByDefaultData && role !== 'ambassador' && (
				<div className='sort-by-section'>
					<div className='sort-by-text'>
						<p>Sort By</p>
					</div>
					<Dropdown
						type='sortBy'
						dropdownData={sortByData}
						defaultData={sortByDefaultData}
						getDropdownVal={getSortByDropdownVal}
						width='140'
						position='bottom-right'
						height={24}
					/>
				</div>
			)}
		</div>
	);
};

export default DashboardSortby;
