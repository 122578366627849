import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import './activity-email-modal.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { useReactToPrint } from 'react-to-print';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { WorkHistoryItem } from '../../../types/interface';
import * as apiReference from '../../../api/api.reference';
import * as utils from '../../../utils/utilsFunctions';
import downloadBlue from '../../../assets/icons/activity/download-blue.svg';
import uploadBlue from '../../../assets/icons/upload-dark.svg';
import { setAlert } from '../../../redux/General/general.actions';

type SubmissionData = {
	html: string;
	image: string;
	date: string;
};

type Props = {
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	activityItem: WorkHistoryItem;
};

const ActivityEmailModal: FC<Props> = ({ setOpenModal, activityItem }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [submissionData, setSubmissionData] = useState<SubmissionData>();
	const [attachment, setAttachment] = useState<any>();
	const overallSubmission = useSelector(
		(state: RootState) => state.profile.overallSubmission,
	);
	const componentRef = useRef<any>(null);

	useEffect(() => {
		const formatData = async () => {
			if (overallSubmission.data) {
				const string = overallSubmission.data.formSigned.substring(
					1,
					overallSubmission.data.formSigned.length - 1,
				);

				let html = await atob(string);

				html = html.replace(/â¢/g, '&#8226;');
				html = html.replace(/â/g, '-');
				const image = overallSubmission.data.signatureUrl;
				const date = utils.formatDateForUser(
					overallSubmission.data.signedDate,
				);

				const data = {
					html,
					image,
					date,
				};

				setSubmissionData(data);
			}
		};
		formatData();
	}, [
		overallSubmission.data.formSigned,
		overallSubmission.data.signedDate,
		overallSubmission.data.signatureUrl,
		overallSubmission.data,
	]);

	const handleDownload = useReactToPrint({
		content: () => {
			return componentRef.current;
		},
	});

	const handleDownloadNonWorks = () => {
		window.open(overallSubmission.data.signatureUrl, '_blank', 'noopener');
	};

	const handleAddAttachment = (event: any) => {
		setAttachment(event.target.files[0]);
	};

	const handleSendEmail = async () => {
		const attached = await toBase64(attachment).catch((err) => {
			throw err;
		});

		const name = `${applicant.name} ${applicant.surname}`;

		if (attachment) {
			const payload = {
				userUuid: applicant.uuid,
				attachment: attached,
				email: activityItem.email,
				toDate: utils.formatDateForDb(activityItem.endAt),
				fromDate: utils.formatDateForDb(activityItem.startAt),
				jobTitle: activityItem.jobTitle
					? utils.capitalizeFirstLetter(activityItem.jobTitle)
					: null,
				name: utils.capitalizeFirstLetterOfEach(name),
				type: activityItem.type,
				activity: activityItem,
			};

			return apiReference
				.sendEmail(payload)
				.then(() => {
					setOpenModal(false);
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully sent reference email',
							isVisible: true,
						}),
					);
				})
				.catch((err) => {
					setOpenModal(false);
					dispatch(
						setAlert({
							type: 'error',
							message: err.message,
							isVisible: true,
						}),
					);
					throw err;
				});
		}
	};

	const toBase64 = (file: any) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	return (
		<div className='activity-email-modal'>
			<div className='email-modal-step-one'>
				<p>Step 1: Download Worker Declaration</p>
				<img
					src={downloadBlue}
					alt='download'
					onClick={
						applicant.flow === 'non-works'
							? handleDownloadNonWorks
							: handleDownload
					}
				/>
			</div>

			{submissionData && (
				<div style={{ display: 'none' }}>
					<div ref={componentRef} className='declaration-content'>
						<div className='print-heading'>
							<h1>Worker Declaration</h1>
						</div>

						<div
							dangerouslySetInnerHTML={{
								__html: submissionData.html,
							}}
						/>
						<div>
							<p>
								{utils.getFullName(
									null,
									applicant.name,
									applicant.surname,
								)}
							</p>
							<img src={submissionData.image} alt='signature' />
							<p>
								{utils.formatDateForUser(submissionData.date)}
							</p>
						</div>
					</div>
				</div>
			)}
			<div className='email-modal-step-two'>
				<div className='email-modal-step-two-upload'>
					<p>Step 2: Add worker declaration as an attachment</p>
					<div className='file-pick-container'>
						<label
							htmlFor='file-upload'
							className='custom-file-upload'
						>
							<img src={uploadBlue} alt='upload' />
						</label>
						<input
							id='file-upload'
							type='file'
							onChange={handleAddAttachment}
							accept='.pdf'
							name='file'
							className='file-pick'
						/>
					</div>
				</div>
				{attachment && (
					<p className='attached'>Attached: {attachment.name}</p>
				)}
			</div>

			<div className='modal-button'>
				<OrkaButton
					buttonContent='Send'
					disabled={!attachment}
					emitClicked={handleSendEmail}
				/>
			</div>
		</div>
	);
};

export default ActivityEmailModal;
