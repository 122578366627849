import React, { FC } from 'react';
import './activity-item.styles.scss';

type Props = {
	children: React.ReactNode;
	label?: string;
	icon?: null | string;
	email?: string;
	emitFunction?: () => void;
};

const ActivityItem: FC<Props> = ({
	children,
	label,
	icon = null,
	email,
	emitFunction,
}) => {
	return (
		<div className='activity-item-container'>
			<p className='orka-profile-label'>{label}</p>
			<div className='activity-item-value'>
				<div className='children'>{children}</div>
				{icon && email && emitFunction && (
					<img
						src={icon}
						alt={label}
						className='pointer'
						onClick={emitFunction}
					/>
				)}
				{icon && !email && (
					<img src={icon} alt={label} className='icon' />
				)}
			</div>
		</div>
	);
};

export default ActivityItem;
