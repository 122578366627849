import React, { FC, Dispatch, SetStateAction } from 'react';
import './invite-staff-modal-section.styles.scss';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import checkboxSelected from '../../../assets/icons/checkbox-blue.svg';

type Props = {
	title: string;
	contentText: string;
	setAccess: Dispatch<SetStateAction<string>>;
	checked: boolean;
	checkVal: string;
};

const InviteStaffModalSection: FC<Props> = ({
	title,
	contentText,
	setAccess,
	checked,
	checkVal,
}) => {
	return (
		<div className='invite-staff-modal-section'>
			<div className='invite-staff-modal-section-top'>
				<h3>{title}</h3>
				<div>
					<img
						onClick={() => setAccess(checkVal)}
						src={checked ? checkboxSelected : checkboxWhite}
						alt='checkbox'
					/>
				</div>
			</div>

			<div className='invite-staff-content'>
				<p>{contentText}</p>
			</div>
		</div>
	);
};

export default InviteStaffModalSection;
