import {
	ILoginPayload,
	IInvitePayload,
	ICreatePayload,
	IClaimInvitePayload,
	IVerifySmsPayload,
	IResetPasswordPayload,
	IForgotPasswordPayload,
} from '../types/interfaces/auth.interface';
import { axiosInstance } from './api.axiosInstance';

export const login = (payload: ILoginPayload): Promise<any> => {
	return axiosInstance()
		.post(`user/login`, payload)
		.catch((err) => {
			throw err;
		});
};

export const logout = (): Promise<any> => {
	return axiosInstance()
		.post(`user/logout`)
		.catch((err) => {
			throw err;
		});
};

export const invite = (payload: IInvitePayload): Promise<any> => {
	const url = `user/invite`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const create = (payload: Partial<ICreatePayload>): Promise<any> => {
	const url = `user/create`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const claimInvite = (payload: IClaimInvitePayload): Promise<any> => {
	const url = `user/claim-invite`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const verifySms = (payload: IVerifySmsPayload): Promise<any> => {
	const url = `user/verify-sms`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const resetPassword = (
	payload: IResetPasswordPayload,
	token: string,
): Promise<any> => {
	const url = `user/reset-password`;
	return axiosInstance()
		.post(url, payload, { headers: { Authorization: `Bearer ${token}` } })
		.catch((err) => {
			throw err;
		});
};

export const forgotPassword = (
	payload: IForgotPasswordPayload,
): Promise<any> => {
	const url = `user/forgot-password`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};
