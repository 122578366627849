import React, { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import './profile-names.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import { RootState } from '../../../types/root-state';
import { NameHistoryItem } from '../../../types/interface';

const ProfileNames: FC = () => {
	const nameHistory = useSelector(
		(state: RootState) => state.profile.nameHistory,
	);
	const [legalNames, setLegalNames] = useState<NameHistoryItem[]>([]);
	const [aliasNames, setAliasNames] = useState<NameHistoryItem[]>([]);

	useEffect(() => {
		const legal = nameHistory.filter((name) => {
			return name.nameType === 'legal';
		});

		const alias = nameHistory.filter((name) => {
			return name.nameType === 'alias';
		});

		setLegalNames(legal);
		setAliasNames(alias);
	}, [nameHistory]);

	return (
		<div className='profile-names'>
			<div className='name-col'>
				{legalNames.length > 0 ? (
					legalNames.map((name, index) => {
						return (
							<div key={name.id} className='margin-bottom-16'>
								{
									<div>
										<p className='orka-profile-label'>
											Legal Name {index + 1}
										</p>

										<p className='orka-profile-value'>
											{utils.getFullNameHistory(
												name.forename,
												name.middleNames,
												name.surname,
											)}
										</p>

										<p className='orka-profile-value'>
											{utils.formatDateForUser(
												name.startAt,
											)}
											-
											{name.endAt
												? utils.formatDateForUser(
														name.endAt,
													)
												: 'Present'}
										</p>
									</div>
								}
							</div>
						);
					})
				) : (
					<ProfileDisplayItem
						label='Legal Names'
						value='None Submitted'
					/>
				)}
			</div>

			<div className='name-col'>
				{aliasNames.length > 0 ? (
					aliasNames.map((name, index) => {
						return (
							<div key={name.id} className='margin-bottom-16'>
								{
									<div>
										<p className='orka-profile-label'>
											Alias Name {index + 1}
										</p>
										<p className='orka-profile-value'>
											{utils.getFullNameHistory(
												name.forename,
												name.middleNames,
												name.surname,
											)}
										</p>
										<p className='orka-profile-value'>
											{utils.formatDateForUser(
												name.startAt,
											)}
											-
											{name.endAt
												? utils.formatDateForUser(
														name.endAt,
													)
												: 'Present'}
										</p>
									</div>
								}
							</div>
						);
					})
				) : (
					<ProfileDisplayItem
						label='Alias Names'
						value='None Submitted'
					/>
				)}
			</div>
		</div>
	);
};

export default ProfileNames;
