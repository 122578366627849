import React, { Dispatch, useState, SetStateAction, FC } from 'react';
import './clearnace-date.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import EditInput from '../../general/edit-input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as apiApplicant from '../../../api/api.applicant';
import { updateClearance } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import { ClearanceDateInterface } from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';
type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	clearanceType: keyof ClearanceDateInterface;
};

const ClearanceDate: FC<Props> = ({ setShowModal, clearanceType }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	const value = clearance?.[clearanceType]
		? new Date(clearance?.[clearanceType])
		: null;

	const [state, setState] = useState({
		[clearanceType]: value,
	});

	const emitValue = (val: Date) => {
		setState({
			[clearanceType]: val,
		});
	};

	const handleSave = () => {
		const payload = {
			[clearanceType]: utils.formatDateForDb(state[clearanceType]),
		};
		apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then(() => {
				dispatch(updateClearance(payload));
				setShowModal(false);
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated clearance date',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				setShowModal(false);
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updated clearance date',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div className='clearance-date-section'>
			<div>
				<EditInput
					title='Date Cleared'
					value={state[clearanceType] ? state[clearanceType] : null}
					emitValue={emitValue}
					placeholder='Click Calendar'
					inputName={clearanceType}
				></EditInput>
			</div>

			<div className='modal-button'>
				<OrkaButton
					buttonContent='Save'
					disabled={false}
					emitClicked={handleSave}
				/>
			</div>
		</div>
	);
};

export default ClearanceDate;
