import moment from 'moment';
import * as data from './data.js';
import {
	LogApplicationOrkaCheck,
	LogEnvironment,
	LogLevel,
} from '@deploi-uk/package-monitoring/lib/enums';
import { createLog } from '@deploi-uk/package-monitoring/lib/log.js';
import { sendLog } from '../api/api.misc';

export const getFullName = (title, name, surname) => {
	const fullName = `${title || ''} ${name} ${surname}`;
	return fullName.trim();
};

export const getFullNameHistory = (name, middleName, surname) => {
	return `${name} ${middleName ? middleName + ' ' : ''}${surname}`;
};

export const formatDateForUser = (date) => {
	const newDate = new Date(date);

	return newDate instanceof Date && !isNaN(newDate) && date !== null
		? moment(newDate).format('DD/MM/YYYY')
		: 'N/A';
};

export const formatDateForDb = (date) => {
	if (!date) {
		return null;
	}
	return moment(new Date(date)).format('YYYY/MM/DD');
};

export const formatDateWithHyphen = (date) => {
	if (!date) {
		return null;
	}
	return moment(new Date(date)).format('YYYY-MM-DD');
};

export const refactorHyphenDate = (date) => {
	if (!date) {
		return null;
	}
	const [year, month, day] = date.split('-');
	return `${day}/${month}/${year}`;
};

//needs integration test
export const getDropdownData = (type, applicant) => {
	const optionData = mergeArray(data.dropdownData[type], type, applicant);
	return optionData;
};

export const getDynamicTableData = (bucket) => {
	let tableData;
	for (let key in data.dynamicTableData) {
		if (bucket === key) {
			tableData = data.dynamicTableData[key];
		}
	}
	return tableData;
};

export const getDateNow = () => {
	return moment().toDate();
};

const mergeArray = (dropDownData, type, data) => {
	let newData = dropDownData;
	if (type === 'actionList') {
		newData = mergeArrayActionListHelper(dropDownData, data);
	}
	if (type === 'complianceStatus') {
		newData = disableComplianceStatus(dropDownData, data);
	}

	return newData;
};

const mergeArrayActionListHelper = (dropDownData, data) => {
	const newDropdownData = dropDownData.map((el) => {
		if (el.value.toLowerCase().includes('suspend')) {
			el.isSuspended = data.suspendedAt ? true : false;
			el.value = el.isSuspended ? 'Unsuspend' : 'Suspend';
		}
		return el;
	});

	return newDropdownData;
};

export const disableComplianceStatus = (dropdownData, applicant) => {
	let disabled = [];

	switch (applicant.clearance?.clearanceStatus) {
		case 'fully_compliant':
			disabled = [false, false, false];
			break;
		case 'partially_compliant':
			disabled = [false, false, false];
			break;
		default:
			disabled = [false, false, false];
			break;
	}

	// return new mapped array with copies of all elements.
	return dropdownData.map((el, i) => ({
		...el,
		disabled: !!disabled[i],
	}));
};

export const calculateDaysLeftToClear = (applicant) => {
	const endDate = moment(applicant.clearance?.provisionallyClearedDate).add(
		83,
		'days',
	);
	const now = moment();
	let difference = endDate.diff(now, 'days');
	let string = `${difference} days left`;

	if (applicant.clearance?.clearanceStatus === 'fully_compliant') {
		string = 'Cleared';
		return string;
	}

	if (typeof difference === 'number' && difference < 0) {
		string = `Expired`;
		return string;
	}

	return string;
};

export const sortByDate = (array) => {
	return array.sort((a, b) => {
		if (!b.endAt) {
			b.endAt = moment();
		}
		if (!a.endAt) {
			a.endAt = moment();
		}
		return +new Date(b.endAt) - +new Date(a.endAt);
	});
};

export const removeHyphen = (string) => {
	if (!string) {
		return;
	}
	return string.replace(/-/g, ' ');
};

export const removeUnderscore = (string) => {
	if (!string) {
		return;
	}
	return string.replace(/_/g, ' ');
};

export const removeSpaces = (inputString) => {
	return inputString.replace(/\s/g, ''); // This regular expression /\s/g matches all whitespace characters globally.
};

export const formatCamelCase = (string) => {
	if (!string) {
		return;
	}
	var pascalCase = string.charAt(0).toUpperCase() + string.substr(1);
	return pascalCase
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
		.replace(/([a-z])([0-9])/gi, '$1 $2')
		.replace(/([0-9])([a-z])/gi, '$1 $2');
};

export const capitalizeFirstLetter = (string) => {
	if (!string) {
		return;
	}
	return string[0].toUpperCase() + string.substring(1);
};

export const capitalizeFirstLetterOfEach = (string) => {
	if (!string) {
		return;
	}
	return string
		.split(' ')
		.map((item) => capitalizeFirstLetter(item))
		.join(' ');
};

export const minimizeString = (string, num) => {
	if (!string) {
		return;
	}
	if (string.length <= num) {
		return string;
	}
	return `${string.substring(0, num)}...`;
};

export const validateTaskType = (string) => {
	let newString = removeHyphen(string).split(' ');
	if (newString[0] === 'rtw' || newString[0] === 'sia') {
		newString[0] = newString[0].toUpperCase();
	}

	return capitalizeFirstLetterOfEach(newString.join(' '));
};

export const instantScroll = () => {
	history.scrollRestoration = 'manual';
	window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
};

export const handleAlerts = (type, message, dispatch) => {
	dispatch(setAlert({ type, message: message, isVisible: true }));
};

export const logData = (
	logName,
	level, // 'CRITICAL' | 'ERROR' | 'WARNING' | 'DEBUG' | 'INFO',
	logIdentifier = '',
	stackError,
	applicantWorkerUuid = 'None',
) => {
	const data = {
		applicantUuid: applicantWorkerUuid,
		level: LogLevel[level],
		message: logName,
		application: LogApplicationOrkaCheck.CONTROL_ROOM,
		environment: capitalizeFirstLetter(process.env.REACT_APP_ENV),
		time: new Date().toISOString(),
		logIdentifier: logIdentifier,
		version: process.env.REACT_APP_VERSION
			? process.env.REACT_APP_VERSION
			: '',
		context: window.location.href,
		error: {
			stack: JSON.stringify(stackError),
		},
	};
	const log = createLog(data);
	sendLog(JSON.stringify(log));
};


export const getDateFromNow = (date, days) => {
	const now = new Date(date);
	const fdate = now.setDate(now.getDate() + days);
	return new Date(fdate);
};

export const generateUniqueKey = () => {
	return (
		Math.random().toString(32).substring(2) +
		Math.random().toString(32).substring(2)
	);
};

export const getYotiDocumentsByFlow = (flow, data) => {
	const found = data.candidates.find((candidate) =>
		candidate.session.flow.includes(flow),
	);
	return found ? found.documents : [];
};

export const getDocumentsByImageType = (imageType, docs) => {
	return docs?.filter((doc) => doc.imageType === imageType) || [];
};

export const mapYotiDocsImages = (data) => {
	const yotiPassportDocs = getYotiDocumentsByFlow('uk-irish-passport', data);
	const yotiOther = getYotiDocumentsByFlow('other-rtw', data);
	const yotiPassportOnly = getDocumentsByImageType(
		'yotiDocument',
		yotiPassportDocs,
	);
	const yotiLivenessOnly = getDocumentsByImageType(
		'yotiLivenessCapture',
		yotiPassportDocs,
	);
	const identityVerificationDocs = [...yotiOther, ...yotiLivenessOnly].sort(
		(a) => {
			// Sort to show yotiDocuments first before livenessCheck docs.
			if (a.imageType === 'yotiDocument') return -1;
			return 0;
		},
	);
	return {
		yotiRtwArray: yotiPassportOnly,
		yotiFaceToFaceArray: identityVerificationDocs,
	};
};
