import React, { FC, useState, SetStateAction, Dispatch } from 'react';
import './face-to-face-modal.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import EditInput from '../../general/edit-input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as apiApplicant from '../../../api/api.applicant';
import { updateApplicantMultiple } from '../../../redux/Profile/profile.actions';
import { setAlert } from '../../../redux/General/general.actions';
import * as utils from '../../../utils/utilsFunctions';
import { RootState } from '../../../types/root-state';
import { IApplicant } from '../../../types/interface';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
};

type State = {
	f2fCompletedDate: string | Date | null;
	f2fCompletedBy: string;
};

const FaceToFaceModal: FC<Props> = ({ setShowModal }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const [state, setState] = useState<State>({
		f2fCompletedBy: applicant.f2fCompletedBy
			? applicant.f2fCompletedBy
			: '',
		f2fCompletedDate: applicant.f2fCompletedDate
			? new Date(applicant.f2fCompletedDate)
			: null,
	});

	const emitValue = (val: string, inputName: keyof IApplicant) => {
		setState({
			...state,
			[inputName]: val,
		});
	};

	const handleSave = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const payload: any = {};

		let key: keyof State;

		for (key in state) {
			if (state[key] !== null) {
				payload[key] =
					key === 'f2fCompletedDate'
						? utils.formatDateForDb(state[key])
						: state[key];
			}
		}

		apiApplicant
			.updateApplicantApi(applicant.uuid, payload)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				dispatch(updateApplicantMultiple(payload));
				setShowModal(false);
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully updated face to face info',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				setShowModal(false);

				dispatch(
					setAlert({
						type: 'error',
						message: 'Error updated face to face info',
						isVisible: true,
					}),
				);
			});
	};

	return (
		<div className='face-to-face-section'>
			<div>
				<EditInput
					title='Ambassador Name'
					value={state.f2fCompletedBy}
					emitValue={emitValue}
					placeholder='Ambassador Name'
					inputName='f2fCompletedBy'
				></EditInput>

				<EditInput
					title='Date Completed'
					value={
						state.f2fCompletedDate
							? new Date(state.f2fCompletedDate)
							: null
					}
					emitValue={emitValue}
					placeholder='Click Calendar'
					inputName='f2fCompletedDate'
				></EditInput>
			</div>

			<div className='modal-button'>
				<OrkaButton
					buttonContent='Save'
					disabled={false}
					emitClicked={handleSave}
				/>
			</div>
		</div>
	);
};

export default FaceToFaceModal;
