import allSelected from '../assets/icons/sidebar/all-users-selected.svg';
import allUsers from '../assets/icons/sidebar/all-users.svg';
import allDark from '../assets/icons/sidebar/all-users-dark.svg';

import toClear from '../assets/icons/sidebar/to-clear.svg';
import toClearDark from '../assets/icons/sidebar/to-clear-dark.svg';
import toClearBlue from '../assets/icons/sidebar/to-clear-blue.svg';

import unassigned from '../assets/icons/sidebar/unassigned.svg';
import unassignedDark from '../assets/icons/sidebar/unassigned-dark.svg';
import unassignedBlue from '../assets/icons/sidebar/unassigned-blue.svg';

import awaiting from '../assets/icons/sidebar/awaiting-submission.svg';
import awaitingDark from '../assets/icons/sidebar/awaiting-submission-dark.svg';
import awaitingBlue from '../assets/icons/sidebar/awaiting-submission-blue.svg';

import toTopUp from '../assets/icons/sidebar/to-top-up.svg';
import toTopUpDark from '../assets/icons/sidebar/to-top-up-dark.svg';
import toTopUpBlue from '../assets/icons/sidebar/to-top-up-blue.svg';

export const dropdownData = {
	searchBy: [
		{
			id: 1,
			value: 'ID/UUID',
			dbValue: 'uuid',
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 2,
			value: 'First Name',
			dbValue: 'name',
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 3,
			value: 'Surname',
			dbValue: 'surname',
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 4,
			value: 'Email',
			dbValue: 'email',
			class: 'dropdown-grey',
			selected: true,
		},
		{
			id: 5,
			value: 'NI Number',
			dbValue: 'nationalInsuranceNumber',
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 6,
			value: 'Tag',
			dbValue: 'tag',
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 7,
			value: 'Location',
			dbValue: 'location',
			class: 'dropdown-grey',
			selected: false,
		},
	],
	statusFilter: [
		{
			id: 1,
			value: 'All',
			dbValue: 'all',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Not Compliant',
			dbValue: 'not_compliant',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'Limited Screening',
			dbValue: 'partially_compliant',
			class: 'dropdown-grey',
		},
		{
			id: 4,
			value: 'Fully Compliant',
			dbValue: 'fully_compliant',
			class: 'dropdown-green',
		},
	],
	flowFilter: [
		{
			id: 1,
			value: 'All',
			dbValue: 'all',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Orka Check',
			dbValue: 'deepdive',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'CV Online',
			dbValue: 'cv-online',
			class: 'dropdown-grey',
		},
		{
			id: 4,
			value: 'Non-Works',
			dbValue: 'non-works',
			class: 'dropdown-grey',
		},
	],
	submissionFilter: [
		{
			id: 1,
			value: 'All',
			dbValue: 'all',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Pending',
			dbValue: 'pending',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'Accepted',
			dbValue: 'accepted',
			class: 'dropdown-grey',
		},
		{
			id: 4,
			value: 'Rejected',
			dbValue: 'rejected',
			class: 'dropdown-grey',
		},
		{
			id: 5,
			value: 'Cancelled',
			dbValue: 'cancelled',
			class: 'dropdown-grey',
		},
	],
	sortBy: [
		{
			id: 1,
			value: 'Urgent',
			dbValue: 'urgent',
			sortAsc: false,
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Name',
			dbValue: 'name',
			sortAsc: false,
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'Email',
			dbValue: 'email',
			sortAsc: false,
			class: 'dropdown-grey',
		},
		{
			id: 4,
			value: 'Created Date',
			dbValue: 'createdAt',
			sortAsc: false,
			class: 'dropdown-grey',
		},
		{
			id: 5,
			value: 'Last Chased',
			dbValue: 'lastChased',
			sortAsc: false,
			class: 'dropdown-grey',
		},
		{
			id: 6,
			value: 'Submission Date',
			dbValue: 'submissionDate',
			sortAsc: false,
			class: 'dropdown-grey',
		},
		{
			id: 7,
			value: 'Clearance Expiry',
			dbValue: 'provisionallyCleared',
			sortAsc: true,
			class: 'dropdown-grey',
		},
	],
	submissionStatus: [
		{
			id: 1,
			value: 'Pending',
			dbValue: 'pending',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Accept',
			dbValue: 'accepted',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'Reject',
			dbValue: 'rejected',
			class: 'dropdown-grey',
		},
		{
			id: 4,
			value: 'Cancel',
			dbValue: 'cancelled',
			class: 'dropdown-grey',
		},
	],
	complianceStatus: [
		{
			id: 1,
			value: 'None',
			dbValue: 'not_compliant',
			class: 'dropdown-grey',
			disabled: false,
		},
		{
			id: 2,
			value: 'Limited Screening',
			dbValue: 'partially_compliant',
			class: 'dropdown-yellow',
			disabled: false,
		},
		{
			id: 3,
			value: 'Fully',
			dbValue: 'fully_compliant',
			class: 'dropdown-green',
			disabled: false,
		},
	],
	flowDropdown: [
		{
			id: 1,
			value: 'Id Check',
			dbValue: 'Id-Check',
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 2,
			value: 'CV Online',
			dbValue: 'cv-online',
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 3,
			value: 'Orka Check',
			dbValue: 'deepdive',
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 3,
			value: 'Non-Works',
			dbValue: 'non-works',
			class: 'dropdown-grey',
			disabled: false,
		},
	],
	defaultFlowDropdown: [
		{
			id: 1,
			value: 'CV Online',
			dbValue: 'cv-online',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Orka Check',
			dbValue: 'deepdive',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'Non-Works',
			dbValue: 'non-works',
			class: 'dropdown-grey',
		},
	],
	actionList: [
		{
			id: 1,
			value: 'Suspended',
			openModal: true,
			isSuspended: false,
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Pause',
			dbValue: 'paused',
			class: 'dropdown-grey',
		},
	],
	employmentTypes: [
		{
			id: 0,
			dbValue: 'select',
			value: 'Select',
			fullView: true,
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 1,
			dbValue: 'employed',
			value: 'Employed',
			fullView: true,
			class: 'dropdown-grey',
		},
		{
			id: 2,
			dbValue: 'self-employed',
			value: 'Self Employed',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 3,
			dbValue: 'studying',
			value: 'Studying',
			fullView: true,
			class: 'dropdown-grey',
		},
		{
			id: 4,
			dbValue: 'carer',
			value: 'Carer',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 6,
			dbValue: 'medical-reasons',
			value: 'Medical Reasons',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 7,
			dbValue: 'travelling',
			value: 'Travelling',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 8,
			dbValue: 'volunteering',
			value: 'Volunteering',
			fullView: true,
			class: 'dropdown-grey',
		},
		{
			id: 9,
			dbValue: 'parental-cover',
			value: 'Parental Cover',
			fullView: true,
			class: 'dropdown-grey',
		},
		{
			id: 10,
			dbValue: 'job-seeking',
			value: 'Job Seeking',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 11,
			dbValue: 'self-study',
			value: 'Self Study',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 12,
			dbValue: 'another-reason',
			value: 'Another Reason',
			fullView: false,
			class: 'dropdown-grey',
		},
	],
	workPattern: [
		{
			id: 0,
			value: 'Select',
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 1,
			value: 'Full Time',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Part Time',
			class: 'dropdown-grey',
		},
	],
	faceToFaceActions: [
		{
			id: 1,
			value: 'Pending',
			dbValue: 'pending',
			class: 'dropdown-grey light',
		},
		{
			id: 2,
			value: 'Approve',
			dbValue: 'passed',
			class: 'dropdown-grey light',
		},
		{
			id: 3,
			value: 'Reject',
			dbValue: 'failed',
			class: 'dropdown-grey light',
		},
		{
			id: 4,
			value: 'Started',
			dbValue: 'started',
			class: 'dropdown-grey light',
		},
	],
	rtwManualDocActions: [
		{
			id: 1,
			value: 'Pending',
			dbValue: 'unselected',
			class: 'dropdown-grey light',
		},
		{
			id: 2,
			value: 'Approve',
			dbValue: 'accepted',
			class: 'dropdown-grey light',
		},
		{
			id: 3,
			value: 'Reject',
			dbValue: 'rejected',
			class: 'dropdown-grey light',
		},
	],
	shareCodeActions: [
		{
			id: 1,
			value: 'Pending',
			dbValue: 'pending',
			class: 'dropdown-grey light',
		},
		{
			id: 2,
			value: 'Approve',
			dbValue: 'accepted',
			class: 'dropdown-grey light',
		},
		{
			id: 3,
			value: 'Reject',
			dbValue: 'rejected',
			class: 'dropdown-grey light',
		},
	],
	referenceStatus: [
		{
			id: 0,
			value: 'Select',
			dbValue: 'Select',
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 1,
			value: 'All Evidence Received',
			dbValue: 'All Evidence Received',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Request Sent',
			dbValue: 'RequestSent',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'In Dispute',
			dbValue: 'In Dispute',
			class: 'dropdown-grey',
		},
		{
			id: 4,
			value: 'Documents received',
			dbValue: 'Documents Received',
			class: 'dropdown-grey',
		},
		{
			id: 5,
			value: 'Unable to Verify',
			dbValue: 'Unable To Verify',
			class: 'dropdown-grey',
		},
		{
			id: 6,
			value: 'In Discussion',
			dbValue: 'In Discussion',
			class: 'dropdown-grey',
		},
	],

	reasonsForLeaving: [
		{
			id: 0,
			value: 'Select Reason',
			dbValue: 'Select Reason',
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 1,
			value: 'Fixed Term Contract',
			dbValue: 'Fixed Term Contract',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			value: 'Dismissal',
			dbValue: 'Dismissal',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			value: 'Redundancy',
			dbValue: 'Redundancy',
			class: 'dropdown-grey',
		},
		{
			id: 4,
			value: 'Family Commitment',
			dbValue: 'Family Commitment',
			class: 'dropdown-grey',
		},
		{
			id: 5,
			value: 'New Employment',
			dbValue: 'Seek New Employment Opportunity',
			class: 'dropdown-grey',
		},
		{
			id: 6,
			value: 'Other',
			dbValue: 'Other',
			class: 'dropdown-grey',
		},
	],
};

export const suspendReasonList = [
	{
		reason: 'Expired Right to Work and ID Verification',
		checked: false,
	},
	{
		reason: 'Not providing docs/information for BS7858',
		checked: false,
	},
	{
		reason: 'SIA expired',
		checked: false,
	},
	{
		reason: 'Time failed - 12 weeks BS7858 (not completed in 12 weeks)',
		checked: false,
	},
	{
		reason: '3 strikes',
		checked: false,
	},
	{
		reason: 'Breach of community guidelines (behaviour/performance)',
		checked: false,
	},
	{
		reason: 'Other',
		checked: false,
	},
];

export const dynamicTableData = {
	all: [
		{
			headings: ['Submitted', 'Assigned', 'Tags', 'Notes'],
		},
		{
			values: ['overallSubmission', 'assigned', 'tags', 'notes'],
		},
	],
	toTopUp: [
		{
			headings: ['Submitted', 'Assigned', 'Tags', 'Notes'],
		},
		{
			values: ['overallSubmission', 'assigned', 'tags', 'notes'],
		},
	],
	toClear: [
		{
			headings: ['Submitted', 'Assigned', 'Tags', 'Notes'],
		},
		{
			values: ['overallSubmission', 'assigned', 'tags', 'notes'],
		},
	],
	unassigned: [
		{ headings: ['Submitted', 'Assigned', 'Tags', 'Notes'] },
		{ values: ['overallSubmission', 'assigned', 'tags', 'notes'] },
	],
	awaitingSubmission: [
		{
			headings: ['Assigned', 'Tags', 'Notes'],
		},
		{
			values: ['assigned', 'tags', 'notes'],
		},
	],
	ambassadors: [
		{
			headings: [],
		},
		{
			values: [],
		},
	],
};

export const bucketDataFile = [
	{
		text: 'All Applicants',
		icon: allUsers,
		iconSelected: allSelected,
		iconDark: allDark,
		selected: true,
		value: 'all',
	},
	{
		text: 'To Top Up',
		icon: toTopUp,
		iconSelected: toTopUpBlue,
		iconDark: toTopUpDark,
		selected: false,
		value: 'toTopUp',
	},
	{
		text: 'To Clear',
		icon: toClear,
		iconSelected: toClearBlue,
		iconDark: toClearDark,
		selected: false,
		value: 'toClear',
	},
	{
		text: 'Unassigned',
		icon: unassigned,
		iconSelected: unassignedBlue,
		iconDark: unassignedDark,
		selected: true,
		value: 'unassigned',
	},
	{
		text: 'Awaiting Submission',
		icon: awaiting,
		iconSelected: awaitingBlue,
		iconDark: awaitingDark,
		selected: true,
		value: 'awaitingSubmission',
	},
];

export const disclosureQuestions = [
	{
		question:
			'Do you have any criminal convictions in any country (including traffic offences)?',
		type: 'conviction',
	},
	{
		question:
			'Have you ever been charged in any country with a criminal offence for which you have not yet been tried in court (including traffic offences)?',
		type: 'awaiting trial',
	},
	{
		question:
			'Have you received any CCJs, made any Individual Voluntary Arrangements, or declared bankruptcy in the past 5 years?',
		type: 'bankruptcy',
	},
	{
		question:
			'In times of either peace or war have you ever been involved in, or suspected of involvement in, war crimes, crimes against humanity or genocide?',
		type: 'war crimes',
	},
	{
		question:
			'Have you ever been involved in, supported, or encouraged terrorist activities in any country? Have you ever been a member of, or given support to an organisation that has been concerned in terrorism?',
		type: 'terrorism',
	},
	{
		question:
			'Have you ever, by any means or medium, expressed views that justify or glorify terrorist violence or that may encourage others to terrorist acts or other serious criminal acts? ',
		type: 'terrorist views',
	},
	{
		question:
			'Have you engaged in any other activities that might indicate that you may not be considered a person of good character?',
		type: 'bad character',
	},
];

export const consentData = [
	{
		heading: 'SCREENING',
		bodyText:
			'The applicant consents to being screened in accordance with BS 7858 and will provide information as required. Any offer of employment is subject to satisfactory screening and the applicant acknowledges that any false statements or omissions could lead to termination of employment.',
		list: [],
		small: false,
	},
	{
		heading: 'AUTHORISATION',
		bodyText:
			'Deploi will use the information you have provided, together with any information we obtain with your consent from third parties for assessing your suitability for employment. It may be necessary to disclose your information to our agents, where required by law, and other service providers. By signing below, you agree to this process.',
		list: [],
		small: false,
	},
	{
		heading: 'CRIMINAL BACKGROUND CHECK',
		bodyText:
			'You are applying for a position of trust and in the event of being offered employment by the company we may apply for a criminal background check. However, having a criminal record does not necessarily bar you from employment. For more information ask a member of staff for a copy of the appropriate criminal record body code of practice and/or our company policy statement regarding ex‐offenders. Criminal record information is treated in a sensitive way and is restricted to those who need to see it to make a recruitment decision. By signing this document, upon request you agree to provide a copy of the criminal record certificates. Criminal background information is not retained.',
		list: [],
		small: false,
	},
	{
		heading: 'DECLARATION',
		bodyText:
			'I understand that employment with this company is subject to references and screening in accordance with BS7858.',
		list: [],
		small: false,
	},
	{
		heading: 'SCREENING',
		bodyText:
			'The applicant consents to being screened in accordance with BS 7858 and will provide information as required. Any offer of employment is subject to satisfactory screening and the applicant acknowledges that any false statements or omissions could lead to termination of employment.',
		list: [],
		small: false,
	},
	{
		heading: '',
		bodyText:
			'I confirm that the information I have provided on my application is true and complete to the best of my knowledge. I understand and agree that I will be subject to any or all of the following checks:',
		list: [
			'• Address check',
			'• Financial probity check which the company will retain on file',
			'• ID verification checks',
			'• Academic/professional qualification check',
			'• Employment history, including any periods of unemployment/self‐employment and any gaps',
			'• Criminal background check',
		],
		small: true,
	},
	{
		bodyText:
			'I authorise the company or its agents to approach government agencies, former employers, educational establishments, for information relating to and verification of my employment or unemployment history, a consumer information search and ID check with a credit reference agency, which will keep a record of those searches in line with current legislation.',
		list: [],
		heading: '',
		small: true,
	},
	{
		bodyText:
			'Furthermore, I agree to your holding of this information and consent to you processing sensitive personal data about me (to include, but not limited to health, credit status, ethnic origin, qualifications and criminal offences). I also consent to the transfer of this information to my current and future potential employers where this is necessary (including companies outside the UK).',
		list: [],
		heading: '',
		small: true,
	},
	{
		bodyText:
			'I declare that any documents that I provide as proof of my identity, proof of address, and any other documents that I provide are genuine and that any falsified documents may be reported to the appropriate authority. I understand that it may be a criminal offence to attempt to obtain employment by deception and that any misrepresentation, omission of a material fact or deception will be cause for immediate withdrawal of any offer of employment.',
		list: [],
		heading: '',
		small: true,
	},
	{
		bodyText:
			'I accept that I may be required to undergo a medical examination where requested by the company.',
		list: [],
		heading: '',
		small: true,
	},
	{
		bodyText:
			'I understand and agree that if so required, I will make a statutory declaration in accordance with the provisions of the Statutory Declarations Act 1835, in confirmation of previous employment or unemployment.',
		list: [],
		heading: '',
		small: true,
	},
	{
		bodyText:
			'I understand that any false statement or omission to the company or its representatives may render me liable to dismissal without notice.',
		list: [],
		heading: '',
		small: true,
	},
];
