import React, { FC } from 'react';
import { IOrganization } from '../../../types/interfaces/reference.interface';
import './organisation-info.styles.scss';
import { WorkHistoryItem } from '../../../types/interface';

type Props = {
	organisation?: IOrganization;
	activityItem?: WorkHistoryItem;
};

const OrganisationInfo: FC<Props> = ({ organisation, activityItem }) => {
	return (
		<div className='organisation-info-container'>
			{organisation && (
				<>
					<div className='organisation-info-container-name'>
						<p>
							<span className='name'>{organisation.company}</span>
							:&nbsp;
							{organisation.region}
						</p>
					</div>
					<div>
						{organisation.address &&
							organisation.postcode &&
							organisation.city && (
								<p>
									{organisation.address}&nbsp;
									{organisation.city}&nbsp;
									{organisation.postcode}
								</p>
							)}
					</div>
					<div>
						<p>{organisation.contactNumber}</p>
						<p>{organisation.email}</p>
					</div>
				</>
			)}
			{activityItem && (
				<>
					<div className='organisation-info-container-name'>
						<p>
							<span className='name'>{activityItem.name}</span>
						</p>
					</div>
					<div>
						<p>
							{activityItem?.address?.streetAddress}&nbsp;
							{activityItem?.address?.city}&nbsp;
							{activityItem?.address?.postcode}
						</p>
					</div>
					{/* <div>
                        <p>{activityItem.}</p>
                        <p>{activityItem.email}</p>
                    </div> */}
				</>
			)}
		</div>
	);
};

export default OrganisationInfo;
