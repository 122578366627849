import React, { FC } from 'react';
import './profile-employment-item-top.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import filesIcon from '../../../assets/icons/files.svg';
import {
	backFromActivity,
	setShowActivity,
} from '../../../redux/Profile/profile.actions';
import { useDispatch } from 'react-redux';
import { WorkHistoryItem } from '../../../types/interface';
import verified from '../../../assets/icons/activity/verified.svg';
import notVerified from '../../../assets/icons/activity/not-verified.svg';

type Props = {
	item: WorkHistoryItem;
};

const ProfileEmploymentItemTop: FC<Props> = ({ item }) => {
	const dispatch = useDispatch();
	const openActivity = () => {
		const data = {
			showActivity: true,
			activityItem: item,
		};
		dispatch(backFromActivity(false));
		dispatch(setShowActivity(data));
	};

	return (
		<div className='profile-employment-top'>
			<div className='profile-employment-top-section'>
				<div className='section-left'>
					<div>
						<p className='section-type' onClick={openActivity}>
							{utils.capitalizeFirstLetter(
								utils.removeHyphen(item.type),
							)}
						</p>
						{item.name && (
							<div className='section-name-container'>
								<p className='section-name'>{item.name}</p>
								<img
									src={
										item?.reference?.addedManually
											? notVerified
											: verified
									}
									alt='verified'
								/>
							</div>
						)}
					</div>

					<div className='section-files'>
						<img src={filesIcon} alt='files' />
						<p>
							x
							{item.files &&
								item.files.filter(
									(file) => file.deletedAt === null,
								).length}
						</p>
						{item?.reference?.status && (
							<div className='reference-status'>
								<p>
									{utils.capitalizeFirstLetterOfEach(
										item?.reference?.status,
									)}
								</p>
							</div>
						)}
					</div>
				</div>
				<div className='section-right'>
					<p className='section-dates'>
						{utils.formatDateForUser(item.startAt)} -{' '}
						{item.endAt
							? utils.formatDateForUser(item.endAt)
							: 'Current'}
					</p>
					{!item.endAt && (
						<p className='section-dates verified'>
							Last Verified:{' '}
							{utils.formatDateForUser(item.verifiedAt)}
						</p>
					)}
				</div>
				{item.overlap && (
					<div className='overlap'>
						<p>Overlap</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfileEmploymentItemTop;
