import React, { FC, useState } from 'react';
import './activity-contact.styles.scss';
import ActivityItem from '../activity-item/activity-item.component';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';
import NumberInput from '../../general/number-input/number-input.component';
import emailIcon from '../../../assets/icons/activity/send-email.svg';
import notVerified from '../../../assets/icons/activity/not-verified.svg';
import Modal from '../../modals/modal/modal.component';
import ActivityEmailModal from '../activity-email-modal/activity-email-modal.component';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import contactCross from '../../../assets/icons/contact-employer-cross.svg';
import { ActivityContactProps } from './activity-contact.models';
import { FormStateType } from '../../../types/enum';

const ActivityContact: FC<ActivityContactProps> = ({
	activityItem,
	updateActivity,
	formStateType,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useDispatch();
	const handleGetNumber = (type: string, val: string) => {
		const payload = {
			reference: {
				[type]: Number(val),
			},
		};
		return updateActivity(payload);
	};

	const handleOpenEmailModal = () => {
		if (
			activityItem &&
			activityItem.type === 'employed' &&
			!activityItem.jobTitle
		) {
			dispatch(
				setAlert({
					type: 'error',
					message: 'Job title is required when applicant is employed',
					isVisible: true,
				}),
			);
			return;
		}
		setOpenModal(true);
	};

	return (
		<div className='activity-contact-section-outer'>
			{activityItem && !activityItem.canContactEmployer && (
				<div className='contact'>
					<img src={contactCross} alt='cross' />
					<p>Do not contact Employer</p>
				</div>
			)}
			<div className='activity-contact-section'>
				{(formStateType === FormStateType.EMPLOYED ||
					formStateType === FormStateType.STUDYING ||
					formStateType === FormStateType.VOLUNTEER) && (
					<>
						<ActivityItem
							label='Email'
							icon={
								activityItem &&
								activityItem.reference &&
								activityItem.reference.addedManually
									? notVerified
									: emailIcon
							}
							emitFunction={handleOpenEmailModal}
							email={activityItem ? activityItem.email : ''}
						>
							<ProfileDisplayItem
								label=''
								value={
									activityItem && activityItem.email
										? activityItem.email
										: ''
								}
							/>
						</ActivityItem>

						<ActivityItem label='Phone Number'>
							<ProfileDisplayItem
								label=''
								value={
									activityItem && activityItem.contactNumber
										? activityItem.contactNumber
										: ''
								}
							/>
						</ActivityItem>
					</>
				)}

				<ActivityItem label='Written Attempts'>
					<NumberInput
						getVal={handleGetNumber}
						label=''
						type='writtenAttempts'
						initialVal={
							activityItem?.reference?.writtenAttempts
								? activityItem?.reference?.writtenAttempts
								: '0'
						}
					/>
				</ActivityItem>

				<ActivityItem label='Verbal Attempts'>
					<NumberInput
						getVal={handleGetNumber}
						label=''
						type='verbalAttempts'
						initialVal={
							activityItem?.reference?.verbalAttempts
								? activityItem?.reference?.verbalAttempts
								: '0'
						}
					/>
				</ActivityItem>
				{openModal && activityItem && (
					<Modal
						title='Send Email'
						setShowModal={setOpenModal}
						showModal={openModal}
						modalContentHeight='40vh'
					>
						<ActivityEmailModal
							setOpenModal={setOpenModal}
							activityItem={activityItem}
						/>
					</Modal>
				)}
			</div>
		</div>
	);
};

export default ActivityContact;
