import React, { useEffect, useState, FC } from 'react';
import './profile.display-item.styles.scss';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	label: string;
	value: string | Date | null;
	na?: boolean;
	emitVal?: any;
	toolTip?: string;
};

const ProfileDisplayItem: FC<Props> = ({
	label,
	value,
	na = false,
	emitVal = null,
	toolTip = '',
}) => {
	const [clickClass, setClickClass] = useState<string>('');

	const handleEmit = () => {
		if (emitVal) {
			emitVal(value, label);
		}
	};

	useEffect(() => {
		if (emitVal) {
			setClickClass('clickable');
		}
	}, [emitVal]);

	return (
		<div className='profile-display-item'>
			<p className='orka-profile-label'>{label}</p>
			<TooltipLg title={toolTip}>
				<p
					className={
						value === 'None Submitted' || value === 'N/A' || na
							? 'orka-profile-value na'
							: `orka-profile-value ${clickClass}`
					}
					onClick={handleEmit}
				>
					{value instanceof Date
						? value.toDateString() // Format Date case
						: value}
				</p>
			</TooltipLg>
		</div>
	);
};

export default ProfileDisplayItem;
