import React, { FC, useState } from 'react';
import './dashboard-table-header.styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

const DashboardTableHeader: FC = () => {
	const dynamicTableData = useSelector(
		(state: RootState) => state.dashboard.dynamicTableData,
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [values, setValues] = useState<{ value: string; class: string }[]>([
		{ value: 'Clearance', class: 'col col-sm' },
		{ value: 'Candidate', class: 'col col-lg email' },
		{ value: 'City/Region', class: 'col col-md text-left' },
	]);

	const getHeadingClass = (val: string) => {
		switch (val) {
			case 'Assigned':
			case 'Notes':
			case 'Activity':
				return 'col-sm center';
			case 'Tags':
				return 'col-md center';
			default:
				return 'col-md';
		}
	};

	return (
		<div className='table-header tablet-hide'>
			{values.map((val) => {
				return (
					<div key={val.value} className={val.class}>
						<p>{val.value}</p>
					</div>
				);
			})}
			{dynamicTableData && dynamicTableData.length > 0 && (
				<>
					{dynamicTableData[0].headings.map((heading: string) => {
						return (
							<div
								key={heading}
								className={getHeadingClass(heading)}
							>
								<p>{heading}</p>
							</div>
						);
					})}
				</>
			)}
			{/*{staticDash ? (*/}
			{/*    <div className='dashboard-table-header static-header-container'>*/}
			{/*        <DashboardTableHeaderStatic />*/}
			{/*    </div>*/}
			{/*) : (*/}
			{/*    <div className='dashboard-table-header dynamic-header-container'>*/}
			{/*        <DashboardTableHeaderDynamic />*/}
			{/*    </div>*/}
			{/*)}*/}
		</div>
	);
};

export default DashboardTableHeader;
