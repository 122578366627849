import React, { useState, FC, Dispatch, SetStateAction } from 'react';
import './sign-up-email.styles.scss';
import Input from '../login-input/login-input.component';
import email from '../../../assets/icons/login/email.svg';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as apiAuth from '../../../api/api.auth';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';

type Props = {
	setShowEmail: Dispatch<SetStateAction<boolean>>;
	setEmail: Dispatch<SetStateAction<string>>;
};

const SignUpEmail: FC<Props> = ({ setShowEmail, setEmail }) => {
	const dispatch = useDispatch();

	const [val, setVal] = useState<string>('');

	const handleCheckEmail = () => {
		const payload = {
			email: val,
		};

		return apiAuth
			.claimInvite(payload)
			.then(() => {
				setShowEmail(false);
				setEmail(val);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Invite not found for this email',
						isVisible: true,
					}),
				);
				setVal('');
			});
	};

	const getValue = (name: string, value: string) => {
		setVal(value.toLowerCase());
	};

	return (
		<div className='sign-up-email-container'>
			<Input
				name='email'
				getValue={getValue}
				value={val}
				placeholder='Email'
				icon={email}
				type='email'
			/>

			<div className='check-email-button'>
				<OrkaButton
					buttonContent='Next'
					emitClicked={handleCheckEmail}
					disabled={!val}
				></OrkaButton>
			</div>
		</div>
	);
};

export default SignUpEmail;
