import { createStore } from 'redux';
import rootReducer from './rootReducer';

const persistedState = localStorage.getItem('reduxState')
	? JSON.parse(localStorage.getItem('reduxState'))
	: {};

const store = createStore(
	rootReducer,
	persistedState,
	window.__REDUX_DEVTOOLS_EXTENSION__ &&
		window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const setLocalStorage = () => {
	return Object.keys(store.getState())
		.filter((key) => !key.includes('profile'))
		.reduce((cur, key) => {
			return Object.assign(cur, { [key]: store.getState()[key] });
		}, {});
};

store.subscribe(() => {
	localStorage.setItem('reduxState', JSON.stringify(setLocalStorage()));
});

export default store;
