import React, {
	useState,
	useEffect,
	FC,
	Dispatch,
	SetStateAction,
	FormEvent,
} from 'react';
import './login-inputs-section.styles.scss';
import email from '../../../assets/icons/login/email.svg';
import password from '../../../assets/icons/login/password.svg';
import { useDispatch } from 'react-redux';
import { clearProfileData } from '../../../redux/Profile/profile.actions';
import { resetDashboardState } from '../../../redux/Dashboard/dashboard.actions';
import Input from '../login-input/login-input.component';
import * as authApi from '../../../api/api.auth';
import { setAlert } from '../../../redux/General/general.actions';
import { Link } from 'react-router-dom';

interface Credentials {
	email: string;
	password: string;
}

type Props = {
	setLoginSubmitted: Dispatch<SetStateAction<boolean>>;
	setCredentials: Dispatch<SetStateAction<Credentials>>;
};

const LoginInputsSection: FC<Props> = ({
	setLoginSubmitted,
	setCredentials,
}) => {
	const dispatch = useDispatch();

	const [state, setState] = useState({
		email: '',
		password: '',
	});

	useEffect(() => {
		dispatch(clearProfileData());
		dispatch(resetDashboardState());
	}, [dispatch]);

	const getValue = (name: keyof Credentials, value: string) => {
		setState({
			...state,
			[name]: value.trim(),
		});
	};

	const handleLogin = (e: FormEvent) => {
		e.preventDefault();
		authApi
			.login(state)
			.then((res) => {
				if (res) {
					if (res.status === 404) {
						dispatch(
							setAlert({
								type: 'error',
								message: 'User Not Found',
								isVisible: true,
							}),
						);
						return;
					}
					setCredentials(state);
					setLoginSubmitted(true);
				} else {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Invalid credentials',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	return (
		<form className='login-inputs-section' onSubmit={handleLogin}>
			<div className='inputs'>
				<Input
					name='email'
					getValue={getValue}
					value={state.email}
					placeholder='Email Address'
					icon={email}
					type='email'
				/>
				<Input
					name='password'
					getValue={getValue}
					value={state.password}
					placeholder='Password'
					icon={password}
					type='password'
				/>
			</div>

			<div className='login-buttons'>
				<button type='submit' className='orka-btn login-button'>
					Login
				</button>
			</div>

			<div className='forgot-password'>
				<Link to='/forgot-password'>Forgot Password?</Link>
			</div>
		</form>
	);
};

export default LoginInputsSection;
